import React, { Component } from "react";
import ReactTable from "react-table";
import { Button } from "semantic-ui-react";
import isEqual from 'lodash.isequal'
import "react-table/react-table.css";

import { patchPoi, deletePoi } from "../../actions/actions_pois";

window.mapboxgl.accessToken = 'pk.eyJ1Ijoib3V0ZG9vcm1hcHBpbmdjb21wYW55IiwiYSI6ImNqYmh3cDdjYzNsMnozNGxsYzlvMmk2bTYifQ.QqcZ4LVoLWnXafXdjZxnZg';
const SOURCE_NAME = 'pois'

let eventListenersAdded = false;

class POIsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            map: null,
            activePopup : false
        }
    }

    componentDidMount() {
        const map = new window.mapboxgl.Map({
            container: 'poi-map',
            style: 'mapbox://styles/mapbox/outdoors-v11',
            center : [-0.5666644, 49.333332],
            zoom : 7
        })
        this.setState({ map })

        // NOTE: component seems to mount twice, need to make sure the event listeners only get added once
        if (!eventListenersAdded) {
            document.addEventListener('click', (e) => {
                // listen for map approved and map draft
                if (e.target && e.target.className.includes('map-approve-btn')) {
                    this.patchPoi(e, 'approved')
                } else if (e.target && e.target.className.includes('map-draft-btn')) {
                    this.patchPoi(e, 'draft')
                } else if (e.target && e.target.className.includes('map-del-btn')) {
                    const btn = e.target
                    this.deletePoi(JSON.parse(btn.parentNode.querySelector('#poi-data').innerHTML));
                }
            })
            eventListenersAdded = true;
        }
    }

    patchPoi = (e, status) => {
        const btn = e.target
        const feature = JSON.parse(btn.parentNode.querySelector('#poi-data').innerHTML)

        if (typeof feature.properties.image_urls === 'string') { feature.properties.image_urls = JSON.parse(feature.properties.image_urls) }

        const newFeature = {
            type: 'Feature',
            geometry: { ...feature.geometry },
            properties: {
                ...feature.properties,
                status
            }
        }
        // delete circle-color prop used for rendering only
        delete newFeature.properties['circle-color']

        this.props.dispatch(patchPoi(newFeature, feature.properties.user_id, this.props.auth.token, 'dashboard'))
    }

    componentWillReceiveProps(nextProps) {
        const { map } = this.state
        if (this.props.allPoisFetched && !isEqual(this.props.allPois, nextProps.allPois)) {
            map.getSource(SOURCE_NAME) && map.getSource(SOURCE_NAME).setData({
                type: 'FeatureCollection',
                features: nextProps.allPois.map((feature) => {
                    return {
                        ...feature,
                        properties: {
                            ...feature.properties,
                            'circle-color': feature.properties.status === 'approved' ? 'green' : 'red'
                        }
                    }
                })
            })
            if (this.state.activePopup) {
                this.state.activePopup.remove()
                this.setState({ activePopup : false })
            }
        }

        if (this.props.allPois && nextProps.allPois && nextProps.allPoisFetched) {
            map.on('load', (e) => {
                if (!map.getSource(SOURCE_NAME)) {
                    map.addSource(SOURCE_NAME, {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: nextProps.allPois.map((feature) => {
                                return {
                                    ...feature,
                                    properties: {
                                        ...feature.properties,
                                        'circle-color': feature.properties.status === 'approved' ? 'green' : 'red'
                                    }
                                }
                            })
                        }
                    })
                    map.addLayer({
                        id: SOURCE_NAME,
                        type: 'circle',
                        source: SOURCE_NAME,
                        paint: {
                            'circle-radius': 10,
                            'circle-color': ['get', 'circle-color']
                        }
                    })

                    map.on('click', SOURCE_NAME, (e) => {
                        const feature = e.features[0]
                        this.openPoiPopup(feature);
                    })
                }
            })
        }
    }

    openPoiPopup = (feature) => {
        const { properties, geometry } = feature
        const { feature_id, user_id, title, status, description } = properties
        let { image_urls } = properties;
        if (typeof image_urls === 'string') { image_urls = JSON.parse(image_urls); }
        const images = image_urls.length > 0 ? image_urls.map((url) => `<img src="${url}" style="max-width: 100px; height: auto; margin : 2px"/>`).join('') : '';

        if (this.state.activePopup) { this.state.activePopup.remove(); }

        const popup = new window.mapboxgl.Popup({ anchor: 'left' })
            .setLngLat(geometry.coordinates).setHTML(`
                <div class="poi-popup" style="max-width: 500px; min-width : 300px; display: flex; flex-direction: column;">
                    <p><b>Title</b>: ${title}</p>
                    <p><b>Description</b>: ${description}</p>
                    <p><b>ID</b>: ${feature_id}</p>
                    <p><b>User ID</b>: ${user_id}</p>
                    <p><b>Status</b>: ${status}</p>
                    <div class="poi-images">
                        ${images}
                    </div>
                    <div style="width : 100%; text-align : center">
                        <button class="map-approve-btn" style="margin-top: 5px; margin-bottom: 5px;">Approve</button>
                        <button class="map-draft-btn">Draft</button>
                        <button class="map-del-btn">Delete</button>
                        <script id="poi-data" type="application/json">${JSON.stringify(feature)}</script>
                    </div>
                </div>
            `)
            .addTo(this.state.map);
            this.setState({ activePopup : popup }); 
    }

    deletePoi(feature) {
        if (window.confirm('Are you sure you would like to delete this POI?')) {
            this.props.dispatch(deletePoi(feature, feature.properties.user_id))
        }
    }

    render() {
        const tableData = this.props.allPois;
        const columns = [
            { Header: "ID", accessor: "properties.feature_id", width : 60 },
            { Header: "User ID", accessor: "properties.user_id", width : 60 },
            { Header: "Title", accessor: "properties.title" },
            { Header: "Description", accessor: "properties.description" },
            { Header: "Status", accessor: "properties.status", width : 100 },
            {
                Header: "Coordinates", accessor: "geometry.coordinates", Cell: row => (
                    <div>{row.value.join()}</div>
                )
            },
            {
                // Header: "Edit",
                accessor: "properties.feature_id",
                filterable: false,
                Cell: row => (
                    <div className="poi-edit-buttons">
                        <Button onClick={() => {
                            var original = JSON.parse(JSON.stringify(row.original));
                            original.properties.status = 'approved';
                            this.props.dispatch(patchPoi(original, original.properties.user_id, this.props.auth.token, 'dashboard'))
                        }}> Approve </Button>
                        <Button onClick={() => {
                            var original = JSON.parse(JSON.stringify(row.original));
                            original.properties.status = 'draft';
                            this.props.dispatch(patchPoi(original, original.properties.user_id, this.props.auth.token, 'dashboard'))
                        }}> Draft </Button>
                        <Button onClick={() => {
                            var original = JSON.parse(JSON.stringify(row.original));
                            window.scrollTo(0, 0)
                            this.openPoiPopup(original);
                        }}> View </Button>
                        <Button onClick={() => {
                            var original = JSON.parse(JSON.stringify(row.original));
                            this.deletePoi(original);
                        }}> Delete </Button>
                    </div>
                )
            }
        ];

        return (
            <div className="pois-container" style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <h3 className="ui dividing header"> Edit POIs </h3>
                <div
                    className="map-wrap"
                    style={{ flex: 1 }}
                >
                    <div
                        id="poi-map"
                        style={{ height: 500 }}
                    ></div>
                </div>
                <div
                    style={{ flex: 1 }}
                >
                    <ReactTable
                        data={tableData}
                        columns={columns}
                        filterable={true}
                        defaultFilterMethod={(filter, row, column) => {
                            const id = filter.pivotId || filter.id;
                            return row[id] !== undefined
                            ? String(row[id])
                            .toLowerCase()
                            .indexOf(filter.value.toLowerCase()) > -1
                            : true;
                        }}
                        className="feature-table"
                        defaultSorted={[
                            {
                                id: 'properties.user_id',
                                desc: false
                            },
                            {
                                id: 'properties.feature_id',
                                desc: false
                            }
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default POIsList;
