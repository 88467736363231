import Constants from '../Constants.js';
import { setMapLoadStatus } from './actions_map'

export const GET_LINES = 'GET_LINES';
export const SET_ACTIVE_LINE = 'SET_ACTIVE_LINE';
export const LINE_ERROR = 'LINE_ERROR';

export function getLines() {
  return (dispatch) => {

    // fetch(window.location.href+'data/frontline_v1.json')
    return fetch(`${process.env.REACT_APP_BACKEND}/front_lines`, {
      method: 'GET',
      // headers: header
    })
    .then(response => Constants.handleErrors(response, dispatch, lineError))
    .then(json => {
      dispatch(addLines(json))
    })
    .catch(error => dispatch(lineError(error)));
  }
}


export function addLines(data) {
  return {
    type : GET_LINES,
    payload : data
  }
}

export function setActiveLines(active) {
  return {
    type : SET_ACTIVE_LINE,
    payload : active
  }
}

export function lineError(error) {
  return {
    type : LINE_ERROR,
    payload : error
  }
}
