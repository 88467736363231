import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import distance from '@turf/distance'; 
import { Segment, Form } from 'semantic-ui-react'
import Slider, { Range } from 'rc-slider';

import { addToMap, setFrontPageMap, setPoiWindow } from '../../actions/actions_map';
import { updateTempLayer } from './lib/MapLayerFunctions';
import { defaultFeatureTypes } from './lib/MapUtil';


class DrawFeature extends Component {
  constructor(props) {
    super(props);
    this.initialLoad = false;
    this.currentFeature = false;
    this.temporaryFeatures = [];
    this.map = false;
    this.state = {
      draw : false,
      editFeature : false,
      lineDistance : false,
      activeOpacity : 4,
      //drawPopup: false,
    }
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.mapLoaded && !this.initialLoad) {
       this.map = nextProps.map;
       this.initialLoad = true;
    }

    if (this.props.addingPoint !== nextProps.addingPoint || this.props.savingPoint !== nextProps.savingPoint) {

        if (this.state.draw) {
            this.map.removeControl(this.state.draw);
            this.setState({ draw: false, lineDistance : false });
        }

        if (nextProps.addingPoint) {
            this.addMapboxDraw(nextProps)
            this.setState({ editFeature : false });
        }
    }

    if (nextProps.poiWindowData) { 
        this.setState({ editFeature : false, lineDistance : false }) 
    }
  }


  addMapboxDraw = (nextProps) => {
    //console.log(nextProps.addingPoint, nextProps.saving, ' nextprops.adding point in mapbox draw function');
    const { addingPoint, savingPoint } = nextProps;
    const map = this.map;

    if (map && addingPoint) {
      var Draw = new MapboxDraw({
        displayControlsDefault: false
      });
      map.addControl(Draw, 'top-left');
      this.props.dispatch(setFrontPageMap({ mbDraw: Draw, map }))
      // ^^ Need this so that EditUserCreatedUnitsTable has access to mbdraw 
      if (addingPoint === 'point') {
        Draw.changeMode('draw_point');
      } else if (addingPoint === 'line' || addingPoint === 'lineMeasure') {
        Draw.changeMode('draw_line_string');
      } else if (addingPoint === 'polygon') {
        Draw.changeMode('draw_polygon');
      }
      this.setState({ draw: Draw });

      if (addingPoint === 'edit-units') {
        // don't register below event listeners if editing unit from front page table
        return
      }

      map.on('draw.update', (e) => {
        var newFeature = JSON.parse(JSON.stringify(this.state.currentFeature));
        newFeature.geometry.coordinates = e.features[0].geometry.coordinates;
        this.setState({ currentFeature: newFeature });
        this.props.dispatch(setPoiWindow({
          geometry: e.features[0].geometry
        }))
      });

      map.on('draw.create', (e) => {
        const { addingPoint, savingPoint } = this.props;
        // Why does this fire so many times?
        //console.log(e);

        if (addingPoint === 'line' || addingPoint === 'polygon') {
            var newFeature = JSON.parse(JSON.stringify(defaultFeatureTypes[this.props.addingPoint]));

            newFeature.geometry.coordinates = e.features[0].geometry.coordinates;
            newFeature.properties.id = e.features[0].id;

            var geojson = {
              "type": "FeatureCollection",
              "features": [newFeature]
            };

            this.currentFeature = newFeature;
            this.addTempFeature(newFeature);
        } else if (addingPoint === 'point' && !savingPoint) {
            this.addMarker(e.features[0]);
        }
      });

      map.on('draw.selectionchange', (e) => {
        //console.log(e.features.length);

        if (this.props.savingPoint) {
            setTimeout(() => {
                if (e.features.length > 0) {
                  this.props.dispatch(setPoiWindow({
                    geometry: e.features[0].geometry
                  }))
                } else {
                  // that.state.drawPopup.remove();
                  // that.setState({drawPopup:false})
                }
            }, 100);
        } else if (this.props.addingPoint === 'lineMeasure') {
            if (e.features.length > 0) {
                let coords = e.features[0].geometry.coordinates;
                let totalDistance = 0;
                for (var i = 0; i < coords.length - 1; i++) {
                    let dist = distance(coords[i], coords[i + 1]);
                    totalDistance += dist;
                }
                this.setState({ lineDistance : (totalDistance * 1000).toLocaleString(undefined, { minimumFractionDigits : 2, maximumFractionDigits : 2 }) });
            }
        } 
      });
    }
  }

  addTempFeature(newFeature) {
    var existingTemporaryFeatures = JSON.parse(JSON.stringify(this.temporaryFeatures));

    if (existingTemporaryFeatures.findIndex(feature => feature.properties.id == newFeature.properties.id) === -1) {

        existingTemporaryFeatures.push(newFeature);
        updateTempLayer(this.map, existingTemporaryFeatures);
        this.temporaryFeatures = existingTemporaryFeatures;

        // If Poly, Line or Point tool, open editor
        if (!this.props.savingPoint) {
            this.setState({ editFeature : this.props.addingPoint, activeOpacity : 4 });
        }

        setTimeout(() => { 
            this.map.removeControl(this.state.draw) 
            this.setState({ draw: false });
            this.props.dispatch(addToMap([false, false]));
        }, 100);
    } 
  }

  addMarker(marker) {
    let el = document.createElement('div');
    el.className = 'marker';

    if (!this.currentPopup || this.currentPopup.options.feature.id !== marker.id) {
        var popup = new window.mapboxgl.Popup({
            anchor : 'center',
            offset : [-25, -15],
            closeOnClick : false,
            closeButton : false,
            feature : marker,
            className : 'marker',
        }).setLngLat(marker.geometry.coordinates)
        .setHTML(
            '<i class="fa fa-map-marker-alt"></i>'
        )
        .addTo(this.map);

        this.currentPopup = popup;
        this.currentFeature = marker;
        this.setState({ editFeature : 'point' });

        setTimeout(() => {
            this.map.removeControl(this.state.draw) 
            this.setState({ draw: false });
            this.props.dispatch(addToMap([false, false]));
        }, 100);
    }
  }

  changeFeatureStyle(value, type) {
    if (type === 'opacity') { this.setState({ activeOpacity : value * 10 }) }

    var existingTemporaryFeatures = JSON.parse(JSON.stringify(this.temporaryFeatures));
    const idx = existingTemporaryFeatures.findIndex(feature => feature.properties.id == this.currentFeature.properties.id);
    if (existingTemporaryFeatures[idx]) {
        existingTemporaryFeatures[idx].properties[type] = value;
        this.currentFeature.properties[type] = value;
        this.forceUpdate();
    }

   this.temporaryFeatures = existingTemporaryFeatures;

   this.map.getSource('poi-features-temporary').setData({
        type: 'FeatureCollection',
        features: existingTemporaryFeatures
   })
  }

  changeIcon(type) {
    this.currentPopup.setHTML(
        `<i class="fa ${type}"></i>`
    );
  }

  resetMeasureTool() {
    this.map.removeControl(this.state.draw);
    this.setState({ draw: false, lineDistance : "0" });
    this.addMapboxDraw(this.props);
  }

  closeMeasureTool() {
    this.props.dispatch(addToMap([false, false]));
  }

  render() {
    const { editFeature } = this.state;
    const currentFeature = this.currentFeature;

    const swatches = ['#d33030', '#763390', '#364398', '#3177af', '#197c68', '#328f41', '#FCBB36', '#F77A1B', 
                      '#e05a54', '#9E509D', '#5D6CB3', '#579ed6', '#25a69a', '#60be67', '#fbef52', '#F4AD1D',
                      '#ffffff', '#e9eaee', '#bdc0c6', '#81868b', '#3b4043', '#000000', '#5A413D', '#926d63'];

    const icons =  ['fa-map-marker-alt', 'fa-heart', 'fa-star', 'fa-check', 'fa-campground', 'fa-info-circle', 'fa-times', 'fa-bed', 'fa-hiking', 'fa-home']
    
    return ( 
        <div>
            { this.state.lineDistance ? 
                <div className="line-distance-container">
                   <div className="header-icons">
                       <i className="fa fa-times" onClick={() => this.closeMeasureTool()}></i>
                       <button onClick={() => this.resetMeasureTool()}><i className="fa fa-redo"></i> Start New </button>
                   </div>

                   <label> Distance </label>
                   <span className="distance"> { this.state.lineDistance } m </span>
                </div>
            : false }

            { editFeature ? 
                <Segment className="feature-edit-window" inverted>
                    <div className="poi-icon" onClick={() => this.setState({ editFeature : false })}>
                       <i className='fa fa-times'></i>
                    </div>
                    <Form inverted>
                        { currentFeature.geometry.type === 'LineString' ? 
                            <Form.Field>
                                <label> Color </label>
                                <div className="color-picker">
                                    <div className="swatches">
                                        {swatches.map(swatch => {
                                            return (
                                                <span className="swatch" key={swatch} style={{ background : swatch }} onClick={() => this.changeFeatureStyle(swatch, 'lineColor' )}>
                                                    { currentFeature.properties.lineColor === swatch ?
                                                        <i className="fa fa-check"></i>
                                                    : false }
                                                </span>
                                            )
                                        })}
                                    </div>
                                </div>
                            </Form.Field>
                        : false }

                        { currentFeature.geometry.type === 'Polygon' ? 
                            <div>
                                <Form.Field>
                                    <label> Fill Color </label>
                                    <div className="color-picker">
                                        <div className="swatches">
                                            {swatches.map(swatch => {
                                                return (
                                                    <span className="swatch" key={swatch} style={{ background : swatch }} onClick={() => this.changeFeatureStyle(swatch, 'fillColor')}>
                                                        { currentFeature.properties.fillColor === swatch ?
                                                            <i className="fa fa-check"></i>
                                                        : false }
                                                    </span>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Form.Field>

                                <Form.Field>
                                    <label> Border Color </label>
                                    <div className="color-picker">
                                        <div className="swatches">
                                            {swatches.map(swatch => {
                                                return (
                                                    <span className="swatch" key={swatch} style={{ background : swatch }} onClick={() => this.changeFeatureStyle(swatch, 'outlineColor')}>
                                                        { currentFeature.properties.outlineColor === swatch ?
                                                            <i className="fa fa-check"></i>
                                                        : false }
                                                    </span>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Form.Field>
                                <Form.Field>
                                    <label> Opacity </label>
                                    <div className="opacity-picker">
                                        <Slider
                                          min={0}
                                          max={10}
                                          onChange={(val) => this.changeFeatureStyle((val / 10), 'opacity')}
                                          value={this.state.activeOpacity}
                                        />
                                    </div>
                                </Form.Field>
                            </div>
                            
                        : false }

                        { currentFeature.geometry.type === 'Point' ? 
                            <Form.Field>
                                <label> Marker </label>
                                <div className="marker-options">
                                    {icons.map(icon => {
                                        return (
                                            <i key={icon} className={`fa ${icon}`} onClick={() => this.changeIcon(icon)}></i>
                                        )
                                    })}
                                </div>
                            </Form.Field>
                        : false }
                    </Form>
                </Segment>
            : false }
        </div>
    );
  }
}

export default DrawFeature;
