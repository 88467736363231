import React, { Component } from 'react';
import { Header, Button, Sidebar, Menu, Loader } from 'semantic-ui-react';
import { Translate, withLocalize } from "react-localize-redux";
import { getDiary, setActiveDiary } from '../../actions/actions_diaries';
import { updateSelectedUnits, setDiaryListInfo } from '../../actions/actions_map';
import { setActiveSidebarPanel } from '../../actions/actions_sidebar';
import { getUnitsDistinct } from '../../actions/actions_units';
import { countryLabelMap } from '../../Constants';
import Select from 'react-select';

import '../../css/UnitsList.scss';

class UnitsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentUnit : false,
            openUnits : {
                Division : false,
                Brigade : false
            }
        }
    }

    componentDidMount() {
        if (!this.props.euidList.length) {
            this.props.dispatch(getUnitsDistinct());
        }
    }

    openDiary(unit) {
        this.props.dispatch(getDiary(unit))
        this.props.dispatch(setActiveSidebarPanel('warDiaries'));
    }

    renderSingleUnit = (unit, unitCountry) => {
        const { openUnits } = this.state;

        const longName = this.props.activeLanguage.code==='en' ? unit.LN : unit.LNameFr
        const iconFont = this.props.mapSettings.iconType.replace('icons-', '');
        const unitIcon = unit.MF ? <div className={`unit-icon ${unitCountry}`} style={{ fontFamily : iconFont }}>{unit.MF}</div> : null
        const unitSize = unit.US && unit.US.toLowerCase()

        const iconClass = (unit.brigades   && openUnits.Division === unit.divID) ||
                          (unit.battalions && openUnits.Brigade  === unit.brigID) ?
                          'fa-caret-up' : 'fa-caret-down';

        const hasDiary = this.props.unitsWithDiaries.length > 0 && this.props.unitsWithDiaries.find(u => unit.EUnitID === u.EUnitID);

        return (
            <div className={`unit-wrap`} key={'units-list-' + unit.EUnitID}>
                <li
                    data-euid={unit.EUnitID}
                    className={`unit ${unitCountry} ${unitSize}`}
                    onClick={hasDiary ? () => this.openDiary(unit.EUnitID) : undefined }
                >
                    <span> {unitIcon} {longName} </span>
                    { hasDiary ?  <i className="fa fa-book-open"></i> : false }
                </li>

                { unit.battalions || unit.brigades ?
                    <i
                        className={`fa ${iconClass} unit-toggle`}
                        onClick={() => this.toggleUnits(unit)}
                    />
                : false }

                {/* Show Brigades */}
                { openUnits.Division === unit.divID && unit.brigades && unit.brigades.map(u => {
                    return (
                        this.renderSingleUnit(u, unitCountry)
                    )
                })}

                {/* Show Battalions */}
                { openUnits.Division === unit.divID                             && // If the Division is open
                  (openUnits.Brigade === unit.brigID || unit.brigID === "0")    && // And the Brigade is open, or there is no Brigade
                  unit.battalions && unit.battalions.map(u => {                    // And there are Battalions
                      return ( this.renderSingleUnit(u, unitCountry) )
                })}

            </div>
        )
    }

    toggleUnits = (unit) => {
        let { openUnits } = this.state;

        const size = unit.US;
        let value = size === 'Division' ? unit.divID : unit.brigID;
        openUnits[size] = openUnits[size] === value ? false : value;

        if (size === 'Division' && openUnits.Division === false) {
            openUnits.Brigade = false;
        }

        this.setState({ openUnits });
    }

    render() {
        const { allUnits, currentDate, activeLanguage, dispatch, euidList } = this.props;

        let options = [];
        if (allUnits[currentDate]) {
            const theseOptions = allUnits[currentDate].map(feature => {
                return { value: feature.properties.LN, label: feature.properties.LN }
            });
            options = options.concat(theseOptions);
        }

        return (
            <div className="units-list-container drawer-panel active">
                <Select
                    isMulti={true}
                    onChange={(e) => this.props.dispatch(updateSelectedUnits(e))}
                    options={options}
                    className="capitalize units-filter"
                    placeholder={this.props.translate('units')+'...'}
                />
                { euidList ?
                    <ul className="units-list">
                        {Object.keys(euidList).map(cntry => {

                            let {
                                country: activeCountry,
                                unit: activeUnit,
                                size: activeSize,
                                mouseOver: activeMouseOver,
                                flyOverInterval: activeFlyOverInterval,
                            } = this.props.diaryListInfo

                            activeCountry = activeCountry && activeCountry.toLowerCase()
                            const unitCountry = cntry.toLowerCase()
                            const headerIcon = activeCountry && activeCountry === unitCountry ? 'minus' : 'plus'

                            const onClickHeader = () => {
                                dispatch(setDiaryListInfo({
                                    country: unitCountry === activeCountry ? null : unitCountry,
                                    unit: activeUnit,
                                    size: activeSize,
                                    flyOverInterval: activeFlyOverInterval
                                }))
                                this.setState({ openUnits : { Division : false, Brigade : false } })
                            }

                            return (
                                <div key={`units-header ${unitCountry}`} className="country-wrap header">
                                    <li
                                        className={`header ${unitCountry}`}
                                        onClick={onClickHeader}
                                    >
                                        {countryLabelMap[cntry]} <i className={`fa fa-${headerIcon}`}></i>
                                    </li>

                                    {activeCountry === unitCountry && euidList[cntry].map(unit => {
                                        return this.renderSingleUnit(unit, unitCountry)
                                    })}
                                </div>
                            )
                        })}
                    </ul>
                : <Loader active style={{color: "#fff"}}> Loading </Loader> }
            </div>
        )
    }
}

export default withLocalize(UnitsList);
