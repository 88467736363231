import React, { Component } from 'react';
import { Button, Form, Grid, Header, Message, Segment, Input, Icon } from "semantic-ui-react";
import ReactTable from 'react-table'

import FeatureEdit from './FeatureEdit'

class UserFeatures extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedFeature : null,
    }
  }

  closeEditScreen = () => {
    this.setState({ selectedFeature: null });
  }

  selectFeature = (featureID) => {
    let { allUserCreatedUnits } = this.props;
    var thisFeature = false;
    var featureIdx = allUserCreatedUnits.findIndex(feature => feature.properties.feature_id === featureID)
    this.setState({selectedFeature:allUserCreatedUnits[featureIdx]});
  }

  render() {

      const { selectedFeature } = this.state;

      var tableData = this.props.allUserCreatedUnits.map(feature=> {
        return { ...feature.properties }
      });
      var columns = [
        { Header: 'Long Name',  accessor: 'LN' },
        { Header: 'Date',       accessor: 'Date' },
        { Header: 'Short',      accessor: 'SN',  maxWidth : 140 },
        { Header: 'ID',         accessor: 'UID', maxWidth : 100 },
        { Header: 'Country',    accessor: 'UC',  maxWidth : 100 },
        { Header: 'Status',     accessor: 'status' },
        { Header: 'Size',       accessor: 'US' },
        { Header: 'Type',       accessor: 'UT' },
        {
            Header: 'Edit',
            accessor: 'feature_id',
            maxWidth : 100 ,
            filterable : false,
            Cell : row => ( <Button onClick={() => this.selectFeature(row.value)}>Edit</Button> )
        }
      ]

      return (
        <div>
          <Button icon color='teal' labelPosition='left 'onClick={() => this.setState({selectedFeature: false})}>
            <Icon name="map marker" />
            Add New Feature
          </Button>

          <FeatureEdit
            selectedFeature={this.state.selectedFeature}
            visible={selectedFeature!==null}
            backToList={() => this.setState({selectedFeature:null})}
            {...this.props}
          />
          <hr />
          <ReactTable
              data={tableData}
              columns={columns}
              filterable={true}
              defaultFilterMethod={(filter, row, column) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined ? String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase())>-1 : true
              }}
              className="feature-table"
          />
        </div>
      )
  }
}

export default UserFeatures;
