import { P44_TOKEN } from '../Constants.js';
import { utf8ArrayToStr } from '../lib/utf8.js';

export const GEOJSON_ERROR = 'GEOJSON_ERROR';
export const GEOJSON_INFO = 'GEOJSON_INFO';

export function uploadGeoJson(geojson) {
    return async (dispatch) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/geojson`, {
                method: 'POST',
                body: JSON.stringify({
                    geojson,
                    token: window.localStorage.getItem(P44_TOKEN)
                }),
                headers: {
                    'Content-type': 'application/json'
                }
            })
            if (res.ok) {
                dispatch(geoJsonInfo({ msg: 'New GeoJSON file is being processed. Check the map in 5-10 minutes for your latest changes. If you don\'t see your latest changes then try another or get in touch with us.' }))
            } else {
                dispatch(geoJsonError(new Error('Something went wrong during the upload process. Please try again or get in touch with us.')))
            }
        } catch (error) {
            dispatch(geoJsonError(error))
        }
    }
}

export function geoJsonError(error) {
    return {
        type: GEOJSON_ERROR,
        payload: error
    }
}

export function geoJsonInfo(info) {
    return {
        type: GEOJSON_INFO,
        payload: info
    }
}
