import {
  GET_UNITS,
  SET_ACTIVE_UNITS,
  GET_UNITS_DISTINCT,
  SET_EUNITID_LIST,
  SET_CLICKED_UNIT,
} from '../actions/actions_units'

const initalState = {
  allUnits : {},
  activeUnits : [],
  distinctUnits: [],
  euidList: {},
  clickedUnit: null,
}
const units = (state = initalState, action) => {
  switch (action.type) {
    case GET_UNITS:
      var newUnits = JSON.parse(JSON.stringify(state.allUnits));
      action.payload.forEach(function(feature) {
        if(newUnits[feature.properties.Date]) {
          newUnits[feature.properties.Date].push(feature);
        } else {
          newUnits[feature.properties.Date] = [feature];
        }
      })
      return {
        ...state,
        allUnits : newUnits
      };
    case SET_ACTIVE_UNITS:
      return {
        ...state,
        activeUnits : action.payload
      };
    case GET_UNITS_DISTINCT:
      return {
        ...state,
        distinctUnits: action.payload
      }
    case SET_EUNITID_LIST:
      return {
        ...state,
        euidList: action.payload
      }
    case SET_CLICKED_UNIT:
      return {
        ...state,
        clickedUnit: action.payload
      }
    default:
      return state
  }
}

export default units
