import React, { Component } from 'react';
import { Translate, withLocalize } from "react-localize-redux";
import { Link } from "react-router-dom";
import { setActiveDiary } from '../../actions/actions_diaries';
import isEqual from 'lodash.isequal'
import { Popup } from 'semantic-ui-react';

import { addToMap, setPoiWindow, setTheatre } from '../../actions/actions_map';
import { setActiveSidebarPanel, toggleBasemapsModal, setUnitsListActive } from '../../actions/actions_sidebar';

import UnitsList from '../../containers/FrontPage/UnitsList';
import WarDiary from '../../containers/FrontPage/WarDiary';
import Filters from '../../containers/FrontPage/Filters';
import Basemaps from '../../containers/FrontPage/Basemaps';

import { setCurrentDate } from '../../actions/actions_dates';

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.clickListenerAdded = false;
    this.sideBar = React.createRef();
    this.state = {
        activePanel : false,
        drawerOpen  : false,
        infoText : false
    }
  }

  togglePanel = (toggledPanel) => {
    const { drawerOpen, activePanel } = this.state;
    let { activeSidebarPanel, activeUnitDiaries, unitsListActive } = this.props;

    // DISPATCH NEW PANEL
    let newPanel = toggledPanel === activeSidebarPanel ? ( activeUnitDiaries ? 'warDiaries' : false ) : toggledPanel
    this.props.dispatch(setActiveSidebarPanel(newPanel))

    if (!newPanel && toggledPanel === 'warDiaries') {
        setTimeout(() => {
             this.props.dispatch(setActiveDiary(0))
        }, 600);
    }

    if (activePanel === 'tools' && newPanel !== 'tools') {
        this.props.dispatch(addToMap([false, false]))
        //this.setState({ activePanel : false });
    }
  }

  toggleUnitsList = () => { // Mobile only
    const { unitsListActive } = this.props;
    if (!unitsListActive && this.props.activeSidebarPanel === 'filters') {
        this.props.dispatch(setActiveSidebarPanel(false))
    }

    this.props.dispatch(setUnitsListActive(!unitsListActive));
  }

  openCampaign = (campaign) => {
    switch (campaign) {
      case 'ETO':
        this.props.map.flyTo({ center: [-0.5666644, 49.333332] }) // normal start position
        this.props.dispatch(setCurrentDate('1944-06-06')) // normal start date
        break;
      case 'MTO':
        this.props.dispatch(setCurrentDate('1943-07-10')) // first date of italian data
        this.props.map.fitBounds([
          [16.66771037836685, 38.577781477106925],
          [10.980553189543684, 36.35400696293027],
        ]) // sicily for now since that's the only place we have units on the first day
        break;
      case 'HK':
        this.props.map.flyTo({
          center: [114.236, 22.256],
          zoom: 11,
        })
        this.props.dispatch(setCurrentDate('1941-12-08'))
        break;
      case 'ALK':
      case 'AK':
        this.props.map.flyTo({
          center: [175.614, 52.246],
          zoom: 7.25,
        })
        this.props.dispatch(setCurrentDate('1943-05-11'))
        break;
    }
    this.props.dispatch(setTheatre(campaign))
  }

  closeSidebarOnExternalClick = (e) => {
    // if the land_units layer was clicked clickedLandUnits is true and will exit this function
    // this is to prevent closing the sidebar after clicking on a land unit since we should be displaying the war_diaries for that unit
    if (this.props.clickedLandUnits) {
      this.props.setClickedLandUnits(false)
      return
    }
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.activeSidebarPanel) { // Open drawer, unless tools
        if (nextProps.activeSidebarPanel !== 'tools') { this.setState({ drawerOpen : true }) }
        this.setState({ activePanel : nextProps.activeSidebarPanel });

        if (!this.clickListenerAdded) {
            document.addEventListener('click', this.closeSidebarOnExternalClick);
            this.clickListenerAdded = true;
        }
    } else if (!nextProps.activeSidebarPanel && this.props.activeSidebarPanel) { // Close
        // Give the drawer time to close before unmounting the components

        this.setState({ drawerOpen : false });

        setTimeout(() => {
            this.setState({ activePanel : nextProps.activeSidebarPanel });
        }, 600);

        if (this.clickListenerAdded) {
            document.removeEventListener('click', this.closeSidebarOnExternalClick);
            this.clickListenerAdded = false;
        }
    }

  }

  toolButtonClick(type, saving) {
    let newVal = this.props.addingPoint === type ? false : type;
    if (type === 'point' && this.props.savingPoint !== saving) {
        newVal = type;
    }
    this.props.dispatch(addToMap([newVal, saving]));

    if (!saving) { this.props.dispatch(setPoiWindow(null)) }

    // Infotext change
    if(newVal) {
      if(type === 'lineMeasure') {
        this.setState({infoText: this.props.translate("measure_distance_help")});
      } else if(type === 'polygon') {
        this.setState({infoText: this.props.translate("polygon_tool_help")});
      } else if(type === 'line') {
        this.setState({infoText: this.props.translate("line_tool_help")});
      } else if(type === 'point') {
        this.setState({infoText: this.props.translate("point_tool_help")});
      } else if(type === 'edit-units') {
        this.setState({infoText: this.props.translate("edit_units_help")});
      } else {
        this.setState({infoText: false})
      }
    } else {
      this.setState({infoText: false})
    }
  }

  render() {
    const { activePanel, drawerOpen, infoText } = this.state;
    const { activeSidebarPanel, activeUnitDiaries, currentUser, basemapsModalOpen, unitsListActive } = this.props;

    return (
      <div className="side-bar" ref={this.sideBar}>

            { window.innerWidth < 768 ?
                <Popup
                    trigger={
                        <div className="icon-wrap">
                            <i className={"fa fa-book-open " + (unitsListActive ? 'active' : '')} onClick={this.toggleUnitsList}></i>
                            <span className="icon-label"> {this.props.translate("units")}</span>
                        </div>
                    }
                    content={this.props.translate("units")}
                    position="left center"
                    style={{ textTransform : 'capitalize', opacity : window.innerWidth > 768 ? 1 : 0 }}
                />
            : null }

            <Popup
                trigger={
                    <div className="icon-wrap">
                        <i className={"fa fa-cog " + (drawerOpen && activePanel === 'filters'  ? 'active' : '')} onClick={() => this.togglePanel('filters')}></i>
                        <span className="icon-label"> {this.props.translate("map_settings")}</span>
                    </div>
                }
                content={this.props.translate("map_settings")}
                position="left center"
                style={{ textTransform : 'capitalize', opacity : window.innerWidth > 768 ? 1 : 0 }}
            />
            <Popup
                trigger={
                    <div className="icon-wrap">
                        <i className={"fa fa-layer-group "  + ( basemapsModalOpen ? 'active' : '')} onClick={() => this.props.dispatch(toggleBasemapsModal(!basemapsModalOpen))}></i>
                        <span className="icon-label"> {this.props.translate("basemaps")}</span>
                    </div>
                }
                content={this.props.translate("basemaps")}
                position="left center"
                style={{ textTransform : 'capitalize', opacity : window.innerWidth > 768 ? 1 : 0 }}
            />
            <Link to="/story-maps" className="story-map-link">
                <Popup
                    trigger={<span className={"story-map-icon " + (activePanel === 'tools' ? 'active' : '')}></span>}
                    content={this.props.translate("story_maps")}
                    position="left center"
                    style={{ textTransform : 'capitalize' }}
                />
            </Link>

            {/*
            <Popup
                trigger={<i className={"fas fa-flag"} onClick={() => this.openCampaign('MTO')}></i>}
                content={this.props.translate("it_43_45")}
                position="left center"
                style={{ textTransform : 'capitalize' }}
            />
            <Popup
                trigger={<i className={"far fa-flag"} onClick={() => this.openCampaign('ETO')}></i>}
                content={this.props.translate("nw_eu_44_45")}
                position="left center"
                style={{ textTransform : 'capitalize' }}
            />
            */}

            <Popup
                trigger={<i className={"fa fa-wrench " + (activePanel === 'tools' ? 'active' : '')} onClick={() => this.togglePanel('tools')}></i>}
                content={this.props.translate("mapping_tools")}
                position="left center"
                style={{ textTransform : 'capitalize' }}
            />

            <div className={"drawer-panel " + (drawerOpen ? 'active' : '')}>
                { activePanel === 'warDiaries'  ? <WarDiary />  : false }
                { activePanel === 'filters'     ? <Filters />  : false }
            </div>

            { basemapsModalOpen ?
                <Basemaps closeModal={() => this.props.dispatch(toggleBasemapsModal(!basemapsModalOpen))} />
            : false }

            { activePanel === 'tools' ?
                <Translate>
                  {({ translate, activeLanguage, languages }) => (
                    <div className="tools-placeholders">
                        <Popup content={infoText ? infoText : translate("magnifying_glass")} trigger={
                            <i className={"fa fa-search-plus " + (this.props.addingPoint === 'magnify' ? 'active' : '')} onClick={() => this.toolButtonClick('magnify', true)} onMouseMove={() => this.setState({infoText:(this.props.addingPoint === 'magnify' ? this.state.infoText : false)})} ></i>
                        }/>
                        { currentUser && currentUser.assigned_units && currentUser.assigned_units.length > 0 ?
                            <Popup content={infoText ? infoText : translate("edit_units")} trigger={
                                <i className={"fa fa-pencil-alt " + (this.props.addingPoint === 'edit-units' ? 'active' : '')}  onClick={() => this.toolButtonClick('edit-units', false)} onMouseMove={() => this.setState({infoText:(this.props.addingPoint === 'edit-units' ? this.state.infoText : false)})}></i>
                            }/>
                        : null }
                        <Popup content={infoText ? infoText : translate("measure_distance")} trigger={
                            <i className={"fa fa-ruler " + (this.props.addingPoint === 'lineMeasure' ? 'active' : '')}  onClick={() => this.toolButtonClick('lineMeasure', false)} onMouseMove={() => this.setState({infoText:(this.props.addingPoint === 'lineMeasure' ? this.state.infoText : false)})} ></i>
                        }/>
                        <Popup content={infoText ? infoText : translate("polygon_tool")} trigger={
                            <i className={"fa fa-draw-polygon " + (this.props.addingPoint === 'polygon' ? 'active' : '')}  onClick={() => this.toolButtonClick('polygon', false)} onMouseMove={() => this.setState({infoText:(this.props.addingPoint === 'polygon' ? this.state.infoText : false)})} ></i>
                        }/>
                        <Popup content={infoText ? infoText : translate("line_tool")} trigger={
                            <i className={"fa fa-arrows-alt-v " + (this.props.addingPoint === 'line' ? 'active' : '')} onClick={() => this.toolButtonClick('line', false)} onMouseMove={() => this.setState({infoText:(this.props.addingPoint === 'line' ? this.state.infoText : false)})} ></i>
                        }/>
                        <Popup content={infoText ? infoText : translate("point_tool")} trigger={
                            <i className={"fa fa-map-marker-alt " + (this.props.addingPoint === 'point' && !this.props.savingPoint ? 'active' : '')} onClick={() => this.toolButtonClick('point', false)} onMouseMove={() => this.setState({infoText:(this.props.addingPoint === 'point' ? this.state.infoText : false)})} ></i>
                        }/>
                        <Popup content={infoText ? infoText : translate("add_photo")} trigger={
                            <i className={"fa fa-camera-retro " + (this.props.addingPoint === 'point' && this.props.savingPoint  ? 'active' : '')} onClick={() => (currentUser ? this.toolButtonClick('point', true) : this.props.openLoginModal()) } onMouseMove={() => this.setState({infoText:(this.props.addingPoint === 'point' ? this.state.infoText : false)})} ></i>
                        }/>
                    </div>
                  )}
                </Translate>
            :
                <div className={"units-list-mobile-container" + (unitsListActive ? ' active' : '')}>
                    <UnitsList />
                </div>
            }
      </div>
    );
  }
}

export default withLocalize(SideBar);
