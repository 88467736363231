import { connect } from 'react-redux'

import MapComponent from '../../components/FrontPage/Map.js'

const mapStateToProps = state => {
  return {
    currentDate : state.mainReducer.dates.currentDate,
    allUnits : state.mainReducer.units.allUnits,
    allLines : state.mainReducer.lines.allLines,
    mapLoadStatus: state.mainReducer.map.status,
    currentUser: state.mainReducer.users.currentUser,
    allPois: state.mainReducer.pois.allPois,
    allApprovedPois : state.mainReducer.approved_pois.allApprovedPois,
    allFilters : state.mainReducer.map.allFilters,
    currentFilters : state.mainReducer.map.currentFilters,
    mapSettings : state.mainReducer.map.mapSettings,
    visibleLayers : state.mainReducer.map.visibleLayers,
    activeUnitDiaries: state.mainReducer.diaries.activeDiary,

    addingPoint : state.mainReducer.map.addingPoint,
    savingPoint : state.mainReducer.map.savingPoint,
    poiWindowData: state.mainReducer.map.poiWindowData,
    autoEditPoi : state.mainReducer.map.mapSettings.autoEditPoi,
  }
}

const Map = connect(
  mapStateToProps
)(MapComponent)

export default Map
