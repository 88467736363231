import React, { Component } from 'react';
import moment from 'moment-timezone'
import centroid from '@turf/centroid';

import { setCustomDataLayers } from '../../actions/actions_map'

const CUSTOM_DATA_POINTS = 'custom_data_points'
const CUSTOM_DATA_LINE_STRINGS = 'custom_data_line_strings'
const CUSTOM_DATA_POLYGONS = 'custom_data_line_polygons'


class CustomData extends Component {

  constructor(props) {
    super(props);
    this.loadingRcafLayer = false;
  }

  componentWillReceiveProps(nextProps) {
    const { map } = this.props;

    //console.log(nextProps, ' NEXT PROPS FROM CUSTOM DATA');

    if (map && !map.getLayer(CUSTOM_DATA_POINTS) && !this.loadingRcafLayer && nextProps.customDataJson) {
      this.loadingRcafLayer = true;
      this.addCustomDataLayers(nextProps);
    }

    if (map) {
        this.updateCustomDataLayers(map, nextProps)
    }
  }

    async addCustomDataLayers({ customDataJson, currentDate }) {
        const { map } = this.props;
        const that = this;

        if (customDataJson) {

            let allFeatures = {
                points : [],
                lineStrings : [],
                polygons : [],
                layerNames : []
            }

            customDataJson.forEach(feature => {
                feature.id = Math.random().toString(36).substr(2, 34);

                feature.properties.geometry = feature.geometry;

                if (feature.geometry.type === 'Point')      { allFeatures.points.push(feature) }
                if (feature.geometry.type === 'LineString') { allFeatures.lineStrings.push(feature) }
                if (feature.geometry.type === 'Polygon')    { allFeatures.polygons.push(feature) }

                if (allFeatures.layerNames.indexOf(feature.properties.name) === -1) {
                    allFeatures.layerNames.push(feature.properties.name);
                }
            });

            allFeatures.lineStrings = allFeatures.lineStrings.map(this.addTopLevelProperties);
            allFeatures.polygons = allFeatures.polygons.map(this.addTopLevelProperties);

            // console.log(allFeatures, ' all features');
            this.props.dispatch(setCustomDataLayers(allFeatures.layerNames));

            if (allFeatures.lineStrings.length > 0) {
                if (!map.getLayer(CUSTOM_DATA_LINE_STRINGS) && !map.getSource(CUSTOM_DATA_LINE_STRINGS)) {
                    map.addSource(CUSTOM_DATA_LINE_STRINGS, {
                        type: 'geojson',
                        data: {
                            type: "FeatureCollection",
                            features: allFeatures.lineStrings
                        }
                    })
                    map.addLayer({
                        id: CUSTOM_DATA_LINE_STRINGS,
                        type: 'line',
                        source: CUSTOM_DATA_LINE_STRINGS,
                        layout: {},
                        paint: {
                            'line-color': [
                                'case',
                                ['==', null, ['get', 'line_colour']],
                                '#888', // use default if above expression returns null
                                ['get', 'line_colour']
                            ],
                            'line-opacity': [
                                'case',
                                ['==', null, ['get', 'opacity']],
                                1, // use default if above expression returns null
                                ['get', 'opacity']
                            ],
                            'line-width': [
                                'case',
                                ['==', currentDate, ['get', 'Date']],
                                4,
                                0
                            ]
                        }
                    })
                    this.addLayerPopup(CUSTOM_DATA_LINE_STRINGS, this.props, map);
                } else {
                    // update layer
                }
            }

            if (allFeatures.polygons.length > 0) {
                if (!map.getLayer(CUSTOM_DATA_POLYGONS) && !map.getSource(CUSTOM_DATA_POLYGONS)) {
                    map.addSource(CUSTOM_DATA_POLYGONS, {
                        type: 'geojson',
                        data: {
                            type: "FeatureCollection",
                            features: allFeatures.polygons
                        }
                    })
                    map.addLayer({
                        id: CUSTOM_DATA_POLYGONS,
                        type: 'fill',
                        source: CUSTOM_DATA_POLYGONS,
                        layout: {},
                        paint: {
                            'fill-color': [
                                'case',
                                ['==', null, ['get', 'fill_colour']],
                                '#888', // use default if above expression returns null
                                ['get', 'fill_colour']
                            ],
                            'fill-opacity': [
                                // get opacity if the current date matches
                                // else use the default: 1
                                'case',
                                ['==', currentDate, ['get', 'Date']],
                                [
                                    'case',
                                    ['==', null, ['get', 'opacity']],
                                    1,
                                    ['get', 'opacity']
                                ],
                                0
                            ],
                        }
                    })
                    this.addLayerPopup(CUSTOM_DATA_POLYGONS, this.props, map);
                } else {
                    // update layer
                }
            }

            if (allFeatures.points.length > 0) {
                if (!map.getLayer(CUSTOM_DATA_POINTS) && !map.getSource(CUSTOM_DATA_POINTS)) {
                    const loadAllImages = allFeatures.points.reduce((acc, feature) => {
                        // add the url if it's not in the icon list yet
                        if (!acc.find((icon) =>  icon.url === feature.properties.icon)) {
                          acc.push({
                            url: feature.properties.icon,
                            name: feature.properties.name + '_icon'
                          })
                        }
                      return acc
                    }, [])
                    .map(({ url, name }) => {
                      return this.loadMapboxImagePromise(url, name)
                    })
                    await Promise.all(loadAllImages)
                    map.addSource(CUSTOM_DATA_POINTS, {
                        type: 'geojson',
                        data: {
                            type: "FeatureCollection",
                            features: allFeatures.points.map((feature) => {
                              // add the icon_name property to refer to it in the layer
                              return {
                                ...feature,
                                properties: {
                                  ...feature.properties,
                                  'icon_name': feature.properties.name + '_icon'
                                }
                              }
                            })
                        }
                    })
                    map.addLayer({
                        "id": CUSTOM_DATA_POINTS,
                        "type": "symbol",
                        "source": CUSTOM_DATA_POINTS,
                        "layout": {
                            'icon-allow-overlap': true,
                            'symbol-placement': 'point',
                            "icon-image": ['get', 'icon_name'],
                            "icon-size": 0.05,
                        },
                        "paint": {
                            /*
                            "icon-opacity": [
                                "case",
                                ["==", currentDate, ["get", "Date"]], 1,
                                0
                            ]
                            */
                        },
                        // any custom data with Date == null
                        // will be displayed across all dates
                        "filter": ["any",
                            ['==', currentDate, ["get", "Date"]],
                            ['==', null, ["get", "Date"]]
                        ]
                    })

                    this.addLayerPopup(CUSTOM_DATA_POINTS, this.props, map);

                } else {
                    // update layer
                }
            }
        }
    }

    addLayerPopup(layer, props, map) {
        if (!props.eventListenersAdded[layer]) {
            map.on('click', layer, (e) => {
                const feature = e.features[0]
                if (!feature) {
                  return
                }

                // if title exists, display popup
                let propKeys  = JSON.parse(feature.properties.properties_map);
                let allProps  = JSON.parse(feature.properties.properties);

                // There's something funky going on where the properties_map key/value pairs are reversed in the original RCAF data
                // Should probably look into that and then refactor this.
                if (!propKeys.title) {
                    propKeys.title = Object.keys(propKeys).find(key => propKeys[key] === 'title');
                }
                if (!propKeys.description) {
                    propKeys.description = Object.keys(propKeys).find(key => propKeys[key] === 'description');
                }

                //console.log(feature);

                if (propKeys.title && allProps[propKeys.title]) {
                    let coordinates;
                    if (feature.properties.geometry_type === 'polygon') {
                        const featureGeometry = centroid(JSON.parse(feature.properties.geometry));
                        coordinates = featureGeometry.geometry.coordinates;
                    } else if (feature.properties.geometry_type === 'line') {
                        coordinates = [ e.lngLat.lng, e.lngLat.lat ];
                    } else {
                        coordinates = e.features[0].geometry.coordinates.slice();
                    }

                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }
                    new window.mapboxgl.Popup()
                        .setLngLat(coordinates)
                        .setHTML(`
                          <div>
                            <div class="popup-content">
                            ${this.getPopupHTML(propKeys, allProps)}
                            </div>
                          </div>
                        `)
                        .addTo(map);
                }

            });
            map.on('mouseenter', layer, function () {
                map.getCanvas().style.cursor = 'pointer';
            });
            map.on('mouseleave', layer, function () {
                map.getCanvas().style.cursor = '';
            });
            var newEventListenerState = JSON.parse(JSON.stringify(props.eventListenersAdded));
            newEventListenerState[layer] = true;

            //that.setState({ eventListenersAdded: newEventListenerState });
            props.updateEventListeners(newEventListenerState);
        }
    }

  getLayerExpressions(layers, date) {
      // any custom data with Date == null
      // will be displayed across all dates
    let dateExpression = ["any",
        ['==', date, ["get", "Date"]],
        ['==', null, ["get", "Date"]],
    ]
    let layerExpression = ['any']

    Object.keys(layers).forEach(key => {
        if (layers[key]) {
            layerExpression.push(
                ['==', key, ['get', 'name']]
            )
        }
    });

    return ['all', dateExpression, layerExpression];
  }

  updateCustomDataLayers = (map, nextProps) => {

    if (map.getLayer(CUSTOM_DATA_POINTS)) {
      map.setFilter(CUSTOM_DATA_POINTS, this.getLayerExpressions(nextProps.visibleLayers, nextProps.currentDate));
    }
    if (map.getLayer(CUSTOM_DATA_LINE_STRINGS)) {
      map.setPaintProperty(CUSTOM_DATA_LINE_STRINGS, 'line-width', [
        'case',
            this.getLayerExpressions(nextProps.visibleLayers, nextProps.currentDate),
        4,
        0
    ]);
    }
    if (map.getLayer(CUSTOM_DATA_POLYGONS)) {
      map.setPaintProperty(CUSTOM_DATA_POLYGONS, 'fill-opacity', [
        'case',
            this.getLayerExpressions(nextProps.visibleLayers, nextProps.currentDate),
        [
            'case',
            ['==', null, ['get', 'opacity']],
            1,
            ['get', 'opacity']
        ],
        0
    ]);
    }
  }

  getPopupHTML = (propKeys, props) => {
    let popupHTML = '';
    let usedHTML = false;
    const keyslist = Object.keys(propKeys);

    // Find the html field first. It can appear in any order
    // so thats why we loop through first to find it.
    // The second loop is to emulate the previous beheviour.
    keyslist.forEach(key => {
        if (key === 'HTML') {
            popupHTML += props[propKeys[key]];
            usedHTML = true;
        }
    });
    keyslist.forEach(key => {
        if (!usedHTML && props[propKeys[key]]) {
            if (key === 'title')  {
                popupHTML += `<b><center>${props[propKeys[key]]}</center></b>`
            } else {
                popupHTML += `<center>${props[propKeys[key]]}</center>`
            }
        }
    });

    return popupHTML;

    /*
    let popupDisplayItems = {}
    let { properties_map } = feature.properties

    if (properties_map && JSON.parse(properties_map)) {
      properties_map = JSON.parse(properties_map)
      feature.properties.properties = JSON.parse(feature.properties.properties)
      for (let prop in properties_map) {
        if (properties_map[prop] && feature.properties.properties[prop]) {
          popupDisplayItems = {
            ...popupDisplayItems,
            [ properties_map[prop] ]:  feature.properties.properties[prop]
          }
        }
      }
    }
    const popupDisplayItemsHTML = Object.keys(popupDisplayItems).map((key) => {
      if (key === 'title') {
        return `<p><b><center>${popupDisplayItems[key]}</center></b></p>`
      } else {
        return `<p><center>${popupDisplayItems[key]}</center></p>`
      }
    }).join('')
    return popupDisplayItemsHTML || ''
    */
  }

    addTopLevelProperties = (feature) => {
        function addTopLevelPropertiesHelper(feature, keyToFind) {
            let foundKey
            for (let key in feature.properties.properties_map) {
                if (feature.properties.properties_map[key] === keyToFind) {
                    foundKey = key
                }
            }
            return foundKey
        }

        const lineColourKey = addTopLevelPropertiesHelper(feature, 'line_colour')
        const fillColourKey = addTopLevelPropertiesHelper(feature, 'fill_colour')
        const opacityKey = addTopLevelPropertiesHelper(feature, 'opacity')

        return {
            ...feature,
            properties: {
                ...feature.properties,
                Date: moment(feature.properties.properties.Date).tz('UTC').format('YYYY-MM-DD'),
                line_colour: feature.properties.properties[lineColourKey],
                fill_colour: feature.properties.properties[fillColourKey],
                opacity: feature.properties.properties[opacityKey],
            }
        }
    }

    loadMapboxImagePromise = (path, imageName) => {
        return new Promise((resolve, reject) => {
            this.props.map.loadImage(path, (err, image) => {
                if (err) {
                    reject(err)
                } else {
                    this.props.map.addImage(imageName, image);
                    resolve(imageName)
                }
            })
        })
    }

  render() { return (null) }
}

export default CustomData;
