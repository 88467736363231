import {
  GET_APPROVED_POIS
} from '../actions/actions_approved_pois'

const approved_pois = (state = {
  allApprovedPois : []
}, action) => {
  switch (action.type) {
    case GET_APPROVED_POIS:
      var allApprovedFeatures = JSON.parse(JSON.stringify(action.payload));
      return {
        ...state,
        allApprovedPois : allApprovedFeatures
      };
    default:
      return state
  }
}

export default approved_pois
