import React, { Component } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { Segment, Form, Button, Popup, Icon } from 'semantic-ui-react'
import isEqual from 'lodash.isequal'

import { postPoi, patchPoi, deletePoi } from '../../actions/actions_pois'
import { setPoiWindow, addToMap } from '../../actions/actions_map'

export default class PoiWindow extends Component {
    constructor(props) {
        super(props)
        const data = this.props.poiWindowData || {}
        this.state = {
            method: 'post',
            geometry: 'point',
            files: [],
            ...data,
            title: data.title === 'null' ? '' : data.title,
            description: data.description === 'null' ? '' : data.description,
            image_urls : data.image_urls || []
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(this.props.poiWindowData, nextProps.poiWindowData)) {
            const data = nextProps.poiWindowData || {}
            this.setState({
                ...data,
                files: [],
                title: data.title === 'null' ? '' : data.title,
                description: data.description === 'null' ? '' : data.description,
            })
        }
    }

    uploadToCloudinary = () => {
        return new Promise(async (resolve, reject) => {
            const file = this.state.files[0]
            if (file) {
                const formData = new FormData()
                formData.append('upload_preset', 'ml_default')
                formData.append('tags', 'p44_browser_upload')
                formData.append('file', file)
                formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY)
                const res = await fetch(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`, {
                    method: 'POST',
                    body: formData
                })
                const { secure_url } = await res.json()
                this.setState({ image_urls: [...this.state.image_urls, secure_url], files : [] }, () => resolve())
            } else {
                resolve()
            }
        })
    }

    onSave = async () => {
        if (this.state.files.length > 0) {
            await this.uploadToCloudinary()
        }
        const { title, description, image_urls, geometry, feature_id } = this.state
        if (this.state.method === 'post') {
            const feature = {
                // TODO update POST api/poi to take a geojson feature, like all our other endpoints
                geometry: 'point',
                coordinates: this.state.geometry.coordinates,
                title,
                description,
                image_urls
            }
            this.props.dispatch(postPoi(feature, this.props.currentUser.id))
            this.props.dispatch(addToMap([false, false]))
        } else if (this.state.method === 'patch') {
            const feature = {
                geometry,
                properties: {
                    feature_id,
                    title,
                    description,
                    image_urls
                }
            }
            this.props.dispatch(patchPoi(feature, this.props.currentUser.id, false, this.props.poiWindowData.approved))
            this.props.dispatch(addToMap([false, false]))
        }
        this.props.dispatch(setPoiWindow(null))
    }

    onDelete = () => {
        if (window.confirm('Are you sure you would like to delete this marker?')) {
            const feature = {
                properties: {
                    feature_id: this.state.feature_id
                }
            }
            this.props.dispatch(deletePoi(feature, this.props.currentUser.id))
            this.props.dispatch(setPoiWindow(null))
        }
    }

    onDeletePoiImg = (url) => {
        const image_urls = this.state.image_urls.filter(imgUrl => url !== imgUrl)
        const { title, description, geometry, feature_id } = this.state
        const feature = {
            geometry,
            properties: {
                feature_id,
                title,
                description,
                image_urls
            }
        }
        this.setState({ image_urls })
        this.props.dispatch(patchPoi(feature, this.props.currentUser.id, false, this.props.poiWindowData.approved))
        this.props.dispatch(addToMap([false, false]))
    }

    render() {
        return (
            <Segment className="poi-window" inverted>
                <div className="poi-icon" onClick={() => this.props.dispatch(setPoiWindow(null))}>
                   <i className='fa fa-times'></i>
                   {/*  <Icon size="small" inverted circular name="close" /> */}
                </div>
                <Form inverted>
                    <Form.Field>
                        <label>Title</label>
                        <Form.Input
                            //placeholder='Marker'
                            name="title"
                            onChange={(e, { name, value }) => {
                                this.setState({ [name]: value })
                            }}
                            value={this.state.title}
                        />
                    </Form.Field>
                    <Form.Field className="file-upload-field">
                        <Form.Input
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            onChange={(e) => {
                                this.setState({ files: e.target.files })
                            }}
                            key={this.state.image_urls}
                        />
                        <Button onClick={this.uploadToCloudinary} style={{ padding : '0.75em', marginLeft : 10 }} disabled={!this.state.files[0]}>Upload</Button>
                    </Form.Field>
                    {this.state.image_urls && this.state.image_urls.length > 0 ?
                        <Form.Field>
                            {this.state.image_urls.map((url, i) => {
                                return (
                                    <div key={`poi-img-url-${url}`}>
                                        <a target="blank" href={url}>Image {i + 1}</a>
                                        <Icon size="small" inverted circular name="close" onClick={() => this.onDeletePoiImg(url)} />
                                    </div>
                                )
                            })}
                        </Form.Field>
                    : null}

                    <Form.Field>
                        <Editor
                             apiKey='kooryg9iwlwx2p78ahe79w24i3geaedvjv77i1dsr4s3kdq4'
                             initialValue={this.state.description}
                             init={{
                               height: 250,
                               menubar: false,
                               statusbar : false,
                               plugins: [ 'link image media' ],
                               toolbar: 'undo redo | media | link | formatselect | bold italic forecolor | alignleft aligncenter alignright',
                             }}
                             onEditorChange={(content, editor) => { this.setState({ description : content })}}

                        />
                    </Form.Field>
                    <div className="button-wrap">
                        {this.state.method === 'patch' && this.state.geometry && this.state.geometry.coordinates ? (
                            <Popup content="Pick new coordinates on the map" trigger={
                                <Button onClick={() => this.props.dispatch(addToMap(['point', true]))}>Move Marker</Button>
                            }/>
                            ) : null}
                        <Button type='submit' onClick={this.onSave}>Save</Button>
                        {this.state.method === 'patch' && <Button  onClick={this.onDelete}>Delete</Button>}
                    </div>
                </Form>
            </Segment>
        )
    }
}
