import Constants, { P44_TOKEN } from '../Constants.js';

export const CUSTOM_DATA_ERROR = 'CUSTOM_DATA_ERROR';
export const CUSTOM_DATA_INFO = 'CUSTOM_DATA_INFO';
export const SET_CUSTOM_DATA = 'SET_CUSTOM_DATA';
export const DELETE_CUSTOM_DATA = 'DELETE_CUSTOM_DATA'
export const EDIT_CUSTOM_DATA = 'EDIT_CUSTOM_DATA'

export function postCustomData(features) {
    return async (dispatch) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/custom_data`, {
                method: 'POST',
                body: JSON.stringify({
                    features,
                    token: window.localStorage.getItem(P44_TOKEN)
                }),
                headers: {
                    'Content-type': 'application/json'
                }
            })
            if (res.status === 201) {
                dispatch(customDataInfo({ msg: 'Custom data succsfully uploaded' }))
            } else {
                const json = await res.json()
                dispatch(customDataError(json))
            }
        } catch (error) {
            dispatch(customDataError(error))
        }
    }
}

export function getCustomData() {
    return (dispatch) => {
        return fetch(`${process.env.REACT_APP_BACKEND}/custom_data`, {
          method: 'GET'
        })
        .then(response => Constants.handleErrors(response, dispatch, customDataError))
        .then(json => dispatch(setCustomData(json)))
        .catch(error => dispatch(customDataError(error)));
      }
}

export function patchCustomData(features, token) {
    token = token ? token : window.localStorage.getItem(P44_TOKEN)
    return (dispatch) => {
        return fetch(`${process.env.REACT_APP_BACKEND}/custom_data`, {
            method: 'PATCH',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                features: Array.isArray(features) ? features : [features],
                token
            })
        })
        .then((res) => res.json())
        .then((features) => {
            dispatch(editCustomData(features))
            dispatch(getCustomData())
        })
        .catch((error) => dispatch(customDataError(error)))
    }
}

export function deleteCustomData(featureIds, token) {
    token = token ? token : window.localStorage.getItem(P44_TOKEN)
    return (dispatch) => {
        return fetch(`${process.env.REACT_APP_BACKEND}/custom_data`, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                features: Array.isArray(featureIds) ? featureIds : [featureIds],
                token
            })
        })
        .then((res) => res.json())
        .then((ids) => {
            dispatch(deleteCustomDataById(ids))
            dispatch(getCustomData())
        })
        .catch((error) => dispatch(customDataError(error)))
    }
}

function editCustomData(features) {
    return {
        type: EDIT_CUSTOM_DATA,
        payload: features
    }
}

function deleteCustomDataById(ids) {
    return {
        type: DELETE_CUSTOM_DATA,
        payload: ids
    }
}

export function setCustomData(json) {
    return {
        type: SET_CUSTOM_DATA,
        payload: json
    }
}

export function customDataError(error) {
    return {
        type: CUSTOM_DATA_ERROR,
        payload: error
    }
}

export function customDataInfo(info) {
    return {
        type: CUSTOM_DATA_INFO,
        payload: info
    }
}
