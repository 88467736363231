import React, { Component } from 'react'
import { Translate, withLocalize } from "react-localize-redux";
import { Segment, Table, Input, Button, Popup, Icon, Pagination } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react'
import Select from 'react-select';
import moment from 'moment';
import { deleteUserCreatedUnits, patchUserCreatedUnit, postUserCreatedUnit } from '../../actions/actions_user_created_units'
import { addToMap } from '../../actions/actions_map';
import { setCurrentDate } from '../../actions/actions_dates';


class EditUserCreatedUnitsTable extends Component {
    constructor(props) {
        super(props)
        this.unitFeatures = [];
        this.visibleUnitFeatures = [];
        this.dropdownOpts = [];
        this.itemsPerPage = 6;
        this.tablePages = 0;
        this.state = {
            currentUnit : null,
            editingUnit: null,
            isEditingCoords: false,
            showTable: true,
            startPage : 1
        }
    }

    componentDidMount() {
        if (this.props.currentUser.assignedUnitsData) {
            this.dropdownOpts = this.props.currentUser.assignedUnitsData.map(unit => {
                const { EUnitID, LN, UC } = unit.properties
                return {
                    value: EUnitID,
                    label: `${LN} -- ${UC}`
                };
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.currentUnit) {
            this.getUnitData(nextProps, this.state.currentUnit);
        }
    }

    editUnit = (feature, index, method = 'patch') => {
        if (!feature) {
            feature = JSON.parse(JSON.stringify(this.visibleUnitFeatures[0]));
            feature.properties.Date = this.props.currentDate;
            feature.tempIndex = 0;
            feature.properties.status = 'draft';
            feature.geometry.coordinates = [0, 0];
            feature.properties.Notes = '';
        } else {
            feature.tempIndex = index
        }

        this.setState({ editingUnit: { ...feature, method } })
    }

    deleteUnit = (feature) => {
        this.props.dispatch(deleteUserCreatedUnits([feature.properties.feature_id], this.props.currentUser.id))
    }

    saveUnit = () => {
        const feature = { ...this.state.editingUnit }
        delete feature.method
        delete feature.tempIndex

        if (this.state.editingUnit.method === 'patch') {
            this.props.dispatch(patchUserCreatedUnit(feature, this.props.currentUser.id))
        } else if (this.state.editingUnit.method === 'post') {
            delete feature.id
            delete feature.properties.feature_id
            this.props.dispatch(postUserCreatedUnit(feature, this.props.currentUser.id))
        }

        this.props.mbDraw.deleteAll()
        this.setState({
            editingUnit: null,
            isEditingCoords: false,
        })
    }

    onChange = (property, value) => {
        this.setState({
            editingUnit: {
                ...this.state.editingUnit,
                properties: {
                    ...this.state.editingUnit.properties,
                    [property]: value
                }
            }
        })
    }

    editCoordinates = () => {
        if (!this.state.isEditingCoords) {
            const onDraw = (e) => {
                const feature = e.features[0]
                if (feature) {
                    this.setState({
                        editingUnit: {
                            ...this.state.editingUnit,
                            geometry: { ...feature.geometry }
                        },
                        showTable: true,
                        isEditingCoords: false
                    })
                }
            }
            this.props.mbDraw.changeMode('draw_point')
            this.props.frontPageMap.on('draw.update', onDraw)
            this.props.frontPageMap.on('draw.create', onDraw)
            this.setState({ isEditingCoords: true, showTable: false })
        }
    }

    dateSort = (a, b) => {
        return new moment(a.properties.Date).format('YYYYMMDD') - new moment(b.properties.Date).format('YYYYMMDD')
    }

    selectUnit(newUnit) {
        const { currentUnit } = this.state;

        this.setState({
            currentUnit : newUnit,
            startPage : 1,
            editingUnit : null
        });

        this.getUnitData(this.props, newUnit);
    }

    cancelEdit() {
        this.setState({ editingUnit : null });
        this.props.mbDraw.deleteAll();
    }

    getUnitData(props, currentUnit) {
        const { allUnits, allUserCreatedUnits } = props;

        // Get all non-user features for this unit
        this.unitFeatures = [];
        Object.keys(allUnits).forEach(date => {
            allUnits[date].forEach(unit => {
                if (unit.properties.EUnitID === currentUnit.value) { this.unitFeatures.push(unit) }
            });
        })
        // Add user created features
        const userUnits = allUserCreatedUnits.filter(feature => feature.properties.EUnitID === currentUnit.value).sort(this.dateSort)

        // Sort 'em all by date
        this.unitFeatures.sort(this.dateSort)
        // doing this to add the user created ones before the examples in the table
        this.unitFeatures = [...userUnits, ...this.unitFeatures]

        this.tablePages = Math.ceil(this.unitFeatures.length / this.itemsPerPage);
    }


    isRowDisabled(Date) {
        return Date === this.props.currentDate ? false : true;
    }

    incrementDay = (startDate = this.props.currentDate) => {
        const nextDate = moment(startDate).add(1, 'day');
        if (nextDate <= moment('1944-08-31') && nextDate > moment('1944-06-05')) {
            this.props.dispatch(setCurrentDate(nextDate.format('YYYY-MM-DD')))

            //const idxOfCurrentFeature = this.visibleUnitFeatures.findIndex(feature => feature.properties.Date === this.props.currentDate);
            //if (idxOfCurrentFeature === (this.itemsPerPage -1)) { this.setState({ startPage : this.state.startPage + 1 }) }
        }
    }

    decrementDay = (startDate = this.props.currentDate) => {
        if (moment(startDate).subtract(1, 'day') >= moment('1944-06-06')) {
            const nextDate = moment(startDate).subtract(1, 'day').format('YYYY-MM-DD')
            this.props.dispatch(setCurrentDate(nextDate))

            //const idxOfCurrentFeature = this.visibleUnitFeatures.findIndex(feature => feature.properties.Date === this.props.currentDate);
            //if (idxOfCurrentFeature === 0) { this.setState({ startPage : this.state.startPage - 1 }) }
        }
    }

    goToCoords = ({ lng, lat }) => {
        if (lng !== 0 && lat !== 0) {
            this.props.frontPageMap.flyTo({ center: [lng, lat], zoom: 10 })
        }
    }

    render() {
        const { currentUnit, startPage, editingUnit } = this.state;

        this.visibleUnitFeatures = [];
        const startIdx = (startPage -1) * this.itemsPerPage;
        for (var i = startIdx; i < startIdx + this.itemsPerPage; i++) {
            if (this.unitFeatures[i]) {
                this.visibleUnitFeatures.push(this.unitFeatures[i]);
            }
        }

        // Add row to top of table if creating a new feature
        if (editingUnit && editingUnit.method === 'post') {
            this.visibleUnitFeatures.unshift(this.unitFeatures[0]);
        }

        return (
            <Segment inverted className={`edit-units-section ${this.state.showTable ? '' : 'hidden'}`}>

                <div className="edit-units-header">
                    <div className="play-buttons">
                        <div onClick={() => this.decrementDay(this.props.currentDate, true)}><i className="fas fa-step-backward" /></div>
                        <div className="date"> {moment(this.props.currentDate).format('MMM D')}</div>
                        <div onClick={() => this.incrementDay(this.props.currentDate, true)}><i className="fas fa-step-forward" /></div>
                    </div>
                    <div className="select-wrap">
                        <Select
                          value={currentUnit}
                          onChange={(e) => this.selectUnit(e)}
                          options={this.dropdownOpts}
                          className="capitalize"
                        />
                    </div>

                    <i className="fa fa-times" onClick={() => this.props.dispatch(addToMap([false, false]))}></i>
                </div>

                <Table celled className={`edit-units-table ${this.state.showTable ? '' : 'hidden'} ${currentUnit ? '' : ' inactive'}`}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Unit ID</Table.HeaderCell>
                            {/* <Table.HeaderCell>Long Name</Table.HeaderCell> */}
                            <Table.HeaderCell>Short Name</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Marker ID</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Country</Table.HeaderCell>
                            <Table.HeaderCell>Size</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Notes</Table.HeaderCell>
                            <Table.HeaderCell>Lng, Lat</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        { this.visibleUnitFeatures.map((feature, i) => {
                            if (editingUnit && editingUnit.tempIndex === i) {
                                const { UID, LN, Date, US, Notes, SN, UC, UT, MID, status } = this.state.editingUnit.properties
                                const [lng, lat] = this.state.editingUnit.geometry.coordinates
                                return (
                                    <Table.Row key={i + '-' + this.props.currentUser.id} disabled={false} className="edit-row">
                                        <Table.Cell>{UID}</Table.Cell>
                                        {/* <Table.Cell> {LN} </Table.Cell> */}
                                        <Table.Cell>{SN} </Table.Cell>
                                        <Popup
                                            content={Date}
                                            trigger={
                                                <Table.Cell style={{ padding : 1 }}>
                                                    <DateInput
                                                        name="Date"
                                                        initialDate={Date}
                                                        value={Date}
                                                        dateFormat="YYYY-MM-DD"
                                                        onChange={(e, { value }) => this.onChange('Date', value)}
                                                    />
                                                </Table.Cell>
                                            }
                                        />
                                        <Table.Cell> {MID} </Table.Cell>
                                        <Table.Cell> {UT} </Table.Cell>
                                        <Table.Cell> {UC} </Table.Cell>
                                        <Table.Cell> {US} </Table.Cell>
                                        <Table.Cell className="capitalize"> {status} </Table.Cell>
                                        <Table.Cell style={{ padding : 1 }}>
                                            <Input
                                                name="Notes"
                                                value={Notes}
                                                onChange={(e, { value }) => this.onChange('Notes', value)}
                                            />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div style={{
                                                display: 'flex',
                                                whiteSpace : 'nowrap',
                                                justifyContent : 'center'
                                            }}>
                                            <p style={{ padding : 0, margin : 0 }}>{lng && lat ? `${roundNumber(lng)}, ${roundNumber(lat)}`: null}</p>
                                            <Popup
                                                content="Pick new coordinates on the map"
                                                trigger={
                                                    <Icon style={{ marginLeft: 2 }}name="crosshairs" onClick={() => this.editCoordinates()} />
                                                }
                                            />
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Popup
                                                content="If you save without coordinates, they will be set at 0,0"
                                                trigger={
                                                    <Button size="mini" color="teal" onClick={this.saveUnit} style={{ textTransform : 'capitalize' }}>{this.props.translate('save')}</Button>
                                                }
                                            />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Button size="mini" onClick={this.cancelEdit} style={{ textTransform : 'capitalize' }}>{this.props.translate('cancel')}</Button>
                                        </Table.Cell>
                                </Table.Row>
                            )
                            } else {
                                const { UID, LN, Date, US, Notes, SN, UC, UT, MID, user_id, status } = feature.properties
                                const [lng, lat] = feature.geometry.coordinates
                                return (
                                    <Table.Row key={feature.properties.Date + '-' + i} disabled={this.isRowDisabled(Date)} style={{ opacity : user_id ? 1 : 0.7 }}>

                                        <Table.Cell onClick={() => this.props.dispatch(setCurrentDate(Date))}>{UID}</Table.Cell>
                                        {/* <Table.Cell onClick={() => this.props.dispatch(setCurrentDate(Date))}>{LN.slice(0, 15) + '...'}</Table.Cell> */}
                                        <Table.Cell onClick={() => this.props.dispatch(setCurrentDate(Date))}>{SN}</Table.Cell>
                                        <Table.Cell onClick={() => this.props.dispatch(setCurrentDate(Date))}>
                                            {Date}
                                            <Button size="mini" color="teal" onClick={() => this.props.dispatch(setCurrentDate(Date))} style={{ marginLeft: '2px', textTransform : 'capitalize' }}>Go to</Button>
                                        </Table.Cell>
                                        <Table.Cell onClick={() => this.props.dispatch(setCurrentDate(Date))}>{MID}</Table.Cell>
                                        <Table.Cell onClick={() => this.props.dispatch(setCurrentDate(Date))}>{UT}</Table.Cell>
                                        <Table.Cell onClick={() => this.props.dispatch(setCurrentDate(Date))}>{UC}</Table.Cell>
                                        <Table.Cell onClick={() => this.props.dispatch(setCurrentDate(Date))}>{US}</Table.Cell>
                                        <Table.Cell onClick={() => this.props.dispatch(setCurrentDate(Date))} className="capitalize">{user_id ? status : null}</Table.Cell>
                                        <Table.Cell onClick={() => this.props.dispatch(setCurrentDate(Date))}>{Notes}</Table.Cell>
                                        <Table.Cell
                                            style={{ whiteSpace : 'nowrap' }}
                                            onClick={() => this.goToCoords({lng, lat})}
                                        >
                                            <Popup
                                                content="Click on the coordinates to fly to that location"
                                                trigger={
                                                    <div>
                                                        {lng && lat ? `${roundNumber(lng)}, ${roundNumber(lat)}`: null}
                                                    </div>
                                                }
                                            />
                                        </Table.Cell>
                                        <Table.Cell style={{ pointerEvents: 'all' }}>
                                            { feature.properties.user_id ? <Button size="mini" color="teal" onClick={() => this.editUnit(feature, i)} style={{ textTransform : 'capitalize' }}>{this.props.translate('edit')}</Button> : null }
                                        </Table.Cell>
                                        <Table.Cell>
                                            { feature.properties.user_id ?  <Button size="mini" negative onClick={() => this.deleteUnit(feature)} style={{ textTransform : 'capitalize' }}>{this.props.translate('delete')}</Button>  : null }
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }
                        })}
                    </Table.Body>
                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan='12'>
                                <Pagination
                                    activePage={startPage}
                                    totalPages={this.tablePages}
                                    onPageChange={(e, data) => this.setState({ startPage : data.activePage, editingUnit : null })}
                                />
                                <Button
                                    floated='right'
                                    icon
                                    labelPosition='left'
                                    primary
                                    size='small'
                                    className="add-button"
                                    onClick={() => this.editUnit(null, null, 'post')}
                                  >
                                    <Icon name='plus' /> Add New Feature
                                  </Button>
                              </Table.HeaderCell>
                          </Table.Row>
                    </Table.Footer>
                </Table>
            </Segment>
        )
    }
}

function roundNumber(num, scale = 3) {
    if (!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale) + "e-" + scale);
    } else {
        var arr = ("" + num).split("e");
        var sig = ""
        if (+arr[1] + scale > 0) {
            sig = "+";
        }
        return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
}

export default withLocalize(EditUserCreatedUnitsTable);

