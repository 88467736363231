import Constants, { P44_TOKEN } from '../Constants.js';

export const SET_BLOGS_LIST = 'SET_BLOGS_LIST';
export const ADD_BLOG = 'ADD_BLOG';
export const UPDATE_BLOG = 'UPDATE_BLOG';
export const REMOVE_BLOG = 'REMOVE_BLOG';
export const BLOG_ERROR = 'BLOG_ERROR';

export function getBlogs() {
  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_BACKEND}/blogs`, {
      method: 'GET',
    })
    .then(response => Constants.handleErrors(response, dispatch, blogError))
    .then(json => {
        dispatch(setBlogList(json))
    })
    .catch(error => dispatch(blogError(error)));
  }
}

export function postBlog(blog) {
  const token = window.localStorage.getItem(P44_TOKEN);

  return (dispatch) => {
    console.log('create new blog', blog);
    delete blog.id;
    return fetch(`${process.env.REACT_APP_BACKEND}/blogs`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer: ${token}`
      },
      body: JSON.stringify({
        data : [blog]
      })
    })
    .then(response => Constants.handleErrors(response, dispatch, blogError))
    .then(json => {
      console.log(json, ' new blog data');
      return dispatch(addBlog(json[0]))
    })
    .catch(error => dispatch(blogError(error)));
  }
}

export function patchBlog(data) {
  const token = window.localStorage.getItem(P44_TOKEN);

  return async (dispatch) => {
    const { id } = data;
    if (!id) {
      return dispatch(blogError('ID is invalid. Please try again.'));
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND}/blogs`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${token}`
        },
        body: JSON.stringify({
          data : [data]
        })
      });

      const json = await Constants.handleErrors(res, dispatch, blogError);
      if (res.status === 201) {
        dispatch(updateBlog(json[0]));
      }
    } catch(error) {
      dispatch(blogError(error));
    }
  }
}

export function deleteBlog(id) {
  const token =  window.localStorage.getItem(P44_TOKEN)

  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_BACKEND}/blogs`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${token}`
      },
      body: JSON.stringify({
          data : [id]
      })
    })
    .then(response => Constants.handleErrors(response, dispatch, blogError))
    .then(json => {
      dispatch(removeBlog(id))
    })
    .catch(error => dispatch(blogError(error)));
  }
}

export function setBlogList(data) {
  return {
    type : SET_BLOGS_LIST,
    payload : data 
  }
}

export function addBlog(blog) {
  return {
    type : ADD_BLOG,
    payload : blog 
  }
}

export function updateBlog(blog) {
  return {
    type : UPDATE_BLOG,
    payload : blog 
  }
}

export function removeBlog(id) {
  return {
    type : REMOVE_BLOG,
    payload : id 
  }
}


export function blogError(error) {
  return {
    type : BLOG_ERROR,
    payload : error
  }
}
