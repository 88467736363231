// import { UNIT_INFO } from '../actions/actions_units'
// import { LINE_INFO } from '../actions/actions_lines'
// import { USER_INFO } from '../actions/actions_users'
// import { DIARY_INFO } from '../actions/actions_diaries'
// import { DATE_INFO } from '../actions/actions_dates'
import { AUTH_INFO } from '../actions/actions_auth'
import { GEOJSON_INFO } from '../actions/actions_geojson'
import { DIARY_INFO } from '../actions/actions_diaries'

const info = (state = false, action) => {
  switch (action.type) {
    // case UNIT_INFO:
    // case USER_INFO:
    // case DIARY_INFO:
    // case DATE_INFO:
    // case LINE_INFO:
    case AUTH_INFO:
    case GEOJSON_INFO:
    case DIARY_INFO:
      return action.payload;
    default:
      return state
  }
}

export default info
