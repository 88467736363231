import React, { Component } from "react"
import {
    Button,
    Form,
    Message,
    Segment,
    Input,
    Checkbox,
    TextArea,
} from "semantic-ui-react"

class BlogEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            blogDetails: null
        }
        this.blogFields = [
            { 
                id: "id", 
                editable: false, 
                label: "Blog ID" 
            },
            { 
                id: "title", 
                editable: true, 
                required: true, 
                label: "Blog Title", 
                validate : true 
             },
            {
                id: "description",
                editable: true,
                label: "Description",
                component : "textarea",
                required: true,
                validate : true
            },
            {
                id: "image",
                editable: true,
                label: "Image",
                required: true,
                validate : true
            },
            {
                id: "url",
                editable: true,
                label: "Url",
                required: true,
                validate : true
            },
            {
                id: "tags",
                editable: true,
                label: "Tags",
            },
            {
                id: "featured",
                editable: true,
                label: "Featured",
                component: "checkbox"
            },
        ]
    }

    componentDidMount() {
        // on mount, scroll to top.
        if (window) {
            window.scrollTo(0, 0)
        }
    }

    componentWillReceiveProps(nextProps) {
        // if the props change, scroll to top to reveal the editor
        if (window) {
            window.scrollTo(0, 0)
        }

        const defaultFields = this.blogFields.reduce((obj, field) => {
            return {
                ...obj,
                [field.id]: field.component === "checkbox" ? false : ""
            }
        }, {})

        const blogDetails = nextProps.blogDetails ? nextProps.blogDetails : defaultFields;
        this.setState({ blogDetails });
    }

    changeBlogDetails = (event, { checked, name : blogInputName, value }) => {
        const { name } = event.target

        if (event.target.files) {
            this.setState({ newImage : event.target.files[0] });
        }

        const inputValue = checked !== undefined ? checked : value
        const inputName = checked !== undefined ? blogInputName : name
        this.setState((prevState) => ({
            blogDetails: {
                ...prevState.blogDetails,
                [inputName]: inputValue 
            }
        }))
    }

    saveBlog = () => {
        const { blogDetails, newImage } = this.state
        const validate = this.dataPassesValidation()

        if (validate.success) {
            if (newImage) {
                this.uploadToCloudinary();
            } else {
                if (blogDetails.tags === '') { blogDetails.tags = [] }
                else if (typeof blogDetails.tags === 'string') {
                    blogDetails.tags = blogDetails.tags.split(",");
                }
                blogDetails.tags = blogDetails.tags.map(tag => { return tag.trim() });

                this.props.saveBlogDetails(blogDetails)
                this.setState({ warningMsg: null })

                this.props.backToList()
            }
        } else {
            const validationErrorsString = Object.keys(validate).reduce((str, validation, i) => {
                if (i > 0) str += ", "
                str += `${validation} is invalid`
                return str
            }, "")
            this.setState({ warningMsg: validationErrorsString })
        }
    }

    deleteBlog = () => {
        if (window.confirm('Delete this blog?')) {
            this.props.deleteBlog(this.state.blogDetails)
        }
        this.props.backToList()
    }

    dataPassesValidation() {
        const { blogDetails } = this.state

        const validationMap = {
            title: value => value.length >= 1,
            description : value => value.length >= 1,
            image : value => value.length >= 1,
            url: value => value.length >= 1
        }

        const fieldsReqValidation = this.blogFields.filter(field => field.validate);

        const validation = fieldsReqValidation.reduce((validationObj, field) => {
            const valid = validationMap[field.id](blogDetails[field.id])
            validationObj[field.label] = valid
            return validationObj
        }, {})
        if (Object.values(validation).every(field => field)) {
            return { success: true }
        } else {
            Object.keys(validation).forEach(k => {
                if (validation[k]) { 
                    delete validation[k] 
                }
            });
            return validation; 
        }

    }

    uploadToCloudinary = async () => {
        const file = this.state.newImage;
        const formData = new FormData()
        formData.append('upload_preset', 'ml_default')
        formData.append('tags', 'p44_browser_upload')
        formData.append('file', file)
        formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY)
        const res = await fetch(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`, {
            method: 'POST',
            body: formData
        })
        const { secure_url } = await res.json()

        const blogDetails = {
            ...this.state.blogDetails,
            image : secure_url
        }
        this.setState({
            blogDetails,
            newImage : false
        })

        setTimeout(() => {
            this.saveBlog();
        }, 500);
    }

    render() {
        const { blogDetails, warningMsg, newImage } = this.state
        const { createMode = false } = this.props

        const componentMap = {
            input: Input,
            checkbox: Checkbox,
            textarea: TextArea,
            button: Button,
        }

        return (
            <div style={{ margin: "15px auto" }}>
                <Segment className="edit-pane">
                    <Form className="props">
                        <h3 className="ui dividing header">
                            {createMode ? "Create" : "Edit"} Blog 
                        </h3>
                        {blogDetails && this.blogFields.map(field => {
                            const resolvedComponent = field.component || "input"
                            const InputComponent = componentMap[resolvedComponent]
                            const isCheckbox = field.component === "checkbox"
                            let renderInputComponent = null
                                renderInputComponent = (
                                <InputComponent
                                    name={field.id}
                                    required={field.required}
                                    style={{ width: 250 }}
                                    checked={isCheckbox ? blogDetails[field.id] : undefined}
                                    value={isCheckbox ? undefined : blogDetails[field.id]}
                                    onChange={this.changeBlogDetails}
                                    disabled={!field.editable}
                                    rows={4}
                                />
                            )

                            return (
                                <Form.Field inline key={field.id} style={{ display: "flex" }}>
                                    <label style={{ width: 130 }}>
                                        {field.label} {field.required ? "*" : ""}
                                        {field.id === 'tags' ? 
                                            <div
                                                className="note"
                                                style={{ fontStyle : 'italic', fontSize : 12, fontWeight : 'normal', color : '#777' }}
                                            > 
                                                Comma separated list. Eg: item1,item2,item3.
                                            </div> 
                                        : null }
                                    </label>
                                    { field.id === 'image' ? 
                                         <div>
                                             { blogDetails.image && !newImage ? 
                                                <img src={blogDetails.image} style={{ height : 100 }}></img>
                                            : null }
                                            <Input
                                                name={field.id}
                                                type="file"
                                                required={field.required && !blogDetails.image}
                                                style={{ display: 'flex', marginTop : 10, width : 250 }}
                                                onChange={this.changeBlogDetails}
                                                accept=".png, .jpg, .jpeg"
                                            />
                                        </div>

                                    : renderInputComponent}
                                </Form.Field>
                            )
                        })}
                        {warningMsg ? (
                            <Message
                                warning
                                visible
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                {warningMsg}
                            </Message>
                        ) : null}
                        <div className="buttonWrap">
                            <Button onClick={() => this.saveBlog()}> Save </Button>
                            {!createMode && (
                                <Button onClick={() => this.deleteBlog()}>
                                    Delete Blog 
                                </Button>
                            )}
                            <Button onClick={this.props.backToList}> Cancel </Button>
                        </div>
                    </Form>
                </Segment>
            </div>
        )
    }
}

export default BlogEdit
