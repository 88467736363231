import { 
    SET_SIDEBAR_PANEL,
    SET_BASEMAPS_MODAL,
    SET_UNITS_LIST_ACTIVE
} from '../actions/actions_sidebar';

const initialState = {
    activeSidebarPanel : false,
    basemapsModalOpen : false,
    unitsListActive : false
}

const sidebar = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDEBAR_PANEL :
      return {
        ...state,
        activeSidebarPanel : action.payload
      };
    case SET_BASEMAPS_MODAL :
      return {
        ...state,
        basemapsModalOpen : action.payload
      };
    case SET_UNITS_LIST_ACTIVE :
      return {
        ...state,
        unitsListActive : action.payload
      };
    default:
      return state
  }
}

export default sidebar 
