import {
    GET_ALL_DATES,
    SET_CURRENT_DATE,
    SET_MONTHS_FETCHED
} from '../actions/actions_dates'

// adjust for your own timezone
const initialState = {
    allDates: [],
    currentDate: '1944-06-06',
    dateLimits: {
        min: '1941-12-01',
        max: '1945-05-31'
    },
    monthsFetched: [],
}
const dates = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_DATES:
            return {
                ...state,
                allDates: action.payload,
            };
        case SET_CURRENT_DATE:
            return {
                ...state,
                currentDate: action.payload
            };
        case SET_MONTHS_FETCHED:
            return {
                ...state,
                monthsFetched: [...state.monthsFetched, action.payload]
            };
        default:
            return state
    }
}

export default dates
