import { setPoiWindow } from '../../../actions/actions_map'

export function returnLandUnitsExpression(filters, units, layerType) {

  // Needs to incorporate checking for filters
  var expressionHolders = ['any'];

  for(var filter in filters) {
    var subExpression = ['all'];
    filters[filter].forEach((thisFilterValue) => {
      subExpression.push(['!=', thisFilterValue, ["get", filter]])
    })
    expressionHolders.push(subExpression);
  }

  var subExpression = ['all'];
  units.forEach((thisFilterValue) => {
    subExpression.push(['!=', thisFilterValue.value, ["get", "LN"]])
  })
  if(subExpression.length>1) {
    expressionHolders.push(subExpression);
  }

  var expressionFalse = 12;

  switch(layerType) {
    case 'text':
      expressionFalse = 12
      break

    case 'icons-allied':
      expressionFalse = [
        'case',
        ['==', 'Army',  ['get', 'US']], 70,
        ['==', 'Army Group',  ['get', 'US']], 70,
        ['==', 'Division',  ['get', 'US']], 60,
        ['==', 'Corp',  ['get', 'US']], 60,
        ['==', 'Company',  ['get', 'US']], 55,
        ['==', 'Platoon',  ['get', 'US']], 55,
        ['==', 'Brigade',   ['get', 'US']], 55,
        ['==', 'Battalion', ['get', 'US']], 55,
        ['==', 'Brigade', ['get', 'US']], 55,
        ['==', 'Regiment', ['get', 'US']], 55,
        20
      ]
      break

    case 'icons-simple':
    case 'icons-german':
    case 'icons-nato':
      expressionFalse = [
        'case',
        ['==', 'Army',  ['get', 'US']], 45,
        ['==', 'Army Group',  ['get', 'US']], 45,
        ['==', 'Division',  ['get', 'US']], 40,
        ['==', 'Corp',  ['get', 'US']], 40,
        ['==', 'Company',  ['get', 'US']], 32,
        ['==', 'Platoon',  ['get', 'US']], 32,
        ['==', 'Brigade',   ['get', 'US']], 32,
        ['==', 'Battalion', ['get', 'US']], 32,
        ['==', 'Brigade', ['get', 'US']], 32,
        ['==', 'Regiment', ['get', 'US']], 32,
        20
      ]
      break
  }

  return [
    'case',
    expressionHolders,
    0, // is true, don't show
    expressionFalse
  ]

}

export function returnMultiFilterExpression(filters, date, units, zoom, layerType) {
  // Date is YYYY-MM-DD
  var expressionHolders = ['any'];

  const dateProperty = !layerType.includes('line') ? 'Date' : 'FrontlineD'
  expressionHolders.push(['!=', date, ["get", dateProperty]]);

  const expressionFalse = 0
  let expressionTrue

  switch (layerType) {

    case 'circle':
      expressionTrue = [
        'case',
        ['==', 'Division',  ['get', 'US']], 14,
        ['==', 'Corp',  ['get', 'US']], 14,
        ['==', 'Army',  ['get', 'US']], 14,
        ['==', 'Company',  ['get', 'US']], 14,
        ['==', 'Platoon',  ['get', 'US']], 14,
        ['==', 'Army Group',  ['get', 'US']], 14,
        ['==', 'Brigade',   ['get', 'US']], 8,
        ['==', 'Division',   ['get', 'US']], 8,
        ['==', 'Battalion', ['get', 'US']], 4,
        ['==', 'Brigade', ['get', 'US']], 4,
        ['==', 'Regiment', ['get', 'US']], 4,
        1
      ]
      break

    case 'circle-lg':
      expressionTrue = 20
        break

    case 'line':
      expressionTrue = 10
      break

    case 'line_offset_negative':
    case 'line_offset_positive':
      expressionTrue = 6
      break

    default:
      expressionTrue = 0.75
      break
  }

  return [
    'case',
    expressionHolders,
    expressionFalse,
    expressionTrue
  ]
}

export function returnIsVisible(feature, filters, date, units, zoom) {
  // Date is YYYY-MM-DD
  var visible = true;

  if(feature.properties.Date!==date) {
    visible = false;
  }
  if (9 >= zoom && visible) {
    if(feature.properties.US !== 'Division' &&
      feature.properties.US !== 'Corp' &&
      // feature.properties.US !== 'Company' &&
      feature.properties.US !== 'Army' &&
      feature.properties.US !== 'Army Group') {
      visible = false;
    }
  }
  if (9 < zoom && 9.9 >= zoom && visible) {
    if(feature.properties.US !== 'Division' &&
       feature.properties.US !== 'Corp' &&
       feature.properties.US !== 'Army' &&
       // feature.properties.US !== 'Company' &&
       feature.properties.US !== 'Army Group' &&
       feature.properties.US !== 'Brigade' &&
       feature.properties.US !== 'Division') {
      visible = false;
    }
  }
  if (9.9 < zoom && visible) {
    if(feature.properties.US !== 'Division' &&
       feature.properties.US !== 'Battalion' &&
       feature.properties.US !== 'Company' &&
       feature.properties.US !== 'Platoon' &&
       feature.properties.US !== 'Corp' &&
       feature.properties.US !== 'Army' &&
       feature.properties.US !== 'Army Group' &&
       feature.properties.US !== 'Brigade') {
      visible = false;
    }
  }
  return visible
}

// Filtering for HTML icons
export function returnIsFiltered(feature, filters, units) {
  var filtered = false;

  if (filters.UC.length > 0) {
      for (var type in filters) {
        if (filters[type].indexOf(feature.properties[type]) === -1) {
            filtered = true;
        }
      }
  } else { filtered = true; }

  if (units.length > 0) {
    let unitFilterMatch = units.some(unit => unit.value === feature.properties.LN);
    if (!unitFilterMatch) { filtered = true; }
  }

  return filtered
}

// Filtering for radial icons
export function returnCircleOpacity(filters, units) {

    var expressionHolders = ['any'];

    for (var type in filters) {
      var filterExpression = ['all'];
      filters[type].forEach(function (filter) {
        filterExpression.push(['!=', filter, ["get", type]]);
      })
      expressionHolders.push(filterExpression);
    }

    if (units && units.length > 0) {
      var filterExpression = ['all'];
      units.forEach(function(unit) {
          filterExpression.push(['!=', unit.properties.LN, ["get",'LN']])
      });
      expressionHolders.push(filterExpression);
    }
    return [ 'case', expressionHolders, 0.4, 1 ];
}

export function returnIcon(feature) {
  return false
}

export function zoomChange(map, filters, date, units, iconType) {
  // map.setLayoutProperty('land_units', 'icon-size', returnMultiFilterExpression(filters, date, units[date], map.getZoom()));
  // map.setLayoutProperty('land_units', 'text-size', returnMultiFilterExpression(filters, date, units[date], map.getZoom(), 'text'));
}

export function handlePOIClick(map, feature, dispatch, currentUser = () => {}) {
  var title = feature.properties.title;
  var description = feature.properties.description;
  var feature_id = feature.properties.feature_id;
  var image_urls = JSON.parse(feature.properties.image_urls);

    if (currentUser.id === feature.properties.user_id) {
        dispatch(setPoiWindow({
          title,
          description,
          geometry: feature.geometry,
          method: 'patch',
          feature_id,
          image_urls,
          approved: feature.properties.status 
        }))
    }
}

export const defaultFeatureTypes = {
  point : {
    type : "Feature",
    properties : { title : "", content : "", image_url : "" },
    geometry : {
      type: "Point",
      coordinates: []
    }
  },
  line : {
    type : "Feature",
    properties : { title : "", content : "", image_url : "", lineColor : "#000000" },
    geometry : {
      type: "LineString",
      coordinates: []
    }
  },
  polygon : {
    type : "Feature",
    properties : { title : "", content : "", image_url : "", fillColor : "#000000", outlineColor : "#000000", opacity : 0.4 },
    geometry : {
      type: "Polygon",
      coordinates: []
    }
  }
}
