import {
  SET_BLOGS_LIST,
  UPDATE_BLOG,
  ADD_BLOG,
  REMOVE_BLOG
} from '../actions/actions_blogs'

const blogs = (state = {
    allBlogs : []
}, action) => {
  switch (action.type) {
    case SET_BLOGS_LIST:
      return {
        ...state,
        allBlogs : action.payload
      };
    case ADD_BLOG:
        state.allBlogs.push(action.payload);
        return {
            ...state,
        }
        return
    case UPDATE_BLOG:
        const idxToUpdate = state.allBlogs.findIndex(blog => blog.id === action.payload.id);
        state.allBlogs[idxToUpdate] = action.payload;
        return {
          ...state,
        };
    case REMOVE_BLOG:
        const idxToRemove = state.allBlogs.findIndex(blog => blog.id === action.payload);
        state.allBlogs.splice(idxToRemove, 1);
        return {
            ...state
        }

    default:
      return state
  }
}

export default blogs; 
