import {
  GET_LINES,
  SET_ACTIVE_LINE
} from '../actions/actions_lines'

const lines = (state = {
  allLines : [],
  activeLine : []
}, action) => {
  switch (action.type) {
    case GET_LINES:
      // var newLines = JSON.parse(JSON.stringify(state.allLines));
      // action.payload.forEach(function(feature) {
      //   if(newLines[feature.properties.FrontlineD]) {
      //     newLines[feature.properties.FrontlineD].push(feature);
      //   } else {
      //     newLines[feature.properties.FrontlineD] = [feature];
      //   }
      // })
      return {
        ...state,
        allLines : action.payload
      };
    case SET_ACTIVE_LINE:
      return {
        ...state,
        activeLines : action.payload
      };
    default:
      return state
  }
}

export default lines
