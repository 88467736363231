import Constants, { P44_TOKEN } from '../Constants.js';
import moment from 'moment';

export const GET_DIARY_LIST = 'GET_DIARY_LIST';
export const ADD_DIARY = 'ADD_DIARY';
export const EDIT_DIARY = 'EDIT_DIARY';
export const REMOVE_DIARY = 'REMOVE_DIARY';
export const SET_ACTIVE_DIARY = 'SET_ACTIVE_DIARY';
export const DIARY_ERROR = 'DIARY_ERROR';
export const DIARY_INFO = 'DIARY_INFO';
export const SET_ALL_DIARIES = 'SET_ALL_DIARIES';
export const SET_DIARIES_SEARCHING = 'SET_DIARIES_SEARCHING';
export const SET_SHOW_DIARY_LIST = 'SET_SHOW_DIARY_LIST'
export const SET_UNITS_WITH_DIARIES = 'SET_UNITS_WITH_DIARIES'

export function getDiaryList() {
  return (dispatch) => {

    return fetch(`${process.env.REACT_APP_BACKEND}/war_diaries/range`, {
      method: 'GET',
      // headers: {
      //   'Content-type': 'application/json'
      // }
    })
    .then(response => Constants.handleErrors(response, dispatch, diaryError))
    .then(json => dispatch(setDiaryList(json)))
    .catch(error => dispatch(diaryError(error)));
  }
}

export function postDiary(diary, qs, token) {
  token = token ? token : window.localStorage.getItem(P44_TOKEN)

  return (dispatch) => {
    delete diary.id;
    return fetch(`${process.env.REACT_APP_BACKEND}/war_diaries?${qs}`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer: ${token}`
      },
      body: JSON.stringify({
        war_diaries: Array.isArray(diary) ? diary : [diary]
      })
    })
    .then(response => Constants.handleErrors(response, dispatch, diaryError))
    .then(json => {
      dispatch(diaryInfo({ msg: `Diaries uploaded succesfully. ${json.created} were created and ${json.updated} were updated.` }))
      return dispatch(addDiary(json))
    })
    .catch(error => dispatch(diaryError(error)));
  }
}

export function patchDiary(data, refreshDiaries, token) {
  token = token ? token : window.localStorage.getItem(P44_TOKEN)

  return async (dispatch) => {
    const { id } = data;
    if (!id) {
      return dispatch(diaryError('ID is invalid. Please try again.'));
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND}/war_diaries`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${token}`
        },
        body: JSON.stringify({
          war_diaries: [data]
        })
      });

      const json = await Constants.handleErrors(res, dispatch, diaryError);
      if (res.status === 201) {
        dispatch(editDiary(json))
        if (refreshDiaries) {
          dispatch(getAllDiaries())
        }
      }
    } catch(error) {
      dispatch(diaryError(error));
    }
  }
}

export function deleteDiary(id, token) {
  token = token ? token : window.localStorage.getItem(P44_TOKEN);
  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_BACKEND}/war_diaries/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer: ${token}`
      }
    })
    .then(response => Constants.handleErrors(response, dispatch, diaryError))
    .then(json => {
      dispatch(removeDiary(json))
      dispatch(getAllDiaries())
    })
    .catch(error => dispatch(diaryError(error)));
  }
}

export function getDiary(unit_id) {
  return (dispatch) => {
    // fetch(window.location.href+'data/units/'+unitNumber+'.json')
    return fetch(`${process.env.REACT_APP_BACKEND}/war_diaries/?UID=${unit_id}`, {
      method: 'GET',
      // headers: header
    })
    .then(response => Constants.handleErrors(response, dispatch, diaryError))
    .then(json => {
            let diaries;
            if (json.length > 1) {
                diaries = json.sort((a,b) => new moment(a.Date).format('YYYYMMDD') - new moment(b.Date).format('YYYYMMDD'))
            } else {
                diaries = json;
            }
            dispatch(setActiveDiary(diaries))
        }
     )
    //.then(json => dispatch(setActiveDiary(json)))
    .catch(error => dispatch(diaryError(error)));
  }
}

export function getAllDiaries(dates) {
  const dateQuery = dates && dates.startDate ? `?Date=${dates.startDate},${dates.endDate}` : ``;

  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_BACKEND}/war_diaries${dateQuery}`, {
      method: 'GET'
    })
    .then(response => Constants.handleErrors(response, dispatch, diaryError))
    .then(json => {
      dispatch(setAllDiaries(json))
      dispatch(setDiariesSearching(false))
    })
    .catch(error => dispatch(diaryError(error)));
  }
}

export function getUnitsWithDiaries() {

  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_BACKEND}/war_diaries/available_war_diaries`, {
      method: 'GET'
    })
    .then(response => Constants.handleErrors(response, dispatch, diaryError))
    .then(json => {
      json.sort((a, b) => a.EUnitID < b.EUnitID ? -1 : 1);
      dispatch(setUnitsWithDiaries(json))
    })
    .catch(error => dispatch(diaryError(error)));
  }
}

export function deleteAllDiaries(token = window.localStorage.getItem(P44_TOKEN)) {
  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_BACKEND}/war_diaries`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer: ${token}`
      }
    })
    .then(response => Constants.handleErrors(response, dispatch, diaryError))
    .then(() => dispatch(getAllDiaries()))
    .catch(error => dispatch(diaryError(error)))
  }
}

export function setShowDiaryList(diaryList) {
  return {
    type: SET_SHOW_DIARY_LIST,
    payload: diaryList
  }
}


export function setDiaryList(list) {
  return {
    type : GET_DIARY_LIST,
    payload : list
  }
}

export function setUnitsWithDiaries(data) {
  return {
    type : SET_UNITS_WITH_DIARIES,
    payload : data
  }
}

export function setActiveDiary(active) {
  return {
    type : SET_ACTIVE_DIARY,
    payload : active
  }
}

export function addDiary(diary) {
  return {
    type : ADD_DIARY,
    payload : diary
  }
}

export function editDiary(diary) {
  return {
    type : EDIT_DIARY,
    payload : diary
  }
}

export function removeDiary(diary) {
  return {
    type : REMOVE_DIARY,
    payload : diary
  }
}

export function diaryError(error) {
  return {
    type : DIARY_ERROR,
    payload : error
  }
}

export function diaryInfo(msg) {
  return {
    type: DIARY_INFO,
    payload: msg
  }
}

export function closeDiary() {
  return setActiveDiary(false)
}

export function setAllDiaries(payload) {
  return {
    type: SET_ALL_DIARIES,
    payload
  }
}

export function setDiariesSearching(isSearching) {
  return {
    type: SET_DIARIES_SEARCHING,
    payload: isSearching
  }
}
