import React, { Component } from "react";
import { Button, Form, Message, Segment } from "semantic-ui-react";
import { Translate } from "react-localize-redux";

import {
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import {
  loginUser,
  sendResetPassword,
  verifyTokenLogin
} from "../../actions/actions_auth";
import { createUser } from "../../actions/actions_users";
import { P44_TOKEN } from "../../Constants";

const initialState = {
  email: "",
  password: "",
  errors: [],
  infos: [],
  formMode: "login", // 'login','reset','register'
  resetSent: false
};
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = Object.assign({}, initialState);
  }

  changeVal = (e, { name, value }) => this.setState({ [name]: value });

  validateLogin = () => {
    let { email, password, errors } = this.state;
    errors = [];

    // Email validation
    if (email.length === 0) {
      errors.push({ type: "email", msg: "Please enter your e-mail" });
    } else if (email.length < 6) {
      errors.push({ type: "email", msg: "Please enter a valid e-mail" });
    }

    // Password Validation
    if (password.length < 6) {
      errors.push({
        type: "password",
        msg: "Your password must be at least 6 characters"
      });
    }

    this.setState({ errors });
    if (errors.length === 0) {
      switch (this.state.formMode) {
        case "login":
          this.doLogin();
          break;
        case "register":
          this.doRegister();
          break;
        case "reset":
          this.doReset();
          break;
      }
    }
  };

  doLogin = async () => {
    const { email, password } = this.state;
    await this.props.dispatch(loginUser({ email, password }));

    if (!(this.state.errors.length || this.state.infos.length)) {
      this.props.closeLoginModal();
    }
  };

  doRegister = async () => {
    const { email, password } = this.state;
    await this.props.dispatch(createUser({ email, password }));
  };

  doReset = async () => {
    const { email } = this.state;
    await this.props.dispatch(sendResetPassword({ email }));
    this.setState({ resetSent: true });
  };

  closeModal = () => {
    this.setState({
      ...initialState
    });
    this.props.closeLoginModal();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ errors: [nextProps.error] });
    }
    if (nextProps.info) {
      this.setState({ infos: [nextProps.info] });
    }
  }

  componentDidMount() {
    // const token = window.localStorage.getItem(P44_TOKEN);
    // if (token) {
    //   this.props.dispatch(verifyTokenLogin(token));
    // }
  }

  render() {
    const { email, password, errors, infos } = this.state;
    return (
      <div>
        <div className="uppercase">
          <Translate id="login" />
        </div>
        <Modal
          backdrop="static"
          centered={true}
          isOpen={this.props.loginModal}
          toggle={this.props.closeLoginModal}
        >
          <ModalHeader>
            {this.state.formMode === "login"
              ? "Log-in to your account"
              : this.state.formMode === "reset"
              ? "Reset your password"
              : "Register for a new account"}
            <Button
              size="mini"
              className="modal-close"
              onClick={this.closeModal}
            >
              <i className="fas fa-times"></i>
            </Button>
          </ModalHeader>
          <ModalBody>
            <Form size="large">
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="E-mail address"
                  name="email"
                  value={email}
                  onChange={this.changeVal}
                  className={
                    errors.find(error => error.type === "email") ? "error" : ""
                  }
                />
                {this.state.formMode === "login" ||
                this.state.formMode === "register" ? (
                  <Form.Input
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder="Password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={this.changeVal}
                    className={
                      errors.find(error => error.type === "password")
                        ? "error"
                        : ""
                    }
                  />
                ) : null}
                <Button
                  color="black"
                  fluid
                  size="large"
                  onClick={this.validateLogin} 
                >
                  {this.state.formMode === "login"
                    ? "Login"
                    : this.state.formMode === "reset"
                    ? "Reset Password"
                    : "Register"}
                </Button>
              </Segment>
            </Form>
            {errors.length ?
              errors.map(error => {
                  // console.log(error)
                return <Message error content={error.msg || error.message} key={error.msg || error.message} />;
              }) : null}
            {infos.length > 0 &&
              infos.map(info => {
                return <Message info content={info.msg} key={info.msg} />;
              })}
          </ModalBody>
          <ModalFooter style={{ justifyContent: "unset" }}>
            <Container>
              <Row>
                <Col sm={{ size: 6 }}>
                  {this.state.formMode === 'login' ? 
                  (<Message>
                    New to us?{" "}
                    <a
                      href="#"
                      onClick={() => {
                        this.setState({ formMode: "register" });
                      }}
                    >
                      Sign Up
                    </a>
                  </Message>) :
                  (<Message>
                    Have an account?{" "}
                    <a
                      href="#"
                      onClick={() => {
                        this.setState({ formMode: "login" });
                      }}
                    >
                      Login
                    </a>
                  </Message>)}
                </Col>
                <Col sm={{ size: 6 }}>
                  <Message>
                    Forgot password?{" "}
                    <a
                      href="#root"
                      onClick={() => {
                        this.setState({ formMode: "reset" });
                      }}
                    >
                      Reset
                    </a>
                  </Message>
                </Col>
              </Row>
            </Container>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Login;
