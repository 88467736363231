import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import { Menu, Checkbox, Segment, Radio, Header } from 'semantic-ui-react';
import { setActiveSidebarPanel, updateVisibleLayers } from '../../actions/actions_map';

class Layers extends Component {

  constructor(props) {
    super(props);
    this.state = {
        // openSections : ['basemaps', 'layers'],
        openSections : ['layers']
    }
  }

  shortNameToLongName(shortName, translate) {
    const nameMap = {
      'front_line'  : translate('front_lines'),
      'poi-features' : translate('photo_points'),
      'storymap-points' : translate('story_map_points'),
      'terrain' : translate('terrain'),
    }
    return nameMap[shortName]
  }

  toggleSection(section) {
    let { openSections } = this.state
    const idx = openSections.indexOf(section)

    if (idx > -1) { openSections.splice(idx, 1) }
    else { openSections.push(section) }

    this.setState({ openSections })
  }

  toggleLayer = (layer, type) => {
    let visibleLayers = JSON.parse(JSON.stringify(this.props.visibleLayers));

    visibleLayers[type][layer] = !visibleLayers[type][layer];
    this.props.dispatch(updateVisibleLayers(visibleLayers));
  }

  renderHeader = (type, translate) => {
    const { openSections } = this.state;
    const title = translate(type);
    return (
        <div onClick={() => this.toggleSection(type)} style={{ cursor : 'pointer' }}>
            <i 
                className="fa fa-angle-double-right "
                style={{ transform : (openSections.indexOf(type) > -1 ? 'rotate(90deg)' : 'none') }}
            ></i>
            {title}
        </div>
    )
  }

  render() {
      const { dispatch, visibleLayers } = this.props;
      const { openSections } = this.state;

      return (
        <Segment inverted className="map-settings capitalize">
          <Translate>
            {({ translate, activeLanguage, languages }) => (
            <div>
                {/* <Header className={"accordion-title"}>
                  <i className="fa fa-times" onClick={() => this.props.dispatch(setActiveSidebarPanel(false))}></i>
                  {translate("layers")}
                </Header> */}
                  <Menu.Item id="layers-accordion">
                    <Header>{this.renderHeader('layers', translate)}</Header>
                    <div className={"accordion" + (openSections.indexOf('layers') > -1 ? ' active' : '' )}>
                        { Object.keys(visibleLayers.staticLayers).map(layer => {
                          if (layer === 'terrain') {
                            // not showing terrain button in this form any longer
                            return null
                          }
                          return (
                              <Checkbox
                                className="capitalize"
                                key={layer}
                                toggle
                                checked={visibleLayers.staticLayers[layer]}
                                onChange={() => this.toggleLayer(layer, 'staticLayers')}
                                label={this.shortNameToLongName(layer, translate)}
                                style={{ display : 'block' }}
                              />
                          )
                        })}

                      <div style={{ textAlign : 'center', fontFamily : 'Barlow Condensed', fontSize : 20, margin : 10 }}> {translate('additional_layers')}</div>

                      { Object.keys(visibleLayers.customLayers).map(layer => {
                        return (
                          <Checkbox
                            className="capitalize"
                            key={layer}
                            style={{ display : 'block' }}
                            toggle
                            checked={visibleLayers.customLayers[layer]}
                            onChange={() => this.toggleLayer(layer, 'customLayers')}
                            label={layer}
                          />
                        )
                      })}
                    </div>
                  </Menu.Item>
              </div>
            )}
          </Translate>
        </Segment>
      );
  }
}

export default Layers;
