import { connect } from 'react-redux'

import SideBarComponent from '../../components/FrontPage/SideBar.js'

const mapStateToProps = state => {
  return {
    addingPoint : state.mainReducer.map.addingPoint,
    savingPoint : state.mainReducer.map.savingPoint,
    activeUnitDiaries: state.mainReducer.diaries.activeDiary,
    currentUser: state.mainReducer.users.currentUser,
    activeSidebarPanel: state.mainReducer.sidebar.activeSidebarPanel,
    basemapsModalOpen : state.mainReducer.sidebar.basemapsModalOpen,
    unitsListActive : state.mainReducer.sidebar.unitsListActive
  }
}

const SideBar = connect(
  mapStateToProps
)(SideBarComponent)

export default SideBar 
