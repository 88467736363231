import React, { Component } from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import "react-table/react-table.css";

import ItemEdit from "./ItemEdit";
import UserEdit from "./UserEdit";
import { getUnitsDistinct } from "../../actions/actions_units";
import {
    patchUser,
    deleteUser,
    createUser,
    sendEmailVerificationEmail
} from "../../actions/actions_users";

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { dispatch } = this.props;
        if (this.props.distinctUnits.length < 1) {
            this.props.dispatch(getUnitsDistinct())
        }
        const tableData = this.props.allUsers;
        const columns = [
            { Header: "Email", accessor: "email" },
            { Header: "ID", accessor: "id" },
            {
                Header: "Subscription Type",
                accessor: "subscription_type",
                className: "capitalize-text",
                Cell: ({ value }) => value.replace('_', ' ')
            },
            {
                Header: "Email Verified",
                accessor: "email_verified",
                className: "center-text",
                Cell: ({ value }) => {
                    if (value) {
                        return (
                            <i className='check icon' />
                        )
                    } else { return null }
                }
            },
            {
                Header: "Role",
                accessor: "role",
                className: "center-text",
                Cell: ({ value }) => {
                    return <p className="capitalize">{value}</p>
                }
            },
            {
                Header: "Edit",
                accessor: "id",
                maxWidth: 100,
                filterable: false,
                Cell: row => (
                    <Link to={"/dashboard/users/" + row.value}>
                        <Button> Edit </Button>
                    </Link>
                )
            },
            {
                Header: "POI List",
                accessor: "id",
                maxWidth: 100,
                filterable: false,
                Cell: row => (
                    <Link to={"/dashboard/pois/" + row.value}>
                        <Button> View POIs </Button>
                    </Link>
                )
            }
        ]
        const isId = this.props.itemId || null;
        const userDetails = this.props.allUsers.find((user) => {
            return user.id === Number(this.props.itemId)
        })
        const isCreateMode = isId === "new" || !userDetails;

        return (
            <div>
                {isId ? (
                    <React.Fragment>
                        <UserEdit
                            isAdmin={this.props.currentUser.role}
                            itemType="User"
                            createMode={isCreateMode}
                            userDetails={userDetails}
                            saveUserDetails={data => {
                                isCreateMode
                                    ? dispatch(createUser(data, false))
                                    : dispatch(patchUser(data));
                            }}
                            onResendEmail={() => dispatch(sendEmailVerificationEmail(userDetails.email))}
                            deleteUser={(user) => dispatch(deleteUser(user.id))}
                            backToList={() => this.props.history.push("/dashboard/users")}
                            distinctUnits={this.props.distinctUnits}
                        />
                    </React.Fragment>
                ) : null}
                <Link to="/dashboard/users/new">
                    <Button icon color="teal" labelPosition="left">
                        <Icon name="user plus" />
                        Add New
                    </Button>
                </Link>
                <hr />
                <ReactTable
                    data={tableData}
                    columns={columns}
                    filterable={true}
                    defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id;
                        return row[id] !== undefined
                            ? String(row[id])
                                .toLowerCase()
                                .indexOf(filter.value.toLowerCase()) > -1
                            : true;
                    }}
                    className="feature-table"
                />
            </div>
        );
    }
}

export default Dashboard;
