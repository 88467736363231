import { connect } from 'react-redux'

import LayersComponent from '../../components/FrontPage/Layers.js'

const mapStateToProps = state => {
  return {
    visibleLayers : state.mainReducer.map.visibleLayers
  }
}

const Layers = connect(
  mapStateToProps
)(LayersComponent)

export default Layers 
