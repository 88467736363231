import React, { Component } from 'react';

class MagnifyingGlassTool extends Component {

  constructor(props) {
    super(props);
    this.bgSize = 0;
    this.imgZoom = 2;
    this.glassSize = 120;
    this.canvas = document.getElementsByClassName("mapboxgl-canvas")[0];
    this.state = {
      img : false,
      cursorPos : []
    }
  }

  componentDidMount() {
    this.captureCanvas();
    document.getElementsByClassName("mapboxgl-canvas-container")[0].style.cursor = 'none';

    this.canvas.addEventListener("mousemove", this.mouseMove);
    this.canvas.addEventListener("touchstart", this.mouseMove);
    this.canvas.addEventListener("touchmove", this.mouseMove);

    // Handles map zoom/pan changes
    this.props.map.on('movestart', this.captureCanvas);
    this.props.map.on('moveend', this.captureCanvas);
  }

  captureCanvas = () => {
    if (!this.props.map.isStyleLoaded()) {
        setTimeout(() => {
            this.captureCanvas();
        }, 200);
    } else {
        this.canvas = document.getElementsByClassName("mapboxgl-canvas")[0];
        this.bgSize = (this.canvas.getAttribute("width") / window.devicePixelRatio) * this.imgZoom;
        var img = this.canvas.toDataURL("image/png")
        this.setState({ img });
    }
  }

  mouseMove = (e) => {
    let x, y;
    if (e.type === 'touchmove' || e.type === 'touchstart') {
        var touch = e.touches[0];
        x = touch.pageX;
        y = touch.pageY;

    } else {
        x = e.offsetX;
        y = e.offsetY;
    }

    this.setState({ cursorPos : [ x, y ] });
  };

  componentWillUnmount() {
    //Remove Event Listeners, add back cursor
    this.canvas.removeEventListener("mousemove", this.mouseMove);
    this.canvas.removeEventListener("touchmove", this.mouseMove);
    this.canvas.removeEventListener("touchstart", this.mouseMove);

    this.props.map.off("movestart", this.captureCanvas);
    this.props.map.off("moveend", this.captureCanvas);

    document.getElementsByClassName("mapboxgl-canvas-container")[0].style.cursor = 'grab'
  }


  render() {
    const { cursorPos, img } = this.state;
    const backgroundPosition = `-${(cursorPos[0] * this.imgZoom) - (this.glassSize / 2)}px -${(cursorPos[1] * this.imgZoom) - (this.glassSize / 2)}px`;

    return (
      <div>
          { img && cursorPos.length > 0 ? 
              <div 
                className="magnifying-glass-tool"
                style={{ 
                    top : (cursorPos[1]) - (this.glassSize / 2),
                    left : (cursorPos[0]) - (this.glassSize / 2),
                    backgroundImage : 'url(' + img + ')',
                    backgroundSize : this.bgSize,
                    backgroundPosition,
                    width : this.glassSize,
                    height : this.glassSize
                }} 
              />
          : false }
      </div>
    )
  }
}

export default MagnifyingGlassTool;
