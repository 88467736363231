import React, { Component } from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { Button, Icon, Segment } from "semantic-ui-react";
import "react-table/react-table.css";

import BlogEdit from "./BlogEdit";
import {
    patchBlog,
    postBlog,
    deleteBlog
} from "../../actions/actions_blogs";

class Blogs extends Component {
    render() {
        const { dispatch } = this.props;
        const tableData = this.props.allBlogs;
        const columns = [
            { Header: "Title",          accessor: "title" },
            { Header: "Description",    accessor: "description" },
            { 
                Header: "Image",
                accessor: "image",
                className: "center-text",
                filterable: false,
                Cell: row => (
                    <img src={row.value} style={{ width : 100 }}></img>
                )
            },
            { Header: "Url",    accessor: "url" },
            { 
                Header: "Tags",
                accessor: "tags",
                Cell: ({ row, value }) => {
                    return (
                        <div>
                            {value.map(val => {
                                return ( <div key={`tag-${row.id}-${val}`}>{val}</div> )
                            })}
                        </div>
                    )
                }
            },
            { 
                Header: "Featured",
                accessor: "featured",
                className: "center-text",
                maxWidth: 100,
                filterable: false,
                Cell: ({ value }) => {
                    if (value) {
                        return (
                            <i className='check icon' />
                        )
                    } else { return null }
                }
            },
            {
                Header: "Edit",
                accessor: "id",
                maxWidth: 100,
                filterable: false,
                Cell: row => (
                    <Link to={"/dashboard/blogs/" + row.value}>
                        <Button> Edit </Button>
                    </Link>
                )
            }
        ]

        const isId = this.props.itemId || null;
        const newButtonLink = "/dashboard/blogs/new"
        const newButtonIcon = "book"
        const blogDetails = this.props.allBlogs.find((item) => {
            return item.id == this.props.itemId
        })
        const isCreateMode = isId === "new" || !blogDetails;

        return (
            <div>
                {isId ? (
                    <React.Fragment>
                        <BlogEdit
                            itemType="Blog"
                            createMode={isCreateMode}
                            blogDetails={blogDetails}
                            saveBlogDetails={blog => {
                                isCreateMode
                                    ? dispatch(postBlog(blog))
                                    : dispatch(patchBlog(blog, true));
                            }}
                            deleteBlog={blog => dispatch(deleteBlog(blog.id))}
                            backToList={() => this.props.history.push("/dashboard/blogs" + this.props.location.search)}
                        />
                    </React.Fragment>
                ) : null}
                <Link to={newButtonLink}>
                    <Button icon color="teal" labelPosition="left">
                        <Icon name={newButtonIcon} />
                        Add New
                    </Button>
                </Link>
                <ReactTable
                    data={tableData}
                    columns={columns}
                    filterable={true}
                    defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id;
                        return row[id] !== undefined
                            ? String(row[id])
                                .toLowerCase()
                                .indexOf(filter.value.toLowerCase()) > -1
                            : true;
                    }}
                    className="feature-table blogs-table"
                />
            </div>
        )
    }
}

export default Blogs;
