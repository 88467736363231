import React, { Component } from 'react';
import { Menu, Checkbox, Segment, Header } from 'semantic-ui-react';
import Select from 'react-select';
import { Translate, withLocalize } from "react-localize-redux";

import { setCurrentFilters, updateSelectedUnits } from '../../actions/actions_map';
import { setActiveSidebarPanel } from '../../actions/actions_sidebar';
import Layers from '../../containers/FrontPage/Layers';
import Settings from '../../containers/FrontPage/Settings';
import IconSettings from '../../containers/FrontPage/IconSettings';

class Filters extends Component {

  constructor(props) {
    super(props);
    this.state = {
        // openSections : ['country_filter', 'unit_type_filter', 'unit_size_filter'],
        openSections : []
    }
  }

  shortNameToLongName(shortName) {
    const nameMap = {
      GB: this.props.translate('great_britain'),
      CAN: this.props.translate('canada'),
      POL: this.props.translate('poland'),
      USA: this.props.translate('united_states'),
      FRE: this.props.translate('france'),
      GMN: this.props.translate('germany'),
      IND: this.props.translate('india'),
      SA: this.props.translate('south_africa'),
      BRA: this.props.translate('brazil'),
      ITA: this.props.translate('italy'),
      NZ: this.props.translate('new_zealand'),
      JPN: this.props.translate('japan'),
      CZE: this.props.translate('czechoslovakia'),
    }
    return nameMap[shortName]
  }

  updateFilters(type, value) {
    var newFilters = JSON.parse(JSON.stringify(this.props.currentFilters));
    if(this.props.currentFilters[type].indexOf(value)>-1) {
      newFilters[type].splice(this.props.currentFilters[type].indexOf(value), 1);
    } else {
      newFilters[type].push(value);
    }

    this.props.dispatch(setCurrentFilters(newFilters));
  }

  toggleSection(section) {
    let { openSections } = this.state
    const idx = openSections.indexOf(section)

    if (idx > -1) { openSections.splice(idx, 1) }
    else { openSections.push(section) }

    this.setState({ openSections })
  }

  renderHeader = (type) => {
    const { openSections } = this.state;
    const title = this.props.translate(type);
    return (
        <div onClick={() => this.toggleSection(type)} style={{ cursor : 'pointer' }}>
            <i 
                className="fa fa-angle-double-right "
                style={{ transform : (openSections.indexOf(type) > -1 ? 'rotate(90deg)' : 'none') }}
            ></i>
            {title}
        </div>
    )
  }

  closeSettings() {
    const newPanel = this.props.activeUnitDiaries ? 'warDiaries' : false;
    this.props.dispatch(setActiveSidebarPanel(newPanel));
  }

  render() {
      const { allFilters, currentFilters, allUnits, currentSelectedUnits, currentDate } = this.props;
      const { openSections } = this.state;

      var options = [];
      if(allUnits[currentDate]) {
        var theseOptions = allUnits[currentDate].map(feature => {
          return { value : feature.properties.LN, label : feature.properties.LN }
        });
        options = options.concat(theseOptions);
      }

      return (
        <Segment inverted className="filters-box">
          <Header className={"capitalize accordion-title"}>
            <i className="fa fa-times" onClick={() => this.closeSettings()}></i>
            {this.props.translate('map_settings')}
          </Header>

          <div>
              <div>
                  <div className="iconsContainer">
                      <IconSettings />
                  </div>

                  <div className="layersContainer">
                      <Layers/>
                  </div>

                  <Menu.Item id="unit-country-accordion">
                    <Header>{this.renderHeader('country_filter')}</Header>
                    {/* <Header>{this.renderHeader('country')}</Header> */}

                    <div className={"accordion" + (openSections.indexOf('country_filter') > -1 ? ' active' : '' )}>
                        {allFilters.UC.map((filter, index) => {
                          return (
                            <div key={`${filter}-${index}`}>
                              <Checkbox
                                className="capitalize"
                                toggle
                                checked={currentFilters.UC.indexOf(filter) > -1}
                                onChange={this.updateFilters.bind(this, 'UC', filter)}
                                label={this.shortNameToLongName(filter)}
                              />
                            </div>
                          )
                        })}
                    </div>
                  </Menu.Item>
                  <Menu.Item id="unit-type-accordion">
                    <Header>{this.renderHeader('unit_type_filter')}</Header>
                    <div className={"accordion" + (openSections.indexOf('unit_type_filter') > -1 ? ' active' : '' )}>
                        {allFilters.UT.map((filter, index) => {
                          return (
                            <div key={`${filter}-${index}`}>
                              <Checkbox
                                className="capitalize"
                                toggle
                                checked={currentFilters.UT.indexOf(filter) > -1}
                                onChange={this.updateFilters.bind(this, 'UT', filter)}
                                label={this.props.translate(filter.toLowerCase())}
                              />
                            </div>
                          )
                        })}
                    </div>
                  </Menu.Item>
                  <Menu.Item id="unit-size-accordion">
                    <Header>{this.renderHeader('unit_size_filter')}</Header>
                    <div className={"accordion" + (openSections.indexOf('unit_size_filter') > -1 ? ' active' : '' )}>
                        {allFilters.US.map((filter, index) => {
                          return (
                            <div key={`${filter}-${index}`}>
                              <Checkbox
                                className="capitalize"
                                toggle
                                checked={currentFilters.US.indexOf(filter) > -1}
                                onChange={this.updateFilters.bind(this, 'US', filter)}
                                label={this.props.translate(filter.toLowerCase().replace(' ', '_'))}
                              />
                            </div>
                          )
                        })}
                    </div>
                  </Menu.Item>

                  <div className="settingsContainer"> 
                    <Settings />
                  </div>
                </div>
            </div>
        </Segment>
      );
  }
}

export default withLocalize(Filters);
