import bbox from '@turf/bbox'
import { returnLandUnitsExpression, returnMultiFilterExpression, returnCircleOpacity } from './MapUtil';
import { setMapLoadStatus } from '../../../actions/actions_map';

const gbColor = '#8b7641';
const canColor = '#aa1d1d';
const gmnColor = '#191515';
const usaColor = '#1e338f';
const polColor = '#eb880f';
const freColor = '#3687d3';
const itaColor = '#36a645';
const jpnColor = '#fc7f03';
const braColor = usaColor;
const nzColor = gbColor;
const saColor = gbColor;
const indColor = gbColor;
const czeColor = gbColor;

export function addFrontlineLayer(map, json, dispatch, filters, date, units, loadPercentage, isVisible) {
    if (!map.getSource('front_line')) {
      var featureCollection = { type: "FeatureCollection", features: json };
      featureCollection.features = featureCollection.features.map((feature) => {
        if (!feature.id) {
          feature.id = '_' + Math.random().toString(36).substr(2, 9);
        }
        return feature
      })
      map.addSource('front_line', {
        type: 'geojson',
        data: featureCollection
      })
    }

    if (!map.getLayer('front_line')) {
      map.addLayer({
        'id': 'front_line',
        'type': 'line',
        'source': 'front_line',
        'layout': {
          'visibility': isVisible ? 'visible' : 'none',
        },
        'paint': {
          'line-width': returnMultiFilterExpression(filters, date, units[date], map.getZoom(), 'line'),
          'line-color': '#000',
          'line-opacity': 1,
          'line-blur': 1,
        }
      })
      dispatch(setMapLoadStatus({
        areFrontLinesLoaded: true,
        loadPercentage: loadPercentage + 50
      }))
    }
    if (!map.getLayer('front_line_offset_negative')) {
      console.log('line-width-neg', returnMultiFilterExpression(filters, date, units[date], map.getZoom(), 'line_offset_negative'))
      map.addLayer({
        'id': 'front_line_offset_negative',
        'type': 'line',
        'source': 'front_line',
        'layout': {
          'visibility': isVisible ? 'visible' : 'none',
        },
        'paint': {
          'line-width': returnMultiFilterExpression(filters, date, units[date], map.getZoom(), 'line_offset_negative'),
          'line-color': '#c52020',
          'line-opacity': 1,
          'line-dasharray': [3, 0.5],
          'line-blur': 1,
          'line-offset': -2.5,
        }
      })
    }
    if (!map.getLayer('front_line_offset_positive')) {
      console.log('line-width-pos', returnMultiFilterExpression(filters, date, units[date], map.getZoom(), 'line_offset_positive'))
      map.addLayer({
        'id': 'front_line_offset_positive',
        'type': 'line',
        'source': 'front_line',
        'layout': {
          'visibility': isVisible ? 'visible' : 'none',
        },
        'paint': {
          'line-width': returnMultiFilterExpression(filters, date, units[date], map.getZoom(), 'line_offset_positive'),
          'line-color': '#2663c0',
          'line-opacity': 1,
          'line-dasharray': [3, 0.5],
          'line-blur': 1,
          'line-offset': 2.5,
        }
      })
    }
}

export function addMapOverlay(map) {
    // Add Overlays
    var layers = map.getStyle().layers;
    var firstSymbolId;
    for (var i = 0; i < layers.length; i++) {
      if (layers[i].type === 'symbol') {
        firstSymbolId = layers[i].id;
        break;
      }
    }

    if (map && !map.getSource('omc-tileset-map-overlay')) {
      // Find the index of the first symbol layer in the map style
      // and add layer below it so that the radial units and front-line layers don't get covered
      map.addSource('omc-tileset-map-overlay', {
        "type": "raster",
        "url": "mapbox://outdoormappingcompany.1bphcjug",
        "tileSize": 256
      })
      map.addLayer({
        "id": "omc-tileset-map-overlay",
        "type": "raster",
        "source": "omc-tileset-map-overlay",
        "minzoom": 0,
        "maxzoom": 22,
        "layout": {
          "visibility": "none"
        }
      }, firstSymbolId)
    }

    if (map && !map.getLayer('wms-overlay')) {
      map.addLayer({
        'id': 'wms-overlay',
        'type': 'raster',
        "minzoom": 11,
        'source': {
          'type': 'raster',
          'tiles': [
            // 'http://wxs.ign.fr/ea2w89mt6t5g61fqvsrbi9ss/geoportail/wmts?service=WMTS&request=GetTile&version=1.0.0&tilematrixset=PM&tilematrix={z}&tilecol={x}&tilerow={y}&layer=ORTHOIMAGERY.ORTHOPHOTOS.1950-1965&format=image/png&style=normal'
            'https://wxs.ign.fr/orthohisto/geoportail/wmts?layer=ORTHOIMAGERY.ORTHOPHOTOS.1950-1965&style=BDORTHOHISTORIQUE&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix={z}&TileCol={x}&TileRow={y}'
          ],
          'tileSize': 256
        },
        'paint': {},
        "layout": {
          "visibility": "none"
        }
      }, firstSymbolId)
    }

    // if (map && !map.getSource('aerial-overlay')) {
    //   map.addSource('aerial-overlay', {
    //     "type": "raster",
    //     "url": "https://wxs.ign.fr/9y7hem0na87y9ygrtn4qvnit/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetCapabilities",
    //   })
    // }
}

export function addApprovedPoisLayer(map, allApprovedPois, isVisible) {
      const sourceName = 'poi-approved-features'
      const layerName = sourceName
      if (!map.getSource(sourceName)) {
        const sourceData = {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: allApprovedPois
          },
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
        }
        map.addSource(sourceName, sourceData)
      } else {
        map.getSource(sourceName).setData({
          type: 'FeatureCollection',
          features: allApprovedPois
        })
      }

      if (!map.getLayer(layerName)) {
        map.loadImage('/img/icons-other/camera-retro-solid.png', function(error, image) {
          if (error) throw error;
          map.addImage('approved-photo-poi', image);
          map.addLayer({
            "id": layerName,
            "type": "symbol",
            "source": sourceName,
            "layout": {
              'icon-allow-overlap': true,
              "icon-image": "approved-photo-poi",
              "icon-size": 0.035,
              "visibility" : isVisible ? 'visible' : 'none'
            },
            filter: ['!', ['has', 'point_count']]
          });
        });
      }
      const clusteredLayer = layerName + '-clustered'
      const clusteredSymbolLayer = layerName + '-symbol-clustered'
      if (!map.getLayer(clusteredLayer)) {
        map.addLayer({
          id: clusteredLayer,
          type: 'circle',
          source: sourceName,
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'step',
              ['get', 'point_count'],
              '#51bbd6',
              100,
              '#f1f075',
              750,
              '#f28cb1'
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              15,
              15,
              20,
              30,
              40
            ]
          }
        })
      }
      if (!map.getLayer(clusteredSymbolLayer)) {
        map.addLayer({
          id: clusteredSymbolLayer,
          type: 'symbol',
          source: sourceName,
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12
          }
        })
      }

      // console.log(allApprovedPois, ' all approved pois');
}

export function addAllPoisLayer(map, allPois, isVisible) {
      const sourceName = 'poi-features'
      const layerName = sourceName
      if (!map.getSource(sourceName)) {
        const sourceData = {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: allPois
          },
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
        }
        map.addSource(sourceName, sourceData)
      } else {
        map.getSource(sourceName).setData({
          type: 'FeatureCollection',
          features: allPois
        })
      }

      if (!map.getLayer(layerName)) {
        map.loadImage('/img/icons-other/camera-retro-solid.png', function(error, image) {
          if (error) throw error;
          map.addImage('photo-poi', image);
          map.addLayer({
            "id": layerName,
            "type": "symbol",
            "source": sourceName,
            "layout": {
              'icon-allow-overlap': true,
              "icon-image": "photo-poi",
              "icon-size": 0.035,
              "visibility" : isVisible ? 'visible' : 'none'
            },
            "filter": ["==", "$type", "Point"],
          });
        });
      }

      const clusteredLayer = layerName + '-clustered'
      const clusteredSymbolLayer = layerName + '-symbol-clustered'
      if (!map.getLayer(clusteredLayer)) {
        map.addLayer({
          id: clusteredLayer,
          type: 'circle',
          source: sourceName,
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'step',
              ['get', 'point_count'],
              '#51bbd6',
              100,
              '#f1f075',
              750,
              '#f28cb1'
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              15,
              15,
              20,
              30,
              40
            ]
          }
        })
      }
      if (!map.getLayer(clusteredSymbolLayer)) {
        map.addLayer({
          id: clusteredSymbolLayer,
          type: 'symbol',
          source: sourceName,
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12
          }
        })
      }
      // console.log(allPois, ' all POIs');
}

export function addLandUnitsLayer(map, date, featureCollection, filters, currentDate, units, settings) {
  // Adding sort key
  // var fixedFeatureCollection = JSON.parse(JSON.stringify(featureCollection));
  // fixedFeatureCollection.features.forEach(feature => {
  //   if(feature.properties['US'] === 'Army Group' || feature.properties['US'] === 'Army' || feature.properties['US'] === 'Corp' || feature.properties['US'] === 'Division') {
  //     feature.properties['sort_key'] = 1;
  //   } else {
  //     feature.properties['sort_key'] = 10;
  //   }
  // })

  // Adding different sources for each day


  map.addSource('land_units_'+date, {
    type : 'geojson',
    data : featureCollection
  })

  map.addLayer({
    'id': 'land_units_'+date,
    'type': 'symbol',
    'source': 'land_units_'+date,
    'layout' : {
      'text-size': returnLandUnitsExpression(filters, units, 'icons-simple'),
      'text-allow-overlap': true,
      'text-font': ['P44Silhouettes Regular'],
      'text-field': ["get", "MF"],
      'text-anchor' : 'center',
      'visibility' : date===currentDate ? 'visible' : 'none'
    },
    'paint': {
      'text-halo-color' : '#FFF',
      'text-halo-width' : 2,
      'text-color': [
          'case',
          ['==', 'GB',  ['get', 'UC']], gbColor,
          ['==', 'SA',  ['get', 'UC']], saColor,
          ['==', 'NZ',  ['get', 'UC']], nzColor,
          ['==', 'IND', ['get', 'UC']], indColor,
          ['==', 'CAN', ['get', 'UC']], canColor,
          ['==', 'GMN', ['get', 'UC']], gmnColor,
          ['==', 'USA', ['get', 'UC']], usaColor,
          ['==', 'BRA', ['get', 'UC']], braColor,
          ['==', 'POL', ['get', 'UC']], polColor,
          ['==', 'FRE', ['get', 'UC']], freColor,
          ['==', 'ITA', ['get', 'UC']], itaColor,
          ['==', 'CZE', ['get', 'UC']], czeColor,
          ['==', 'JPN', ['get', 'UC']], jpnColor,
          '#000'
      ],
      'text-opacity' : [
        'interpolate',
        ['exponential', 1.96],
        ['zoom'],
        0, ['case',
            ['==', 'Army', ["get", 'US']], 1,
            ['==', 'Army Group', ["get", 'US']], 1,
            ['==', 'Division', ["get", 'US']], 0.19,
            ['==', 'Corps', ["get", 'US']], 0.19,
            ['==', 'Corp', ["get", 'US']], 0.19,
            ['==', 'Brigade', ["get", 'US']], 0,
            ['==', 'Battalion', ["get", 'US']], 0,
            ['==', 'Company', ["get", 'US']], 0,
            ['==', 'Regiment', ["get", 'US']], 0,
            ['==', 'Platoon', ["get", 'US']], 0,
           0 ],
        8, ['case',
            ['==', 'Army', ["get", 'US']], 1,
            ['==', 'Army Group', ["get", 'US']], 1,
            ['==', 'Division', ["get", 'US']], 0.95,
            ['==', 'Corps', ["get", 'US']], 0.95,
            ['==', 'Corp', ["get", 'US']], 0.95,
            ['==', 'Brigade', ["get", 'US']], 0.5,
            ['==', 'Battalion', ["get", 'US']], 0.5,
            ['==', 'Company', ["get", 'US']], 0.5,
            ['==', 'Regiment', ["get", 'US']], 0.5,
            ['==', 'Platoon', ["get", 'US']], 0.5,
           0 ],
        10, ['case',
            ['==', 'Army', ["get", 'US']], 1,
            ['==', 'Army Group', ["get", 'US']], 1,
            ['==', 'Division', ["get", 'US']], 1,
            ['==', 'Corps', ["get", 'US']], 1,
            ['==', 'Corp', ["get", 'US']], 1,
            ['==', 'Brigade', ["get", 'US']], 1,
            ['==', 'Battalion', ["get", 'US']], 1,
            ['==', 'Company', ["get", 'US']], 1,
            ['==', 'Regiment', ["get", 'US']], 1,
            ['==', 'Platoon', ["get", 'US']], 1,
            0 ]
      ]
    }
  })

  map.addLayer({
    'id': 'land_units_text_'+date,
    'type': 'symbol',
    'source': 'land_units_'+date,
    'layout' : {
      'text-size': returnLandUnitsExpression(filters, units, 'text'),
      'text-allow-overlap': settings.labelOverlap,
      'text-ignore-placement' : false,
      'text-font': ['Roboto Mono Bold', 'Arial Unicode MS Regular'],
      'text-field': ["get", "SN"],
      'text-offset': [0, 0.56],
      'text-anchor' : 'top',
      'text-letter-spacing' : 0.1,
      'text-line-height' : 1.2,
      'text-max-width' : 5,
      // 'text-padding' : 10,
      'symbol-sort-key' : ["get", "sort_key"],
      'visibility' : date===currentDate && settings.showMarkerLabels ? 'visible' : 'none'
    },
    'paint': {
      'text-halo-color' : '#FFF',
      'text-halo-width' : 1,
      'text-color': '#000',
      'text-opacity' : [
        'interpolate',
        ['exponential', 1.96],
        ['zoom'],
        0, ['case',
            ['==', 'Army', ["get", 'US']], 1,
            ['==', 'Army Group', ["get", 'US']], 1,
            ['==', 'Division', ["get", 'US']], 0.56,
            ['==', 'Corps', ["get", 'US']], 0.56,
            ['==', 'Corp', ["get", 'US']], 0.56,
            ['==', 'Brigade', ["get", 'US']], 0,
            ['==', 'Battalion', ["get", 'US']], 0,
            ['==', 'Company', ["get", 'US']], 0,
            ['==', 'Regiment', ["get", 'US']], 0,
            ['==', 'Platoon', ["get", 'US']], 0,
           0 ],
        8, ['case',
            ['==', 'Army', ["get", 'US']], 1,
            ['==', 'Army Group', ["get", 'US']], 1,
            ['==', 'Division', ["get", 'US']], 0.95,
            ['==', 'Corps', ["get", 'US']], 0.95,
            ['==', 'Corp', ["get", 'US']], 0.95,
            ['==', 'Brigade', ["get", 'US']], 0,
            ['==', 'Battalion', ["get", 'US']], 0,
            ['==', 'Company', ["get", 'US']], 0,
            ['==', 'Regiment', ["get", 'US']], 0,
            ['==', 'Platoon', ["get", 'US']], 0,
           0 ],
        8.5, ['case',
            ['==', 'Army', ["get", 'US']], 1,
            ['==', 'Army Group', ["get", 'US']], 1,
            ['==', 'Division', ["get", 'US']], 1,
            ['==', 'Corps', ["get", 'US']], 1,
            ['==', 'Corp', ["get", 'US']], 1,
            ['==', 'Brigade', ["get", 'US']], 0,
            ['==', 'Battalion', ["get", 'US']], 0,
            ['==', 'Company', ["get", 'US']], 0,
            ['==', 'Regiment', ["get", 'US']], 0,
            ['==', 'Platoon', ["get", 'US']], 0,
           0 ],
        11, ['case',
            ['==', 'Army', ["get", 'US']], 1,
            ['==', 'Army Group', ["get", 'US']], 1,
            ['==', 'Division', ["get", 'US']], 1,
            ['==', 'Corps', ["get", 'US']], 1,
            ['==', 'Corp', ["get", 'US']], 1,
            ['==', 'Brigade', ["get", 'US']], 0,
            ['==', 'Battalion', ["get", 'US']], 0,
            ['==', 'Company', ["get", 'US']], 0,
            ['==', 'Regiment', ["get", 'US']], 0,
            ['==', 'Platoon', ["get", 'US']], 0,
            0 ],
         12, ['case',
             ['==', 'Army', ["get", 'US']], 1,
             ['==', 'Army Group', ["get", 'US']], 1,
             ['==', 'Division', ["get", 'US']], 1,
             ['==', 'Corps', ["get", 'US']], 1,
             ['==', 'Corp', ["get", 'US']], 1,
             ['==', 'Brigade', ["get", 'US']], 1,
             ['==', 'Battalion', ["get", 'US']], 1,
             ['==', 'Company', ["get", 'US']], 1,
             ['==', 'Regiment', ["get", 'US']], 1,
             ['==', 'Platoon', ["get", 'US']], 1,
             0 ]
      ]
    }
  })
}

export function addStoryMapLayerExport(json, map, eventListenersAdded, isVisible) {
    map.loadImage('/img/icons-other/StoryMap.png', function(error, image) {
      if (error) throw error;
      if (!map.getLayer('storymap-points')) {
          map.addImage('storymap', image);
          map.addLayer({
            "id": "storymap-points",
            "type": "symbol",
            "source": {
              "type": "geojson",
              "data": json
            },
            "layout": {
              'icon-allow-overlap': true,
              "icon-image": "storymap",
              "icon-size": 0.05,
              "visibility" : isVisible ? 'visible' : 'none'
            }
          });

          if(!eventListenersAdded['storymap-points']) {
            map.on('click', 'storymap-points', function (e) {
              window.open('http://pollard.project44.ca');
            });
            map.on('mouseenter', 'storymap-points', function () {
              map.getCanvas().style.cursor = 'pointer';
            });
            map.on('mouseleave', 'storymap-points', function () {
              map.getCanvas().style.cursor = '';
            });
          }
       }
    });
}

export function updateTempLayer(map, features) {

    const sourceName = 'poi-features-temporary'
    const layerName = sourceName

    if (!map.getSource(sourceName)) {
      const sourceData = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: features
        }
      }
      map.addSource(sourceName, sourceData)
      addLayers();
    } else {
      map.getSource(sourceName).setData({
        type: 'FeatureCollection',
        features: features
      })
    }

    function addLayers() {
        if (!map.getLayer(layerName + '-point')) {
          map.addLayer({
            id: layerName + '-point',
            type: 'circle',
            source: sourceName,
            paint: {
              'circle-radius': 5,
              'circle-color': 'black'
            },
            "filter": ["==", "$type", "Point"],
          })
        }
        if (!map.getLayer(layerName + '-polygon')) {
          map.addLayer({
            id: layerName + '-polygon',
            type: 'fill',
            source: sourceName,
            paint: {
              //'fill-color': 'black',
              'fill-color': ['get', 'fillColor'],
              'fill-outline-color': ['get', 'outlineColor'],
              'fill-opacity' : ['get', 'opacity'],
            },
            "filter": ["==", "$type", "Polygon"],
          })
        }
        if (!map.getLayer(layerName + '-line')) {
          map.addLayer({
            id: layerName + '-line',
            type: 'line',
            source: sourceName,
            paint: {
              'line-color': ['get', 'lineColor'],
              'line-width': 3
            },
            "filter": ["==", "$type", "LineString"],
          })
        }
    }
}


export function toggleLayerVisibility(newLayers, map) {
    Object.keys(newLayers).forEach(layer => {
        const value = newLayers[layer] ? 'visible' : 'none';
        if (layer === 'front_line') {
          ['front_line', 'front_line_offset_positive', 'front_line_offset_negative'].forEach(frontLine => {
            if (map.getLayer(frontLine)) {
              if (map.getLayoutProperty(frontLine, 'visibility') !== value) {
                map.setLayoutProperty(frontLine, 'visibility', value);
              }
            }
          })
        } else if (layer.includes('feature')) {
            ['poi-features', 'poi-approved-features', 'poi-approved-features-symbol-clustered', 'poi-approved-features-clustered'].forEach(layer => {
                if (map.getLayer(layer)) {
                    if (map.getLayoutProperty(layer, 'visibility') !== value) {
                        map.setLayoutProperty(layer, 'visibility', value);
                    }
                }
            });
        } else if (layer.includes('terrain')) {
          const terrainSetting = newLayers[layer] ? { 'source': 'terrain', 'exaggeration': 1.5 } : null
          map.setTerrain(terrainSetting)
        } else if (map.getLayer(layer)) {
            if (map.getLayoutProperty(layer, 'visibility') !== value) {
                map.setLayoutProperty(layer, 'visibility', value);
            }
        }
    });
}

export function handleClusterPoiClick(map, { point }) {
    const cluster = map.queryRenderedFeatures(point, { layers: ["poi-approved-features-symbol-clustered"] });
    if (cluster.length > 0) {
        const limit = 1000;
        const offset = 0;
        const clusterSource = map.getSource("poi-approved-features");
        clusterSource.getClusterLeaves(cluster[0].properties.cluster_id, limit, offset, (err, features) => {
            if (err) {
                console.error(err);
            } else {
                const bounds = bbox({ type: "FeatureCollection", features });
                map.fitBounds(bounds, { padding: 48 });
            }
        });
    }
}
