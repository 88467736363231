import Constants, { consoleLogDev, sortEuidList } from '../Constants.js';
import moment from 'moment'

export const GET_UNITS = 'GET_UNITS';
export const GET_UNITS_DISTINCT = 'GET_UNITS_DISTINCT';
export const SET_ACTIVE_UNITS = 'SET_ACTIVE_UNITS';
export const UNIT_ERROR = 'UNIT_ERROR';
export const SET_EUNITID_LIST = 'SET_EUNITID_LIST';
export const SET_CLICKED_UNIT = 'SET_CLICKED_UNIT';

window.moment = moment


export function getUnitsDistinct() {
    return (dispatch) => {
        return fetch(`${process.env.REACT_APP_BACKEND}/units/distinct`, {
            method: 'GET'
        })
        .then(response => Constants.handleErrors(response, dispatch, unitError))
        .then((json) => {
            dispatch(addUnitsDistinct(json))
            dispatch(setEUnitIdList(sortEuidList(json)))
        })
        .catch(error => dispatch(unitError(error)));
    }
}

export function getUnits() {
    return (dispatch) => {

        const url = process.env.REACT_APP_BACKEND

        const getAllUnits          = fetch(`${url}/units`).then( resp => resp.json());
        const getUserApprovedUnits = fetch(`${url}/user_created_units?status=approved`).then( resp => resp.json() );

        Promise.all([ getAllUnits, getUserApprovedUnits ])
            .then( ([allUnits, userApprovedUnits]) => {
                //console.log(allUnits, userApprovedUnits);
                dispatch(addUnits(allUnits.concat(userApprovedUnits)))
            })
        .catch( e => console.error( 'Error fetching units', e ));

        /*
        return fetch(`${process.env.REACT_APP_BACKEND}/units`, {
                method: 'GET',
                // headers: header
            })
            .then(response => Constants.handleErrors(response, dispatch, unitError))
            .then(json => {
                dispatch(addUnits(json))
            })
            .catch(error => dispatch(unitError(error)));
        */
    }
}

export function getUnitRangeByMonthYear(date) {
    if (!date) {
        throw(new Error('getUnitRange(): a month must be provided'))
    }

    return (dispatch) => {
        const startDate = moment(date).startOf('month').format('YYYY-MM-DD')
        const endDate = moment(date).endOf('month').format('YYYY-MM-DD')
        const qs = `?Date=${startDate},${endDate}`
        return fetch(`${process.env.REACT_APP_BACKEND}/units${qs}`, {
                method: 'GET',
                // headers: header
            })
            .then(response => Constants.handleErrors(response, dispatch, unitError))
            .then(json => dispatch(addUnits(json)))
            .catch(error => dispatch(unitError(error)));
    }
}

export function addUnits(data) {
    return {
        type: GET_UNITS,
        payload: data
    }
}

export function setActiveUnits(active) {
    return {
        type: SET_ACTIVE_UNITS,
        payload: active
    }
}

export function unitError(error) {
    return {
        type: UNIT_ERROR,
        payload: error
    }
}

export function addUnitsDistinct(data) {
    return {
        type: GET_UNITS_DISTINCT,
        payload: data
    }
}

export function setEUnitIdList(data) {
    return {
        type: SET_EUNITID_LIST,
        payload: data
    }
}

export function setClickedUnit(data) {
    return {
        type: SET_CLICKED_UNIT,
        payload: data
    }
}
