import React, { Component } from 'react';
import {
    Button,
    Form,
    Grid,
    Header,
    Message,
    Segment
} from "semantic-ui-react";
import { updatePassword, checkResetPasswordToken } from '../../actions/actions_auth'

export default class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            token: this.props.match.params.token,
            errors: [],
            infos: [],
        }
    }

    changeVal = (e, { name, value }) => this.setState({ [name]: value });

    validateReset = () => {
        let { email, password, token, errors } = this.state;
        errors = [];

        // Email validation
        if (email.length === 0) {
            errors.push({ type: 'email', msg: 'Please enter your e-mail' });
        } else if (email.length < 6) {
            errors.push({ type: 'email', msg: 'Please enter a valid e-mail' });
        }

        // Password Validation
        if (password.length < 6) {
            errors.push({ type: 'password', msg: 'Your password must be at least 6 characters' });
        }

        // Token validation
        if (!token) {
            errors.push({ type: 'token', msg: 'Your token is missing' })
        }

        this.setState({ errors });
        if (errors.length === 0) {
            this.doUpdatePassword(email, password, token)
        }
    }

    doUpdatePassword = (email, password, token) => {
        this.props.dispatch(updatePassword({ email, password, token }))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.error) {
            console.log(nextProps.error)
            this.setState({ errors: [ nextProps.error ] })
        }
        if (nextProps.auth.passwordResetSuccess === true) {
            this.setState({
                infos: [{ msg: 'Password reset succesful' }],
                errors: []
            })
            setTimeout(() => {
                this.props.history.push('/login')
            }, 500)
        }

        if (nextProps.auth.tokenValid === false) {
            this.setState({ errors: [{
                type: 'token',
                msg: 'Token not valid/expired'
            }]});
        }

        if (nextProps.info) {
            this.setState({ infos: [nextProps.info] })
        }
    }

    componentDidMount() {
        this.props.dispatch(checkResetPasswordToken({ token: this.state.token }))
    }

    render() {
        const { email, password, errors, infos } = this.state;
        return (
            <div className="login">
                <Grid textAlign="center" verticalAlign="middle">
                    <Grid.Column style={{ maxWidth: 450, minWidth: 400 }}>
                        <Header as="h2" color="teal" textAlign="center">
                            Reset Password
                        </Header>
                        <Form size="large">
                            <Segment stacked>
                                <Form.Input
                                    fluid
                                    icon="user"
                                    iconPosition="left"
                                    placeholder="E-mail address"
                                    name="email"
                                    value={email}
                                    onChange={this.changeVal}
                                    className={errors.find(error => error.type === 'email') ? 'error' : ''}
                                />
                                <Form.Input
                                    fluid
                                    icon="lock"
                                    iconPosition="left"
                                    placeholder="Password"
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={this.changeVal}
                                    className={errors.find(error => error.type === 'password') ? 'error' : ''}
                                />
                                <Button color="teal" fluid size="large" onClick={this.validateReset}>
                                    Submit
                                </Button>
                            </Segment>
                        </Form>
                        {errors.length > 0 && errors.map(error => {
                            return (
                                <Message
                                    error
                                    content={error.msg}
                                    key={error.msg}
                                />
                            )
                        })}
                        {infos.length > 0 && infos.map(info => {
                            return (
                                <Message
                                    info
                                    content={info.msg}
                                    key={info.msg}
                                />
                            )
                        })}
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}
