import Constants, { P44_TOKEN } from '../Constants.js';
import { getApprovedPoisFeatures } from './actions_approved_pois';

export const GET_POIS = 'GET_POIS';
export const RESET_POIS = 'RESET_POIS';
export const ADD_POI = 'ADD_POI';
export const EDIT_POI = 'EDIT_POI';
export const REMOVE_POI = 'REMOVE_POI';
export const POI_ERROR = 'POI_ERROR';

// get POIs by user
export function getPois(user_id, token) {
  token = token ? token : window.localStorage.getItem(P44_TOKEN)

  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_BACKEND}/pois/users/${user_id}`, {
      method: 'GET',
      headers: { 'Authorization': 'Bearer: '+token }
    })
    .then(response => Constants.handleErrors(response, dispatch, poiError))
    // Filter out any approved features. This layer will only include non approved features, should probably rename it for clarity.
    .then(json => dispatch(setPoisList(json.filter(feature => feature.properties.status !== 'approved'))))
    //.then(json => dispatch(setPoisList(json)))
    .catch(error => dispatch(poiError(error)));
  }
}

// get all POIs
export function getAllPois(token) {
  token = token ? token : window.localStorage.getItem(P44_TOKEN)

  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_BACKEND}/pois/users`, {
      method: 'GET',
      headers: { 'Authorization': 'Bearer: '+token }
    })
    .then(response => Constants.handleErrors(response, dispatch, poiError))
    .then(json => dispatch(setPoisList(json)))
    .catch(error => dispatch(poiError(error)));
  }
}

export function postPoi(feature, user_id, token) {
  token = token ? token : window.localStorage.getItem(P44_TOKEN)

  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_BACKEND}/pois/users/${user_id}`, {
      method: 'POST',
      body: JSON.stringify({features:[feature]}),
      headers: { 'Authorization': 'Bearer: '+token, 'Content-Type' : 'application/json' }
    })
    .then(response => Constants.handleErrors(response, dispatch, poiError))
    .then(json => dispatch(addPoi(json)))
    .then(() => dispatch(getPois(user_id)))
    .catch(error => dispatch(poiError(error)));
  }
}

export function patchPoi(feature, user_id, token, type) {
  token = token ? token : window.localStorage.getItem(P44_TOKEN)

  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_BACKEND}/pois/users/${user_id}`, {
      method: 'PATCH',
      body: JSON.stringify({features:[feature]}),
      headers: { 'Authorization': 'Bearer: '+token, 'Content-Type' : 'application/json'  }
    })
    .then(response => Constants.handleErrors(response, dispatch, poiError))
    .then(json => {
        dispatch(editPoi(json))
        // Refresh data depending on whether the patch is an approved unit, draft unit, or if its coming from the dashboard
        if (type === 'approved') {
            dispatch(getApprovedPoisFeatures());
        } else if (type === 'draft') {
            dispatch(getPois(user_id))
        } else if (type === 'dashboard') {
            dispatch(getAllPois())
        }
    })
    .catch(error => dispatch(poiError(error)));
  }
}

export function deletePoi(feature, user_id, token) {
  token = token ? token : window.localStorage.getItem(P44_TOKEN)

  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_BACKEND}/pois/users/${user_id}`, {
      method: 'DELETE',
      body: JSON.stringify({features:[feature.properties.feature_id]}),
      headers: { 'Authorization': 'Bearer: '+token, 'Content-Type' : 'application/json'  }
    })
    .then()
    .then(json => dispatch(removePoi(feature)))
    .catch(error => dispatch(poiError(error)));
  }
}


export function setPoisList(list) {
  return {
    type : GET_POIS,
    payload : list
  }
}

export function resetPoisList(list) {
  return {
    type : RESET_POIS,
    payload : list
  }
}

export function addPoi(userFeature) {
  return {
    type : ADD_POI,
    payload : userFeature
  }
}

export function editPoi(userFeature) {
  return {
    type : EDIT_POI,
    payload : userFeature
  }
}

export function removePoi(userFeature) {
  return {
    type : REMOVE_POI,
    payload : userFeature
  }
}

export function poiError(error) {
  return {
    type : POI_ERROR,
    payload : error
  }
}
