import React, { Component } from 'react';

import { postPoi, patchPoi, deletePoi } from '../../actions/actions_pois';
import { P44_TOKEN } from '../../Constants';

import { addToMap } from '../../actions/actions_map';


class PoiEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poiPopup: null
    }
  }

  componentDidMount() {

    // Save new POI
    // document.addEventListener('click', async (e) => {
    //   if (e.target && e.target.className.includes('poi-save')) {
    //     if (!this.props.currentUser) {
    //       alert('You must be logged in to save your feature.')
    //       return
    //     }
    //     const btn = e.target
    //     const { geometry, coordinates } = JSON.parse(btn.parentNode.querySelector('#popup-data').innerHTML)
    //     const fileInput = btn.parentNode.querySelector('.poi-file')
    //     const file = fileInput && fileInput.files && fileInput.files[0]
    //     const image_url = await this.cloudinaryUpload(file)
    //     const feature = {
    //       coordinates,
    //       image_url,
    //       geometry,
    //       title: btn.parentNode.querySelector('.poi-title').value,
    //       description: btn.parentNode.querySelector('.poi-description').value,
    //     }
    //     this.props.dispatch(postPoi(feature, this.props.currentUser.id))
    //     const closeBtn = btn.parentElement.parentElement.querySelector('.mapboxgl-popup-close-button')
    //     setTimeout(() => closeBtn && closeBtn.click(), 500)
    //     this.props.dispatch(addToMap([false, false]));
    //   }
    // })
    //
    // // event listener for bringing up the editing tab
    // document.addEventListener('click', (e) => {
    //   if (e.target && e.target.className.includes('poi-edit')) {
    //     const btn = e.target
    //     const {
    //       title,
    //       description,
    //       image,
    //       feature_id,
    //       thisCentroid,
    //       marker
    //     } = JSON.parse(btn.parentElement.querySelector('#poi-data').innerHTML)
    //     var popup = new window.mapboxgl.Popup({
    //       anchor: 'center',
    //       offset: [-10, 0],
    //       closeOnClick: false,
    //       closeButton: false,
    //       className: 'poi-popup'
    //     });
    //     var properties = [
    //       { name: 'Title', content: title },
    //       { name: 'Description', content: description },
    //       { name: 'Image', content: image }
    //     ]
    //
    //     popup.setLngLat(thisCentroid)
    //       .setHTML(`
    //         <div id="poi-${feature_id}-details" data-feature_id="${feature_id}">
    //           ${properties.map((item) => {
    //             return `
    //               <div class="poi-row">
    //                 <b>${item.name}</b>:
    //                 <br/>
    //                 <input
    //                   type="${item.name === 'Image' ? 'file' : 'text'}"
    //                   id="poi-${feature_id}-${item.name}"
    //                   name="${item.name}"
    //                   value="${item.content}"
    //                 >
    //               </div>
    //             `}).join('')}
    //             <button class="poi-update" id="poi-${feature_id}-save">Save</button>
    //             <button class="poi-cancel" id="poi-${feature_id}-cancel">Cancel</button>
    //             <button class="poi-delete" id="poi-${feature_id}-delete">Delete</button>
    //             <script id="poi-data" type="application/json">${JSON.stringify({ marker })}</script>
    //         </div>
    //       `)
    //       .addTo(this.props.map);
    //     this.setState({ poiPopup: popup })
    //   }
    // })
    //
    // // event listeners for editing/deleting an already created POI
    // // document.querySelector(`#poi-${feature_id}-save`).addEventListener('click', async () => {
    // document.addEventListener('click', async (e) => {
    //   if (e.target && e.target.className.includes('poi-update')) {
    //     const btn = e.target
    //     const imageInput = btn.parentElement.querySelector('input[name="Image"]')
    //     const { marker, geometry_type } = JSON.parse(btn.parentElement.querySelector('#poi-data').innerHTML)
    //     const feature_id = Number(btn.parentElement.dataset.feature_id)
    //     const file = imageInput && imageInput.files && imageInput.files[0]
    //     console.log(file, ' file');
    //     const image_url = await this.cloudinaryUpload(file)
    //     const feature = {
    //       ...marker,
    //       properties: {
    //         feature_id,
    //         title: btn.parentElement.querySelector('input[name="Title"]').value,
    //         description: btn.parentElement.querySelector('input[name="Description"]').value,
    //         image_url,
    //       }
    //     }
    //     // remove mapbox properties
    //     delete feature['layer']
    //     delete feature['source']
    //     delete feature['state']
    //
    //     this.props.dispatch(patchPoi(feature, this.props.currentUser.id, localStorage.getItem(P44_TOKEN)))
    //     this.state.poiPopup && this.state.poiPopup.remove()
    //     this.setState({ poiPopup: null })
    //   }
    // })
    //
    // // on delete
    // document.addEventListener('click', (e) => {
    //   if (e.target && e.target.className.includes('poi-delete')) {
    //     const { marker } = JSON.parse(e.target.parentElement.querySelector('#poi-data').innerHTML)
    //     this.props.dispatch(deletePoi({ ...marker }, this.props.currentUser.id, localStorage.getItem(P44_TOKEN)))
    //     this.state.poiPopup && this.state.poiPopup.remove()
    //     this.setState({ poiPopup: null })
    //   }
    // })
    //
    // // on cancel
    // document.addEventListener('click', (e) => {
    //   if (e.target && e.target.className.includes('poi-cancel')) {
    //     this.state.poiPopup && this.state.poiPopup.remove()
    //     this.setState({ poiPopup: null })
    //   }
    // })
  }

  async cloudinaryUpload(file) {
    // return undefined so that knex will ignore this value in patch/post POI
    if (!file) {
      return undefined
    }

    const formData = new FormData()
    formData.append('upload_preset', 'ml_default')
    formData.append('tags', 'p44_browser_upload')
    formData.append('file', file)
    formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY)
    const res = await fetch(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`, {
      method: 'POST',
      body: formData
    })
    const { secure_url } = await res.json()
    return secure_url
  }


  render() {
    return (
        null
    );
  }
}

export default PoiEdit;
