import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import { Checkbox } from 'semantic-ui-react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { updateMapSettings } from '../../actions/actions_map';

class Basemaps extends Component {

  constructor(props) {
    super(props);
    this.state = {
        isOpen : true,
    }
  }

  closeBasemaps = () => {
    this.setState({ isOpen : false });
    setTimeout(() => {
        this.props.closeModal();
    }, 400);
  }

  // Controls visibility of 3d based on the basemaps toggle
  onTerrainToggle = (event, data) => {
    const { checked } = data
    const map = this.props.frontPageMap;

    if (checked) {
      if (!map.getSource('terrain')) {
        map.addSource('terrain', {
          'type': 'raster-dem',
          'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
          'tileSize': 512,
          'maxzoom': 14
        });
        // add the DEM source as a terrain layer with exaggerated height
        map.addLayer({
          'id': 'sky',
          'type': 'sky',
          'paint': {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15
          }
        });
      }
      map.setTerrain({ 'source': 'terrain', 'exaggeration': 1.5 });
      map.setLayoutProperty('sky', 'visibility', 'visible')
    } else {
      map.setTerrain();
      map.setLayoutProperty('sky', 'visibility', 'none')
    }

    this.props.dispatch(updateMapSettings(['threeD', checked]));
  }

  render() {
      const { dispatch, mapType, threeD } = this.props;

      return (
      <Translate>
        {({ translate, activeLanguage, languages }) => (
            <Modal
              centered={true}
              isOpen={this.state.isOpen}
              toggle={this.closeBasemaps}
              className="basemaps-modal"
            >
                <div className="basemaps-header">
                    <span className="capitalize">{translate('basemaps')}</span>
                    <i className="fa fa-times" onClick={this.closeBasemaps}></i>
                </div>

                  <Checkbox
                    className="terrain-toggle"
                    type="checkbox"
                    toggle
                    inverted
                    label="3D Terrain"
                    checked={threeD}
                    onChange={this.onTerrainToggle}
                  />

                  <div className="basemaps-container">
                        <div
                          onClick={() => dispatch(updateMapSettings(['mapType', ['default', 'mapbox://styles/outdoormappingcompany/ck8sxkycp2lr51ijyd5s9lmac']]))}
                          className={ mapType[0] === 'default' ? 'active' : '' }
                          style={{ backgroundImage : 'url(./img/basemaps/base-map-default-1.png' }}
                        >
                            <label>Default</label>
                        </div>

                        <div
                          onClick={() => dispatch(updateMapSettings(['mapType', ['satellite', 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.jpg']]))}
                          className={ mapType[0] === 'satellite' ? 'active' : '' }
                          style={{ backgroundImage : 'url(./img/basemaps/base-map-satellite-1.png' }}
                        >
                            <label>Satellite</label>
                        </div>

                        <div
                          onClick={() => dispatch(updateMapSettings(['mapType', ['road-map', 'mapbox://styles/outdoormappingcompany/ckkftvt2s02te17nmlvu4ped4']]))}
                          className={ mapType[0] === 'road-map' ? 'active' : '' }
                          style={{ backgroundImage : 'url(./img/basemaps/base-map-roadmap.png' }}
                        >
                            <label>Road Map</label>
                        </div>

                        <div
                          onClick={() => dispatch(updateMapSettings(['mapType', ['map-overlay', 'mapbox://styles/outdoormappingcompany/cjoytywrf1non2rmhisurt6gs']]))}
                          className={ mapType[0] === 'map-overlay' ? 'active' : '' }
                          style={{ backgroundImage : 'url(./img/basemaps/base-map-overlay-1.png' }}
                        >
                            <label>Normandy GSGS</label>
                        </div>

                        <div
                          onClick={() => dispatch(updateMapSettings(['mapType', ['aerial-overlay', 'mapbox://styles/outdoormappingcompany/cjoytywrf1non2rmhisurt6gs']]))}
                          className={ mapType[0] === 'aerial-overlay' ? 'active' : '' }
                          style={{ backgroundImage : 'url(./img/basemaps/base-map-aerial-1.png' }}
                        >
                            <label>Aerial Overlay</label>
                        </div>
                        <div
                          onClick={() => dispatch(updateMapSettings(['mapType', ['lite', 'mapbox://styles/outdoormappingcompany/ckr6etmcu19o818nt2spu6r3y']]))}
                          className={ mapType[0] === 'lite' ? 'active' : '' }
                          style={{ backgroundImage : 'url(./img/basemaps/base-map-lite.png' }}
                        >
                            <label>Lite</label>
                        </div>
                        <div
                          onClick={() => dispatch(updateMapSettings(['mapType', ['navigation', 'mapbox://styles/outdoormappingcompany/ckr6ewplf04ro17moedri6oi3']]))}
                          className={ mapType[0] === 'navigation' ? 'active' : '' }
                          style={{ backgroundImage : 'url(./img/basemaps/base-map-navigation.png' }}
                        >
                            <label>Navigation</label>
                        </div>
                    </div>

            </Modal>
            )}
          </Translate>
      );
  }
}

export default Basemaps;
