import React, { Component } from 'react';
import {
    Button,
    Form,
    Message,
    Segment,
    Select
} from "semantic-ui-react";
import queryString from "query-string";

import { P44_TOKEN, personalMapperPlanId, contributorPlanId, paypalClientId, ROLE_NO_AUTH, ROLE_ADMIN } from '../../Constants.js';

import { patchUser, getUsers } from "../../actions/actions_users";
import { getPois } from "../../actions/actions_pois";

import { Row, Col, Container, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { verifyTokenLogin, logoutUser, setDataLoaded } from '../../actions/actions_auth'

const subscriptionTypes = [
    {
      key: 0,
      value: "basic",
      text: "Basic"
    },
    {
      key: 1,
      value: "personal_mapper",
      text: "Personal Mapper"
    },
    {
      key: 2,
      value: "contributor",
      text: "Contributor"
    },
  ];

class Account extends Component {

    constructor(props) {
        super(props);
        this.state = {
          queryString: queryString.parse(this.props.location.search),
          collapsed: true,
          currentUser : {},
          infos: [],
          errors: []
        };
      }

    changeVal = (e, { name, value }) => this.setState({ [name]: value });

    changeUserSubType(event, { value }) {
        let { currentUser } = this.state;
        currentUser.subscription_type = value;
        this.setState({ currentUser });
      }

    closeModal = () => {
        this.props.closeAccountModal()
    }

    handleSubscribe = () => {
        let { id, subscription_type} = this.state.currentUser;
        // console.log(subscription_type, id, ' handle subscribe');

        const paypalBtnOpts = {
        createSubscription: (data, actions) => {
            let plan_id;
            switch (subscription_type) {
            case "personal_mapper":
                plan_id = personalMapperPlanId
                // console.log(plan_id);
                break;
            case "contributor":
                plan_id = contributorPlanId
                break;
            }
            if (subscription_type !== "basic") {
            return actions.subscription.create({
                plan_id
            });
            }
        },
        onApprove: ({ subscriptionID }, actions) => {
            this.props.dispatch(
            patchUser({
                id,
                subscription_id: subscriptionID,
                subscription_type,
                subscription_active: true
            })
            );
        },
        onError: (err) => {
          console.log('error in paypal subscription', err)
        }
        };
        window.paypal.Buttons(paypalBtnOpts).render("#paypal-button-container");
    };

    handleCancelSubscription = async (user_id) => {
        const token = window.localStorage.getItem(P44_TOKEN);
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND}/users/${user_id}/cancel_subscription`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer: ${token}`
            },
            body: JSON.stringify({
              reason: "not happy with the service"
            })
          }
        );
        if (res.status === 204) {
          // console.log("good job bud");
        } else {
          try {
            // console.log("uh oh");
            const json = await res.json();
            console.log("json", json);
          } catch (err) {
            console.log(err);
          }
        }
      };

    componentWillReceiveProps(nextProps) {
          if (
            nextProps.auth.userRole && 
            nextProps.auth.userRole !== ROLE_NO_AUTH &&
            !nextProps.auth.dataLoaded
          ) {
            this.props.dispatch(getUsers());
            this.props.dispatch(
              getPois(nextProps.currentUser.id, nextProps.auth.token)
            );
            this.props.dispatch(setDataLoaded());
          }
        // TO DO: Adjust backend so we get the full user object in props.currentUser
        if (nextProps.currentUser && nextProps.allUsers.length > 0) {
            let fullUserObject = nextProps.allUsers.find(user => user.id === nextProps.currentUser.id);
            this.setState({ currentUser : fullUserObject });
        }

        if (nextProps.error) {
        this.setState({ errors: [nextProps.error] })
        }
        if (nextProps.info) {
        this.setState({ infos: [nextProps.info] })
        }
    }

    componentDidMount() {
        // if (window.localStorage[P44_TOKEN]) {
        //     this.props.dispatch(verifyTokenLogin(window.localStorage[P44_TOKEN]))
        // }
        this.addPayPalScript()
    }

    addPayPalScript = () => {
      if (document.querySelector('#paypal-script') === null) {
        const payPalScript = document.createElement('script')
        payPalScript.src = `https://www.paypal.com/sdk/js?client-id=${paypalClientId}&vault=true`
        payPalScript.id = 'paypal-script'
        document.querySelector('body').appendChild(payPalScript)
      }
    }

    render() {
        const {
            currentUser,
          } = this.state;
        return (
        <Modal backdrop="static" centered={true} isOpen={this.props.accountModal} toggle={this.props.closeAccountModal}>
            <ModalHeader>
                <Button onClick={() => {
                    this.props.dispatch(logoutUser());
                    this.props.closeAccountModal();
                    }}
                >
                    Logout
                </Button>
                <Button
                  size="mini"
                  className="modal-close"
                  onClick={this.closeModal}>
                  <i className="fas fa-times" ></i>
                </Button>
            </ModalHeader>
        <div>
            <Segment style={{ maxWidth : 600 }}>
                <h3 className="ui dividing header">
                My Account
                </h3>
                <div style={{ margin : '20px 0px'}}>
                    <label style={{ fontWeight : 'bold', marginRight : 10 }}>Subscription Type: </label>
                    <Select
                    type="text"
                    name="subscription-type"
                    style={{ width: 250 }}
                    value={currentUser.subscription_type}
                    onChange={this.changeUserSubType.bind(this)}
                    options={subscriptionTypes}
                    disabled={currentUser && currentUser.role === ROLE_ADMIN}
                    />
                </div>

                <div>
                    { currentUser.subscription_type !== 'basic' ?
                    <span>
                        { currentUser.subscription_active ?
                            <Button negative onClick={() => this.handleCancelSubscription(currentUser.id)}>
                            {" "}
                            Cancel{" "}
                            </Button>
                        :
                            <span>
                                <Button onClick={this.handleSubscribe.bind(this)} style={{ marginBottom : 10 }}>
                                {" "}
                                Subscribe{" "}
                                </Button>
                                <div id="paypal-button-container" />
                            </span>
                        }
                    </span>
                    : null }
                </div>
            </Segment>
            {this.state.errors.length > 0 && this.state.errors.map(error => {
            const content = error.line ? `Line ${error.line}: ${error.msg}` : error.feature ? `${error.msg}\n\n${JSON.stringify(error.feature, null, 4)}` : error.msg
            return (
                <Message
                error
                children={<pre>{content || error.message}</pre>}
                key={error.msg + error.line}
                />
            )
            })}
            {this.state.infos.length > 0 && this.state.infos.map(info => {
            return (
                <Message
                info
                content={info.msg}
                key={info.msg + info.line}
                />
            )
            })}
        </div>
        </Modal>
        )}
}

export default Account;
