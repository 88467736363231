import { connect } from 'react-redux'

import MapContainerComponent from '../../components/FrontPage/MapContainer.js'

const mapStateToProps = state => {
  return {
    mapLoadStatus: state.mainReducer.map.status,
    addingPoint: state.mainReducer.map.addingPoint,
    savingPoint: state.mainReducer.map.savingPoint,
    allDates : state.mainReducer.dates.allDates,
    currentDate : state.mainReducer.dates.currentDate,
    dateLimits : state.mainReducer.dates.dateLimits,
    allUnits : state.mainReducer.units.allUnits,
    users : state.mainReducer.users,
    currentUser : state.mainReducer.users.currentUser,
    auth: state.mainReducer.auth,
    activeUnitDiaries: state.mainReducer.diaries.activeDiary,
    customDataJson: state.mainReducer.custom_data.json,
    showDiariesList: state.mainReducer.diaries.showDiariesList,
    allUserCreatedUnits: state.mainReducer.user_created_units.allUserCreatedUnits,
    frontPageMap: state.mainReducer.map.frontPageMap,
    mbDraw: state.mainReducer.map.mbDraw,
    poiWindowData: state.mainReducer.map.poiWindowData,
    playLoop : state.mainReducer.map.mapSettings.playLoop,
    diaryListInfo : state.mainReducer.map.diaryListInfo,
    currentTheatre : state.mainReducer.map.theatre,
    currentFilters: state.mainReducer.map.currentFilters,
  }
}

const MapContainer = connect(
  mapStateToProps
)(MapContainerComponent)

export default MapContainer
