import { connect } from 'react-redux'

import WarDiaryComponent from '../../components/FrontPage/WarDiary.js'

const mapStateToProps = state => {
  return {
    currentDate : state.mainReducer.dates.currentDate,
    unitsWithDiaries : state.mainReducer.diaries.unitsWithDiaries,
    allUnits : state.mainReducer.units.allUnits,
    allDates : state.mainReducer.dates.allDates,
    showDiariesList: state.mainReducer.diaries.showDiariesList,
    activeUnitDiaries: state.mainReducer.diaries.activeDiary,
    mapSettings : state.mainReducer.map.mapSettings,
    diaryListInfo : state.mainReducer.map.diaryListInfo,
    theatre : state.mainReducer.map.theatre,
    frontPageMap : state.mainReducer.map.frontPageMap,
    clickedUnit : state.mainReducer.units.clickedUnit,
  }
}

const WarDiary = connect(
  mapStateToProps
)(WarDiaryComponent)

export default WarDiary 
