import React, { Component } from "react";
import ReactTable from "react-table";
import { Button, Icon } from "semantic-ui-react";
import "react-table/react-table.css";

import { patchUserCreatedUnit } from "../../actions/actions_user_created_units";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    approveUnit = ({ ...feature }) => {
        feature.properties.status = 'approved'
        this.props.dispatch(patchUserCreatedUnit(feature, null, this.props.auth.token))
    }

    draftUnit = ({ ...feature }) => {
        feature.properties.status = 'draft'
        this.props.dispatch(patchUserCreatedUnit(feature, null, this.props.auth.token))
    }

    downloadGeoJSON = (e) => {
        e.preventDefault()
        const geojson = {
            type: 'FeatureCollection',
            features: this.props.allUserCreatedUnits.filter((feature) => {
                return feature.properties.status === 'approved'
            })
        }
        const geojsonBlob = new Blob([JSON.stringify(geojson, undefined, 4)], { type: 'application/geojson' })
        const a = document.createElement('a')
        a.href = URL.createObjectURL(geojsonBlob)
        a.download = 'project-44-approved-units.geojson'
        a.click()
    }

    render() {
        const tableData = this.props.allUserCreatedUnits
        const columns = [
            { Header: "Long Name", accessor: "properties.LN" },
            { Header: "Date", accessor: "properties.Date" },
            { Header: "Short", accessor: "properties.SN", maxWidth: 140 },
            { Header: "ID", accessor: "properties.EUnitID", maxWidth: 100 },
            { Header: "Country", accessor: "properties.UC", maxWidth: 100 },
            { Header: "Status", accessor: "properties.status" },
            { Header: "Size", accessor: "properties.US" },
            { Header: "Type", accessor: "properties.UT" },
            {
                filterable: false,
                Cell: ({ original: feature }) => (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Button style={{ marginBottom: 5 }} onClick={() => this.approveUnit(feature)}> Approve </Button>
                        <Button onClick={() => this.draftUnit(feature)}> Draft </Button>
                    </div>
                )
            }
        ]

        return (
            <div>
                <Button color="teal" icon labelPosition="left" title="Download a GeoJSON of all approved units" onClick={this.downloadGeoJSON}>
                    <Icon name="download" />
                    Download GeoJSON
                </Button>
                <hr />
                <ReactTable
                    data={tableData}
                    columns={columns}
                    filterable={true}
                    defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id;
                        return row[id] !== undefined
                            ? String(row[id])
                                .toLowerCase()
                                .indexOf(filter.value.toLowerCase()) > -1
                            : true;
                    }}
                    className="feature-table"
                />
            </div>
        )
    }
}

export default Dashboard;
