import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import { Menu, Checkbox, Segment, Radio, Header } from 'semantic-ui-react';
import { updateMapSettings, setActiveSidebarPanel } from '../../actions/actions_map';

class Settings extends Component {

  constructor(props) {
    super(props);
    this.state = {
        // openSections : ['icons', 'follow_type', 'auto_edit_poi'],
        openSections : []
    }
  }

  toggleSection = (section) => {
    let { openSections } = this.state
    const idx = openSections.indexOf(section)

    if (idx > -1) { openSections.splice(idx, 1) }
    else { openSections.push(section) }

    this.setState({ openSections })
  }

  renderHeader = (type, translate) => {
    const { openSections } = this.state;
    const title = translate(type);
    return (
        <div onClick={() => this.toggleSection(type)} style={{ cursor : 'pointer' }}>
            <i 
                className="fa fa-angle-double-right "
                style={{ transform : (openSections.indexOf(type) > -1 ? 'rotate(90deg)' : 'none') }}
            ></i>
            {title}
        </div>
    )
  }

  render() {
      const { dispatch, iconType, showMarkerLabels, playLoop, autoEditPoi } = this.props;
      const { openSections } = this.state;

      return (
        <Segment inverted className="map-settings capitalize">
          <Translate>
            {({ translate, activeLanguage, languages }) => (
            <div>
                  <Menu.Item>
                    <Header>{this.renderHeader('follow_type', translate)}</Header>
                    <div className={"accordion" + (openSections.indexOf('follow_type') > -1 ? ' active' : '' )}>
                      <Radio
                        className="capitalize"
                        label={translate("follow")}
                        name="follow-type"
                        value="follow"
                        checked={this.props.followType.indexOf('follow') !== -1 }
                        onChange={() => dispatch(updateMapSettings(['followType', 'follow']))}
                      />
                      <Radio
                        className="capitalize"
                        label={translate("static")}
                        name="follow-type"
                        value="static"
                        checked={this.props.followType.indexOf('static') !== -1 }
                        onChange={() => dispatch(updateMapSettings(['followType', 'static']))}
                      />
                    </div>
                  </Menu.Item>

                  <Menu.Item>
                    <Header>{this.renderHeader('auto_edit_poi', translate)}</Header>
                    <div className={"accordion" + (openSections.indexOf('auto_edit_poi') > -1 ? ' active' : '' )}>
                      <Checkbox
                        className="capitalize"
                        type="checkbox"
                        toggle
                        checked={this.props.autoEditPoi}
                        onChange={() => dispatch(updateMapSettings(['autoEditPoi', !autoEditPoi]))}
                        label={translate("auto_edit_poi")}
                      />
                    </div>
                  </Menu.Item>
              </div>
            )}
          </Translate>
        </Segment>
      );
  }
}

export default Settings;
