import React, { Component } from 'react';
import { Progress, Segment, Table, Button, Input } from 'semantic-ui-react';
import Joyride from 'react-joyride';

import { setActiveSidebarPanel, toggleBasemapsModal, setUnitsListActive } from '../../actions/actions_sidebar';
import { getDiary, setActiveDiary } from '../../actions/actions_diaries';

const demoSteps = [
    {
      target: '#layers-accordion',
      content: 'Layers tab allows you to turn on or off additional data.',
      disableBeacon: true
    },
    {
      target: '#target-basemaps',
      content: 'Choose from contemporary maps, satellite imagery, aerial imagery from 1950, and original topo maps from WW2.',
      disableBeacon: true,
      placement : 'right'
    },
    {
      target: window.innerWidth > 768 ? '.units-list' : '#target-units',
      content: 'Explore over 130 war diaries of First Canadian Army, and select Allied and German units.',
      disableBeacon: true,
      placement : 'left-start'
    },
    {
      target: window.innerWidth > 768 ? '.drawer-panel.active' : '#target-units',
      content: 'Use the war diary to advance the timeline.',
      disableBeacon: true,
      placement : 'left-start',
    },
    {
      target: '.diary-follow-button',
      content: 'Click the follow button to have the map always center on the unit.',
      disableBeacon: true
    },
    {
      target: '.iconsContainer',
      content: 'Switch between icon settings.',
      placement : 'top',
      disableBeacon: true
    },
    {
      target: '#unit-country-accordion',
      content: 'Turn off countries.',
      placement : 'left-start',
      disableBeacon: true
    },
    {
      target: '#unit-type-accordion',
      content: 'Turn off units by type.',
      placement : 'left',
      disableBeacon: true
    },
    {
      target: '#unit-size-accordion',
      content: 'Turn off units by size.',
      placement : 'left',
      disableBeacon: true
    },
    {
      target: '#target-photos-button',
      content: 'Add historical photos to the web map.',
      disableBeacon: true
    },
    {
      target: '.tools-placeholders .fa-map-marker-alt',
      content: 'Add points to the web map for personal use.',
      disableBeacon: true
    },
    {
      target: '.tools-placeholders .fa-arrows-alt-v',
      content: 'Add lines to the web map for personal use.',
      disableBeacon: true
    },
    {
      target: '.tools-placeholders .fa-draw-polygon',
      content: 'Add polygons to the web map for personal use.',
      disableBeacon: true
    },
    {
      target: '.tools-placeholders .fa-ruler',
      content: 'Use to measure distances on the map.',
      disableBeacon: true
    },
    {
      target: '.time-slider-section .play-buttons div:nth-child(4)',
      content: 'Use the Advance buttons to advance one day.',
      disableBeacon: true,
      styles : { padding : 30 }
    },
    {
      target: '.slider-wrap .rc-slider',
      content: 'Use the timeslider to pick specific dates.',
      disableBeacon: true
    },
    {
      target: '.fa-sync',
      content: 'Use the loop button for continuously looping through the web map animation.',
      placement : 'top',
      disableBeacon: true
    },
    {
      target: window.innerWidth > 768 ? '#map' : '.fake-plastic-class',
      content: 'Left click and drag  to explore more of the map.',
      placement : 'top-start',
      disableBeacon: true,
      spotlightClicks : true
    },
    {
      target: window.innerWidth > 768 ? '#map' : '.fake-plastic-class',
      content: 'Right click and drag to adjust the bearing and pitch of the map.',
      placement : 'top-start',
      disableBeacon: true,
      spotlightClicks : true
    },
]

class Tutorial extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tutorialStepIndex: 0
    }
  }

  demoMapPanning(type) {
      const { map } = this.props;
      if (map) {
          if (type === 'pan') {
              const currentCenter = map.getCenter();
              setTimeout(() => {
                  map.flyTo({
                    center : { lng :  currentCenter.lng + 0.5, lat : currentCenter.lat },
                    duration : 1200,
                  });
                  setTimeout(() => {
                      map.flyTo({
                        center : currentCenter,
                        duration : 1200,
                     });
                  }, 1500);
              }, 500);
          } else {
              setTimeout(() => {
                  map.flyTo({
                    bearing : -30,
                    pitch : 100,
                    duration : 2000,
                  });
                  setTimeout(() => {
                      map.flyTo({
                        bearing : 0,
                        pitch : 0,
                        duration : 2000,
                     });
                  }, 2700);
              }, 500);
          }
      }
  }

  render() {
    return (
      <div>
        <Joyride
            steps={demoSteps}
            showSkipButton={true}
            continuous={true}
            hideBackButton={true}
            showProgress={true}
            stepIndex={this.state.tutorialStepIndex}
            callback={(state) => {
                // console.log('tutorial state', state)
                const { index, action, lifecycle } = state

                if (index === 0 && lifecycle === 'complete')  {
                    this.props.dispatch(setActiveSidebarPanel(false));
                    this.props.dispatch(toggleBasemapsModal(true));
                }
                if (index === 1 && lifecycle === 'complete')  {
                    this.props.dispatch(toggleBasemapsModal(false));
                    this.props.dispatch(setActiveDiary(0));

                    if (window.innerWidth < 768) {
                        this.props.dispatch(setUnitsListActive(true));
                    }
                }
                if (index === 2 && lifecycle === 'complete')  {
                    this.props.dispatch(getDiary(1600001));
                    this.props.dispatch(setActiveSidebarPanel('warDiaries'));
                }
                if (index === 4 && lifecycle === 'complete')  {
                    this.props.dispatch(setActiveSidebarPanel('filters'));
                    if (window.innerWidth < 768) {
                        this.props.dispatch(setUnitsListActive(false));
                    }
                }
                if (index === 5 && lifecycle === 'complete')  {
                    this.props.dispatch(setActiveSidebarPanel('filters'));
                }
                if (index === 8 && lifecycle === 'complete')  {
                    this.props.dispatch(setActiveSidebarPanel('tools'));
                }
                if (index === 13 && lifecycle === 'complete')  {
                    this.props.dispatch(setActiveSidebarPanel(false));
                }
                if (index === 16 && lifecycle === 'complete')  {
                    this.demoMapPanning('pan');
                }
                if (index === 17 && lifecycle === 'complete')  {
                    this.demoMapPanning('pitch');
                }
                if (action === 'reset')  {
                    this.setState({ tutorialStepIndex: 0 });
                    this.props.dispatch(setActiveSidebarPanel(false));
                    this.props.closeTutorial();
                }
                if (lifecycle === 'complete') {
                  setTimeout(() => this.setState({ tutorialStepIndex: this.state.tutorialStepIndex + 1 }), 0);
                }
            }}
        />

          <div className="phantom-els">
            <span id="target-photos-button"></span>
            <span id="target-basemaps"></span>
            <span id="target-units"></span>
          </div>
      </div>
    );
  }
}

export default Tutorial;
