import { connect } from 'react-redux'

import FiltersComponent from '../../components/FrontPage/Filters.js'

const mapStateToProps = state => {
  return {
    currentDate : state.mainReducer.dates.currentDate,
    allUnits : state.mainReducer.units.allUnits,
    allFilters : state.mainReducer.map.allFilters,
    currentFilters : state.mainReducer.map.currentFilters,
    currentSelectedUnits : state.mainReducer.map.currentSelectedUnits,
    activeUnitDiaries: state.mainReducer.diaries.activeDiary
  }
}

const Filters = connect(
  mapStateToProps
)(FiltersComponent)

export default Filters 
