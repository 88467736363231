import { connect } from 'react-redux'

import UnitsComponent from '../../components/FrontPage/Units.js'

const mapStateToProps = state => {
  return {
    currentDate : state.mainReducer.dates.currentDate,
    allDates : state.mainReducer.dates.allDates,
    allUnits : state.mainReducer.units.allUnits,
    mapLoadStatus: state.mainReducer.map.status,
    currentUser: state.mainReducer.users.currentUser,
    currentFilters : state.mainReducer.map.currentFilters,
    currentSelectedUnits : state.mainReducer.map.currentSelectedUnits,
    mapSettings : state.mainReducer.map.mapSettings,
    allUserCreatedUnits: state.mainReducer.user_created_units.allUserCreatedUnits
  }
}

const Units = connect(
  mapStateToProps
)(UnitsComponent)

export default Units
