import React, { Component } from 'react';
import { Progress, Segment, Table, Button, Input, Popup } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Translate } from "react-localize-redux";
import moment from 'moment';

import { getLines } from '../../actions/actions_lines';
import { getAllDates } from '../../actions/actions_dates';
import { getPois } from "../../actions/actions_pois";
import { getApprovedPoisFeatures } from '../../actions/actions_approved_pois';
import { setActiveSidebarPanel } from '../../actions/actions_sidebar';
import { getCustomData } from '../../actions/actions_custom_data';
import { getUnitsWithDiaries } from '../../actions/actions_diaries';
import { getUserCreatedUnits, patchUserCreatedUnit, postUserCreatedUnit, deleteUserCreatedUnits } from "../../actions/actions_user_created_units";
import { verifyToken } from "../../actions/actions_auth";
import { P44_TOKEN, ROLE_NO_AUTH, haveTwoWeeksPassed } from '../../Constants';

import Map from '../../containers/FrontPage/Map';
import TimeSlider from './TimeSlider'
import Navigation from './Navigation';
import SideBar from '../../containers/FrontPage/SideBar';
import EditUserCreatedUnitsTable from './EditUserCreatedUnitsTable'
import PoiWindow from './PoiWindow'
import Tutorial from './Tutorial'

class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginModal : false,
      accountModal : false,
      blogsModal : false,
      playLoop: false,
      playing: false,
      tutorialPopup : false,
      donationPopup : false,
      queueDonationPopup : false,
      clickedLandUnits: false,
    }
  }

  componentDidMount() {
    this.props.dispatch(getAllDates(true)); // initialLoad == true
    this.props.dispatch(getLines());
    this.props.dispatch(getApprovedPoisFeatures());
    this.props.dispatch(getCustomData());
    const tokenCandidate = window.localStorage.getItem(P44_TOKEN);
    if (tokenCandidate && !this.props.auth.userRole){
      this.props.dispatch(verifyToken(tokenCandidate));
    }
    if (!localStorage.visitedBefore) {
        this.setState({ tutorialPopup : true });
        localStorage.visitedBefore = true;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.auth.userRole &&
      nextProps.auth.userRole !== ROLE_NO_AUTH &&
      !nextProps.auth.dataLoaded &&
      nextProps.currentUser
    ) {
      this.props.dispatch(
        getPois(nextProps.currentUser.id, nextProps.auth.token)
      );
      this.props.dispatch(getUserCreatedUnits(nextProps.currentUser.id, nextProps.auth.token))
    }

    if (!this.props.mapLoadStatus.areIconsLoaded && nextProps.mapLoadStatus.areIconsLoaded) {
      if (!localStorage.donationPromptSeen || haveTwoWeeksPassed()) {
          setTimeout(() => {
             this.setState({ donationPopup : true });
          }, 30000);
      }

      // Adding this here for now so that the data is ready to go when the war diaries tab is clicked
      this.props.dispatch(getUnitsWithDiaries())
    }
  }

  toggleLoginModal = () => {
    this.setState({
        loginModal: !this.state.loginModal
    })
  }

  toggleAccountModal = () => {
    this.setState({
        accountModal: !this.state.accountModal
    })
  }

  setClickedLandUnits = (bool) => {
    this.setState({ clickedLandUnits: bool })
  }

  /*
  addToMap = (type, saving) => {
    var newVal = this.state.addingPoint ? false : type;
    this.setState({addingPoint:newVal, savingPoint: saving});
  }
  */

  openDemo = () => {
    this.props.dispatch(setActiveSidebarPanel('filters'));
    setTimeout(() => {
        this.setState({ demoMode : true, tutorialPopup : false });
    }, 400);
  }

  closeDonationPopup = () => {
    this.setState({ donationPopup: false });
    window.localStorage.donationPromptSeen = new Date();
  }

  render() {
    return (
      <div className="App">

          {this.props.mapLoadStatus.loadPercentage < 100 ?
              <div id="loading-container" className="capitalize">
                  <span> <Translate id="loading_status" /> </span>
                  <Progress percent={this.props.mapLoadStatus.loadPercentage} indicating />
                  <div className="month-status">
                      <div className={this.props.mapLoadStatus.areIconsLoaded ? 'loaded' : ''} ><Translate id="units" /><i className="fas fa-check-circle"></i></div>
                      <div className={this.props.mapLoadStatus.areFrontLinesLoaded ? 'loaded' : ''} ><Translate id="front_lines" /><i className="fas fa-check-circle"></i></div>
                  </div>
              </div>
          : null}

            { this.state.tutorialPopup ?
                <Segment className="tutorial-prompt">
                    Need help? Click the <i className="fa fa-question-circle"></i> icon any time to view tutorial.
                    <div>
                        <Button onClick={() => this.setState({ tutorialPopup : false })}>Got it!</Button>
                    </div>
                </Segment>
            : false }

            <Popup
                content="Start Tutorial"
                position="left center"
                trigger={<i className="fa fa-question-circle" onClick={this.openDemo}></i>}
                style={{ opacity : window.innerWidth > 768 ? 1 : 0 }}
            />

            { this.state.demoMode ?
                <Tutorial
                    dispatch={this.props.dispatch}
                    closeTutorial={() => this.setState({ demoMode : false })}
                    map={this.props.frontPageMap}
                />
            : false }

            <Navigation
                loginModal={this.state.loginModal}
                accountModal={this.state.accountModal}
                blogsModal={this.state.blogsModal}
                toggleLoginModal={this.toggleLoginModal}
                toggleAccountModal={this.toggleAccountModal}
                {...this.props}
            />

            {this.props.poiWindowData !== null ?
              <PoiWindow {...this.props} />
            : null}
            {this.props.currentUser && this.props.addingPoint === 'edit-units' ?
              <EditUserCreatedUnitsTable {...this.props} />
            : <TimeSlider
                setPlay={(val) => this.setState({ playing: val })}
                playing={this.state.playing}
                playLoop={this.state.playLoop}
                map={this.props.frontPageMap}
                {...this.props}
              />}

            <Map
              playing={this.state.playing}
              customDataJson={this.props.customDataJson}
              setClickedLandUnits={this.setClickedLandUnits}
              diaryListInfo={this.props.diaryListInfo}
            />

            <SideBar
              openLoginModal={this.toggleLoginModal}
              clickedLandUnits={this.state.clickedLandUnits}
              setClickedLandUnits={this.setClickedLandUnits}
              map={this.props.frontPageMap}
            />

          {this.props.addingPoint !== 'edit-units' &&
            <div className="cc" style={{ display: 'none' }}>
              <div className="capitalize"><Translate id="credit" /><a href="https://www.crma-acrc.ca/" property="cc:attributionName" rel="cc:attributionURL"> <Translate id="cmra" /></a></div>

              <br />
              <br />

              <Translate id="license" options={{ renderInnerHtml: true }} />

              <br />
              <br />
              <a rel="license" href="https://www.crma-acrc.ca/">
                <img alt="Creative Commons License" src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png" />
              </a>
            </div>
          }
          <Segment className={"donation-prompt " + (this.state.donationPopup ? "active" : "")}>
            <h1><a href="https://secure.squarespace.com/checkout/donate?donatePageId=5c8aa665eef1a10f5a1b748a&ss_cid=250d0b71-f7fe-4e93-bd4b-237f00cd144f&ss_cvisit=1570594888664&ss_cvr=31e2a7a5-435e-4fc2-8e01-2863dc4f217f%7C1570459780820%7C1570499989346%7C1570594888578%7C3" target="_blank">Donate today!</a></h1>
            <p>Consider <a href="https://secure.squarespace.com/checkout/donate?donatePageId=5c8aa665eef1a10f5a1b748a&ss_cid=250d0b71-f7fe-4e93-bd4b-237f00cd144f&ss_cvisit=1570594888664&ss_cvr=31e2a7a5-435e-4fc2-8e01-2863dc4f217f%7C1570459780820%7C1570499989346%7C1570594888578%7C3" target="_blank">donating today</a> to help run Project '44. Over the past year, our hosting fees have gone up almost 500 percent as our web map has grown.</p>
            <p>Help us continue to grow by donating to our not for profit today. You can learn more about the Canadian Research and Mapping Association by visiting <a href="https://www.crma-acrc.ca" target="_blank">www.crma-acrc.ca</a>.</p>
            <div>
                <Button onClick={() => this.closeDonationPopup()}>Close</Button>
            </div>
          </Segment>
      </div>
    );
  }
}

export default MapContainer;
