import {
  GET_POIS,
  RESET_POIS,
  ADD_POI,
  EDIT_POI,
  REMOVE_POI
} from '../actions/actions_pois'

const pois = (state = {
  allPois : [],
  fetched : false
}, action) => {
  switch (action.type) {
    case RESET_POIS:
      return {
        ...state,
        allPois : [],
        fetched : false
      };
    case GET_POIS:
      var allFeatures = JSON.parse(JSON.stringify(action.payload));
      return {
        ...state,
        allPois : allFeatures,
        fetched : true
      };
    case ADD_POI:
      var allFeatures = JSON.parse(JSON.stringify(state.allPois));
      action.payload.forEach(function(feature) {
        allFeatures.push(feature);
      });
      return {
        ...state,
        allPois : allFeatures
      };
    case EDIT_POI:
      var allFeatures = JSON.parse(JSON.stringify(state.allPois));
      var newFeatures = [];
      allFeatures.forEach(function(thisFeature) {
        var matched = false;
        action.payload.forEach(function(feature) {
          if(thisFeature.properties.feature_id===feature.properties.feature_id) {
            matched = feature;
          }
        });
        if(!matched) {
          newFeatures.push(thisFeature);
        } else {
          newFeatures.push(matched);
        }
      })
      return {
        ...state,
        allPois : newFeatures
      };
    case REMOVE_POI:
      var allFeatures = JSON.parse(JSON.stringify(state.allPois));
      var newFeatures = [];
      allFeatures.forEach(function(thisFeature) {
        if(thisFeature.properties.feature_id!==action.payload.properties.feature_id) {
          newFeatures.push(thisFeature);
        }
      })
      return {
        ...state,
        allPois : newFeatures
      };
    default:
      return state
  }
}

export default pois
