import React, { Component } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Input,
  Checkbox,
  TextArea,
  Select,
  Dropdown
} from "semantic-ui-react";
import {
  DateInput,
  TimeInput,
  DateTimeInput,
  DatesRangeInput
} from "semantic-ui-calendar-react";
import _ from "underscore";
import { Editor } from "@tinymce/tinymce-react";


const subscriptionTypes = [
  {
    key: 0,
    value: "basic",
    text: "Basic"
  },
  {
    key: 1,
    value: "personal_mapper",
    text: "Personal Mapper"
  },
  {
    key: 2,
    value: "contributor",
    text: "Contributor"
  },
  {
    key: 3,
    value: "admin",
    text: "Admin"
  }
];

const EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
class ItemEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentData: null,
      dataSaved: false
    };
  }

  componentDidMount() {
    // on mount, scroll to top.
    if (window) {
      window.scrollTo(0, 0);
    }
  }

  componentWillReceiveProps(nextProps) {
    // if the props change, scroll to top to reveal the editor
    if (window) {
      window.scrollTo(0, 0);
    }

    // if we receive a diary prop and currentData is unset
    if (
      (nextProps.item && this.state.currentData === null) ||
      nextProps.item !== (this.state.currentData && this.state.currentData.id)
    ) {
      const fields = nextProps.fields || [];
      // make sure the object is populated with all our fields by default
      const defaultFields = fields.reduce(
        (obj, field) => ({
          ...obj,
          [field.id]: field.component === "checkbox" ? false : ""
        }),
        {}
      );
      this.setState({
        currentData: {
          ...defaultFields,
          ...nextProps.item
        }
      });
    }
  }

  changeProp = (event, { checked, name: otherName, value }) => {
    const otherNames = ['Date', 'subscription_type', 'assigned_units']
    if (otherNames.includes(otherName)) {
      this.setState(prev => ({
        currentData: {
          ...prev.currentData,
          [otherName]: value
        }
      }));
    } else {
      const { name, value } = event.target;
      const resolvedValue = checked !== undefined ? checked : value;
      const resolvedName = checked !== undefined ? otherName : name;

      this.setState(prev => ({
        currentData: {
          ...prev.currentData,
          [resolvedName]: resolvedValue
        }
      }));
    }
  };

  saveItem = () => {
    const { currentData } = this.state;
    const { onSave } = this.props;
    const validate = this.dataPassesValidation();

    if (validate.success) {
      // console.log("Item Save triggered with following data: ", { currentData });
      // pass the data object to the onSave prop
      onSave && onSave(currentData);
      this.setState({ dataSaved: true, warningMsg: null });
      this.props.backToList();
    } else {
      const validationErrorsString = Object.keys(validate).reduce(
        (str, validation, i) => {
          if (i > 0) str += ", ";
          str += `${validation} is invalid`;
          return str;
        },
        ""
      );
      this.setState({ warningMsg: validationErrorsString });
    }
  };

  deleteItem = () => {
    const { currentData } = this.state;
    const { onDelete, itemType } = this.props;
    if (window.confirm(`Delete ${itemType}?`)) {
      onDelete && onDelete(currentData);
    }

    this.props.backToList();
  };

  dataPassesValidation() {
    const { currentData } = this.state;
    const { fields } = this.props;
    const validationMap = {
      password: value => value.length >= 5,
      email: value => EMAIL_REGEX.test(value)
    };
    const fieldsReqValidation = fields.filter(field => {
      const validationReq = field.validate;
      if (typeof validationReq === "function") {
        return validationReq(this.props);
      }

      return validationReq;
    });

    const validation = fieldsReqValidation.reduce((validationObj, field) => {
      const valid = validationMap[field.id](currentData[field.id]);
      validationObj[field.label] = valid;
      return validationObj;
    }, {});
    if (Object.values(validation).every(field => field)) {
      return { success: true };
    }

    return validation;
  }

  render() {
    const { currentData, dataSaved, warningMsg } = this.state;
    const { itemType, fields: propFields, createMode = false } = this.props;
    const fields = propFields || [];
    const componentMap = {
      input: Input,
      checkbox: Checkbox,
      textarea: TextArea,
      editor: Editor,
      select: Select,
      button: Button,
      dropdown: Dropdown
    };

    return (
      <div style={{ margin: "15px auto" }}>
        <Segment className="edit-pane">
          <Form className="props">
            <h3 className="ui dividing header">
              {createMode ? "Create" : "Edit"} {itemType || "Item"}
            </h3>
            {currentData &&
              fields.map(field => {
                const resolvedComponent = field.component || "input";
                const isCheckbox = field.component === "checkbox";
                const InputComponent = componentMap[resolvedComponent];
                return (
                  <Form.Field inline key={field.id} style={{ display: "flex" }}>
                    <label style={{ width: 80 }}>
                      {" "}
                      {field.label} {field.required ? "*" : ""}
                    </label>
                    {field.type === "date" ? (
                      <DateInput
                        name="Date"
                        required={field.required}
                        initialDate="1944-06-06"
                        value={currentData[field.id]}
                        dateFormat="YYYY-MM-DD"
                        onChange={this.changeProp}
                      />
                    ) :
                    field.id === 'assigned_units' ?
                      <InputComponent
                        name={field.id}
                        fluid
                        multiple
                        search
                        selection
                        options={this.props.distinctUnits && this.props.distinctUnits.map(({ UID, LN, UC }, idx) => {
                          // console.log('Number(UID)', Number(UID))
                          // console.log('UID', UID)
                          const text = Number(UID) ? `${LN} -- ${UID}` : `${LN} -- ${UC}`
                          return {
                            key: idx,
                            value: UID,
                            text
                          }
                        })}
                        value={currentData.assigned_units || []}
                        onChange={this.changeProp}
                        style={{ minWidth: 250 }}
                      />
                    : (
                      <InputComponent
                        type={field.type || undefined}
                        name={field.id}
                        required={field.required}
                        style={{ width: 250 }}
                        checked={isCheckbox ? currentData[field.id] : undefined}
                        onEditorChange={
                          field.component === "editor"
                            ? content => {
                                currentData[field.id] = content;
                              }
                            : undefined
                        }
                        value={isCheckbox ? undefined : currentData[field.id]}
                        onChange={this.changeProp}
                        disabled={!field.editable}
                        rows={4}
                        options={
                          resolvedComponent === "select"
                            ? subscriptionTypes
                            : undefined
                        }
                      />
                    )}
                  </Form.Field>
                );
              })}
            {warningMsg ? (
              <Message
                warning
                visible
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                {warningMsg}
              </Message>
            ) : (
              false
            )}

            <div className="buttonWrap">
              <Button onClick={() => this.saveItem()}> Save </Button>
              {!createMode && (
                <Button onClick={() => this.deleteItem()}>
                  {" "}
                  Delete {itemType}{" "}
                </Button>
              )}
              <Button onClick={this.props.backToList}> Cancel </Button>
              {this.props.isAdmin && (
                <Button onClick={this.props.onResendEmail}>
                  Resend verification email
                </Button>
              )}
            </div>

            {dataSaved ? (
              <Message success visible>
                {itemType} {createMode ? "created" : "saved"}!
              </Message>
            ) : (
              false
            )}
          </Form>
        </Segment>
      </div>
    );
  }
}

export default ItemEdit;
