import { p44Style } from '../Constants';
import {
  SET_ALL_FILTERS,
  SET_CURRENT_FILTERS,
  UPDATE_SELECTED_UNITS,
  UPDATE_MAP_SETTINGS,
  ADD_TO_MAP,
  SET_MAP_LOAD_STATUS,
  SET_FRONT_PAGE_MAP,
  SET_POI_WINDOW,
  UPDATE_VISIBLE_LAYERS,
  SET_CUSTOM_DATA_LAYERS,
  SET_DIARY_LIST_INFO,
  SET_THEATRE
} from '../actions/actions_map'

const initialState = {
  mapSettings : window.localStorage.user_map_settings ? JSON.parse(window.localStorage.user_map_settings) : {
    mapType : ["default", 'mapbox://styles/outdoormappingcompany/ck8sxkycp2lr51ijyd5s9lmac'],
    threeD : false,
    followType : 'static',
    iconType : 'icons-simple',
    showMarkerLabels : true,
    labelOverlap : false,
    playLoop : false,
    autoEditPoi : false
  },
  status: {
    loadPercentage: 0,
    areIconsLoaded: false,
    areFrontLinesLoaded: false,
  },
  allFilters : { UC: [], UT: [], US: [], theatre: [] },
  currentFilters : { UC: [], UT: [], US: [], theatre: [] },
  currentSelectedUnits : window.localStorage.user_selected_units ? JSON.parse(window.localStorage.user_selected_units) : [],

  addingPoint : false,
  saving : false,
  frontPageMap: null,
  mbDraw: null,
  poiWindowData: null,
  visibleLayers : {
    staticLayers : {
        'front_line' : true,
        // 'front_line_offset_positive' : true,
        // 'front_line_offset_negative' : true,
        'poi-features' : true,
        'storymap-points' : true,
        'terrain' : true,
    },
    customLayers : {}
  },
  diaryListInfo: {
    country: 'CAN',
    unit: null,
    size: null
  },
  theatre: 'ETO',
  size: null,
  mouseOver: null,
  flyOverInterval: null,
  // addingPoint : false,
  // saving : false
}
const map = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_FILTERS:
      return {
        ...state,
        ...action.payload
      };
    case SET_CURRENT_FILTERS:
      if (window.localStorage.getItem('p44_token')) {
          window.localStorage.setItem('user_filters', JSON.stringify({
            ...action.payload,
            // we keep all the campaigns visible in local storage so that when the user
            // comes back the main units aren't filtered and shown an empty map
            theatre: ['ETO', 'MTO', 'AK', 'HK'],
          }));
      }
      return {
        ...state,
        currentFilters : action.payload,
      };
    case UPDATE_SELECTED_UNITS:
      if (window.localStorage.getItem('p44_token')) {
          window.localStorage.setItem('user_selected_units', JSON.stringify(action.payload))
      }
      return {
        ...state,
        currentSelectedUnits : action.payload,
      };
    case UPDATE_MAP_SETTINGS :
      const type = action.payload[0];
      const val  = action.payload[1];
      // console.log(state);
      var newStateSettings = JSON.parse(JSON.stringify(state.mapSettings));
      newStateSettings[type] = val;
      if (window.localStorage.getItem('p44_token')) {
          window.localStorage.setItem('user_map_settings', JSON.stringify(newStateSettings))
      }
        return {
            ...state,
            mapSettings : newStateSettings
        }
    case ADD_TO_MAP :
      return {
        ...state,
        addingPoint : action.payload[0],
        savingPoint : action.payload[1]
      };
    case SET_MAP_LOAD_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          ...action.payload
        }
      };
    case SET_FRONT_PAGE_MAP:
      return {
        ...state,
        frontPageMap: action.payload.map,
        mbDraw: action.payload.mbDraw,
      }
    case SET_POI_WINDOW:
      return {
        ...state,
        poiWindowData: action.payload
      }
    case UPDATE_VISIBLE_LAYERS:
      // console.log('update visibile layers', action.payload);
      return {
        ...state,
        visibleLayers : action.payload
      }
    case SET_CUSTOM_DATA_LAYERS:
      let newVisibleLayers = JSON.parse(JSON.stringify(state.visibleLayers));
      action.payload.forEach(layer => { newVisibleLayers.customLayers[layer] = false });
      return {
        ...state,
        visibleLayers : newVisibleLayers
      }
    case SET_DIARY_LIST_INFO:
      return {
        ...state,
        diaryListInfo : action.payload
      }
    case SET_THEATRE:
      return {
        ...state,
        theatre : action.payload
      }
    default:
      return state
  }
}

export default map
