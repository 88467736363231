import {
    GET_USER_CREATED_UNITS,
    ADD_USER_CREATED_UNITS,
    EDIT_USER_CREATED_UNITS,
    REMOVE_USER_CREATED_UNITS,
    USER_CREATED_UNITS_ERROR
} from '../actions/actions_user_created_units';

const intialState = {
    allUserCreatedUnits: []
}
const user_created_units = (state = intialState, action) => {
    switch (action.type) {
        case GET_USER_CREATED_UNITS:
            return {
                ...state,
                allUserCreatedUnits: [...action.payload]
            }
        case ADD_USER_CREATED_UNITS:
            return {
                ...state,
                allUserCreatedUnits: [...state.allUserCreatedUnits, ...action.payload]
            }
        case EDIT_USER_CREATED_UNITS:
            return {
                ...state,
                allUserCreatedUnits: [...state.allUserCreatedUnits].map((feature) => {
                    const foundUnit = action.payload.find((feat) => {
                        return feat.properties.feature_id === feature.properties.feature_id
                    })

                    return foundUnit ? foundUnit : feature
                })
            }
        default: return state
    }
}

export default user_created_units
