import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Translate, withLocalize } from "react-localize-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Select from 'react-select';
import { Input } from "semantic-ui-react"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';

import { getBlogs } from '../../actions/actions_blogs';

class Blogs extends Component {

  constructor(props) {
    super(props);
    this.blogs = { featured : [], nonFeatured : [] };
    this.state = {
        tags : [],
        selectedTags : [],
        searchQuery : '',
        isOpen : true,
        carouselHeight : this.getCarouselHeight()
    }
  }
  
  componentDidMount() {
    if (this.props.allBlogs.length === 0) {
        this.props.dispatch(getBlogs());
    } else {
        this.componentWillReceiveProps(this.props);
    }
    window.addEventListener("resize", () => {
        this.setState({ carouselHeight : this.getCarouselHeight() });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.allBlogs.length > 0 && this.state.tags.length === 0) {
        nextProps.allBlogs.forEach(blog => {
            if (blog.featured) {
                this.blogs.featured.push(blog);
            } else {
                this.blogs.nonFeatured.push(blog);
            }
        })
        this.getAllTags(this.blogs.nonFeatured);
    }
  }

  getCarouselHeight() {
        let carouselHeight = 350;
        const windowWidth = window.innerWidth;

        if (windowWidth < 800) {
            if (windowWidth < 500) {
                carouselHeight = 650;
            } else {
                carouselHeight = 480;
            }
        }
        return carouselHeight;
  }

  getAllTags(blogs) {
    let tags = blogs.reduce((acc, blog) => {
        blog.tags.forEach(tag => { if (!acc.includes(tag) && tag.length > 0) { acc.push(tag) }});
        return acc;
    }, []);

    this.setState({ tags });
  }

  closeBlogs() {
    this.setState({ isOpen : false });
    setTimeout(() => {
        this.props.history.push("/");
    }, 400);
  }

  render() {
    const { tags, selectedTags, searchQuery } = this.state;
    const selectedTagsSimple = selectedTags.map(tag => { return tag.value });

    const sortedBlogs = this.blogs.nonFeatured.sort((a, b) => { return a.featured && !b.featured ? -1 : 1 });
    
    let filteredBlogs = [];
    if (selectedTagsSimple.length > 0) {
        filteredBlogs = sortedBlogs.filter(blog => blog.tags.some(tag => selectedTagsSimple.includes(tag)))
    } else {
        filteredBlogs = sortedBlogs;
    }

    if (searchQuery.length > 0) {
        filteredBlogs = filteredBlogs.filter(blog => 
            blog.title.toLowerCase().includes(searchQuery.toLowerCase()) || 
            blog.description.toLowerCase().includes(searchQuery.toLowerCase())
        )
    }

    const blogItem = (blog, isFeatured = false) => {
        return (
            <div className="blog-item" key={blog.id}>
                {isFeatured ? <h1 className="featured-title">{blog.title}</h1> : false}
                <div className="blog-img" style={{ backgroundImage : `url(${blog.image})`}} >
                    {isFeatured ? false : <h1>{blog.title}</h1>}
                    <div className="blog-description">
                        {blog.description}
                        <a href={blog.url} target="_blank">Go To Blog </a>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Modal
          centered={true}
          isOpen={this.state.isOpen}
          toggle={() => this.closeBlogs()}
          className="blogs-modal"
        >
            <div className="blogs-custom-header">
                <span className="capitalize">{this.props.translate('story_maps')}</span>
                <i className="fa fa-times" onClick={() => this.closeBlogs()}></i>
            </div>
            <ModalBody>
                { this.blogs.featured.length > 0 ? 
                     <CarouselProvider
                        naturalSlideWidth={800}
                        naturalSlideHeight={this.state.carouselHeight}
                        totalSlides={this.blogs.featured.length}
                        isPlaying={true}
                        interval={7000}
                        className="featured-carousel"
                      >
                        <Slider className="slider-container">
                          { this.blogs.featured.map((blog, i) => {
                              return (
                                  <Slide key={`featured-blog-${i}`} index={i} className="blog-slide">
                                    {blogItem(blog, 'featured')}
                                  </Slide>
                              )
                          })}
                        </Slider>

                        <DotGroup className="slider-dots" />
                        <div className="slider-arrow-buttons">
                            <ButtonBack>
                                <i className="fa fa-angle-left"></i>
                            </ButtonBack>
                            <ButtonNext>
                                <i className="fa fa-angle-right"></i>
                            </ButtonNext>
                        </div>
                      </CarouselProvider>
                : false }
                <div className="normal-blogs">
                    <div className="search-and-filter">
                        <Select
                          isMulti={true}
                          value={selectedTags}
                          onChange={(e) => this.setState({ selectedTags : e })}
                          options={tags.map(tag => { return { value : tag, label : tag } })}
                          className="capitalize blog-select"
                          placeholder={this.props.translate('filter_by_tags')}
                        />
                        <Input
                            value={searchQuery}
                            onChange={(e) => { this.setState({ searchQuery : e.target.value }) }}
                            className="blog-search-input"
                            placeholder="Search..."
                            icon="search"
                        />
                    </div>
                    <div className="normal-blogs-wrap">
                        { filteredBlogs.map(blog => {
                            return (
                                <div>
                                    {blogItem(blog)}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
  }
}

export default withRouter(withLocalize(Blogs));
