import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import { Menu, Checkbox, Segment, Radio, Header } from 'semantic-ui-react';
import { updateMapSettings, setActiveSidebarPanel } from '../../actions/actions_map';

class IconSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
        isOpen : true 
    }
  }

  renderHeader = (type, translate) => {
    const { isOpen } = this.state;
    const title = translate(type);
    return (
        <div onClick={() => this.setState({ isOpen : !isOpen }) } style={{ cursor : 'pointer' }}>
            <i 
                className="fa fa-angle-double-right "
                style={{ transform : (isOpen ? 'rotate(90deg)' : 'none') }}
            ></i>
            {title}
        </div>
    )
  }

  render() {
      const { dispatch, iconType, showMarkerLabels } = this.props;
      const { isOpen } = this.state;

      console.log(this.props, ' ICONS COMPONENT PROPS');

      return (
        <Segment inverted className="map-settings capitalize">
          <Translate>
            {({ translate, activeLanguage, languages }) => (
            <div>
                {/* <Header className={"accordion-title"}>
                  <i className="fa fa-times" onClick={() => this.props.dispatch(setActiveSidebarPanel(false))}></i>
                  {translate("map_settings")}
                </Header> */}
                  <Menu.Item>
                    <Header>{this.renderHeader('icons', translate)}</Header>
                    <div className={"accordion" + (isOpen ? ' active' : '' )}>
                      <Radio
                        className="capitalize icon-type"
                        label={<label>{translate('simple')}<span style={{ fontFamily : 'simple'}}>A</span></label>}
                        name="icons-type"
                        value="icons-simple"
                        checked={this.props.iconType.indexOf('icons-simple') !== -1 }
                        onChange={() => dispatch(updateMapSettings(['iconType', 'icons-simple']))}
                      />

                      <Radio
                        className="capitalize icon-type"
                        label={<label>{translate('allied')}<span style={{ fontFamily : 'allied'}}>A</span></label>}
                        name="icons-type"
                        value="icons-allied"
                        checked={this.props.iconType.indexOf('icons-allied') !== -1 }
                        onChange={() => dispatch(updateMapSettings(['iconType', 'icons-allied']))}
                      />

                      <Radio
                        className="capitalize icon-type"
                        label={<label>{translate('german')}<span style={{ fontFamily : 'german'}}>A</span></label>}
                        name="icons-type"
                        value="icons-german"
                        checked={this.props.iconType.indexOf('icons-german') !== -1 }
                        onChange={() => dispatch(updateMapSettings(['iconType', 'icons-german']))}
                      />

                      <Radio
                        className="capitalize icon-type"
                        label={<label>{translate('nato')}<span style={{ fontFamily : 'nato'}}>A</span></label>}
                        name="icons-type"
                        value="icons-nato"
                        checked={this.props.iconType.indexOf('icons-nato') !== -1 }
                        onChange={() => dispatch(updateMapSettings(['iconType', 'icons-nato']))}
                      />

                      <hr style={{ borderColor : 'rgba(255,255,255,0.5)', marginBottom : '1.4rem'}} />
                      <Checkbox
                          className="capitalize"
                          type="checkbox"
                          toggle
                          checked={this.props.showMarkerLabels}
                          onChange={() => dispatch(updateMapSettings(['showMarkerLabels', !showMarkerLabels]))}
                          label={translate("show_marker_labels")}
                      />
                      <Checkbox
                          className="capitalize"
                          type="checkbox"
                          toggle
                          checked={this.props.labelOverlap}
                          onChange={() => dispatch(updateMapSettings(['labelOverlap', !this.props.labelOverlap]))}
                          label={translate("label_overlap")}
                      />
                    </div>
                  </Menu.Item>

              </div>
            )}
          </Translate>
        </Segment>
      );
  }
}

export default IconSettings;
