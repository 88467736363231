import Constants, { Constant, P44_TOKEN } from '../Constants'

export const GET_USER_CREATED_UNITS = 'GET_USER_CREATED_UNITS'
export const ADD_USER_CREATED_UNITS = 'ADD_USER_CREATED_UNITS'
export const EDIT_USER_CREATED_UNITS = 'EDIT_USER_CREATED_UNITS'
export const REMOVE_USER_CREATED_UNITS = 'REMOVE_USER_CREATED_UNITS'
export const USER_CREATED_UNITS_ERROR = 'USER_CREATED_UNITS_ERROR'

export function getUserCreatedUnits(user_id, token) {
    token = token ? token : window.localStorage.getItem(P44_TOKEN)
    const url = user_id ?
        `${process.env.REACT_APP_BACKEND}/user_created_units/${user_id}` :
        `${process.env.REACT_APP_BACKEND}/user_created_units`

    return (dispatch) => {
        return fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer: ${token}`
            }
        })
        .then((resp) => Constants.handleErrors(resp, dispatch, userCreatedUnitsError))
        .then((json) => dispatch(setUserCreatedUnitsList(json)))
        .catch((error) => dispatch(userCreatedUnitsError(error)))
    }
}

export function postUserCreatedUnit(feature, user_id, token) {
    token = token ? token : window.localStorage.getItem(P44_TOKEN)

    return (dispatch) => {
        return fetch(`${process.env.REACT_APP_BACKEND}/user_created_units/${user_id}`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer: ${token}`
            },
            body: JSON.stringify({ features: [feature] })
        })
        .then((resp) => Constants.handleErrors(resp, dispatch, userCreatedUnitsError))
        .then((json) => dispatch(addUserCreatedUnits(json)))
        .then(() => dispatch(getUserCreatedUnits(user_id)))
        .catch((error) => dispatch(userCreatedUnitsError(error)))
    }
}

export function patchUserCreatedUnit(feature, user_id, token) {
    token = token ? token : window.localStorage.getItem(P44_TOKEN)
    // const url = user_id ?
    //     `${process.env.REACT_APP_BACKEND}/user_created_units/${user_id}` :
    //     `${process.env.REACT_APP_BACKEND}/user_created_units`

    return (dispatch) => {
        return fetch(`${process.env.REACT_APP_BACKEND}/user_created_units`, {
            method: 'PATCH',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer: ${token}`
            },
            body: JSON.stringify({ features: [feature] })
        })
        .then((resp) => Constants.handleErrors(resp, dispatch, userCreatedUnitsError))
        .then((json) => dispatch(editUserCreatedUnit(json)))
        .then(() => dispatch(getUserCreatedUnits(user_id)))
        .catch((error) => dispatch(userCreatedUnitsError(error)))
    }
}

export function deleteUserCreatedUnits(featureIds, user_id, token) {
    token = token ? token : window.localStorage.getItem(P44_TOKEN)

    return (dispatch) => {
        return fetch(`${process.env.REACT_APP_BACKEND}/user_created_units`, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer: ${token}`
            },
            body: JSON.stringify({ features: featureIds })
        })
            .then((resp) => Constants.handleErrors(resp, dispatch, userCreatedUnitsError))
            .then(() => dispatch(getUserCreatedUnits(user_id)))
            .catch((error) => dispatch(userCreatedUnitsError(error)))
    }
}

export function setUserCreatedUnitsList(list) {
    return {
        type: GET_USER_CREATED_UNITS,
        payload: list
    }
}

export function addUserCreatedUnits(userCreatedUnit) {
    return {
        type: ADD_USER_CREATED_UNITS,
        payload: userCreatedUnit
    }
}

export function userCreatedUnitsError(error) {
    return {
      type : USER_CREATED_UNITS_ERROR,
      payload : error
    }
}

export function editUserCreatedUnit(userCreatedUnit) {
    return {
        type: EDIT_USER_CREATED_UNITS,
        payload: userCreatedUnit
    }
}
