import { connect } from 'react-redux'
import UnitsListComponent from '../../components/FrontPage/UnitsList.js'

const mapStateToProps = state => {
  return {
    allUnits : state.mainReducer.units.allUnits,
    euidList : state.mainReducer.units.euidList,
    mapSettings : state.mainReducer.map.mapSettings,
    currentDate : state.mainReducer.dates.currentDate,
    diaryListInfo : state.mainReducer.map.diaryListInfo,
    unitsWithDiaries : state.mainReducer.diaries.unitsWithDiaries,
  }
}

const UnitsList = connect(
  mapStateToProps
)(UnitsListComponent)

export default UnitsList 
