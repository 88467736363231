import { P44_TOKEN, ROLE_NO_AUTH } from '../Constants.js';

export const LOGIN_USER = 'LOGIN_USER';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_RESET_PASSWORD_SUCCESS = 'SET_RESET_PASSWORD_SUCCESS';
export const SET_RESET_PASSWORD = 'SET_RESET_PASSWORD';
export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS';
export const SET_RESET_PASSWORD_TOKEN_STATUS = 'SET_RESET_PASSWORD_TOKEN_STATUS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_INFO = 'AUTH_INFO';
export const SET_EMAIL_VERIFIED = 'SET_EMAIL_VERIFIED'
export const SET_DATA_LOADED = 'SET_DATA_LOADED';

async function getAssignedUnitsData(currentUser, dispatch) {
    const qs = `EUnitID=${currentUser.assigned_units.join(',')}`
    const resp = await fetch(`${process.env.REACT_APP_BACKEND}/units?${qs}`, {
        method: 'GET'
    })
    const features = await resp.json()
    const assignedUnitsData = currentUser.assigned_units
        .filter(EUnitID => features.find((feature) => feature.properties.EUnitID === EUnitID))
        .map((EUnitID) => features.find((feature) => feature.properties.EUnitID === EUnitID))
    dispatch(setCurrentUser({
        ...currentUser,
        assignedUnitsData
    }))
}

export function loginUser({ email, password }) {
    return async (dispatch) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/auth/login`, {
                method: 'POST',
                body: JSON.stringify({
                    email,
                    password
                }),
                headers: {
                    'Content-type': 'application/json'
                }
            })
            const json = await res.json()
            if (res.status === 200) {
                dispatch(setUserToken(json.token));
                dispatch(setCurrentUser(json.user));
                if (json.user.assigned_units && json.user.assigned_units.length > 0) {
                    getAssignedUnitsData(json.user, dispatch);
                }
                dispatch(setUserRole(json.user.role))
                dispatch(authError(false))
            } else {
                dispatch(authError(json))
            }
        } catch(error) {
            dispatch(authError(error))
        }
    }
}

export function verifyToken(token) {
    return async (dispatch) => {
        try {
            const resolvedToken = token || window.localStorage.getItem(P44_TOKEN);
            if (!resolvedToken) {
                dispatch(setUserRole(ROLE_NO_AUTH))
                return;
            }
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/auth/verify`, {
                method: 'POST',
                body: JSON.stringify({ token: resolvedToken }),
                headers: {
                    'Content-type': 'application/json'
                }
            })
            if (res.status === 200) {
                const { user } = await res.json()
                const currentUser = user
                dispatch(setCurrentUser(currentUser));
                dispatch(setUserRole(user.role))
                // get assigned_units_data
                if (currentUser.assigned_units && currentUser.assigned_units.length > 0) {
                    getAssignedUnitsData(currentUser, dispatch);
                }
            } else {
                const json = await res.json()
                dispatch(setUserRole(ROLE_NO_AUTH))
                dispatch(authError(json))
                window.localStorage.removeItem(P44_TOKEN);
            }
        } catch(error) {
            dispatch(authError(error))
        }
    }
}

export function verifyTokenLogin(token) {
    return async (dispatch) => {
        try {
            if (!token) {
                token = window.localStorage.getItem(P44_TOKEN)
            }
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/auth/verify`, {
                method: 'POST',
                body: JSON.stringify({ token }),
                headers: {
                    'Content-type': 'application/json'
                }
            })
            if (res.status === 200) {
                // console.log('token good')
                const json = await res.json()
                dispatch(setCurrentUser(json.user));
                dispatch(setUserRole(json.user.role))
            } else {
                const json = await res.json()
                dispatch(setUserRole(json.user.role))
                dispatch(authError(json))
                window.localStorage.removeItem(P44_TOKEN);
            }
        } catch(error) {
            dispatch(authError(error))
        }
    }
}

export function sendResetPassword({ email }) {
    return async (dispatch) => {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/auth/reset_password`, {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: {
                'Content-type': 'application/json'
            }
        })
        if (res.status === 200) {
            dispatch(authInfo({ msg: `Password reset email sent to: ${email}` }))
        } else {
            const json = await res.json()
            dispatch(authError(json))
        }
    }
}

export function checkResetPasswordToken({ token }) {
    return async (dispatch) => {
        const qs = `?token=${token}`
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/auth/reset_password${qs}`)
        if (res.status === 200) {
            dispatch(setResetPasswordTokenStatus(true))
        } else {
            const json = await res.json()
            dispatch(setResetPasswordTokenStatus(false))
            dispatch(authError(json))
        }
    }
}

export function updatePassword({ email, password, token }) {
    return async (dispatch) => {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/auth/update_password_with_email`, {
            method: 'PATCH',
            body: JSON.stringify({ email, password, token }),
            headers: {
                'Content-type': 'application/json'
            }
        })
        if (res.status === 204) {
            // reset successful
            dispatch(setResetPasswordSuccess(true))
        } else {
            const json = await res.json()
            dispatch(setResetPasswordSuccess(false))
            dispatch(authError(json))
        }
    }
}

export function verifyEmailVerificationToken({ email, token }) {
    return async (dispatch) => {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/auth/update_email_verified`, {
            method: 'PATCH',
            body: JSON.stringify({ email, token }),
            headers: {
                'Content-type': 'application/json'
            }
        })
        if (res.status === 204) {
            // email verified
            dispatch(setEmailVerified(true))
            dispatch(authInfo({
                msg: `Your email, ${email}, was verified. Thank you!`
            }))
            dispatch(sendThankYouEmail({ email }))
        } else {
            const json = await res.json()
            dispatch(setEmailVerified(false))
            dispatch(authError(json))
        }
    }
}

export function sendThankYouEmail({ email }) {
    return async (dispatch) => {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/auth/send_thank_you_email`, {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: {
                'Content-type': 'application/json'
            }
        })
        if (res.status === 201) {
            // noop
        } else {
            const json = await res.json()
            dispatch(authError(json))
        }
    }
}

export function logoutUser() {
    [P44_TOKEN, 'user_filters', 'user_selected_units', 'user_map_settings'].forEach(k => window.localStorage.removeItem(k));
    return {
        type: LOGOUT_USER
    }
}

export function setResetPasswordTokenStatus(data) {
    return {
        type: SET_RESET_PASSWORD_TOKEN_STATUS,
        payload: data
    }
}

export function setResetPasswordSuccess(data) {
    return {
        type: SET_RESET_PASSWORD_SUCCESS,
        payload: data
    }
}

export function setUserToken(data) {
    window.localStorage.setItem(P44_TOKEN, data)
    return {
        type: SET_USER_TOKEN,
        payload: data
    }
}

export function setCurrentUser(data) {
    return {
        type: SET_CURRENT_USER,
        payload: data
    }
}

export function setEmailVerified(data) {
    return {
        type: SET_EMAIL_VERIFIED,
        payload: data
    }
}

export function setUserRole(role) {
    return {
        type: SET_USER_ROLE,
        payload: role
    }
}

export function authError(error) {
    return {
        type: AUTH_ERROR,
        payload: error
    }
}

export function authInfo(info) {
    return {
        type: AUTH_INFO,
        payload: info
    }
}

export function setDataLoaded() {
    return {
        type: SET_DATA_LOADED,
        payload: true
    }
}
