import { connect } from 'react-redux'

import AccountComponent from '../../components/Dashboard/Account.js'

const mapStateToProps = state => {
  return {
    allUsers : state.mainReducer.users.allUsers,
    currentUser : state.mainReducer.users.currentUser,
    allPois : state.mainReducer.pois.allPois,
    auth: state.mainReducer.auth,
    error: state.mainReducer.error,
    info: state.mainReducer.info,
  }
}

const AccountContainer = connect(
  mapStateToProps
)(AccountComponent)

export default AccountContainer
