import { combineReducers } from 'redux';

import units from './reducers_units';
import lines from './reducers_lines';
import users from './reducers_users';
import diaries from './reducers_diaries';
import dates from './reducers_dates';
import map from './reducers_map';
import pois from './reducers_pois';
import approved_pois from './reducers_approved_pois';
import error from './reducers_error';
import auth from './reducers_auth';
import info from './reducers_info';
import geojson from './reducers_geojson';
import user_created_units from './reducers_user_created_units'
import custom_data from './reducers_custom_data'
import blogs from './reducers_blogs'
import sidebar from './reducers_sidebar'

const rootReducer = combineReducers({
  units,
  lines,
  dates,
  diaries,
  pois,
  approved_pois,
  users,
  map,
  error,
  auth,
  info,
  geojson,
  user_created_units,
  custom_data,
  blogs,
  sidebar
});

export default rootReducer;
