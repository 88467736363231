import { connect } from 'react-redux'

import BlogsComponent from '../../components/FrontPage/Blogs.js'

const mapStateToProps = state => {
  return {
    allBlogs: state.mainReducer.blogs.allBlogs
  }
}

const Blogs = connect(
  mapStateToProps
)(BlogsComponent)

export default Blogs 
