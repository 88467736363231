import { connect } from 'react-redux'

import IconSettingsComponent from '../../components/FrontPage/IconSettings.js'


const mapStateToProps = state => {
  return {
    iconType : state.mainReducer.map.mapSettings.iconType,
    labelOverlap : state.mainReducer.map.mapSettings.labelOverlap,
    showMarkerLabels : state.mainReducer.map.mapSettings.showMarkerLabels
  }
}

const Icons = connect(
  mapStateToProps
)(IconSettingsComponent)

export default Icons 
