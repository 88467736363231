import {
  GET_USERS,
  EDIT_USER,
  REMOVE_USER,
  ADD_USER
} from '../actions/actions_users'
import {
  SET_CURRENT_USER
} from '../actions/actions_auth'

const users = (state = {
  allUsers : [],
  currentUser : false
}, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        allUsers: action.payload
      };
    case ADD_USER:
      return {
        ...state,
        allUsers: [...state.allUsers, action.payload]
      };
    case EDIT_USER:
      return {
        ...state,
        allUsers: state.allUsers.reduce((allUsers, currUser) => {
          // if the current user is the one we've edited, modify it!
          // otherwise, push the user back in untouched
          if (currUser.id === action.payload.id) {
            allUsers.push(action.payload)
          } else {
            allUsers.push(currUser);
          }

          return allUsers;
        }, [])
      };
    case REMOVE_USER:
      return {
        ...state,
        allUsers: state.allUsers.filter(currUser => currUser.id !== action.payload)
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser : action.payload
      };
    default:
      return state;
  }
};

export default users;
