import React, { Component } from 'react';
import { Checkbox, Segment, Button, Form, Icon } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import moment from 'moment';
import queryStringLib from "query-string";

import { getAllDiaries, setDiariesSearching } from "../../actions/actions_diaries";



// Should be "all dates", quick selectors by month, automatic range picker with two dates
// Should all work by query string so that people can link searches or pages to each other
// Filters should be shown by default, no button press needed
// should be dynamic to the totality of the category of data (units, front lines, etc)

var allFilters = [];

var propertiesIncluded = {
  units : [
    { name : 'UC', label : 'Country' },
    { name : 'US', label : 'Size' },
    { name : 'UT', label : 'Type' },
    { name : 'status', label : 'Status' }
  ],
  users : [
    //{ name : 'created', label : 'Created Date' }
  ]
};

class AdvancedSearch extends Component {

  constructor(props) {
    super(props);
    this.doSearch = this.doSearch.bind(this);
    this.state = {
      startDate : '1944-06-01',
      endDate : '1944-08-31',
      currentFilters : {},
      activeFilters : {},
      isSearching: false
    }
  }

  componentDidMount() {
    let dates = queryStringLib.parse(this.props.location.search);
    if (dates.startDate && dates.endDate) {
        this.setState({
            startDate : dates.startDate,
            endDate : dates.endDate
        });
    }
    this.props.dispatch(getAllDiaries(dates));
  }

  selectFilter(selected) {
    // console.log(selected);
  }

  doSearch() {
    const { startDate, endDate } = this.state;

    var queryString = '?startDate=' + startDate + '&endDate=' + endDate;
    if (window.history.pushState) {
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + queryString;
        window.history.pushState({path:newurl},'',newurl);
        this.props.location.search = queryString;
    }

    let dates = { startDate, endDate };
    this.props.dispatch(setDiariesSearching(true))
    this.props.dispatch(getAllDiaries(dates));
  }

  render() {
      const { startDate, endDate, currentFilters, activeFilters } = this.state;
      return (
        <div>
            { this.props.activePanel!=='users' ?
              <div className="search-wrap">

                <div className="date-wrap">
                  <div>
                    <p className="date-label-text">From</p>
                    <DateInput
                      name="from"
                      initialDate="1944-06-06"
                      value={startDate}
                      dateFormat="YYYY-MM-DD"
                      onChange={(e, {name, value}) => this.setState({startDate:moment(value).format('YYYY-MM-DD')})}
                    />
                  </div>
                  <div style={{marginLeft: '20px'}}>
                    <p className="date-label-text">To</p>
                    <DateInput
                       name="to"
                       initialDate="1944-06-07"
                       value={endDate}
                       dateFormat="YYYY-MM-DD"
                       onChange={(e, {name, value}) => this.setState({endDate:moment(value).format('YYYY-MM-DD')})}
                    />
                  </div>
                  {!isEmpty(currentFilters) && !isEmpty(activeFilters) ?
                    <div className="filter-wrap">
                    {propertiesIncluded[this.props.activePanel].map((prop, i) => {
                      return (
                        <div className="single-filter" key={'filters-'+i}>
                          <p className="select-label">{prop.label}</p>
                          <ReactMultiSelectCheckboxes
                            onChange={this.selectFilter.bind(this)}
                            options={currentFilters[prop.name]}
                            value={activeFilters[prop.name]}
                            isMulti={true}
                          />
                        </div>
                      )
                    })}
                    </div>
                  : false}
                   <Button color="orange" icon labelPosition="left" style={{marginTop:'19px', marginLeft: '15px', maxHeight: '38px'}} onClick={this.doSearch.bind(this)}>
                  <Icon name="search plus" />
                  Search
                </Button>
                </div>
                <hr />
              </div>
            : false}
        </div>
      )
  }
}

function makeFilters(array, type, propertiesIncluded) {
  var filters = {};
  array.forEach(function(unit) {
    propertiesIncluded[type].forEach(function(property) {
      if(!filters[property.name]) {
        filters[property.name] = [];
        filters[property.name].push({ label : unit.properties[property.name], value : unit.properties[property.name] });
      } else {
        var found = false;
        filters[property.name].forEach(function(info) {
          if(unit.properties[property.name] === info.value) {
            found = true;
          }
        })
        if(!found) {
          filters[property.name].push({ label : unit.properties[property.name], value : unit.properties[property.name] });
        }
      }
    });
  });
  return filters;
}

function updateUrl(url,key,value){
    if(value!==undefined){
      value = encodeURI(value);
    }
    var hashIndex = url.indexOf("#")|0;
    if (hashIndex === -1) hashIndex = url.length|0;
    var urls = url.substring(0, hashIndex).split('?');
    var baseUrl = urls[0];
    var parameters = '';
    var outPara = {};
    if(urls.length>1){
        parameters = urls[1];
    }
    if(parameters!==''){
      parameters = parameters.split('&');
      for(k in parameters){
        var keyVal = parameters[k];
        keyVal = keyVal.split('=');
        var ekey = keyVal[0];
        var evalue = '';
        if(keyVal.length>1){
            evalue = keyVal[1];
        }
        outPara[ekey] = evalue;
      }
    }

    if(value!==undefined){
      outPara[key] = value;
    }else{
      delete outPara[key];
    }
    parameters = [];
    for(var k in outPara){
      parameters.push(k + '=' + outPara[k]);
    }

    var finalUrl = baseUrl;

    if(parameters.length>0){
      finalUrl += '?' + parameters.join('&');
    }

    return finalUrl + url.substring(hashIndex);
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export default AdvancedSearch;
