import React, { Component } from "react"
import {
    Button,
    Form,
    Message,
    Segment,
    Input,
    Checkbox,
    TextArea,
    Select,
    Dropdown
} from "semantic-ui-react"
import { DateInput } from "semantic-ui-calendar-react"
import _ from "underscore"
import { Editor } from "@tinymce/tinymce-react"
import { consoleLogDev, EMAIL_REGEX } from "../../Constants"

class WarDiariesEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            diaryDetails: null,
            dataSaved: false
        }
        this.diaryFields = [
            { id: "id", editable: false, label: "Diary ID" },
            { id: "unit_UID", editable: true, required: true, label: "Unit ID" },
            {
                id: "Date",
                editable: true,
                required: true,
                label: "Date",
                type: "date"
            },
            {
                id: "content",
                editable: true,
                label: "Content",
                component: "editor",
                required: true
            },
            { id: "status", editable: true, required: true, label: "Status" }
        ]
    }

    componentDidMount() {
        // on mount, scroll to top.
        if (window) {
            window.scrollTo(0, 0)
        }
    }

    componentWillReceiveProps(nextProps) {
        // if the props change, scroll to top to reveal the editor
        if (window) {
            window.scrollTo(0, 0)
        }

        // if we receive a diary prop and currentData is unset
        if (
            (nextProps.diaryDetails && this.state.currentData === null) ||
            nextProps.diaryDetails !== (this.state.currentData && this.state.currentData.id)
        ) {
            // make sure the object is populated with all our fields by default
            const defaultFields = this.diaryFields.reduce((obj, field) => {
                return {
                    ...obj,
                    [field.id]: field.component === "checkbox" ? false : ""
                }
            }, {})
            this.setState({
                diaryDetails: {
                    ...defaultFields,
                    ...nextProps.diaryDetails
                }
            })
        }
    }

    changeDiaryDetails = (event, { checked, name: diaryInputName, value }) => {
        if (diaryInputName === 'Date') {
            this.setState((prevState) => ({
                diaryDetails: {
                    ...prevState.diaryDetails,
                    [diaryInputName]: value
                }
            }))
        } else {
            const { name, value } = event.target
            const inputValue = checked !== undefined ? checked : value
            const inputName = checked !== undefined ? diaryInputName : name

            this.setState((prevState) => ({
                diaryDetails: {
                    ...prevState.diaryDetails,
                    [inputName]: inputValue
                }
            }))
        }
    }

    saveDiary = () => {
        const { diaryDetails } = this.state
        const validate = this.dataPassesValidation()

        if (validate.success) {
            consoleLogDev("Item Save triggered with following data: ", { diaryDetails })
            this.props.saveDiaryDetails(diaryDetails)
            this.setState({ dataSaved: true, warningMsg: null })
            this.props.backToList()
        } else {
            const validationErrorsString = Object.keys(validate).reduce((str, validation, i) => {
                if (i > 0) str += ", "
                str += `${validation} is invalid`
                return str
            }, "")
            this.setState({ warningMsg: validationErrorsString })
        }
    }

    deleteDiary = () => {
        if (window.confirm('Delete War Diary?')) {
            this.props.deleteDiary(this.state.diaryDetails)
        }

        this.props.backToList()
    }

    dataPassesValidation() {
        const { diaryDetails } = this.state
        const validationMap = {
            password: value => value.length >= 5,
            email: value => EMAIL_REGEX.test(value)
        }
        const fieldsReqValidation = this.diaryFields.filter(field => {
            const validationReq = field.validate
            if (typeof validationReq === "function") {
                return validationReq(this.props)
            }

            return validationReq
        })
        const validation = fieldsReqValidation.reduce((validationObj, field) => {
            const valid = validationMap[field.id](diaryDetails[field.id])
            validationObj[field.label] = valid
            return validationObj
        }, {})
        if (Object.values(validation).every(field => field)) {
            return { success: true }
        }

        return validation
    }

    render() {
        const { diaryDetails, dataSaved, warningMsg } = this.state
        const { createMode = false } = this.props
        const componentMap = {
            input: Input,
            checkbox: Checkbox,
            textarea: TextArea,
            editor: Editor,
            select: Select,
            button: Button,
            dropdown: Dropdown
        }

        return (
            <div style={{ margin: "15px auto" }}>
                <Segment className="edit-pane">
                    <Form className="props">
                        <h3 className="ui dividing header">
                            {createMode ? "Create" : "Edit"} War Diary
                        </h3>
                        {diaryDetails && this.diaryFields.map(field => {
                            const resolvedComponent = field.component || "input"
                            const isCheckbox = field.component === "checkbox"
                            const InputComponent = componentMap[resolvedComponent]
                            let renderInputComponent = null

                            if (field.type === 'date') {
                                renderInputComponent = (
                                    <DateInput
                                        name="Date"
                                        required={field.required}
                                        initialDate="1944-06-06"
                                        value={diaryDetails[field.id]}
                                        dateFormat="YYYY-MM-DD"
                                        onChange={this.changeDiaryDetails}
                                    />
                                )
                            } else {
                                renderInputComponent = (
                                    <InputComponent
                                        apiKey='kooryg9iwlwx2p78ahe79w24i3geaedvjv77i1dsr4s3kdq4'
                                        type={field.type || undefined}
                                        name={field.id}
                                        required={field.required}
                                        style={{ width: 250 }}
                                        checked={isCheckbox ? diaryDetails[field.id] : undefined}
                                        onEditorChange={
                                            field.component === "editor"
                                                ? content => {
                                                    diaryDetails[field.id] = content
                                                }
                                                : undefined
                                        }
                                        value={isCheckbox ? undefined : diaryDetails[field.id]}
                                        onChange={this.changeDiaryDetails}
                                        disabled={!field.editable}
                                        rows={4}
                                    />
                                )
                            }

                            return (
                                <Form.Field inline key={field.id} style={{ display: "flex" }}>
                                    <label style={{ width: 80 }}>
                                        {field.label} {field.required ? "*" : ""}
                                    </label>
                                    {renderInputComponent}
                                </Form.Field>
                            )
                        })}
                        {warningMsg ? (
                            <Message
                                warning
                                visible
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                {warningMsg}
                            </Message>
                        ) : null}
                        <div className="buttonWrap">
                            <Button onClick={() => this.saveDiary()}> Save </Button>
                            {!createMode && (
                                <Button onClick={() => this.deleteDiary()}>
                                    Delete War Diary
                                </Button>
                            )}
                            <Button onClick={this.props.backToList}> Cancel </Button>
                        </div>
                        {dataSaved ? (
                            <Message success visible>
                                War Diary {createMode ? "created" : "saved"}!
                            </Message>
                        ) : null}
                    </Form>
                </Segment>
            </div>
        )
    }
}

export default WarDiariesEdit
