import React, { Component } from 'react';
import ReactTable from 'react-table'
import geojsonhint from '@mapbox/geojsonhint'
import { Menu, Message, Button, Grid, Label, Input, Select, Form, } from 'semantic-ui-react'
import { DateInput, TimeInput, DateTimeInput, DatesRangeInput } from 'semantic-ui-calendar-react';
import 'react-table/react-table.css'
import JSZip from 'jszip'
import mammoth from 'mammoth'
import moment from 'moment';
import { consoleLogDev, URL_REGEX, rewind, CUSTOM_DATA_FIELDS, UNIT_UPLOAD_VALID_FIELDS, FRONTLINE_UPLOAD_VALID_FIELDS, UPLOAD_VALID_COUNTRY_CODES } from '../../Constants';
import { uploadGeoJson } from '../../actions/actions_geojson'
import { postDiary } from '../../actions/actions_diaries';
import { postCustomData } from '../../actions/actions_custom_data';

class Upload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            infos: [],
            uploadedFile: null,
            uploadedFileOriginal: null,
            inputs: {},
            allCustomProperties: {},
            customDataGeojson: {}
        }
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.error) {
            this.setState({ errors: [nextProps.error] })
        }
        if (nextProps.info) {
            this.setState({ infos: [nextProps.info] })
        }
    }

    uploadToCloudinary = async () => {
        const files = this.state.allCustomProperties.icon

        if (!files) {
            this.setState({ infos: [{ msg: 'No file for icon upload found.' }] })
            return
        }
        const file = files[0]
        if (file) {
            const formData = new FormData()
            formData.append('upload_preset', 'ml_default')
            formData.append('tags', 'p44_browser_upload')
            formData.append('file', file)
            formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY)
            const res = await fetch(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`, {
                method: 'POST',
                body: formData
            })
            const { secure_url } = await res.json()
            this.setState({
                allCustomProperties: {
                    ...this.state.allCustomProperties,
                    icon: secure_url
                }
            })
        }
        this.saveCustomData();
    }

    saveCustomData() {
      const { customDataGeojson, allCustomProperties } = this.state;
      const features = customDataGeojson.features.map(feature => {
        const { name, icon, ...props } = allCustomProperties;
        feature.properties.properties_map = { ...props };

        return {
          ...feature,
          properties: {
            name: allCustomProperties.name,
            icon: allCustomProperties.icon,
            Date: feature.properties.Date,
            properties: { ...feature.properties },
            properties_map: { ...props }
          }
        };
      });
      this.props.dispatch(postCustomData(features));
      this.setState({
        allCustomProperties: {},
        customDataGeojson: {},
        infos: [{ msg: 'File sent to server' }]
      });
      setTimeout(() => {
        this.setState({ infos: [] })
      }, 5000)
    }

    validateGeoJSONUpload = (name, geojson, isSecondTry = false) => {
        const rightHandRuleErrorMsg = 'Polygons and MultiPolygons should follow the right-hand rule'
        const geoJSONString = geojson || this.state.uploadedFileOriginal
        const validationErrors = geojsonhint.hint(geoJSONString)
        if (validationErrors.length > 0) {
            const errors = validationErrors.map((err) => {
                return {
                    msg: err.message,
                    line: err.line
                }
            })
            if (errors.find((e) => e.msg === rightHandRuleErrorMsg)) {
              const fixedJson = rewind(JSON.parse(geoJSONString), true)
              if (isSecondTry) {
                this.setState({ errors: [{ msg: 'Unable to automatically correct json.'}, ...errors ], infos: [] })
              } else {
                this.validateGeoJSONUpload(name, JSON.stringify(fixedJson), true)
              }
            } else {
              this.setState({ errors, infos: [] })
            }
        } else {
            this.validateCustomFields(name)
        }
    }

    addNotesProperty = (feature) => {
      return {
        ...feature,
        properties: {
          ...feature.properties,
          Notes: feature.properties.Notes || ''
        }
      }
    }

    validateCustomFields = async (name) => {
        const geojson = JSON.parse(this.state.uploadedFileOriginal)
        const requiredPropertiesPoints = UNIT_UPLOAD_VALID_FIELDS
        const requiredPropertiesLineString = FRONTLINE_UPLOAD_VALID_FIELDS
        const legitUnitCountryCodes = UPLOAD_VALID_COUNTRY_CODES
        const minDate = new Date('1941-12-01')
        const maxDate = new Date('1945-05-31')

        if (name !== 'linestrings') {
          geojson.features = geojson.features.map(this.addNotesProperty)
        }
        const invalidFeatures = geojson.features.reduce((acc, feature) => {
            const { OBJECTID, ...properties } = feature.properties
            if (!feature.geometry || !feature.geometry.coordinates) {
              return [...acc, { msg: 'Coordinates missing or null in this feature:', feature }]
            }
            const { type } = feature.geometry
            if (type === 'Point') {
              if (name === 'custom_data') {
                // no validation on custom_data right now
              } else {
                if (!arrayCompleteSubset(requiredPropertiesPoints, Object.keys(properties))) {
                  // some properties missing, reject
                  consoleLogDev(JSON.stringify({
                    requiredProperties: requiredPropertiesPoints,
                    featureProperties: Object.keys(properties),
                    feature
                  }, null, 4))
                  return [...acc, { msg: 'One or more properties are missing from this feature:', feature }]
                }

                const featureDate = properties.Date && moment(properties.Date).format('x')
                if (featureDate && (+featureDate < +minDate || +featureDate > +maxDate)) {
                  // Date out of bounds, reject
                  return [...acc, { msg: 'Date out of bounds: 1943-07-09 to 1945-05-31. Feature:', feature }]
                }

                if (!legitUnitCountryCodes.includes(properties.Unit_Count)) {
                  return [...acc, { msg: 'One or more values for property Unit_Count were not valid. Feature:', feature }]
                }
              }
            } else if (type === 'LineString') {
              if (name === 'custom_data') {
                // no validation on custom_data right now
              } else {
                const lineStringProperties = { OBJECTID, ...properties }
                if (!arrayCompleteSubset(requiredPropertiesLineString, Object.keys(lineStringProperties))) {
                  consoleLogDev(JSON.stringify({
                    requiredProperties: requiredPropertiesLineString,
                    featureProperties: Object.keys(lineStringProperties),
                    feature
                  }, null, 4))
                  return [...acc, { msg: 'One or more properties are missing from this feature. Required properties for front line uploads are: OBJECTID, SHAPE_Leng, FrontlineD', feature }]
                }

                const featureDate = new Date(properties.FrontlineD)
                if (+featureDate < minDate || +featureDate > maxDate) {
                  // Date out of bounds, reject
                  return [...acc, { msg: 'Date out of bounds: 1943-07-09 to 1945-05-31. Feature:', feature }]
                }
              }
            } else if (type === 'Polygon') {
              if (name === 'custom_data') {
                // no validation on custom_data right now
              }
            } else {
                return [...acc, { msg: 'One or more features is not of type "LineString", "Point", or "Polygon". Feature:', feature }]
            }

            for (let key in properties) {
                const valUndefined =
                    properties[key] === undefined ||
                    properties[key] === 'undefined'

                if (valUndefined) {
                    // undefined, reject
                    return [...acc, { msg: 'One or more property keys were undefined. Feature:', feature }]
                }
            }

            return acc
        }, [])

        if (invalidFeatures.length > 0) {
            this.setState({ errors: invalidFeatures, infos: [] })
        } else {
            // send to server
            this.setState({ infos: [{ msg: 'GeoJSON validated' }] })
            const unitPropertiesMap = {
                date: 'Date',
                UnitID: 'UID',
                Unit_Type: 'UT',
                Unit_Count: 'UC',
                Unit_Size: 'US',
                Marker_ID: 'MID',
                SName: 'SN',
                LName: 'LN',
                MarkerFont: 'MF',
                Theatre: 'theatre',
                Source: 'source',
            }
            const propNameMatchesColName = ['Notes', 'Date', 'LNameFr', 'EUnitID']
            if (name !== 'custom_data') {
                geojson.features = geojson.features.map((feature, i) => {
                  if (feature.geometry.type === 'Point') {
                    const { OBJECTID, ...properties } = feature.properties
                    // replace property names, skipping the ones that are the same
                    for (let oldPropertyName in properties) {
                            if (!propNameMatchesColName.includes(oldPropertyName)) {
                                Object.defineProperty(
                                    properties,
                                    unitPropertiesMap[oldPropertyName],
                                    Object.getOwnPropertyDescriptor(properties, oldPropertyName)
                                )
                                delete properties[oldPropertyName]
                            }
                        }

                        return {
                            ...feature,
                            properties
                        }
                    }

                    return feature
                })
            }
            if (name === 'custom_data') {
                const allCustomProperties = {}
                geojson.features.forEach((feature) => {
                    if (feature.properties.date) {
                        feature.properties.Date = feature.properties.date
                        delete feature.properties.date
                    }
                    for (let prop in feature.properties) {
                        allCustomProperties[prop] = ''
                    }
                })
                // remove Date
                delete allCustomProperties.Date
                this.setState({ customDataGeojson: geojson, allCustomProperties })
            } else {
                this.props.dispatch(uploadGeoJson(geojson))
            }
        }
    }

    handleFileUpload = (e, name) => {
        this.setState({ errors: [] })
        const files = this.state.inputs[name]
        if (!files) {
            this.setState({ infos: [{ msg: `No file for ${name} upload found.` }] })
            return
        }
        const file = files[0]
        const reader = new FileReader()
        reader.onload = ((f) => {
            return (e) => {
                this.setState({
                    uploadedFileOriginal: e.target.result
                }, () => this.validateGeoJSONUpload(name))
            }
        })(file)
        reader.readAsText(file)
        this.setState({ infos: [{ msg: 'Reading file...' }] })
    }

    handleDiaryUpload = async (e, name) => {
        const files = this.state.inputs[name]
        if (!files) {
            this.setState({ infos: [{ msg: `No file for ${name} upload found.` }] })
            return
        }
        const file = files[0]
        const jsZip = new JSZip()
        const zip = await jsZip.loadAsync(file)
        this.setState({ infos: [{ msg: 'Processing war dairies...' }] })
        const allDiaries = await Promise.all(this.loadDiaryZips(zip))
        this.setState({ infos: [{ msg: `Uploading ${allDiaries.flat().length} dairies...` }] })
        this.props.dispatch(postDiary(allDiaries.flat(), 'creation_method=upsert'))
    }

    loadDiaryZips = (zip) => {
        const diaries = []
        zip.forEach((path, file) => {
            const p = new Promise(async (resolve, reject) => {
                try {
                    const arrayBuffer = await file.async('arraybuffer')
                    const { value, messages } = await mammoth.convertToHtml({ arrayBuffer })
                    if (messages) {
                        // console.log('html conversion warnings:', messages)
                    }
                    if (value) {
                        //   console.log(`file ${file.name} has been unzipped. Parsed unit_UID: ${file.name.split('.')[0]}`)
                        const uid = file.name.match(/\d+/g)[0]
                        resolve(this.diaryRowsFromHtml(value, uid, file.name))
                    }
                } catch(err) {
                    console.log(err)
                    reject(err)
                }
            })
            diaries.push(p)
        })
        return diaries
    }

    checkHTML = (html) => {
        const doc = document.createElement('div')
        doc.innerHTML = html
        return doc.innerHTML === html
    }

    validateDiaryRow = ({ Date, content, note, unit_UID }, fileName) => {
        // Date's already been validated by this point
        if (!Date) {
            this.setState({ errors: [{ msg: `File: ${fileName} has an invalid Date value of: ${Date}. Removing from upload.` }] })
            return null
        }
        if (!content) {
            this.setState({ errors: [{ msg: `File: ${fileName} has an invalid content value of: ${content}. Removing from upload.` }] })
            return null
        }
        if (!this.checkHTML(content)) {
            this.setState({ errors: [{ msg: `File: ${fileName} has an invalid content value. Field content is not valid html. Removing from upload.` }] })
            return null
        }
        if (!unit_UID) {
            this.setState({ errors: [{ msg: `File: ${fileName} has an invalid unit_UID value of: ${unit_UID}. Removing from upload.` }] })
            return null
        }

        return { Date, content, note, unit_UID }
    }

    diaryRowsFromHtml = (html, unit_UID, fileName) => {
        const rows = []
        let el = document.createElement('html')
        el.innerHTML = html
        let tableRows = el.getElementsByTagName('tr')

        for (let tableRow of tableRows) {
            const dateField = tableRow.getElementsByTagName('td')[0].textContent.trim()
            if (dateField !== "") {
                if (dateField.indexOf('-') > -1) {
                    const dates = dateField.split(' ')[0]
                    for (let i = dates.split('-')[0]; i <= dates.split('-')[1]; i++) {
                        const thisDate = `${i} ${dateField.split(' ')[1]} 1944`
                        const validatedDiaryRow = this.validateDiaryRow({
                            Date: moment(thisDate).format('YYYY-MM-DD'),
                            content: tableRow.getElementsByTagName('td')[1].innerHTML,
                            note: tableRow.getElementsByTagName('td')[2].innerHTML,
                            unit_UID
                        })
                        if (validatedDiaryRow) {
                            rows.push(validatedDiaryRow)
                        }
                    }
                } else {
                    const rowText = tableRow.querySelector('td').innerText.trim().replace(/\s\s+/g, ' ')
                    let date = moment(rowText)

                    if (!date.isValid()) {
                        // try to regex the date
                        const rgxp_DD_MM_YYYY = new RegExp(/\d{2}\s+\w{3,}\s+\d{4}/g).exec(rowText)
                        const rgxp_MMMM_DD_YYYY = new RegExp(/\w{3,}\s+\d{2}\s+\d{4}/g).exec(rowText)
                        const rgxp_YYYY_MM_DD = new RegExp(/\d{4}\s+\w{3,}\s+\d{2}/g).exec(rowText)

                        if (rgxp_DD_MM_YYYY) {
                            date = rgxp_DD_MM_YYYY[0]
                        } else if (rgxp_MMMM_DD_YYYY) {
                            date = rgxp_MMMM_DD_YYYY[0]
                        } else if (rgxp_YYYY_MM_DD) {
                            date = rgxp_YYYY_MM_DD[0]
                        }

                        // Regex failed, try slicing out the dates
                        if (!moment(date).isValid()) {
                          const slicedDate = date.slice(0, 2) + ' ' + date.slice(3, 6)  + ' ' + date.slice(7)
                          if (moment(slicedDate).isValid()) {
                            date = slicedDate
                          } else {
                            // window.mom = moment
                            this.setState({ errors: [ ...this.state.errors, { msg: `File: ${fileName} has an invalid date: ${rowText}` }] })
                            consoleLogDev('invalid date!:', 'original:', rowText, 'converted:', date)
                          }
                        }
                    }
                    const validatedDiaryRow = this.validateDiaryRow({
                        Date: moment(date).format('YYYY-MM-DD'),
                        content: tableRow.getElementsByTagName('td')[1].innerHTML,
                        note: tableRow.getElementsByTagName('td')[2].innerHTML,
                        unit_UID
                    })

                    if (validatedDiaryRow) {
                        rows.push(validatedDiaryRow)
                    }
                }
            }
        }

        return rows
    }


    render() {
        const { errors, infos } = this.state;

        const saveButtonStyle = !this.state.allCustomProperties.name || this.state.allCustomProperties.name.length < 1 ?
           { opacity : 0.4, pointerEvents : 'none' } : {};

        return (
          <div>
            <h3>Upload</h3>
            <p>
              Uploading here will replace all entries in the database, excluding user-created entries and war
              diaries.
            </p>
            <Input
              label="Upload Units GeoJSON (Point)"
              type="file"
              accept=".geojson, .json"
              name="points"
              onChange={e => this.setState({ inputs: { [e.target.name]: e.target.files } })}
              action={{
                icon: "upload",
                onClick: e => this.handleFileUpload(e, "points"),
                color: "teal",
                content: "Upload"
              }}
              accept=".geojson, .json"
            />
            <br />
            <br />
            <a href="/data/Landunits_v7.json" target="_blank">
              Land Units Sample geoJSON
            </a>
            <hr />
            <Input
              label="Upload Frontline GeoJSON (LineString)"
              type="file"
              name="linestrings"
              onChange={e => this.setState({ inputs: { [e.target.name]: e.target.files } })}
              action={{
                icon: "upload",
                onClick: e => this.handleFileUpload(e, "linestrings"),
                color: "teal",
                content: "Upload"
              }}
              accept=".geojson, .json"
            />
            <br />
            <br />
            <a href="/data/frontline_v1.json" target="_blank">
              Front Line Sample geoJSON
            </a>
            <hr />
            <Input
              label="Upload war diaries"
              type="file"
              name="diaries"
              onChange={e => this.setState({ inputs: { [e.target.name]: e.target.files } })}
              action={{
                icon: "upload",
                onClick: e => this.handleDiaryUpload(e, "diaries"),
                color: "teal",
                content: "Upload"
              }}
              accept=".zip"
            />
            <br />
            <br />
            <a href="/data/WarDiaryV3 (1) (1).zip" target="_blank">
              War Diaries Sample zip
            </a>
            <hr />
            <Input
              label="Upload custom data"
              type="file"
              name="custom_data"
              onChange={e => this.setState({ inputs: { [e.target.name]: e.target.files } })}
              action={{
                icon: "upload",
                onClick: e => this.handleFileUpload(e, "custom_data"),
                color: "teal",
                content: "Upload"
              }}
              accept=".json, .geojson"
            />
            <br />
            <br />
            <a href="/data/jsons/RCAF.json" target="_blank">
              Custom Data Sample json
            </a>
            <br />
            <br />
            <div className="custom-properties-table">
              {Object.keys(this.state.allCustomProperties).length > 0 && (
                <Form className="props">
                  <h3 className="ui dividing header">Properties</h3>
                  <p className="ui">Associate properties with front-end values. <strong>Types can only be used once.</strong></p>
                  <Form.Field inline key="name" style={{ display: "flex" }}>
                    <label style={{ width: 80 }}> Name <span style={{ color : 'red' }}>*</span> </label>
                    <Input
                      name="name"
                      style={{ width: 250 }}
                      value={this.state.allCustomProperties.name}
                      onChange={e => {
                        this.setState({
                          allCustomProperties: {
                            ...this.state.allCustomProperties,
                            name: e.target.value
                          }
                        });
                      }}
                      rows={4}
                    />
                  </Form.Field>
                  <Form.Field inline key="icon" style={{ display: "flex" }}>
                    <label style={{ width: 80 }}> Icon </label>
                    <Input
                      name="icon"
                      type="file"
                      style={{ width: 250 }}
                      onChange={e => {
                        this.setState({
                          allCustomProperties: {
                            ...this.state.allCustomProperties,
                            icon: e.target.files
                          }
                        });
                      }}
                      rows={4}
                      accept=".png, .jpg, .jpeg"
                    />
                  </Form.Field>
                  {Object.keys(this.state.allCustomProperties).map((property, i) => {
                    if (property === "name" || property === "icon" || property === "OBJECTID") {
                      return null;
                    }
                    const { allCustomProperties } = this.state;
                    const currValues = Object.values(allCustomProperties);
                    const uniqueTest = typeCandidate => currValues.some(type => type === typeCandidate);

                    const options = Object.entries(CUSTOM_DATA_FIELDS).map(([fieldType, value]) => {
                      const isNotUnique = uniqueTest(fieldType);
                      return {
                        text: value,
                        key: fieldType,
                        value: fieldType,
                        disabled: isNotUnique
                      };
                    }).sort((a, b) => {
                        if (a.disabled) return 1;
                        if (b.disabled) return -1;
                        return 0;
                    });

                    return (
                      <Form.Field inline key={property + "-" + i} style={{ display: "flex" }}>
                        <label style={{ width: 80 }}>{property}</label>
                        <Select
                          name={property}
                          placeholder="Select field type"
                          value={this.state.allCustomProperties[property]}
                          options={options}
                          selection
                          clearable
                          onChange={(e, { value }) => {
                            const newType = value;
                            this.setState({
                              allCustomProperties: {
                                ...this.state.allCustomProperties,
                                [property]: newType
                              },
                              fieldErrors: {
                                ...this.state.fieldErrors,
                                [property]: null
                              }
                            });
                          }}
                        />
                      </Form.Field>
                    );
                  })}
                  <Button
                    onClick={e => {
                      e.preventDefault();

                      if (this.state.allCustomProperties.icon) { this.uploadToCloudinary() }
                      else { this.saveCustomData() }
                    }}
                    style={saveButtonStyle}
                  >
                    {" "}
                    Save{" "}
                  </Button>
                </Form>
              )}
            </div>
            {errors.length > 0 &&
              errors.map((error, idx) => {
                const content = error.line
                  ? `Line ${error.line}: ${error.msg}`
                  : error.feature
                  ? `${error.msg}\n\n${JSON.stringify(error.feature, null, 4)}`
                  : error.msg;
                return <Message error children={<pre>{content}</pre>} key={error.msg + '-' + idx} />;
              })}
            {infos.length > 0 &&
              infos.map(info => {
                return <Message info content={info.msg} key={info.msg + info.line} />;
              })}
          </div>
        );
    }
}

// returns true if b contains all values of a
function arrayCompleteSubset(a, b) {
    let containsAllVals = true;

    for (let i of a) {
      if (b.indexOf(i) < 0) {
        // not found
        containsAllVals = false
      }
    }

    return containsAllVals;
}

export default Upload;
