import {
    SET_USER_TOKEN,
    SET_USER_ROLE,
    SET_RESET_PASSWORD_TOKEN_STATUS,
    SET_RESET_PASSWORD_SUCCESS,
    LOGOUT_USER,
    SET_EMAIL_VERIFIED,
    SET_DATA_LOADED
} from '../actions/actions_auth'
import { P44_TOKEN, ROLE_ADMIN, ROLE_BASIC, ROLE_MODERATOR, ROLE_NO_AUTH } from '../Constants'

const intialState = {
    token: window.localStorage.getItem(P44_TOKEN),
    userRole: null,
    tokenValid: null,
    passwordResetSuccess: null,
    emailVerified: null,
    dataLoaded : null
}
const auth = (state = intialState, action) => {
    switch (action.type) {
        case SET_USER_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        case SET_USER_ROLE:
            return {
                ...state,
                userRole: action.payload
            }
        case SET_RESET_PASSWORD_TOKEN_STATUS:
            return {
                ...state,
                tokenValid: action.payload
            }
        case SET_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordResetSuccess: action.payload
            }
        case LOGOUT_USER:
            return {
                ...state,
                token: null,
                userRole: false,
                tokenValid: false,
                dataLoaded: false
            }
        case SET_EMAIL_VERIFIED:
            return {
                ...state,
                emailVerified: action.payload
            }
        case SET_DATA_LOADED:
            return {
                ...state,
                dataLoaded : action.payload 
            }
        default:
            return state
    }
}

export default auth
