import Constants from '../Constants.js';

export const GET_APPROVED_POIS = 'GET_APPROVED_POIS';
export const APPROVED_POIS_ERROR = 'APPROVED_POIS_ERROR';

export function getApprovedPoisFeatures() {
  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_BACKEND}/pois/users/approved`, {
      method: 'GET'
    })
    .then(response => Constants.handleErrors(response, dispatch, approvedPoisError))
    .then(json => dispatch(setApprovedPoisList(json)))
    .catch(error => dispatch(approvedPoisError(error)));
  }
}

export function setApprovedPoisList(list) {
  return {
    type : GET_APPROVED_POIS,
    payload : list
  }
}

export function approvedPoisError(error) {
  return {
    type : APPROVED_POIS_ERROR,
    payload : error
  }
}
