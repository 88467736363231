import { connect } from 'react-redux'

import BasemapsComponent from '../../components/FrontPage/Basemaps.js'

const mapStateToProps = state => {
  return {
    mapType : state.mainReducer.map.mapSettings.mapType,
    threeD : state.mainReducer.map.mapSettings.threeD,
    frontPageMap: state.mainReducer.map.frontPageMap
  }
}

const Basemaps = connect(
  mapStateToProps
)(BasemapsComponent)

export default Basemaps 
