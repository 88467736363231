import { connect } from 'react-redux'
import ResetPasswordContainerComponent from '../../components/Dashboard/ResetPassword'

const mapStateToProps = (state) => {
    return {
        auth: state.mainReducer.auth,
        error: state.mainReducer.error,
        info: state.mainReducer.info
    }
}

export default connect(mapStateToProps)(ResetPasswordContainerComponent)
