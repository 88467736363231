import Constants, { P44_TOKEN, ROLE_NO_AUTH } from '../Constants.js';
import { setUserToken, setUserRole } from './actions_auth'

export const GET_USERS = 'GET_USERS';
export const CREATE_USER = 'CREATE_USER';
export const USER_ERROR = 'USER_ERROR';
export const EDIT_USER = 'EDIT_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const ADD_USER = 'ADD_USER';

export function getUsers() {
  return (dispatch) => {
    const token = window.localStorage.getItem(P44_TOKEN)
    return fetch(`${process.env.REACT_APP_BACKEND}/users`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer: ${token}`
      },
    })
    .then(response => Constants.handleErrors(response, dispatch, userError))
    .then(json => dispatch(addUsers(json)))
    .catch(error => dispatch(userError(error)));
  }
}

export function createUser(data, userRegisterMode = true) {
  return async (dispatch) => {
    try {
      const { email, password, role = 'basic', can_edit_users = false, can_create_features = false } = data
      const res = await fetch(`${process.env.REACT_APP_BACKEND}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          email,
          password,
          role,
          can_edit_users,
          can_create_features
        })
      })
      const json = await res.json()

      if (res.status === 201) {
        if (userRegisterMode) {
          dispatch(setUserToken(json.token))
          dispatch(setUserRole(json.user.role))
        }
        dispatch(sendEmailVerificationEmail(email))
        dispatch(addUserToList(json.user))
      } else {
        if (userRegisterMode) {
          dispatch(setUserRole(ROLE_NO_AUTH))
        }
        dispatch(userError(json))
      }
    } catch (err) {
      console.log(err)
      dispatch(userError(err))
    }
  }
}

export function sendEmailVerificationEmail(email) {
  return async (dispatch) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND}/auth/send_email_verification_email`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          email
        })
      })
      if (res.status !== 201) {
        const json = await res.json()
        dispatch(userError(json))
      }
    } catch (err) {
      console.log(err)
      dispatch(userError(err))
    }
  }
}

export function patchUser(data) {
  return async (dispatch) => {
    const { id } = data;
    const token = window.localStorage.getItem(P44_TOKEN)

    if (!id) {
      return dispatch(userError('ID is invalid. Please try again.'));
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND}/users/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer: ${token}`
        },
        body: JSON.stringify({
          user: data
        })
      })
      const json = await Constants.handleErrors(res, dispatch, userError);
      dispatch(editUser(json))
    } catch(error) {
      dispatch(userError(error));
    }
  };
}

export function deleteUser(userId) {
  return async (dispatch) => {
    const token = window.localStorage.getItem(P44_TOKEN)

    if (!userId) {
      return dispatch(userError('ID is invalid. Please try again.'));
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND}/users/${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer: ${token}`
        }
      })
      await Constants.handleErrors(res, dispatch, userError);
      dispatch(removeUserFromList(userId))
    } catch(error) {
      dispatch(userError(error));
    }
  };
}

export function addUsers(data) {
  return {
    type : GET_USERS,
    payload : data
  }
}

export function userError(error) {
  return {
    type : USER_ERROR,
    payload : error
  }
}

export function editUser(data) {
  return {
    type : EDIT_USER,
    payload: data
  }
}

export function addUserToList(user) {
  return {
    type : ADD_USER,
    payload: user
  }
}

export function removeUserFromList(id) {
  return {
    type : REMOVE_USER,
    payload: id
  }
}
