import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";

import enTranslations from "../translations/en.translations.json";
import frTranslations from "../translations/fr.translations.json";

import MapContainer from '../containers/FrontPage/MapContainer';
import Blogs from '../containers/FrontPage/Blogs';
import DashboardContainer from '../containers/Dashboard/DashboardContainer';
import Login from '../containers/Login/LoginContainer';
import ResetPassword from '../containers/ResetPassword/ResetPasswordContainer';
import '../css/App.scss';
import '../css/admin.css';
import 'pure-react-carousel/dist/react-carousel.es.css';

class App extends Component {

  constructor(props) {
    super(props);
    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "French", code: "fr" }
      ],
      translation: {},
      options: { renderToStaticMarkup }
    });
    this.props.addTranslationForLanguage(enTranslations, 'en');
    this.props.addTranslationForLanguage(frTranslations, 'fr');
    this.props.setActiveLanguage(getParameterByName('lang') ? getParameterByName('lang') : 'en');
  }

  // We should have virtually everything routed. When they click on a war diary, that should be shareable.
  // Backend should also have everything routed, but we also need to make sure they are authenticated

  render() {
      return (
        <div className="App">
            <Router>
              {/* front end */}
              <Route path={["/", "/story-maps"]}    exact component={MapContainer} />
              <Route path="/story-maps"             exact component={Blogs} />
              <Route path="/login"                  exact component={Login} />
              <Route path="/reset_password/:token"  exact component={ResetPassword} />
              {/* backend */}
              <Route path="/dashboard"              exact component={DashboardContainer} />
              <Route path="/dashboard/:type"        exact component={(props) => <DashboardContainer {...props} />} />
              <Route path="/dashboard/:type/:id"    exact component={(props) => <DashboardContainer {...props} />} />
              <Route path="/dashboard/:type/:id/:id2"    exact component={(props) => <DashboardContainer {...props} />} />
            </Router>
        </div>
      );
  }
}

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default withLocalize(App);
