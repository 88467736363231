import React, { Component } from "react"
import {
    Button,
    Form,
    Grid,
    Header,
    Message,
    Segment,
    Input,
    Checkbox,
    TextArea,
    Select,
    Dropdown
} from "semantic-ui-react"
import {
    DateInput,
    TimeInput,
    DateTimeInput,
    DatesRangeInput
} from "semantic-ui-calendar-react"
import _ from "underscore"
import { Editor } from "@tinymce/tinymce-react"
import { consoleLogDev, EMAIL_REGEX } from "../../Constants"

class UserEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userDetails: null,
            dataSaved: false
        }
        this.userFields = [
            { id: "id", editable: false, label: "User ID" },
            {
                id: "email",
                editable: true,
                required: true,
                label: "Email Address",
                type: "email",
                validate: "email"
            },
            {
                id: "password",
                editable: true,
                required: true,
                label: "Password",
                type: "password",
                validate: ({ createMode }) => (createMode ? "password" : false)
            },
            {
                id: "role",
                editable: true,
                label: "User Role",
                component: "select"
            },
            {
                id: "can_edit_users",
                editable: true,
                label: "Can Edit Users?",
                component: "checkbox"
            },
            {
                id: "can_create_features",
                editable: true,
                label: "Can Create Features?",
                component: "checkbox"
            },
            {
                id: "subscription_type",
                editable: false,
                label: "Subscription type",
                component: "select"
            },
            {
                id: 'assigned_units',
                editable: true,
                label: 'Editable Units',
                component: 'dropdown'
            }
        ]
        this.subscriptionTypes = [
            {
                key: 0,
                value: "basic",
                text: "Basic"
            },
            {
                key: 1,
                value: "personal_mapper",
                text: "Personal Mapper"
            },
            {
                key: 2,
                value: "contributor",
                text: "Contributor"
            },
            {
                key: 3,
                value: "admin",
                text: "Admin"
            }
        ]
        this.userRoles = [
            {
                key: 0,
                value: "basic",
                text: "Basic"
            },
            {
                key: 1,
                value: "moderator",
                text: "Moderator"
            },
            {
                key: 2,
                value: "admin",
                text: "Admin"
            },
        ]
    }

    componentDidMount() {
        // on mount, scroll to top.
        if (window) {
            window.scrollTo(0, 0)
        }
    }

    componentWillReceiveProps(nextProps) {
        // if the props change, scroll to top to reveal the editor
        if (window) {
            window.scrollTo(0, 0)
        }

        // if we receive a diary prop and userDetails is unset
        if (
            (nextProps.userDetails && this.state.userDetails === null) ||
            nextProps.userDetails !== (this.state.userDetails && this.state.userDetails.id)
        ) {
            // make sure the object is populated with all our fields by default
            const defaultFields = this.userFields.reduce((obj, field) => {
                return {
                    ...obj,
                    [field.id]: field.component === "checkbox" ? false : ""
                }
            }, {})
            this.setState({
                userDetails: {
                    ...defaultFields,
                    ...nextProps.userDetails
                }
            })
        }
    }

    updateUserDetails = (event, { checked, name: userInputName, value }) => {
        if (userInputName === 'assigned_units' || userInputName === 'role') {
            this.setState((prevState) => ({
                userDetails: {
                    ...prevState.userDetails,
                    [userInputName]: value
                }
            }))
        } else {
            const { name, value } = event.target
            const inputValue = checked !== undefined ? checked : value
            const inputName = checked !== undefined ? userInputName : name

            this.setState((prevState) => ({
                userDetails: {
                    ...prevState.userDetails,
                    [inputName]: inputValue
                }
            }))
        }
    }

    saveUser = () => {
        const { userDetails } = this.state
        const validate = this.dataPassesValidation()

        if (validate.success) {
            consoleLogDev("Item Save triggered with following data: ", { userDetails })
            this.props.saveUserDetails(userDetails)
            this.setState({ dataSaved: true, warningMsg: null })
            this.props.backToList()
        } else {
            const validationErrorsString = Object.keys(validate).reduce((str, validation, i) => {
                if (i > 0) str += ", "
                str += `${validation} is invalid`
                return str
            }, "")
            this.setState({ warningMsg: validationErrorsString })
        }
    }

    deleteUser = () => {
        if (window.confirm('Delete user?')) {
            this.props.deleteUser(this.state.userDetails)
        }
        this.props.backToList()
    }

    dataPassesValidation() {
        const { userDetails } = this.state
        const validationMap = {
            password: value => value.length >= 5,
            email: value => EMAIL_REGEX.test(value)
        }
        const fieldsReqValidation = this.userFields.filter(field => {
            const validationReq = field.validate
            if (typeof validationReq === "function") {
                return validationReq(this.props)
            }

            return validationReq
        })
        const validation = fieldsReqValidation.reduce((validationObj, field) => {
            const valid = validationMap[field.id](userDetails[field.id])
            validationObj[field.label] = valid
            return validationObj
        }, {})

        if (Object.values(validation).every(field => field)) {
            return { success: true }
        }
        return validation
    }

    render() {
        const { userDetails, dataSaved, warningMsg } = this.state
        const { createMode = false } = this.props
        const componentMap = {
            input: Input,
            checkbox: Checkbox,
            textarea: TextArea,
            editor: Editor,
            select: Select,
            button: Button,
            dropdown: Dropdown
        }

        return (
            <div style={{ margin: "15px auto" }}>
                <Segment className="edit-pane">
                    <Form className="props">
                        <h3 className="ui dividing header">
                            {createMode ? "Create" : "Edit"} User
                        </h3>
                        {userDetails && this.userFields.map(field => {
                            const resolvedComponent = field.component || "input"
                            const isCheckbox = field.component === "checkbox"
                            const InputComponent = componentMap[resolvedComponent]
                            let renderInputComponent = null

                            if (field.type === 'date') {
                                renderInputComponent = (
                                    <DateInput
                                        name="Date"
                                        required={field.required}
                                        initialDate="1944-06-06"
                                        value={userDetails[field.id]}
                                        dateFormat="YYYY-MM-DD"
                                        onChange={this.updateUserDetails}
                                    />
                                )
                            } else if (field.id === 'assigned_units') {
                                renderInputComponent = (
                                    <InputComponent
                                        name={field.id}
                                        fluid
                                        multiple
                                        search
                                        selection
                                        options={this.props.distinctUnits && this.props.distinctUnits.reduce((acc, { UID, LN, UC, EUnitID }, idx) => {
                                            if (EUnitID !== 0) { 
                                                const text = Number(UID) ? `${LN} -- ${UID}` : `${LN} -- ${UC}`
                                                acc.push({
                                                    key: idx,
                                                    value: EUnitID,
                                                    text
                                                })
                                            }
                                            return acc;
                                        }, [])}
                                        value={userDetails.assigned_units || []}
                                        onChange={this.updateUserDetails}
                                        style={{ minWidth: 250 }}
                                    />
                                )
                            } else {
                                const options = resolvedComponent === 'select' ? field.id === 'role' ? this.userRoles : this.subscriptionTypes : undefined
                                renderInputComponent = (
                                    <InputComponent
                                        type={field.type || undefined}
                                        name={field.id}
                                        required={field.required}
                                        style={{ width: 250 }}
                                        checked={isCheckbox ? userDetails[field.id] : undefined}
                                        onEditorChange={
                                            field.component === "editor"
                                                ? content => {
                                                    userDetails[field.id] = content
                                                }
                                                : undefined
                                        }
                                        value={isCheckbox ? undefined : userDetails[field.id]}
                                        onChange={this.updateUserDetails}
                                        disabled={!field.editable}
                                        rows={4}
                                        options={options}
                                    />
                                )
                            }

                            return (
                                <Form.Field inline key={field.id} style={{ display: "flex" }}>
                                    <label style={{ width: 80 }}>
                                        {field.label} {field.required ? "*" : ""}
                                    </label>
                                    {renderInputComponent}
                                </Form.Field>
                            )
                        })}
                        {warningMsg ? (
                            <Message
                                warning
                                visible
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                {warningMsg}
                            </Message>
                        ) : null}
                        <div className="buttonWrap">
                            <Button onClick={() => this.saveUser()}> Save </Button>
                            {!createMode && (
                                <Button onClick={() => this.deleteUser()}>
                                    Delete User
                                </Button>
                            )}
                            <Button onClick={this.props.backToList}> Cancel </Button>
                            {this.props.isAdmin && (
                                <Button onClick={this.props.onResendEmail}>
                                    Resend verification email
                                </Button>
                            )}
                        </div>
                        {dataSaved ? (
                            <Message success visible>
                                User {createMode ? "created" : "saved"}!
                            </Message>
                        ) : null}
                    </Form>
                </Segment>
            </div>
        )
    }
}

export default UserEdit
