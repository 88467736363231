import React, { Component } from 'react';
import isEqual from 'lodash.isequal'
import { Translate, withLocalize } from "react-localize-redux";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';

import { getAllFilters, setDiaryListInfo, setFrontPageMap } from '../../actions/actions_map';
import { getPois } from '../../actions/actions_pois';
import { verifyToken } from '../../actions/actions_auth';
import Constants, { P44_TOKEN, consoleLogDev } from '../../Constants';

import DrawFeature from './DrawFeature';
import PoiEdit from './PoiEdit';
import CustomData from './CustomData';
import Units from '../../containers/FrontPage/Units';
import MagnifyingGlassTool from './MagnifyingGlassTool';

import {
    returnMultiFilterExpression,
    handlePOIClick
} from './lib/MapUtil';
import {
    addFrontlineLayer,
    addMapOverlay,
    addApprovedPoisLayer,
    addAllPoisLayer,
    addStoryMapLayerExport,
    toggleLayerVisibility,
    handleClusterPoiClick
} from './lib/MapLayerFunctions';

// window.mapboxgl.accessToken = 'pk.eyJ1IjoiaW5kaWdlbm91c21hcCIsImEiOiJjamRnaWZiYXMwazh0MnhwZ2pvNm1icGFmIn0.b4ie-szZ4kLfPH0B4_WP9w';
window.mapboxgl.accessToken = 'pk.eyJ1Ijoib3V0ZG9vcm1hcHBpbmdjb21wYW55IiwiYSI6ImNqYmh3cDdjYzNsMnozNGxsYzlvMmk2bTYifQ.QqcZ4LVoLWnXafXdjZxnZg';

class Map extends Component {
  constructor(props) {
    super(props);
    this.mapStyleURL = this.props.mapSettings.mapType[1];
    // Data from static files
    this.poiJson = false;
    this.storyMapJson = false;
    this.state = {
      map : false,
      playLoop: false,
      monthsFetched: [],
      initialLoad: true,
      eventListenersAdded : {},
      photoModal : false,
      photoModalData : { name : null, description : null }
    };
  }

  componentDidMount() {
    // this.props.dispatch(verifyToken())
    const map = new window.mapboxgl.Map({
      container: 'map',
      // style: 'mapbox://styles/mapbox/outdoors-v11',
      style: this.props.mapSettings.mapType[1],
      center : [-0.5666644, 49.333332],
      zoom : 8,
      preserveDrawingBuffer : true

    });

    var mapboxNav = new window.mapboxgl.NavigationControl();
    map.addControl(mapboxNav, 'bottom-right');
    map.addControl(
        new window.mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        })
    );
    map.addControl(
      new window.MapboxGeocoder({
        accessToken: window.mapboxgl.accessToken,
        mapboxgl: window.mapboxgl
      })
    );

    this.bindMapDev(map)
    map.on('click', this.clearFlyOverInterval)
    map.on('touchend', this.clearFlyOverInterval)
    map.on('moveend', () => this.flyOverEnded(map))
    map.on('load',function() {
      this.setState({ mapLoaded: true }, () => {
        this.componentWillReceiveProps(this.props);
        this.addLayersFromStaticData(map);
      })
      this.props.dispatch(setFrontPageMap({ map }))
      // setTimeout(() => {
      //   map.addSource('mapbox-dem', {
      //     'type': 'raster-dem',
      //     'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
      //     'tileSize': 512,
      //     'maxzoom': 14
      //   });
      //   // add the DEM source as a terrain layer with exaggerated height
      //   map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
      // }, 0);

    }.bind(this))

    this.setState({ map });
  }


  componentWillReceiveProps(nextProps) {
    const { map, mapLoaded } = this.state;

    if (!mapLoaded) { return }

    // Check for changes in map settings
    const newMapSettings = nextProps.mapSettings;
    const oldMapSettings = this.props.mapSettings;

    if (newMapSettings.mapType[0] !== oldMapSettings.mapType[0]) {
        this.switchMapType(newMapSettings.mapType[0], newMapSettings.mapType[1], newMapSettings.threeD);
        return;
    }

    // Check for changes in layer visibility
    if (!isEqual(nextProps.visibleLayers.staticLayers, this.props.visibleLayers.staticLayers)) {
        toggleLayerVisibility(nextProps.visibleLayers.staticLayers, map);
    }

    if (this.state.initialLoad) {
      this.setState({ initialLoad: false })
      this.props.dispatch(getAllFilters(nextProps.allUnits));
    }

    if (nextProps.currentUser !== this.props.currentUser) {
        this.props.dispatch(getPois(nextProps.currentUser.id));
    }

    // if (map && !isEqual(nextProps.allUnits, this.props.allUnits)) {
    //   this.updateMapSourceData('land_units', nextProps.allUnits)
    // }
    if (map) {
        if (map.getLayer('front_line')) {
              this.updateMapLayerFrontLine(map, nextProps)
        } else if (this.props.allLines && this.props.allLines.length > 0) {
          addFrontlineLayer(this.state.map, this.props.allLines, this.props.dispatch, this.props.allFilters, this.props.currentDate, this.props.allUnits, this.props.mapLoadStatus.loadPercentage, this.props.visibleLayers.staticLayers['front_line'])
        }
    }

    addMapOverlay(map);
    this.addallPois(map, nextProps)
    this.addApprovedPois(map, nextProps)

    if (!this.state.eventListenersAdded['poi-approved-features-symbol-clustered'] && map.getLayer('poi-approved-features-symbol-clustered')) {
      this.state.eventListenersAdded['poi-approved-features-symbol-clustered'] = true
      map.on('click', 'poi-approved-features-symbol-clustered', (e) => handleClusterPoiClick(map, e))
    }

     // Generate new filters if unit data has changed
     if (!isEqual(nextProps.allUnits, this.props.allUnits)) {
        this.props.dispatch(getAllFilters(nextProps.allUnits, nextProps.currentFilters, this.props.allFilters));
     }

     if (this.props.autoEditPoi !== nextProps.autoEditPoi) {
        if (nextProps.autoEditPoi) {
            map.on('click', 'poi-features', this.openPoiEditWindow)
            map.on('click', 'poi-approved-features', this.openPoiEditWindow)
        } else {
            map.off('click', 'poi-features', this.openPoiEditWindow)
            map.off('click', 'poi-approved-features', this.openPoiEditWindow)
        }
     }
  }

  bindMapDev = (map) => {
    window.map = map
    setTimeout(() => window.reactProps = this.props, 5000)
    map.on('click', ({ point }) => {
      console.log('f', map.queryRenderedFeatures(point)[0], map.queryRenderedFeatures(point))
    })
  }

  clearFlyOverInterval = () => {
    if (this.props.diaryListInfo.flyOverInterval) {
      window.clearInterval(this.props.diaryListInfo.flyOverInterval)
      this.props.dispatch(setDiaryListInfo({
        ...this.props.diaryListInfo,
        flyOverInterval: null
      }))
    }
  }

  flyOverEnded = (map) => {
    if (this.props.diaryListInfo.isFlying) {
      const flyOverInterval = window.setInterval(() => {
        map.setBearing(map.getBearing() + 0.05)
      }, 50)
      this.props.dispatch(setDiaryListInfo({
        ...this.props.diaryListInfo,
        isFlying: false,
        flyOverInterval
      }))
    }
  }

  updateMapSourceData = (source, data) => {
    let allFeatures = [];
    for (const date in data) {
      allFeatures = allFeatures.concat(data[date]);
    }
    const featureCollection = { type: "FeatureCollection", features: allFeatures };
    featureCollection.features = featureCollection.features.map((feature) => {
      if (!feature.id) {
        feature.id = feature.properties.feature_id || '_' + Math.random().toString(36).substr(2, 9)
      }
      return feature
    })

    this.state.map.getSource(source).setData(featureCollection);
  }

    addLayersFromStaticData(map) {
        var that = this;
        function addStoryMapLayer(json, map) {
            addStoryMapLayerExport(json, map, that.state.eventListenersAdded, that.props.visibleLayers.staticLayers['storymap-points']);

            var newEventListenerState = JSON.parse(JSON.stringify(that.state.eventListenersAdded));
            newEventListenerState['storymap-points'] = true;
            that.setState({ eventListenersAdded: newEventListenerState });
        }
        // Add special POIs
        if (!this.storyMapJson) {
            fetch('/data/StoryMap.json').then(response => response.json()).then(json => {
                this.storyMapJson = json;
                addStoryMapLayer(json, map);
            })
        } else {
            addStoryMapLayer(this.storyMapJson, map);
        }
    }

  updateMapLayerFrontLine = (map, nextProps)  => {
    ['line', 'line_offset_positive', 'line_offset_negative'].forEach(layerName => {
      map.setPaintProperty(`front_${layerName}`, 'line-width', returnMultiFilterExpression(nextProps.currentFilters, nextProps.currentDate, nextProps.allUnits[this.props.currentDate], map.getZoom(), layerName))
    })
  }

  openPoiEditWindow = (e) => {
    handlePOIClick(this.props.map, e.features[0], this.props.dispatch, this.props.currentUser)
  }

  addApprovedPois = (map, nextProps) => {
    if (!isEqual(this.props.allApprovedPois, nextProps.allApprovedPois) || nextProps.allApprovedPois.length > 0 && !map.getSource('poi-approved-features')) {
        addApprovedPoisLayer(map, nextProps.allApprovedPois, nextProps.visibleLayers.staticLayers['poi-features']);

        var eventListenersAdded = JSON.parse(JSON.stringify(this.state.eventListenersAdded));
        if (!eventListenersAdded['poi-approved-features']) {
            map.on('click', 'poi-approved-features', (e) => {
                this.setState({
                    photoModal : true,
                    photoModalData : {
                        name : e.features[0].properties.title,
                        description : e.features[0].properties.description,
                        imgUrls : JSON.parse(e.features[0].properties.image_urls)
                    }
                });
            });
            if (nextProps.autoEditPoi) {
                map.on('click', 'poi-approved-features', this.openPoiEditWindow);
            }

            map.on('mouseenter', 'poi-approved-features', (e) => {
                map.getCanvas().style.cursor = 'pointer';
                const popup = new window.mapboxgl.Popup({ anchor: 'bottom' })
                const poiTitle = e.features[0].properties.title
                popup.setLngLat(e.features[0].geometry.coordinates)
                    .setHTML(`<p><strong>${poiTitle}</strong></p>`)
                    .addTo(map)
                this.setState({ poiPopup: popup })
            });
            map.on('mouseleave', 'poi-approved-features', (e) => {
                map.getCanvas().style.cursor = '';
                this.state.poiPopup && this.state.poiPopup.remove()
                this.setState({ poiPopup: null })
            });
          }
      }
  }

  addallPois = (map, nextProps) => {
    if (
      !isEqual(this.props.allPois, nextProps.allPois) ||
      nextProps.allPois.length > 0 && !map.getSource('poi-features')
    ) {
        addAllPoisLayer(map, nextProps.allPois, nextProps.visibleLayers.staticLayers['poi-features']);
        var eventListenersAdded = JSON.parse(JSON.stringify(this.state.eventListenersAdded));

        if (!eventListenersAdded['poi-features']) {
            map.on('click', 'poi-features', (e) => {
                this.setState({
                    photoModal : true,
                    photoModalData : {
                        name : e.features[0].properties.title,
                        description : e.features[0].properties.description,
                        imgUrls : JSON.parse(e.features[0].properties.image_urls)
                    }
                });
            });
            if (nextProps.autoEditPoi) {
                map.on('click', 'poi-features', this.openPoiEditWindow);
            }

            map.on('mouseenter', 'poi-features', (e) => {
                map.getCanvas().style.cursor = 'pointer';
                const popup = new window.mapboxgl.Popup({ anchor: 'bottom' })
                const poiTitle = e.features[0].properties.title
                popup.setLngLat(e.features[0].geometry.coordinates)
                    .setHTML(`<p><strong>${poiTitle}</strong></p>`)
                    .addTo(map)
                this.setState({ poiPopup: popup })
            });
            map.on('mouseleave', 'poi-features', (e) => {
                map.getCanvas().style.cursor = '';
                this.state.poiPopup && this.state.poiPopup.remove()
                this.setState({ poiPopup: null })
            });

            var eventListenersAdded = JSON.parse(JSON.stringify(this.state.eventListenersAdded));
            // Updating for both layers in one spot because one was overwriting the other previously
            eventListenersAdded['poi-features'] = true;
            eventListenersAdded['poi-approved-features'] = true;

            this.setState({ eventListenersAdded });
        }
      }
  }

  toggleOverlay = (type) => {
    const { map } = this.state;

    if (type === 'map-overlay') {
      if (map.getLayer('omc-tileset-map-overlay')) {
        map.setLayoutProperty('omc-tileset-map-overlay', 'visibility', 'visible')
      }
      if (map.getLayer('wms-overlay')) {
        map.setLayoutProperty('wms-overlay', 'visibility', 'none')
      }
    } else if (type === 'aerial-overlay') {
      if (map.getLayer('wms-overlay')) {
        map.setLayoutProperty('wms-overlay', 'visibility', 'visible')
      }
      if (map.getLayer('omc-tileset-map-overlay')) {
        map.setLayoutProperty('omc-tileset-map-overlay', 'visibility', 'none')
      }
    }
  }

  switchMapType(type, url, threeD) {
    const { map } = this.state;
    this.mapType = type;
    // Need to check against this.mapType because for some reason the type var changes in the style.load
    if (type === "satellite") {
        // map.setStyle accepts a url to a style json or js object that confirms to the style spec
        url = {
            version: 8,
            sources: {
                'raster-tiles': {
                    type: "raster",
                    tiles: [url],
                    tileSize: 256,
                    attribution: 'Map tiles by <a target="_top" rel="noopener" href="http://stamen.com">Stamen Design</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>',
                },
            },
            layers: [
                {
                    id: "simple-tiles",
                    type: "raster",
                    source: "raster-tiles",
                    minzoom: 0,
                    maxzoom: 22,
                },
            ],
            glyphs: "mapbox://fonts/outdoormappingcompany/{fontstack}/{range}.pbf",
        };
    }

    if (this.mapStyleURL !== url) {
        this.setState({ mapLoaded: false })
        map.setStyle(url);
        this.mapStyleURL = url;

        map.on('style.load', () => {
            const waiting = () => {
              if (!map.isStyleLoaded()) {
                setTimeout(waiting, 200);
              } else {
                this.setState({ mapLoaded: true })
                addFrontlineLayer(this.state.map, this.props.allLines, this.props.dispatch, this.props.allFilters, this.props.currentDate, this.props.allUnits, this.props.mapLoadStatus.loadPercentage, this.props.visibleLayers.staticLayers['front_line'])

                this.addLayersFromStaticData(map);
                if (this.mapType.includes('overlay')) {
                    this.toggleOverlay(this.mapType);
                }

                if (this.mapType.includes('aerial')) {
                  const currZoom = map.getZoom();
                  if (currZoom <= 10) {
                    map.flyTo({ zoom: 11, speed: 0.6 });
                  }
                  // console.log(`It's aerial. Zoom is ${map.getZoom()}`)
                }

                if (threeD) {
                  if (!map.getSource('terrain')) {
                    map.addSource('terrain', {
                      'type': 'raster-dem',
                      'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                      'tileSize': 512,
                      'maxzoom': 14
                    });
                    // add the DEM source as a terrain layer with exaggerated height
                    map.addLayer({
                      'id': 'sky',
                      'type': 'sky',
                      'paint': {
                        'sky-type': 'atmosphere',
                        'sky-atmosphere-sun': [0.0, 0.0],
                        'sky-atmosphere-sun-intensity': 15
                      }
                    });
                  }
                  map.setTerrain({ 'source': 'terrain', 'exaggeration': 1.5 });
                  map.setLayoutProperty('sky', 'visibility', 'visible')
                }
              }
            }
            waiting();
        })

    } else if (this.mapType.includes('overlay')) {
      this.toggleOverlay(this.mapType);
    }
  }

  render() {
    const { photoModal, photoModalData } = this.state;

    return (
      <div>
        <div id="map" />

        <Units
            map={this.state.map}
            mapLoaded={this.state.mapLoaded}
            activeUnitDiaries={this.props.activeUnitDiaries}
            activeLanguage={this.props.activeLanguage}
            playing={this.props.playing}
            setClickedLandUnits={this.props.setClickedLandUnits}
        />

        <DrawFeature
            map={this.state.map}
            addingPoint={this.props.addingPoint}
            savingPoint={this.props.savingPoint}
            dispatch={this.props.dispatch}
            mapLoaded={this.state.mapLoaded}
            poiWindowData={this.props.poiWindowData}
        />

        { this.props.addingPoint === 'magnify' ?
            <MagnifyingGlassTool map={this.state.map} />
        : false }

        <PoiEdit
            map={this.state.map}
            currentUser={this.props.currentUser}
            dispatch={this.props.dispatch}
        />

        <CustomData
            map={this.state.map}
            currentDate={this.props.currentDate}
            customDataJson={this.props.customDataJson}
            visibleLayers={this.props.visibleLayers.customLayers}
            eventListenersAdded={this.state.eventListenersAdded}
            updateEventListeners={(eventListenersAdded) => { this.setState({ eventListenersAdded })} }
            dispatch={this.props.dispatch}
        />
        <Modal
          centered={true}
          isOpen={photoModal}
          toggle={() => this.setState({ photoModal : false })}
          className="photo-modal"
        >
            <ModalHeader toggle={() => this.setState({ photoModal : false })}>
                { photoModalData.name }
            </ModalHeader>
            <ModalBody id="poi-photo-modal-body">
                <div className="poi-img">
                    { photoModalData.imgUrls && photoModalData.imgUrls.length > 1 ?
                         <CarouselProvider
                            naturalSlideWidth={400}
                            naturalSlideHeight={325}
                            totalSlides={photoModalData.imgUrls.length}
                          >
                            <Slider>
                              { photoModalData.imgUrls.map((url, i) => {
                                  return (
                                      <Slide
                                        index={i}
                                        className="slider-img"
                                        style={{ backgroundImage : 'url(' + url + ')'}}
                                      ></Slide>
                                  )
                              })}
                            </Slider>

                            <DotGroup className="slider-dots" />
                            <div className="slider-arrow-buttons">
                                <ButtonBack>
                                    <i className="fa fa-angle-left"></i>
                                </ButtonBack>
                                <ButtonNext>
                                    <i className="fa fa-angle-right"></i>
                                </ButtonNext>
                            </div>
                          </CarouselProvider>
                    :
                        <div>
                            {photoModalData.imgUrls && photoModalData.imgUrls.map((url) => {
                              return <img key={url} width="100%" src={`${url}`} />
                            })}
                        </div>
                    }
                </div>
                { photoModalData.description !== "null" ?
                    <div className="description" dangerouslySetInnerHTML={{__html: photoModalData.description}} />
                : null }
            </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withLocalize(Map);
