import React, { Component } from 'react';
import { Header, Button, Image, Sidebar, Menu, Popup, Checkbox, Loader } from 'semantic-ui-react';
import { Translate, withLocalize } from "react-localize-redux";
import { Waypoint } from 'react-waypoint';
import moment from 'moment';
import wiki from 'wikijs'
import '../../css/Diary.scss';
import { closeDiary, setActiveDiary, setShowDiaryList } from '../../actions/actions_diaries';
import { setCurrentDate } from '../../actions/actions_dates';
import { updateMapSettings, setDiaryListInfo, clearFilters } from '../../actions/actions_map';
import { setActiveSidebarPanel } from '../../actions/actions_sidebar';
import { dateRange } from '../../Constants';

function getClosestMatchIndex(results, unitName) {
    function formatInput(input) {
        if (input.substring(0, 3) === "The") { input = input.substring(3) }
        return input.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }

    let matchIdx = results.findIndex(item => formatInput(item).includes(formatInput(unitName).substring(0, 10)));
    matchIdx = matchIdx > -1 ? matchIdx : 0;

    return matchIdx;
}

class WarDiary extends Component {
    constructor(props) {
        super(props)
        //this.sideBar = React.createRef();
        this.isUnitChanging = false;
        this.wayPointChange = false;
        this.state = {
            tab : 'diary',
            fontSize : 15,
            wikiUrl: null,
            wikiImage: null,
            wikiTitle: null,
            wikiContent : [],
            wikiSummary : '',
            currentUnit : false
        }
    }

    componentDidMount() {
        this.props.dispatch(setShowDiaryList([this.props.currentDate]))
        this.componentWillReceiveProps(this.props);

        // If loading back from another component, retrieve active unit and scroll to diary
        if (this.props.unitsWithDiaries) {
            const currentUnit = this.props.unitsWithDiaries.find(unit => this.props.activeUnitDiaries[0] && unit.EUnitID === this.props.activeUnitDiaries[0].unit_UID);
            this.setState({ currentUnit });

            this.showCurrentDiary(this.props);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.wayPointTimeout)
    }

    shortNameToLongName(shortName) {
        const nameMap = {
          GB: this.props.translate('great_britain'),
          CAN: this.props.translate('canada'),
          POL: this.props.translate('poland'),
          USA: this.props.translate('united_states'),
          FRE: this.props.translate('france'),
          GMN: this.props.translate('germany'),
          CZE: this.props.translate('czechoslovakia'),
          JPN: this.props.translate('japan'),
        }
        return nameMap[shortName]
    }

    /* DEPR
    resetSidebarPos = () => {
        console.log('reset sidebar pos');

        if (this.sideBar.current && this.sideBar.current.ref) {
            this.sideBar.current.ref.scrollTop = 0
        }
    }
    */

    handleSideBarScroll = () => {
        if (this.props.diaryListInfo.flyOverInterval) {
            window.clearInterval(this.props.diaryListInfo.flyOverInterval)
            setDiaryListInfo({
                ...this.props.diaryListInfo,
                flyOverInterval: null
            })
        }
    }

    async componentWillReceiveProps(nextProps) {
        if (
            (this.props.activeUnitDiaries && nextProps.activeUnitDiaries.length > 0 && this.props.activeUnitDiaries[0] && nextProps.activeUnitDiaries[0] && this.props.activeUnitDiaries[0].unit_UID !== nextProps.activeUnitDiaries[0].unit_UID) ||
            // ^ unit selected and current doesn't match next
            !this.props.activeUnitDiaries && nextProps.activeUnitDiaries
            // ^ no unit selected and one is selected
        ) {

            const currentUnit = this.props.unitsWithDiaries.find(unit => nextProps.activeUnitDiaries[0] && unit.EUnitID === nextProps.activeUnitDiaries[0].unit_UID);
            this.setState({ currentUnit, unitNotFound: null });

            // If there is no unit data or no diary on the current date, change date to the first date that a diary exists after June 6th
            const unitExistsOnCurrentDate  = nextProps.allUnits[nextProps.currentDate].find(unit => currentUnit && unit.properties && unit.properties.EUnitID === currentUnit.EUnitID)
            const diaryExistsOnCurrentDate = nextProps.activeUnitDiaries.find(diary => diary.Date === nextProps.currentDate);
            if (nextProps.activeUnitDiaries[0] && (!unitExistsOnCurrentDate || !diaryExistsOnCurrentDate)) {
                const newDate = moment(nextProps.activeUnitDiaries[0].Date).isBefore('1944-06-06') ? '1944-06-06' : nextProps.activeUnitDiaries[0].Date;
                // check if the new wd matches the current theatre
                const startDate = moment(newDate).startOf('month').format('YYYY-MM-DD')
                const endDate = moment(newDate).endOf('month').format('YYYY-MM-DD')
                const qs = `?Date=${startDate},${endDate}&EUnitID=${nextProps.activeUnitDiaries[0].unit_UID}`
                const resp = await fetch(`${process.env.REACT_APP_BACKEND}/units${qs}`)
                const units = await resp.json()
                const unitOnNewDiaryDate = units.find(unit => unit.properties.Date === newDate)
                if (!unitOnNewDiaryDate || unitOnNewDiaryDate.properties.theatre !== this.props.theatre) {
                    // no unit on the day of the first war diary
                    // or the unit's theatre for that day doesn't match the current theatre
                    return
                }
                this.props.dispatch(setCurrentDate(newDate));
            }

            this.showCurrentDiary(nextProps);

            // Wiki!
            if (!nextProps.activeUnitDiaries[0]) {
                return
            }
            const unitId = nextProps.activeUnitDiaries[0].unit_UID
            const resp = await fetch(`${process.env.REACT_APP_BACKEND}/units?EUnitID=${unitId}`)
            const json = await resp.json()
            const longName = json[0].properties.LN
            const { results } = await wiki({apiUrl: 'https://en.wikipedia.org/w/api.php'}).search(`${longName} ${this.shortNameToLongName(json[0].properties.UC)}`)
            // Check for match of the first 10 characters of the unit name. Otherwise default to the first result
            const matchIdx = getClosestMatchIndex(results, longName);

            if (results[matchIdx]) {
                // console.log(results[matchIdx], ' getting this page');
                const page = await wiki({apiUrl: 'https://en.wikipedia.org/w/api.php'}).page(results[matchIdx])
                const url = page.url()
                // TODO: refactor using Promise.all
                const content = await page.content()
                const summary = await page.summary()
                const infoBox = await page.fullInfo()

                const mainImage = await page.mainImage()
                this.setState({
                    wikiUrl: url,
                    wikiImage: mainImage,
                    wikiTitle: results[matchIdx],
                    wikiData : infoBox.general,
                    wikiSummary : summary,
                    wikiContent : this.getWikiContent(content)
                })
            } else {
                this.setState({
                    wikiUrl: null,
                    wikiImage: null,
                    wikiTitle: null,
                    wikiData : null,
                    wikiSummary : null,
                    wikiContent : null
                })
            }
        } else if (nextProps.currentDate !== this.props.currentDate) {
            this.updateScrollDate(nextProps)
            if (!this.wayPointChange) { this.scrollToDiary(nextProps.currentDate) }
        } else if (nextProps.activeUnitDiaries && nextProps.activeUnitDiaries.length === 0) {
            // ^ a unit without any diaries was chosen)
            this.setState({ unitNotFound: this.props.clickedUnit })
        } else if (!this.props.activeUnitDiaries.length && nextProps.activeUnitDiaries) {
            // ^ a unit without diaries was chosen first and then a unit with diaries was chosen
            this.setState({ unitNotFound: null })
        }

        // Get previous dates if war diaries were opened by an icon click
        // This is kind of scrappy, could probably be done better
        if (nextProps.activeUnitDiaries[0] && nextProps.activeUnitDiaries[0].Date !== nextProps.showDiariesList[0] && nextProps.showDiariesList.length > 1) {
            this.showCurrentDiary(nextProps);
        }

        // set transparency/opacity of the units on the map
        // based on the EUnitID of the currently moused-over
        // unit in the war diaries sidebar list
        if (nextProps.diaryListInfo.mouseOver !== this.props.diaryListInfo.mouseOver) {
            const newEUnitID = nextProps.diaryListInfo.mouseOver
            const oldEUnitID = this.props.diaryListInfo.mouseOver
            const map = this.props.frontPageMap.map || this.props.frontPageMap

            if (!newEUnitID) {
                // we've left the sidebar and should restore all opacity
                map.setPaintProperty('land_units_'+this.props.currentDate, 'text-opacity', [
                    'interpolate',
                    ['exponential', 1.96],
                    ['zoom'],
                    0, ['case',
                        ['==', 'Army', ["get", 'US']], 1,
                        ['==', 'Army Group', ["get", 'US']], 1,
                        ['==', 'Division', ["get", 'US']], 0.19,
                        ['==', 'Corps', ["get", 'US']], 0.19,
                        ['==', 'Corp', ["get", 'US']], 0.19,
                        ['==', 'Brigade', ["get", 'US']], 0,
                        ['==', 'Battalion', ["get", 'US']], 0,
                        ['==', 'Company', ["get", 'US']], 0,
                        ['==', 'Regiment', ["get", 'US']], 0,
                        ['==', 'Platoon', ["get", 'US']], 0,
                       0 ],
                    8, ['case',
                        ['==', 'Army', ["get", 'US']], 1,
                        ['==', 'Army Group', ["get", 'US']], 1,
                        ['==', 'Division', ["get", 'US']], 0.95,
                        ['==', 'Corps', ["get", 'US']], 0.95,
                        ['==', 'Corp', ["get", 'US']], 0.95,
                        ['==', 'Brigade', ["get", 'US']], 0.5,
                        ['==', 'Battalion', ["get", 'US']], 0.5,
                        ['==', 'Company', ["get", 'US']], 0.5,
                        ['==', 'Regiment', ["get", 'US']], 0.5,
                        ['==', 'Platoon', ["get", 'US']], 0.5,
                       0 ],
                    10, ['case',
                        ['==', 'Army', ["get", 'US']], 1,
                        ['==', 'Army Group', ["get", 'US']], 1,
                        ['==', 'Division', ["get", 'US']], 1,
                        ['==', 'Corps', ["get", 'US']], 1,
                        ['==', 'Corp', ["get", 'US']], 1,
                        ['==', 'Brigade', ["get", 'US']], 1,
                        ['==', 'Battalion', ["get", 'US']], 1,
                        ['==', 'Company', ["get", 'US']], 1,
                        ['==', 'Regiment', ["get", 'US']], 1,
                        ['==', 'Platoon', ["get", 'US']], 1,
                        0 ]
                  ])
            } else if (newEUnitID) {
                // make all other units 70% transparancy
                // make this one 100% if it exists today
                // for now this is overwriting our carefully crafted opacity
                // to control opacity of unit sizes at different zoom levels
                map.getLayer('land_units_'+this.props.currentDate)
                map.setPaintProperty('land_units_'+this.props.currentDate, 'text-opacity', [
                    'case',
                    ['==', newEUnitID, ['get', 'EUnitID']], 1,
                    0.7
                ])
            }
        }
    }

    //Get diaires starting from first available diary to 5 after the current date
    showCurrentDiary = (props) => {
        const { activeUnitDiaries, currentDate } = props;

        const firstDate = activeUnitDiaries[0] ? activeUnitDiaries[0].Date : currentDate;
        const dates = dateRange(currentDate, 5, firstDate)

        this.props.dispatch(setShowDiaryList(dates))
        this.scrollToDiary();
    }

    setWaypointChange() {
       this.wayPointChange = true;

       if (this.wayPointTimeout) {
            clearTimeout(this.wayPointTimeout)
       }
       this.wayPointTimeout = setTimeout(() => {
            this.wayPointChange = false;
        }, 3000);
    }

    updateScrollDate = (nextProps) => {
        const firstDate = nextProps.activeUnitDiaries[0] ? nextProps.activeUnitDiaries[0].Date : nextProps.currentDate;
        const dates = dateRange(nextProps.currentDate, 5, firstDate)

        if (!this.props.showDiariesList.includes(dates[dates.length -1])) {
            this.props.dispatch(setShowDiaryList(dates))
        }
    }

    scrollToDiary(newDate) {
        this.isUnitChanging = true;
        const thisDate = newDate ? newDate : this.props.currentDate;

        const waiting = () => {
            var el = document.getElementById(`waypoint-${thisDate}`);
            if (!el) { setTimeout(waiting, 200) }
            else {
                var containerEl = document.getElementById("diaries-container")
                containerEl.scrollTop = (el.offsetTop - containerEl.offsetTop - 5);

                // Wait for offset position to change before allowing waypoints to trigger again
                setTimeout(() => { this.isUnitChanging = false }, 500);
            }
        }
        waiting();
    }

    incrementDay = (startDate = this.props.currentDate, updateShowDiaryList = false) => {
        const nextDate = moment(startDate).add(1, 'day');
        this.props.dispatch(setCurrentDate(nextDate.format('YYYY-MM-DD')))
    }

    decrementDay = (startDate = this.props.currentDate, updateShowDiaryList = false) => {
        const nextDate = moment(startDate).subtract(1, 'day').format('YYYY-MM-DD')
        this.props.dispatch(setCurrentDate(nextDate))
    }

    formatDate(date) {
        let day   = moment(date.Date).format('D')
        let month = this.props.translate(moment(date.Date).format('MMMM').toLowerCase());
        let year = moment(date.Date).format('YYYY');

        return `${day} ${month.toUpperCase()} ${year}`;
    }

    toggleFollow() {
         const newFollow = this.props.mapSettings.followType === 'follow' ? 'static' : 'follow';
         this.props.dispatch(updateMapSettings(['followType', newFollow]));
    }

    changeTab(tab) {
        if (tab === 'diary') { this.scrollToDiary() }
        if (tab === 'info')  { document.getElementById("diaries-container").scrollTop = 0 }
        this.setState({ tab })
    }

    closeDiary = () => {
        this.props.dispatch(setActiveSidebarPanel(false));
        // Give time for drawer to close
        setTimeout(() => { this.props.dispatch(setActiveDiary(0))}, 600);
    }

    getWikiContent(content) {

        function checkForWWII(item) {
           if ( (item.title.includes('Second World') || item.title.includes('War II')) && item.content.length > 0) {
            return item;
           }
        }

        let wikiContent = [];

        content.forEach(section => {
            if (checkForWWII(section)) {
                wikiContent.push(section);
            } else if (section.items) {
                section.items.forEach(item => {
                    if (checkForWWII(item)) {
                        wikiContent.push(item);
                    }
                });
            }
        });

        return wikiContent;
    }

    startFlyOver = (unit) => {
        return new Promise((resolve, reject) => {
            const map = this.props.frontPageMap.map || this.props.frontPageMap;
            const { currentDate, allUnits } = this.props;
            const unitOnThisDay = allUnits[currentDate].find(u => u.properties.EUnitID === unit.EUnitID)
            // first clear any old intervals
            if (this.props.diaryListInfo.flyOverInterval) {
                window.clearInterval(this.props.diaryListInfo.flyOverInterval)
                setDiaryListInfo({
                    ...this.props.diaryListInfo,
                    flyOverInterval: null
                })
            }
            setTimeout(() => {
                const doFlyOver = (unit) => {
                    let isHidden = false
                    const filterCategories = ['UC', 'US', 'UT']
                    for (let cat of filterCategories) {
                        if (!this.props.currentFilters[cat].includes(unit.properties[cat])) {
                            isHidden = true
                            break
                        }
                    }
                    if (isHidden) {
                        this.props.dispatch(clearFilters())
                    }
                    map.flyTo({
                        center: unit.geometry.coordinates,
                        speed: 0.6,
                        zoom: 11
                    })
                    // transition start
                    this.props.dispatch(setDiaryListInfo({
                        ...this.props.diaryListInfo,
                        isFlying: true
                    }))
                }
                if (unitOnThisDay) {
                    doFlyOver(unitOnThisDay)
                } else {
                    for (let date in allUnits) {
                        const unitFound = allUnits[date].find(u => u.properties.EUnitID === unit.EUnitID)
                        if (unitFound) {
                            // set date and fly over
                            this.props.dispatch(setCurrentDate(date))
                            doFlyOver(unitFound)
                            break
                        }
                    }
                    // console.log('never lucky :(')
                }
            }, 0)
        })
    }

    getUnitSource = (unit) => {
        const { allUnits, currentDate } = this.props;
        if (!allUnits[currentDate]) {
            return ''
        }
        const unitToday = allUnits[currentDate].find(u => u.properties.EUnitID === unit.EUnitID)
        return unitToday ? unitToday.properties.source : ''
    }

    render() {
        // activeUnitDiaries is an array of all diaries for that unit by date OR
        // 0 if the unit doesn't have any war diaries
        let { tab, wikiData, wikiContent, wikiSummary, fontSize, currentUnit } = this.state;
        const { allUnits, currentDate, currentSelectedUnits, activeLanguage, activeUnitDiaries, dispatch, } = this.props;

        if (currentUnit && activeUnitDiaries) {
            const source = this.getUnitSource(currentUnit)
            const longName = activeLanguage.code==='en' ? currentUnit.LN : currentUnit.LNameFr

            return (
                <Sidebar
                    as={Menu}
                    animation='push'
                    icon='labeled'
                    direction="right"
                    onHide={() => this.setState({ sidebar: true })}
                    vertical
                    visible={!!this.props.activeUnitDiaries}
                    width='wide'
                    className="war-diaries"
                    ref={this.sideBar}
                >
                    <Header className="main-war-diaries-header">

                        <Translate>
                          {({ translate, activeLanguage, languages }) => (
                            <div>
                                <i className="fa fa-times" onClick={() => this.closeDiary()}></i>
                                <div className="diary-arrows play-buttons">
                                    <Popup className="capitalize" trigger={<div onClick={() => this.decrementDay(this.props.currentDate, true)}><i className="fas fa-step-backward" /></div>} content={translate("step_backward")} />
                                    <Popup className="capitalize" trigger={<div onClick={() => this.incrementDay(this.props.currentDate, true)}><i className="fas fa-step-forward" /></div>} content={translate("step_forward")} />
                                </div>
                                <h1> { longName } </h1>
                                { source ? <h1 className="source">{'Source: ' + source }</h1> : null }
                                <span className="text-size-controls">
                                    <i className="fa fa-minus-square" onClick={() => this.setState({ fontSize : fontSize- 1 })}></i>
                                    <i className="fa fa-plus-square"  onClick={() => this.setState({ fontSize : fontSize + 1 })}></i>
                                </span>
                              <Checkbox
                                className="capitalize diary-follow-button"
                                label={translate("follow")}

                                type="checkbox"
                                toggle

                                name="follow-type"
                                value="follow"
                                checked={this.props.mapSettings.followType.indexOf('follow') !== -1 }
                                onChange={() => this.toggleFollow()}
                              />

                            </div>

                          )}
                        </Translate>

                        <ul className="war-diary-menu">
                            <li onClick={() => this.changeTab('diary')} className={ tab === 'diary' ? 'active' : '' }><span className="capitalize"><Translate id="units" /></span></li>
                            <li onClick={() => this.changeTab('info')} className={ tab === 'info' ? 'active' : '' }><span className="capitalize"><Translate id="unit_info" /></span></li>
                        </ul>
                     </Header>

                        <div className="diaries" id="diaries-container" ref={this.diariesContainer}>

                            { tab === 'diary' && this.props.activeUnitDiaries.map((day, idx) => {
                                const showDiary = day.Date && this.props.showDiariesList.includes(day.Date)
                                if (showDiary) {
                                    return (
                                        <Waypoint
                                            onEnter={() => {
                                                if (!this.isUnitChanging) {
                                                    this.setWaypointChange()}
                                                }
                                            }
                                            onLeave={({ currentPosition }) => {
                                                // ignore changes when the unit changes
                                                if (currentPosition === 'above' && !this.isUnitChanging) {
                                                    this.incrementDay(day.Date)
                                                    this.setWaypointChange()
                                                }
                                                if (currentPosition === 'below' && !this.isUnitChanging) {
                                                    this.decrementDay(day.Date)
                                                    this.setWaypointChange()
                                                }
                                            }}
                                            bottomOffset="20%"
                                            topOffset="20%"
                                            key={`${day.Date}-${idx}`}
                                            fireOnRapidScroll={true}
                                        >
                                            <div className="main-diary" id={`waypoint-${day.Date}`}>
                                                {/* <Header style={{ textAlign: 'center', color: '#fff' }} className="date">{moment(day.Date).format('D MMMM YYYY')}</Header> */}
                                                 <Header style={{ textAlign: 'center', color: '#fff' }} className="date">{this.formatDate(day)}</Header>
                                                <div style={{ fontSize }} dangerouslySetInnerHTML={{ __html: day.content }} />
                                            </div>
                                        </Waypoint>
                                    )
                                } else { return false; }
                            })}

                         { tab === 'info' && this.state.wikiImage && this.state.wikiUrl ?
                            <div className="unit-info">
                                <h1 style={{ color : '#2e3b41', fontFamily : 'Barlow Condensed', fontSize : 24 }}>{this.state.wikiTitle}</h1>
                                <Image style={{ margin: '10px auto' }} size="medium" src={this.state.wikiImage} />
                                { wikiData.caption ? <span className="img-caption" style={{ margin : '10 auto', fontSize : 16 }}> {wikiData.caption} </span> : null }

                                <div className="wiki-content" style={{ fontSize }}>
                                    <h1 className="capitalize"> {this.props.translate('summary')} </h1>
                                    { wikiSummary }
                                </div>

                                { wikiContent.length > 0 ?
                                    <div className="wiki-content" style={{ fontSize }}>
                                        <h1> World War II </h1>
                                        { wikiContent.map((item, i) => {
                                            return (
                                                <div key={`wikicontent-${i}`}>
                                                    {item.content}
                                                </div>
                                            )
                                        })}
                                    </div>
                                 : false }


                                {/*
                                <ul className="unit-data-list">
                                    { wikiData.country ?  <li><label> Country:      </label>  {wikiData.country}    </li> : null }
                                    { wikiData.branch?    <li><label> Branch:       </label>  {wikiData.branch}     </li> : null }
                                    { wikiData.type ?     <li><label> Type:         </label>  {wikiData.type}       </li> : null }
                                    { wikiData.nickname ? <li><label> Nickname:     </label>  {wikiData.nickname}   </li> : null }
                                    { wikiData.march ?    <li><label> March:        </label>  {wikiData.march}      </li> : null }
                                    { wikiData.motto ?    <li><label> Motto:        </label>  {wikiData.motto}      </li> : null }
                                    { wikiData.garrison ? <li><label> garrison:     </label>  {wikiData.garrison}   </li> : null }
                                </ul>
                                */}

                                <div><a href={this.state.wikiUrl} target="_blank" > Visit Wikipedia Page <i className="fa fa-external-link-alt"></i></a></div>
                            </div>
                        : null}

                    </div>


                    {typeof this.props.currentWarDiary !== 'boolean' ?
                        <div className="war-diary-buttons">
                            <Button color='black' onClick={() => this.closeDiary()}>
                                <span className="capitalize"><Translate id="view_list" /></span>
                            </Button>
                        </div>
                    : false}
                </Sidebar>
            );
        //} else if (this.props.activeUnitDiaries === 0) { // Unit_ID == 0
        } else if (this.state.unitNotFound) {
            const source = this.getUnitSource(this.state.unitNotFound.properties)
            const longName = this.props.activeLanguage.code==='en' ? this.state.unitNotFound.properties.LN : this.state.unitNotFound.properties.LNameFr
            return (
                <Sidebar
                    as={Menu}
                    animation='push'
                    icon='labeled'
                    direction="right"
                    onHide={() => this.setState({ sidebar: true })}
                    vertical
                    visible={!!this.props.activeUnitDiaries}
                    width='wide'
                    className="war-diaries"
                    ref={this.sideBar}
                >
                    <Header className="main-war-diaries-header">
                        <Translate>
                            {({ translate, activeLanguage, languages }) => (
                                <div>
                                    <i className="fa fa-times" onClick={() => this.closeDiary()}></i>
                                    <div className="diary-arrows play-buttons">
                                        <Popup className="capitalize" trigger={<div onClick={() => this.decrementDay(this.props.currentDate, true)}><i className="fas fa-step-backward" /></div>} content={translate("step_backward")} />
                                        <Popup className="capitalize" trigger={<div onClick={() => this.incrementDay(this.props.currentDate, true)}><i className="fas fa-step-forward" /></div>} content={translate("step_forward")} />
                                    </div>
                                    <h1> { longName } </h1>
                                    { source ? <h1 className="source">{'Source: ' + source }</h1> : null }
                                    <span className="text-size-controls">
                                        <i className="fa fa-minus-square" onClick={() => this.setState({ fontSize : fontSize- 1 })}></i>
                                        <i className="fa fa-plus-square"  onClick={() => this.setState({ fontSize : fontSize + 1 })}></i>
                                    </span>
                                    <Checkbox
                                        className="capitalize diary-follow-button"
                                        label={translate("follow")}
                                        type="checkbox"
                                        toggle
                                        name="follow-type"
                                        value="follow"
                                        checked={this.props.mapSettings.followType.indexOf('follow') !== -1 }
                                        onChange={() => this.toggleFollow()}
                                    />
                                </div>
                            )}
                        </Translate>
                        <ul className="war-diary-menu">
                            <li onClick={() => this.changeTab('diary')} className={ tab === 'diary' ? 'active' : '' }><span className="capitalize"><Translate id="units" /></span></li>
                            <li onClick={() => this.changeTab('info')} className={ tab === 'info' ? 'active' : '' }><span className="capitalize"><Translate id="unit_info" /></span></li>
                        </ul>
                    </Header>
                    {typeof this.props.currentWarDiary !== 'boolean' ?
                        <div className="war-diary-buttons">
                            <Button color='black' onClick={() => this.backToList()}>
                                <span className="capitalize"><Translate id="view_list" /></span>
                            </Button>
                        </div>
                    : false}
                    <div className="diaries" id="diaries-container" ref={this.diariesContainer}>
                        <div className="diary-not-found-content" style={{ fontSize }}>
                            <h1 style={{ marginTop: fontSize }}>Diary does not exist yet for this unit.</h1>
                        </div>
                    </div>
                </Sidebar>
            );
        } else {
            return (
                <Sidebar
                    as={Menu}
                    animation='push'
                    icon='labeled'
                    direction="right"
                    onHide={() => this.setState({ sidebar: true })}
                    vertical
                    visible={!!this.props.activeUnitDiaries}
                    width='wide'
                    className="war-diaries"
                    ref={this.sideBar}
                >
                    <Loader active style={{color: "#fff"}}> Loading </Loader>
                </Sidebar>
            );
        }
    }
}

export default withLocalize(WarDiary);
