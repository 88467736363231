import { consoleLogDev } from '../Constants'
import { UNIT_ERROR } from '../actions/actions_units'
import { LINE_ERROR } from '../actions/actions_lines'
import { USER_ERROR } from '../actions/actions_users'
import { DIARY_ERROR } from '../actions/actions_diaries'
import { DATE_ERROR } from '../actions/actions_dates'
import { AUTH_ERROR } from '../actions/actions_auth'
import { GEOJSON_ERROR } from '../actions/actions_geojson'
import { USER_CREATED_UNITS_ERROR } from '../actions/actions_user_created_units'

const error = (state = false, action) => {
  switch (action.type) {
    case UNIT_ERROR:
    case USER_ERROR:
    case DIARY_ERROR:
    case DATE_ERROR:
    case LINE_ERROR:
    case AUTH_ERROR:
    case GEOJSON_ERROR:
    case USER_CREATED_UNITS_ERROR:
      action.payload !== false && consoleLogDev(`[${action.type}]:`, action.payload)
      return action.payload;
    default:
      return state
  }
}

export default error
