import {
  GET_DIARY_LIST,
  SET_ACTIVE_DIARY,
  SET_ALL_DIARIES,
  EDIT_DIARY,
  SET_DIARIES_SEARCHING,
  SET_SHOW_DIARY_LIST,
  SET_UNITS_WITH_DIARIES
} from '../actions/actions_diaries'

const diaries = (state = {
  diaryList : [
      "1", "020", "021", "200", "210", "211", "212", "213", "220", "221",
      "222", "223", "230", "231", "232", "233", "251", "252", "261", "253", "262", "263", "264", "300",
      "310", "311", "312", "313", "320", "321", "322", "323", "330",
      "331", "332", "333", "351", "352", "353", "354", "361", "362",
      "363", "364", "365", "367", "368", "369", "370", "400", "410", "411", "412", "413", "420", "421",
      "422", "423", "451", "452", "453", "454", "462", "463"
  ],
  activeDiary : false,
  allDiaries: [],
  isSearching: false,
  showDiariesList: [],
  unitsWithDiaries : []
}, action) => {
  switch (action.type) {
    case GET_DIARY_LIST:
      return {
        ...state,
        diaryList : action.payload
      };
    case SET_ACTIVE_DIARY:
      return {
        ...state,
        activeDiary : action.payload
      };
    case SET_ALL_DIARIES:
      return {
        ...state,
        allDiaries : action.payload
      };
    case EDIT_DIARY:
        return {
          ...state,
          diaryList: state.diaryList.reduce((diaryList, currDiary) => {
            // if the current diary is the one we've edited, modify it!
            // otherwise, push the diary back in untouched
            if (currDiary.id === action.payload.id) {
              diaryList.push(action.payload)
            } else {
              diaryList.push(currDiary);
            }

            return diaryList;
          }, [])
        };
    case SET_DIARIES_SEARCHING :
        return {
            ...state,
            isSearching: action.payload
        }
    case SET_SHOW_DIARY_LIST:
        return {
            ...state,
            showDiariesList: action.payload
        }
    case SET_UNITS_WITH_DIARIES:
        return {
            ...state,
            unitsWithDiaries : action.payload
        }

    default:
      return state
  }
}

export default diaries
