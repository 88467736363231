export const SET_SIDEBAR_PANEL = 'SET_SIDEBAR_PANEL';
export const SET_BASEMAPS_MODAL = 'SET_BASEMAPS_MODAL';
export const SET_UNITS_LIST_ACTIVE = 'SET_UNITS_LIST_ACTIVE';

export function setActiveSidebarPanel(data) {
  return {
    type: SET_SIDEBAR_PANEL,
    payload: data 
  }
}

export function toggleBasemapsModal(data) {
  return {
    type: SET_BASEMAPS_MODAL,
    payload: data 
  }
}

export function setUnitsListActive(data) {
  return {
    type: SET_UNITS_LIST_ACTIVE,
    payload: data 
  }
}

