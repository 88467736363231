import React, { Component } from 'react';
import moment from 'moment';

import { getDiary, setActiveDiary } from '../../actions/actions_diaries';
import { setActiveSidebarPanel } from '../../actions/actions_sidebar';
import { returnIsVisible, returnCircleOpacity } from './lib/MapUtil';

import { addLandUnitsLayer } from './lib/MapLayerFunctions';
import { zoomChange } from './lib/MapUtil';
import {
    addIcons,
    removeIcons,
    toggleMarkerLabels,
    toggleLayerOverlap
} from './lib/IconFunctions';
import { setClickedUnit } from '../../actions/actions_units';

class Units extends Component {
  constructor(props) {
    super(props);
    //this.currentIcons = [];
    this.state = {
      hoveredFeatureId: null,
      popup : false,
      currentUnit: {},
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.mapLoaded) { return }
    const { map } = nextProps;

    if (map && nextProps.allUserCreatedUnits && nextProps.allUserCreatedUnits.length > 0) {
        this.addMapLayer(nextProps.allUnits, nextProps.map, nextProps.allUserCreatedUnits);
    }

    if (map && !map.getLayer('land_units_'+nextProps.currentDate) && nextProps.allUnits[nextProps.currentDate]) {
      this.addMapLayer(nextProps.allUnits, nextProps.map, nextProps.allUserCreatedUnits)
    }

    if (nextProps.currentDate !== this.props.currentDate || nextProps.mapSettings.followType !== this.props.mapSettings.followType) {
        this.setMapCenterIfFollowing(map, nextProps)
    }

    if (map && map.getLayer('land_units_'+nextProps.currentDate)) {
      addIcons(map, nextProps, this.props.currentDate, nextProps.currentDate, nextProps.mapSettings.iconType);
    }

    if (this.props.currentDate !== nextProps.currentDate) {
      map.getLayer('land_units_'+this.props.currentDate) && map.getLayer('land_units_'+this.props.currentDate).setLayoutProperty('visibility', 'none')
      map.getLayer('land_units_text_'+this.props.currentDate) && map.getLayer('land_units_text_'+this.props.currentDate).setLayoutProperty('visibility', 'none')
    
      // Change map center so that new units are in view 
      const currentDate = moment(nextProps.currentDate);
      const prevDate = moment(this.props.currentDate);

      if (currentDate.isAfter('1944-09-01') && prevDate.isBefore('1944-09-02')) {
        map.flyTo({ center : [2.6861572265625, 49.75642885858046], zoom : 6 });
      } else if (currentDate.isBefore('1944-09-02') && prevDate.isAfter('1944-09-01')) {
        map.flyTo({ center : [-0.5666644, 49.333332], zoom : 8 });
      }
    }

    const newMapSettings = nextProps.mapSettings;
    const oldMapSettings = this.props.mapSettings;

    if (newMapSettings.iconType !== oldMapSettings.iconType) {
        addIcons(map, nextProps, this.props.currentDate, nextProps.currentDate, nextProps.mapSettings.iconType);
        return;
    }

    if (newMapSettings.showMarkerLabels !== oldMapSettings.showMarkerLabels) {
        toggleMarkerLabels(map, nextProps.currentDate);
        return;
    }

    if (newMapSettings.labelOverlap !== oldMapSettings.labelOverlap) {
        toggleLayerOverlap(map, nextProps.currentDate, newMapSettings.labelOverlap);
        return;
    }

     // Center the map when an icon has been clicked
     if (nextProps.activeUnitDiaries !== this.props.activeUnitDiaries) {
          let currentUnit = {};
          if (nextProps.activeUnitDiaries && nextProps.allUnits[nextProps.currentDate]) {
              currentUnit = nextProps.allUnits[nextProps.currentDate].find((feature) => {
                return nextProps.activeUnitDiaries[0] && feature.properties.EUnitID === nextProps.activeUnitDiaries[0].unit_UID;
              })

             if (currentUnit) {
                 let isVisible = returnIsVisible(currentUnit, this.props.currentFilters, this.props.currentDate, this.props.currentSelectedUnits, map.getZoom())
                  map.flyTo({
                    center : currentUnit.geometry.coordinates,
                    zoom: isVisible ? map.getZoom() : 10,
                    speed: 0.6
                 });
                 this.setState({ currentUnit });
             }
          }
     }
  }

  addMapLayer(json, map, userCreatedUnits) {

    // var allFeatures = [];
    for(var date in json) {
      // allFeatures = allFeatures.concat(json[date]);

      var featureCollection = { type: "FeatureCollection", features: json[date] };
      featureCollection.features = featureCollection.features.map((feature) => {
        if (!feature.id) {
          feature.id = '_' + Math.random().toString(36).substr(2, 9);
        }
        return feature
      })

      userCreatedUnits && userCreatedUnits.map(ucu => {
        if (ucu.properties.status === 'draft' && ucu.properties.Date === date) { 
            if (!ucu.id) {
              ucu.id = '_' + Math.random().toString(36).substr(2, 9);
            }
            featureCollection.features.push(ucu);
        }
      })

      if(map.getSource('land_units_'+date)) {
        map.getSource('land_units_'+date).setData(featureCollection);
      } else {
        addLandUnitsLayer(map, date, featureCollection, this.props.filters, this.props.currentDate, this.props.currentSelectedUnits, this.props.mapSettings);
      }

      if (map.getLayer('land_units_' + this.props.currentDate)) {
        var popup = new window.mapboxgl.Popup({
          anchor : 'left',
          closeOnClick : true,
          closeButton: false,
          offset : 10
        });
        this.setState({ popup });

        // Pop war diary when unit icon is clicked
        window.addEventListener("click", function(event) {
          if(typeof event.target.className === 'string' && event.target.className.indexOf('icon-image')>-1) {
            var markerIndex = event.target.getAttribute('data-index');
            var thisFeature = this.state.originalJsons.features[markerIndex];

            if (thisFeature.properties.UID !== 0) {
                this.props.fetchWarDiary(thisFeature.properties.EUnitID, thisFeature.properties.LN);
            }
          }
        }.bind(this));
      }

      map.on('mousemove', 'land_units_'+date, function (e) {
        var feature = map.queryRenderedFeatures(e.point,{ layers: ['land_units_'+date] })[0];

        // Don't trigger popup if the unit opacity is 0. Should probably handle this logic in the layer style expression
        if (this.isUnitVisible(e.features[0].properties.US)) {
            map.getCanvas().style.cursor = 'pointer';
            var coordinates = e.features[0].geometry.coordinates.slice();
            const hasWarDiary = false;

            var longName = this.props.activeLanguage.code==='en' ? e.features[0].properties.LN : e.features[0].properties.LNameFr
            this.state.popup.setLngLat(coordinates)
              .setHTML(
                '<p><strong>'+longName+'</strong></p>'+
                (hasWarDiary ? '<p>Click to see a war diary from this unit.</p>' : '')
              )
              .addTo(map);
        }
      }.bind(this));

      map.on('click','land_units_'+date,function(e) {
        this.props.setClickedLandUnits(true)
        const feature = e.features[0]
        const showPopup = this.isUnitVisible(e.features[0].properties.US);
        if (feature.properties && feature.properties.EUnitID && showPopup) {
          this.props.dispatch(getDiary(feature.properties.EUnitID))
          this.props.dispatch(setActiveSidebarPanel('warDiaries'));
          this.props.dispatch(setClickedUnit(feature));
          this.setState({ currentUnit: feature })
        } else if (feature.properties && feature.properties.EUnitID === 0) {
          this.props.dispatch(setActiveDiary(feature.properties.EUnitID))
        }

        if (this.props.mapSettings.followType === 'follow') {
          map.setCenter(feature.geometry.coordinates)
        }
      }.bind(this))

      map.on('mouseleave', 'land_units_'+date, function () {
        map.getCanvas().style.cursor = '';
        this.state.popup.remove();
      }.bind(this));

    }

    this.setState({ land_units: json });
  }

  setMapCenterIfFollowing = (map, nextProps) => {
    //if (nextProps.mapSettings.followType === 'follow' && nextProps.playing && this.state.currentUnit.properties) {
    if (nextProps.mapSettings.followType === 'follow' && this.state.currentUnit.properties && nextProps.allUnits[nextProps.currentDate]) {
      const currentUnitAndDateFeature = nextProps.allUnits[nextProps.currentDate].find((feature) => {
        return feature.properties.EUnitID === this.state.currentUnit.properties.EUnitID
      })

      if (currentUnitAndDateFeature) {
          if (nextProps.playing) {
              map.setCenter(currentUnitAndDateFeature.geometry.coordinates)
          } else {
              map.flyTo({
                center : currentUnitAndDateFeature.geometry.coordinates,
                speed: 0.2
             });
          }
      }
    }
  }

  isUnitVisible = (unitSize) => {
    let ret = true;

    const hiddenAtLowerZoom = ['Battalion', 'Regiment', 'Brigade', 'Company', 'Platoon']
    if (this.props.map.getZoom() < 10.2 && hiddenAtLowerZoom.indexOf(unitSize) !== -1) { ret = false }

    return ret;
  }

  render() {
    return (
        null
    );
  }
}

export default Units;
