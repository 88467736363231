import { connect } from 'react-redux'

import SettingsComponent from '../../components/FrontPage/Settings.js'

const mapStateToProps = state => {
  return {
    ...state.mainReducer.map.mapSettings,
  }
}

const Settings = connect(
  mapStateToProps
)(SettingsComponent)

export default Settings 
