import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import queryString from "query-string";
import { Grid, Button, Select, Segment, Message } from "semantic-ui-react";
import {
  Navbar,
  NavItem,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav
} from "reactstrap";
import "react-table/react-table.css";

import AdvancedSearch from "./AdvancedSearch";
import Upload from "./Upload";
import UserFeatures from "./UserFeatures";
import UserList from "./UserList";
import WarDiariesList from "./WarDiariesList";
import POIsList from "./POIsList";
import Blogs from "./Blogs";
import UnitApprovalList from "./UnitApprovalList";

import { getUnits } from "../../actions/actions_units";
import { getLines } from "../../actions/actions_lines";
import { getUsers, patchUser } from "../../actions/actions_users";
import { getAllDates } from "../../actions/actions_dates";
import { getDiaryList } from "../../actions/actions_diaries";
import { getBlogs } from "../../actions/actions_blogs";
import { getPois, resetPoisList, getAllPois } from "../../actions/actions_pois";
import { setDataLoaded } from "../../actions/actions_auth";
import {
  verifyToken,
  logoutUser,
  authError,
  verifyEmailVerificationToken
} from "../../actions/actions_auth";
import { P44_TOKEN, ROLE_NO_AUTH, ROLE_BASIC, ROLE_ADMIN, ROLE_MODERATOR } from "../../Constants";
import { getUserCreatedUnits } from "../../actions/actions_user_created_units";
import CustomDataList from "./CustomDataList";

const subscriptionTypes = [
  {
    key: 0,
    value: "basic",
    text: "Basic"
  },
  {
    key: 1,
    value: "personal_mapper",
    text: "Personal Mapper"
  },
  {
    key: 2,
    value: "contributor",
    text: "Contributor"
  },
];

class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePanel:
        this.props.match && this.props.match.params.type
          ? this.props.match.params.type
          : "dashboard",
      authorized: true,
      queryString: queryString.parse(this.props.location.search),
      collapsed: true,
      currentUser : {},
      infos: [],
      errors: [],
      initialLoad: true
    };
  }

  componentDidMount() {
    this.props.dispatch(verifyToken());
    this.maybeVerifyEmail();
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.initialLoad && nextProps.auth.userRole === ROLE_MODERATOR) {
      this.setState({ initialLoad: false })
      this.props.history.push('/dashboard/pois')
    }
    if (nextProps.auth.userRole === ROLE_NO_AUTH || nextProps.auth.userRole === ROLE_BASIC) {
      this.props.history.push("/");
    }
    if (
      nextProps.auth.userRole &&
      (nextProps.auth.userRole !== ROLE_NO_AUTH || nextProps.auth.userRole !== ROLE_BASIC) &&
      !nextProps.auth.dataLoaded
    ) {
      this.props.dispatch(getDiaryList());
      this.props.dispatch(getAllDates());
      this.props.dispatch(getUnits());
      this.props.dispatch(getLines());
      this.props.dispatch(getUsers());
      this.props.dispatch(getBlogs());
      this.props.dispatch(setDataLoaded());
      if (this.props.currentUser && this.props.currentUser.role === ROLE_ADMIN) {
        this.props.dispatch(getUserCreatedUnits())
      } else if (this.props.currentUser && this.props.currentUser.id) {
        this.props.dispatch(getUserCreatedUnits(this.props.currentUser.id))
      }
    }
    if (nextProps.match.params.type === "pois" && !nextProps.allPoisFetched) {
      this.props.dispatch(getAllPois())
    } else if(nextProps.allPoisFetched && nextProps.match.params.type!=="pois") {
      this.props.dispatch(resetPoisList([]));
    }
    // TO DO: Adjust backend so we get the full user object in props.currentUser
    if (nextProps.currentUser && nextProps.allUsers.length > 0) {
        let fullUserObject = nextProps.allUsers.find(user => user.id === nextProps.currentUser.id);
        this.setState({ currentUser : fullUserObject });

        // console.log(nextProps.currentUser);
    }

    if (nextProps.match && nextProps.match.params.type) {
      this.setState({ activePanel: nextProps.match.params.type });
    }

    if (nextProps.error) {
      this.setState({ errors: [nextProps.error] })
    }
    if (nextProps.info) {
      this.setState({ infos: [nextProps.info] })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(authError(false));
  }

  maybeVerifyEmail() {
    const { user_email, email_verification_token } = this.state.queryString;

    if (user_email && email_verification_token) {
      this.props.dispatch(
        verifyEmailVerificationToken({
          email: user_email,
          token: email_verification_token
        })
      );
    }
  }

  changeUserSubType(event, { value }) {
    let { currentUser } = this.state;
    currentUser.subscription_type = value;
    this.setState({ currentUser });
  }

  handleSubscribe = () => {
    let { id, subscription_type} = this.state.currentUser;
    // console.log(subscription_type, id, ' handle subscribe');

    const paypalBtnOpts = {
      createSubscription: (data, actions) => {
        let plan_id;
        switch (subscription_type) {
          case "personal_mapper":
            plan_id = process.env.REACT_APP_PP_PERSONAL_MAPPER_PLAN_DEV;
            // console.log(plan_id);
            break;
          case "contributor":
            plan_id = process.env.REACT_APP_PP_CONTRIBUTOR_PLAN_DEV;
            break;
        }
        if (subscription_type !== "basic") {
          return actions.subscription.create({
            plan_id
          });
        }
      },
      onApprove: ({ subscriptionID }, actions) => {
        this.props.dispatch(
          patchUser({
            id,
            subscription_id: subscriptionID,
            subscription_type,
            subscription_active: true
          })
        );
      }
    };
    window.paypal.Buttons(paypalBtnOpts).render("#paypal-button-container");
  };

  handleCancelSubscription = async ({ id }) => {
    const token = window.localStorage.getItem(P44_TOKEN);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND}/users/${id}/cancel_subscription`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer: ${token}`
        },
        body: JSON.stringify({
          reason: "not happy with the service"
        })
      }
    );
    if (res.status === 204) {
      // console.log("good job bud");
    } else {
      try {
        // console.log("uh oh");
        const json = await res.json();
        console.log("json", json);
      } catch (err) {
        console.log(err);
      }
    }
  };


  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    const {
      units,
      frontLines,
      features,
      activePanel,
      selectedFeature,
      activeFeatureType,
      authorized,
      currentUser,
    } = this.state;

    // Returns the ID param or null -- used by table list to determine if we're in editing mode
    const itemId =
      (this.props.match.params && this.props.match.params.id) || null;
    const secondId =
      (this.props.match.params && this.props.match.params.id2) || null;
    // const noAuthModal = (
    //   <Modal open={authorized === false} centered={true} style={{
    //     left: 'initial',
    //     top: '20vh',
    //     height: 'initial'
    //   }}>
    //     <Header icon='desktop' content='Not logged in' />
    //     <Modal.Content>
    //       <p>Please log in to use the dashboard.</p>
    //     </Modal.Content>
    //     <Modal.Actions>
    //       <Button color='green' inverted onClick={() => this.props.history.push('/login')}>
    //         <Icon name='checkmark' /> Login
    //       </Button>
    //     </Modal.Actions>
    //   </Modal>
    // )

    return (
      <div
        className={`dashboard-container${
          authorized === false ? "no-auth" : ""
        }`}
      >
        <Navbar color="dark" dark className="dashboard-nav" expand="md">
          <NavbarBrand>
            Project {" '"}44 <sup>Admin</sup>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse isOpen={!this.state.collapsed} navbar>
            <Nav navbar className="ml-auto">
              {this.props.currentUser.role !== ROLE_MODERATOR && (<NavItem active={activePanel === "dashboard"}>
                <Link to="/dashboard">Dashboard</Link>
              </NavItem>)}
              {this.props.currentUser.role !== ROLE_MODERATOR ?
                (<NavItem active={activePanel === "my_data"}>
                    <Link to="/dashboard/my_data">My Data</Link>
                  </NavItem>
                ) : (
                false
              )}
              {this.props.currentUser.role === ROLE_ADMIN ? (
                <NavItem active={activePanel === "users"}>
                  <Link to="/dashboard/users">Users</Link>
                </NavItem>
              ) : (
                false
              )}
              {this.props.currentUser.role === ROLE_ADMIN ? (
                <NavItem active={activePanel === "diaries"}>
                  <Link to="/dashboard/diaries">War Diaries</Link>
                </NavItem>
              ) : (
                false
              )}
              {this.props.currentUser.role === ROLE_ADMIN ? (
                <NavItem active={activePanel === "upload"}>
                  <Link to="/dashboard/upload">Upload</Link>
                </NavItem>
              ) : (
                false
              )}
              {this.props.currentUser.role === ROLE_ADMIN ? (
                <NavItem active={activePanel === 'approve-units'}>
                  <Link to="/dashboard/unit_approval">Unit Approval</Link>
                </NavItem>
              ) : (
                false
              )}
              {this.props.currentUser.role === ROLE_ADMIN ? (
                <NavItem active={activePanel === 'custom_data'}>
                  <Link to="/dashboard/custom_data">Custom Data</Link>
                </NavItem>
              ) : (
                false
              )}
              {this.props.currentUser.role === ROLE_ADMIN || this.props.currentUser.role === ROLE_MODERATOR ? (
                <NavItem active={activePanel === 'pois'}>
                  <Link to="/dashboard/pois">POIs</Link>
                </NavItem>
              ) : (
                false
              )}
              {this.props.currentUser.role === ROLE_ADMIN ? (
                <NavItem active={activePanel === 'blogs'}>
                  <Link to="/dashboard/blogs">Blogs</Link>
                </NavItem>
              ) : (
                false
              )}
              <NavItem
                onClick={() => {
                  this.props.dispatch(logoutUser());
                  this.props.history.push("/");
                }}
              >
                Logout
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <Grid padded>
          <Grid.Column stretched width={14}>
              {activePanel === "dashboard" ? (
                <div>
                  <h1>Welcome to the Dashboard</h1>
                  <ul>
                    <li>User stats and information</li>
                    <li>User-created features and stuff</li>
                    <li>Any how-tos, documentation, videos, messages</li>
                  </ul>


                  <Segment style={{ maxWidth : 600 }}>
                      <h3 className="ui dividing header">
                        My Account
                      </h3>
                      <div style={{ margin : '20px 0px'}}>
                          <label style={{ fontWeight : 'bold', marginRight : 10 }}>Subscription Type: </label>
                          <Select
                            type="text"
                            name="subscription-type"
                            style={{ width: 250 }}
                            value={currentUser.subscription_type}
                            onChange={this.changeUserSubType.bind(this)}
                            options={subscriptionTypes}
                            disabled={currentUser && currentUser.role === ROLE_ADMIN}
                          />
                      </div>

                      <div>
                          { currentUser.subscription_type !== 'basic' ?
                          <span>
                              { currentUser.subscription_active ?
                                  <Button negative onClick={() => this.handleCancelSubscription(currentUser.id)}>
                                    {" "}
                                    Cancel{" "}
                                  </Button>
                              :
                                  <span>
                                      <Button onClick={this.handleSubscribe.bind(this)} style={{ marginBottom : 10 }}>
                                        {" "}
                                        Subscribe{" "}
                                      </Button>
                                      <div id="paypal-button-container" />
                                  </span>
                              }
                          </span>
                          : null }
                      </div>
                    </Segment>
                      {this.state.errors.length > 0 && this.state.errors.map(error => {
                        const content = error.line ? `Line ${error.line}: ${error.msg}` : error.feature ? `${error.msg}\n\n${JSON.stringify(error.feature, null, 4)}` : error.msg
                        return (
                          <Message
                            error
                            children={<pre>{content}</pre>}
                            key={error.msg + error.line}
                          />
                        )
                      })}
                      {this.state.infos.length > 0 && this.state.infos.map(info => {
                        return (
                          <Message
                            info
                            content={info.msg}
                            key={info.msg + info.line}
                          />
                        )
                      })}
                  </div>
              ) : (
                false
              )}

              {activePanel === "upload" ? <Upload {...this.props} /> : false}
              {activePanel === "my_data" ? (
                <UserFeatures {...this.props} />
              ) : null}
              {activePanel === 'users' ? (
                <UserList
                  itemId={itemId}
                  secondId={secondId}
                  {...this.props}
                />
              ) : null}
              {activePanel === 'diaries' ? (
                <div>
                  <AdvancedSearch
                    activePanel={this.state.activePanel}
                    {...this.props}
                  />
                  <WarDiariesList
                    itemId={itemId}
                    secondId={secondId}
                    activePanel={activePanel}
                    {...this.props}
                  />
                </div>
              ) : null}
              {activePanel === 'unit_approval' ? (
                <UnitApprovalList {...this.props} />
              ) : null}
              {activePanel === 'pois' ? (
                <POIsList
                  itemId={itemId}
                  secondId={secondId}
                  activePanel={this.state.activePanel}
                  {...this.props}
                />
              ) : null}
              {activePanel === 'blogs' ? (
                <Blogs
                  itemId={itemId}
                  secondId={secondId}
                  {...this.props}
                />
              ) : null}
              {activePanel === 'custom_data' ? (
                <CustomDataList
                  itemId={itemId}
                  secondId={secondId}
                  activePanel={this.state.activePanel}
                  {...this.props}
                />
              ): null}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default DashboardContainer;
