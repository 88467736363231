import Constants from '../Constants.js';
import isEqual from 'lodash.isequal'

export const SET_CURRENT_FILTERS = 'SET_CURRENT_FILTERS';
export const SET_ALL_FILTERS  = 'SET_ALL_FILTERS';
export const UPDATE_SELECTED_UNITS = 'UPDATE_SELECTED_UNITS';
export const UPDATE_MAP_SETTINGS  = 'UPDATE_MAP_SETTINGS';
export const ADD_TO_MAP  = 'ADD_TO_MAP';
export const MAP_ERROR = 'MAP_ERROR';
export const SET_MAP_LOAD_STATUS = 'SET_MAP_LOAD_STATUS';
export const SET_FRONT_PAGE_MAP = 'SET_FRONT_PAGE_MAP';
export const SET_POI_WINDOW = 'SET_POI_WINDOW';
export const UPDATE_VISIBLE_LAYERS = 'UPDATE_VISIBLE_LAYERS';
export const SET_CUSTOM_DATA_LAYERS = 'SET_CUSTOM_DATA_LAYERS';
export const SET_DIARY_LIST_INFO = 'SET_DIARY_LIST_INFO';
export const SET_THEATRE = 'SET_THEATRE';

export function getAllFilters(allUnits, currentFilters, previousFilters) {
    return (dispatch) => {
        // Generate filters from all units
        const unitSizeOrder = ["Army Group", "Army", "Corp", "Division", "Brigade", "Regiment", "Battalion", "Company", "Platoon"];
        var filters = {UC: [], UT: [], US: unitSizeOrder, theatre: [] }
        var units = [];
        for(var date in allUnits) {
          allUnits[date].forEach((feature) => {
            for(var property in filters) {
              if(filters[property] && feature.properties[property].toString().trim()!=='' && filters[property].indexOf(feature.properties[property])===-1) {
                filters[property].push(feature.properties[property]);
              }
            }
          })
        }

        // If no currentFilters were received as an argument, set currentFilters to filters
        if (!currentFilters) {
            currentFilters = filters;
            if (window.localStorage.user_filters) {
                currentFilters = JSON.parse(window.localStorage.user_filters);
            }
            dispatch(setAllFilters({ allFilters : filters, currentFilters }));
        // If the new filters object doesn't match the previous filters object, add new filters to currentFilters object
        } else if (previousFilters && !isEqual(filters, previousFilters)) {
            for (var filter in filters) {
                filters[filter].forEach(thisFilter => {
                    if (previousFilters[filter].indexOf(thisFilter) === -1) {
                        currentFilters[filter].push(thisFilter);
                    }
                });
            }
            dispatch(setAllFilters({ allFilters : filters, currentFilters }));
        }
    }
}

export function addToMap(data) {
  return {
    type : ADD_TO_MAP,
    payload : data
  }
}

export function setAllFilters(data) {
  return {
    type : SET_ALL_FILTERS,
    payload : data
  }
}

export function setCurrentFilters(data) {
  return {
    type : SET_CURRENT_FILTERS,
    payload : data
  }
}

export function updateSelectedUnits(data) {
  return {
    type : UPDATE_SELECTED_UNITS,
    payload : data
  }
}

export function updateMapSettings(data) {
  return {
    type : UPDATE_MAP_SETTINGS,
    payload : data
  }
}

export function mapError(error) {
  return {
    type : MAP_ERROR,
    payload : error
  }
}

export function setMapLoadStatus(newStatus) {
    // status {
    //     loadPercentage: integer
    //     iconsLoaded: boolean
    //     frontLinesLoaded: boolean
    // }
    return {
        type: SET_MAP_LOAD_STATUS,
        payload: newStatus
    }
}

export function setFrontPageMap({ map, mbDraw }) {
  return {
    type: SET_FRONT_PAGE_MAP,
    payload: { map, mbDraw }
  }
}

export function setPoiWindow(data) {
  return {
    type: SET_POI_WINDOW,
    payload: data
  }
}

export function updateVisibleLayers(data) {
  return {
    type: UPDATE_VISIBLE_LAYERS,
    payload: data
  }
}

export function setCustomDataLayers(data) {
  return {
    type: SET_CUSTOM_DATA_LAYERS,
    payload: data
  }
}

export function setDiaryListInfo(data) {
  return {
    type: SET_DIARY_LIST_INFO,
    payload: data
  }
}

export function setTheatre(data) {
  return {
    type: SET_THEATRE,
    payload: data
  }
}

export function clearFilters() {
  // resets to default i.e. no filters
  return {
    type: SET_CURRENT_FILTERS,
    payload: {
      UC: ["GB", "GMN", "IND", "USA", "NZ", "FRE", "POL", "ITA", "SA", "CAN", 'JPN', 'CZE'],
      US: ["Army Group", "Army", "Corp", "Division", "Brigade", "Regiment", "Battalion", "Company", "Platoon", "Infantry"],
      UT: ["Infantry", "Armoured", "Artillery", "Airborne", "Engineer", "Reconnaissance", "Anti-Aircraft", "Mechanized", "Anti-Tank", "Cavalry", "Ranger"],
      theatre: ['ETO', 'MTO', 'AK', 'HK'],
    }
  }
}
