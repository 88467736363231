import React, { Component } from 'react';
import { Button, Form, Select, Header, Message, Segment, Input, TextArea, Checkbox } from "semantic-ui-react";
import { DateInput, TimeInput, DateTimeInput, DatesRangeInput } from 'semantic-ui-calendar-react';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import _ from 'underscore';

import { postPoi, patchPoi, deletePoi } from '../../actions/actions_pois';
import { postUserCreatedUnit, patchUserCreatedUnit } from '../../actions/actions_user_created_units'
import { ROLE_ADMIN } from '../../Constants';

window.mapboxgl.accessToken = 'pk.eyJ1Ijoib3V0ZG9vcm1hcHBpbmdjb21wYW55IiwiYSI6ImNqYmh3cDdjYzNsMnozNGxsYzlvMmk2bTYifQ.QqcZ4LVoLWnXafXdjZxnZg';

const featureFields = [
  { id: 'feature_id', editable: false, label: 'Feature ID' },
  { id: 'UID', editable: true, label: 'Unit ID' },
  { id: 'MID', editable: true, label: 'Marker ID' },
  { id: 'Date', editable: true, label: 'Date', type: 'date' },
  { id: 'UT', editable: true, label: 'Unit Type' },
  { id: 'UC', editable: true, label: 'Unit Country' },
  { id: 'US', editable: true, label: 'Unit Size' },
  { id: 'SN', editable: true, label: 'Short Name' },
  { id: 'LN', editable: true, label: 'Long Name' },
  { id: 'Notes', editable: true, label: 'Notes', component: 'textarea' },
  { id: 'status', editable: true, label: 'Status' },
  // { id: 'status', editable: true, label: 'Status Approved', checkbox: true, component: 'checkbox' }
];

class FeatureEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
        currentFeature : false,
        currentFeatureId : false,
        featureSaved : false,
        draw : false,
        map : false,
        currentAssignedUnit: null,
        assignedUnitsData: [],
        isEditingAssignedUnit: false
    }
  }

  componentDidMount() {
    const map = new window.mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/outdoors-v11',
      center : [-0.5666644, 49.333332],
      zoom : 7
    });

    var Draw = new MapboxDraw({
      displayControlsDefault: false
    });
    map.addControl(Draw, 'top-left');

    map.on('draw.update', function(e) {
      var newFeature = JSON.parse(JSON.stringify(this.state.currentFeature));
      newFeature.geometry.coordinates = e.features[0].geometry.coordinates;
      this.clearMap();
      var geojson = {
         "type": "FeatureCollection",
         "features": [ newFeature ]
      };
      var featureId = this.state.draw.add(geojson);
      this.setState({currentFeature:newFeature, currentFeatureId: featureId});
    }.bind(this));

    map.on('draw.create', function(e) {
      var newFeature = JSON.parse(JSON.stringify(this.state.currentFeature));
      newFeature.geometry.coordinates = e.features[0].geometry.coordinates;
      this.clearMap();
      var geojson = {
         "type": "FeatureCollection",
         "features": [ newFeature ]
      };
      var featureId = this.state.draw.add(geojson);
      this.setState({currentFeature:newFeature, currentFeatureId: featureId});
    }.bind(this));

    this.setState({map, draw:Draw})
  }

  changeMap = (currentFeature) => {
    const { map, draw } = this.state;
    const { featureType } = this.props;

    var geojson = {
       "type": "FeatureCollection",
       "features": [ currentFeature ]
    };

    if(geojson.features[0].geometry.coordinates.length>0) {
      var featureId = draw.add(geojson);
      draw.changeMode('simple_select', { featureIds : featureId })
      this.setState({ currentFeatureId: featureId });
    } else {
      draw.changeMode('draw_point');
    }
  }

  clearMap(map) {
    this.state.draw.deleteAll()
    this.setState({currentFeatureId:false})
  }

  componentWillReceiveProps(nextProps) {
    const { map } = this.state;

    if (!_.isEqual(this.props.selectedFeature, nextProps.selectedFeature)) {
      this.clearMap(map)

      if (nextProps.visible && nextProps.selectedFeature === false ) {
          // Create feature mode
          let currentFeature = {
            "type": "Feature",
            "geometry": {
              "type" : "Point",
              "coordinates": [ ]
             }
           }
           currentFeature.properties = {}
           featureFields.forEach(function(prop) {
             currentFeature.properties[prop.id] = null;
           })
           currentFeature.properties['status'] = 'draft';
           delete currentFeature.properties['feature_id'];

           this.changeMap(currentFeature);
           setTimeout(function() { map.resize() }, 0);

           this.setState({
              currentFeature : currentFeature,
              featureSaved : false
           })

      } else if (nextProps.visible) {
          // Edit feature mode
          let selectedFeature = JSON.parse(JSON.stringify(nextProps.selectedFeature));

          this.changeMap(selectedFeature);
          setTimeout(function() { map.resize() }, 0);

          this.setState({ currentFeature : selectedFeature });

      } else {
          this.setState({
              currentFeature : false,
              featureSaved : false,
              deleteFeatureWarn : false,
              warningMsg: false,
              isEditingAssignedUnit: false
          });
      }
    }

  }

  changeProp = (key, e) => {
    let currentFeature = this.state.currentFeature;

    let val = (key === 'MID' || key === 'UID') ? parseInt(e.target.value) : e.target.value;

    currentFeature.properties[key] = val;
    this.setState({ currentFeature })
  }

  changeDate = (event, {name, value}) => {
    let { currentFeature } = this.state
    currentFeature.properties[name] = value;
    this.setState({ currentFeature });
  }

  saveFeature = () => {
    const { currentFeature } = this.state;
    const { featureType } = this.props;

    if(!currentFeature.properties.feature_id) {
        this.props.dispatch(postUserCreatedUnit(currentFeature, this.props.currentUser.id, this.props.auth.token))
    } else {
      this.props.dispatch(patchUserCreatedUnit(currentFeature, this.props.currentUser.id, this.props.auth.token))
    }
    var that = this;
    setTimeout(function() {
      that.props.backToList();
    },1000)

    this.setState({
      featureSaved : true,
      warningMsg : false
    });
  }

  deleteFeature = () => {
    const { featureType } = this.props;
    const { currentFeature } = this.state;
    const featureProps = this.state.currentFeature.properties;
    this.props.dispatch(deletePoi(currentFeature, this.props.currentUser.id, this.props.auth.token));
    var that = this;
    setTimeout(function() {
      that.props.backToList();
    },1000)
  }

  changeAssignedUnit = (event, { name, value }) => {
    const newFeature = (this.props.currentUser.assignedUnitsData || []).find((feature) => {
      return feature.properties.EUnitID === value
    })
    newFeature.geometry = {
      type: 'Point',
      coordinates: []
    }
    newFeature.properties.status = 'draft'
    delete newFeature.properties.feature_id
    delete newFeature.properties.Date

    this.setState({
      currentFeature: newFeature,
      isEditingAssignedUnit: true
    })
  }

  render() {
      const { featureSaved, currentFeature, warningMsg, map } = this.state;

      const componentMap = {
        input: Input,
        checkbox: Checkbox,
        textarea: TextArea
      };

      const assignedUnitsOptions = (this.props.currentUser.assignedUnitsData || []).map((feature) => {
        const { feature_id, UID, LN } = feature.properties
        return {
          key: feature_id,
          value: UID,
          text: `${LN} -- ${UID}`
        }
      })

      return (
        <div className={"map-edit " + (this.props.visible ? '' : 'hide-section')} >
            <Segment className="edit-pane">
                <h2> {currentFeature ? currentFeature.properties.LN : ''} </h2>
                <div className="map-wrap">
                    <div id="map"></div>
                </div>
                {currentFeature ?
                  <Form className="props">
                      <h3 className="ui dividing header">Assigned Units</h3>
                      <Select
                        name='assigned_unit'
                        placeholder="Select a unit"
                        options={assignedUnitsOptions}
                        onChange={this.changeAssignedUnit}
                      />
                      <h3 className="ui dividing header">Properties</h3>
                      {featureFields.map((field)=> {
                          const resolvedComponent = field.component || 'input';
                          const InputComponent = componentMap[resolvedComponent];
                          return (
                              <Form.Field inline key={field.id} style={{ display : 'flex' }}>
                                  <label style={{ width : 80 }}> { field.label } </label>
                                  {field.type === "date" ? (
                                    <DateInput
                                      name="Date"
                                      initialDate="1944-06-06"
                                      value={currentFeature.properties[field.id]}
                                      dateFormat="YYYY-MM-DD"
                                      onChange={this.changeDate}
                                    />
                                  ) : (
                                    <InputComponent
                                      type={field.type || undefined}
                                      name={field.id}
                                      style={{ width: 250 }}
                                      checked={
                                        field.checkbox ? currentFeature.properties[field.id] : undefined
                                      }
                                      value={
                                        field.checkbox ? undefined : currentFeature.properties[field.id]
                                      }
                                      onChange={this.changeProp.bind(this,field.id)}
                                      disabled={!field.editable || this.props.currentUser.role === ROLE_ADMIN && field.id === 'status'}
                                      rows={4}
                                    />
                                  )}
                              </Form.Field>
                          )
                      })}


                      { warningMsg?
                          <Message warning visible style={{ display : 'flex', justifyContent : 'space-between', alignItems : 'center' }}>
                              {warningMsg}
                          </Message>
                      : false }

                      <div className="buttonWrap">
                          <Button onClick={() => {
                            this.saveFeature()
                            this.setState({ isEditingAssignedUnit: false })
                          }}> Save </Button>
                          { !currentFeature.properties.feature_id ? false : <Button onClick={(...args) => {
                            this.deleteFeature(...args)
                            this.setState({ isEditingAssignedUnit: false })
                          }}> Delete </Button> }
                          <Button onClick={(...args) => {
                            this.props.backToList(...args)
                            this.setState({ isEditingAssignedUnit: false })
                          }}> Cancel </Button>
                      </div>
                      { featureSaved ?
                          <Message success visible>
                              Feature Saved!
                          </Message>
                      : false }
                  </Form>
                : false}
            </Segment>
        </div>
      )
  }
}

export default FeatureEdit;
