import React, { Component } from 'react';
import { Popup, Menu, Checkbox, Segment, Accordion, Dropdown } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import Slider, { Range } from 'rc-slider';
import { Translate, withLocalize } from "react-localize-redux";
import 'rc-slider/assets/index.css';
import moment from 'moment';
import { DateInput } from 'semantic-ui-calendar-react';

import { setCurrentDate } from '../../actions/actions_dates';
import { setShowDiaryList } from '../../actions/actions_diaries';
import { setCurrentFilters, updateMapSettings } from '../../actions/actions_map';
import { dateRange } from '../../Constants';

const campaigns = ['nw_eu_44_45', 'it_43_45', 'hong_kong', 'aleutian_islands'];

class TimeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing : false,
      speed : 1,
      sliderVal: moment(this.props.currentDate).format('x'),
      campaign: 'nw_eu_44_45',
      dateFilterMin: '1943-07-09', // initial values
      dateFilterMax: '1945-05-31', // initial values
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.currentDate !== nextProps.currentDate) {
        this.setState({ sliderVal : moment(nextProps.currentDate).format('x') });
        // clear out the unit rotation set by the diary flyover if the date has changed
        if (this.props.diaryListInfo.flyOverInterval) {
          window.clearInterval(this.props.diaryListInfo.flyOverInterval)
        }
    }
  }

  play() {
    const datesAsObject = Object.assign({}, ...this.props.allDates.map((s) => ({ [s]: true } )));
    if(this.props.playing) {
      clearInterval(this.props.playing)
      this.props.setPlay(false)
    } else {
      const thisInterval = setInterval(() => {
        const newDate = moment(this.props.currentDate).add(1, 'day').format('YYYY-MM-DD');
        if(datesAsObject[newDate]) {
          this.props.dispatch(setCurrentDate(newDate))
          return;
        } else if (this.props.playLoop && moment(this.props.currentDate).format('YYYY-MM-DD') === moment(this.props.allDates[this.props.allDates.length - 1]).format('YYYY-MM-DD')) {
          this.props.dispatch(setCurrentDate(moment(this.props.dateLimits.min).format('YYYY-MM-DD')))
          return
        }
      },1000*(1/this.state.speed));
      this.props.setPlay(thisInterval)
    }
  }

  incrementDay = () => {
    if (moment(this.props.currentDate).add(1, 'day') <= moment(this.props.dateLimits.max)) {
      const nextDate = moment(this.props.currentDate).add(1, 'day').format('YYYY-MM-DD')
      this.props.dispatch(setCurrentDate(nextDate))
    }
  }

  decrementDay = () => {
    if (moment(this.props.currentDate).subtract(1, 'day') >= moment(this.props.dateLimits.min)) {
      const nextDate = moment(this.props.currentDate).subtract(1, 'day').format('YYYY-MM-DD')
      this.props.dispatch(setCurrentDate(nextDate))
    }
  }

  openCampaign = (campaign) => {
    switch (campaign) {
      case 'nw_eu_44_45':
        this.setState({ campaign })
        this.props.map.flyTo({ center: [-0.5666644, 49.333332] }) // normal start position
        this.props.dispatch(setCurrentDate('1944-06-06')) // normal start date
        break;
      case 'it_43_45':
        this.setState({ campaign })
        this.props.dispatch(setCurrentDate('1943-07-10')) // first date of italian data
        this.props.map.fitBounds([
          [16.66771037836685, 38.577781477106925],
          [10.980553189543684, 36.35400696293027],
        ]) // sicily for now since that's the only place we have units on the first day
        break;
      case 'hong_kong':
        this.setState({ campaign })
        this.props.map.flyTo({
          center: [114.236, 22.256],
          zoom: 11,
        })
        this.props.dispatch(setCurrentDate('1941-12-08'))
        break;
      case 'aleutian_islands':
        this.setState({ campaign })
        this.props.map.flyTo({
          center: [
            177.68745975002133,
            51.97092603696123
          ],
          zoom: 9.25,
        })
        this.props.dispatch(setCurrentDate('1943-08-16'))
    }
    const campaignDates = {
      'aleutian_islands': {
        min: '1943-05-11',
        max: '1943-12-31',
      },
      'hong_kong': {
        min: '1941-12-08',
        max: '1941-12-25',
      },
      'nw_eu_44_45': {
        min: '1944-06-06',
        max: '1945-05-31',
      },
      'it_43_45': {
        min: '1943-07-10',
        max: '1945-05-31',
      },
    };
    const theatreCampaignMap = {
      'aleutian_islands': 'AK',
      'hong_kong': 'HK',
      'nw_eu_44_45': 'ETO',
      'it_43_45': 'MTO',
    }
    const { min, max } = campaignDates[campaign];
    this.setState({
      dateFilterMin: min,
      dateFilterMax: max,
    });
    // merge current filters with the new theatre filter
    this.props.dispatch(setCurrentFilters({
      ...this.props.currentFilters,
      theatre: [theatreCampaignMap[campaign]],
    }));
  }

  render() {
      const sliderMin = parseInt(moment(this.state.dateFilterMin).format('x'))
      const sliderMax = parseInt(moment(this.state.dateFilterMax).format('x'))

      return (
        <Segment className="time-slider-section" inverted>
          <div>
            <div className="slider-container">
              <Dropdown text={`${this.props.translate(this.state.campaign)}`} value='italy' className="campaign-dropdown">
                <Dropdown.Menu>
                  {campaigns.map(campaign => {
                    return <Dropdown.Item text={this.props.translate(campaign)} onClick={() => this.openCampaign(campaign)} />
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <div className="date" style={window.innerWidth < 720 ? { textAlign: 'center', fontSize: 22 }: {}}>
                {window.innerWidth >= 720 ?
                  <DateInput
                    name="from"
                    initialDate="1944-06-06"
                    value={
                      moment(this.props.currentDate).format('DD MMM YYYY')
                    }
                    dateFormat="DD MMM YYYY"
                    className="main-date-input"
                    // icon="caret down"
                    closeOnMouseLeave={true}
                    onChange={(e, { name, value }) => {
                      const nextDate = moment(value).format('YYYY-MM-DD')
                      this.props.dispatch(setCurrentDate(nextDate))
                    }}
                  />
                  : moment(this.props.currentDate).format('DD MMM YYYY')}
              </div>
                  <div className="slider-wrap">
                      <Slider
                        min={sliderMin}
                        max={sliderMax}
                        onChange={(val) => {
                          this.setState({ sliderVal: moment(val).format('x') })
                        }}
                        onAfterChange={(val) => {
                          const nextDate = moment(val).format('YYYY-MM-DD')
                          this.props.dispatch(setCurrentDate(nextDate))
                        }}
                        value={Number(this.state.sliderVal)}
                      />
                      <span className="marker-wrap min">
                          <DateInput
                            name="from"
                            initialDate={this.state.dateFilterMax}
                            value={moment(this.state.dateFilterMin).format('DD MMM YYYY')}
                            dateFormat="DD MMM YYYY"
                            className="ts-date"
                            icon="caret down"
                            closeOnMouseLeave={true}
                            onChange={(e, {name, value}) => {
                              const nextDate = moment(value).format('YYYY-MM-DD')
                              this.setState({
                                dateFilterMin: nextDate,
                                sliderVal: moment(this.props.currentDate).format('x')
                              })
                            }}
                          />
                      </span>
                      <span className="marker-wrap max">
                          <DateInput
                            name="to"
                            initialDate={this.state.dateFilterMax}
                            value={moment(this.state.dateFilterMax).format('DD MMM YYYY')}
                            dateFormat="DD MMM YYYY"
                            className="ts-date"
                            icon="caret down"
                            onChange={(e, {name, value}) => {
                              const nextDate = moment(value).format('YYYY-MM-DD')
                              this.setState({
                                dateFilterMax: nextDate,
                                sliderVal: moment(this.props.currentDate).format('x')
                              })
                            }}
                          />
                      </span>
                  </div>
                </div>

                <Translate>
                  {({ translate, activeLanguage, languages }) => (
                    <div className="play-buttons">
                      <Popup className="capitalize" trigger={<div onClick={this.decrementDay}><i className="fas fa-step-backward" /></div>} content={translate("step_backward")} position="left center" />
                      <Popup className="capitalize" trigger={<div onClick={this.play.bind(this)}>{!this.props.playing ? <i className="fas fa-play" /> : <i className="fas fa-pause" />}</div>} content={translate("play")} />
                      <Popup className="capitalize" trigger={
                        <div onClick={() => this.setState({speed: (this.state.speed<5 ? this.state.speed+1 : 1)})} >{[...Array(this.state.speed)].map((e, i) => {
                          return <i key={i} className="fas fa-angle-right" />
                        })}</div>
                      } content={translate("change_speed")} />
                      <Popup className="capitalize" trigger={<div><i onClick={this.incrementDay} className="fas fa-step-forward" /></div>} content={translate("step_forward")} position="right center" />
                      <Popup
                        className="capitalize"
                        trigger={<div className={"play-loop-toggle" + (this.props.playLoop ? ' active' : '')}><i onClick={() => this.props.dispatch(updateMapSettings(['playLoop', !this.props.playLoop]))} className="fas fa-sync" /></div>}
                        content={translate("loop_text")}
                      />
                    </div>
                  )}
                </Translate>
          </div>
        </Segment>
      );
  }
}

export default withLocalize(TimeSlider);
