import React, { Component } from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { Button, Icon, Segment, Dimmer, Loader } from "semantic-ui-react";
import "react-table/react-table.css";

import WarDiariesEdit from "./WarDiariesEdit";
import {
    patchDiary,
    postDiary,
    deleteDiary,
    deleteAllDiaries
} from "../../actions/actions_diaries";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handleDeleteAll = () => {
        const confirm1 = 'Are you sure you want to delete all war diaries?'
        const confirm2 = 'Please confirm again. This will delete all war diaries.'
        if (window.confirm(confirm1) && window.confirm(confirm2)) {
            this.props.dispatch(deleteAllDiaries())
        }
    }

    render() {
        const { dispatch } = this.props;
        const tableData = this.props.allDiaries;
        const columns = [
            { Header: "ID", accessor: "id" },
            { Header: "Unit ID", accessor: "unit_UID" },
            { Header: "Date", accessor: "Date" },
            { Header: "Content", accessor: "content" },
            { Header: "Status", accessor: "status" },
            {
                Header: "Edit",
                accessor: "id",
                maxWidth: 100,
                filterable: false,
                Cell: row => (
                    <Link to={"/dashboard/diaries/" + row.value + this.props.location.search}>
                        <Button> Edit </Button>
                    </Link>
                )
            }
        ]
        const isId = this.props.itemId || null;
        const newButtonLink = "/dashboard/diaries/new"
        const newButtonIcon = "book"
        const diaryDetails = this.props.allDiaries.find((item) => {
            return item.id == this.props.itemId
        })
        const isCreateMode = isId === "new" || !diaryDetails;

        return (
            <div>
                {isId ? (
                    <React.Fragment>
                        <WarDiariesEdit
                            itemType="Diary"
                            createMode={isCreateMode}
                            diaryDetails={diaryDetails}
                            saveDiaryDetails={diary => {
                                isCreateMode
                                    ? dispatch(postDiary(diary))
                                    : dispatch(patchDiary(diary, true));
                            }}
                            deleteDiary={diary => dispatch(deleteDiary(diary.id))}
                            backToList={() => this.props.history.push("/dashboard/diaries" + this.props.location.search)}
                        />
                    </React.Fragment>
                ) : null}
                <Link to={newButtonLink}>
                    <Button icon color="teal" labelPosition="left">
                        <Icon name={newButtonIcon} />
                        Add New
                    </Button>
                </Link>
                <Button icon color="red" labelPosition="left" onClick={this.handleDeleteAll}>
                    <Icon name="warning sign" />
                    Delete All
                </Button>
                <hr />
                {this.props.isDiariesSearching ? (
                    <div>
                        <Segment style={{ minHeight: '50vh' }}>
                            <Dimmer active>
                                <Loader size='massive'>Loading</Loader>
                            </Dimmer>
                        </Segment>
                    </div>
                ) : (
                    <ReactTable
                        data={tableData}
                        columns={columns}
                        filterable={true}
                        defaultFilterMethod={(filter, row, column) => {
                            const id = filter.pivotId || filter.id;
                            return row[id] !== undefined
                                ? String(row[id])
                                    .toLowerCase()
                                    .indexOf(filter.value.toLowerCase()) > -1
                                : true;
                        }}
                        className="feature-table"
                    />
                )}
            </div>
        )
    }
}

export default Dashboard;
