import { returnLandUnitsExpression, returnMultiFilterExpression, returnCircleOpacity, returnIsFiltered, returnIsVisible } from './MapUtil';
import { setMapLoadStatus } from '../../../actions/actions_map';

export function removeIcons(map, filters, date, units, currentIcons) {
    // const circleRadius = returnMultiFilterExpression(
    //   filters,
    //   date,
    //   units[date],
    //   map.getZoom(),
    //   'circle'
    // )
    // map.setPaintProperty('land_units_radial', 'circle-radius', circleRadius)
    // map.setPaintProperty('land_units_radial', 'circle-opacity', returnCircleOpacity(filters, units[date]));
    // currentIcons.forEach((popup, index) => {
    //     popup.remove()
    // });
}

export function toggleLayerOverlap(map, date, layerOverlap) {
  if(layerOverlap) {
    map.setLayoutProperty('land_units_text_'+date, 'text-allow-overlap', true);
    map.setLayoutProperty('land_units_text_'+date, 'text-ignore-placement', true);
  } else {
    map.setLayoutProperty('land_units_text_'+date, 'text-allow-overlap', false);
    map.setLayoutProperty('land_units_text_'+date, 'text-ignore-placement', false);
  }
}

export function addIcons(map, props, prevDate, nextDate, iconType) {
    let newPopups = [];

    // Change icon type as needed
    //console.log(props);
    if(iconType === 'icons-simple') {
      map.setLayoutProperty('land_units_'+nextDate, 'text-font', ['P44Silhouettes Regular']);
      map.setLayoutProperty('land_units_'+nextDate, 'text-size', returnLandUnitsExpression(props.currentFilters, props.currentSelectedUnits, 'icons-simple'));
      map.setLayoutProperty('land_units_text_'+nextDate, 'text-size', returnLandUnitsExpression(props.currentFilters, props.currentSelectedUnits, 'text'));
    } else if(iconType === 'icons-allied') {
      map.setLayoutProperty('land_units_'+nextDate, 'text-font', ['P44AlliedMarkers Regular']);
      map.setLayoutProperty('land_units_'+nextDate, 'text-size', returnLandUnitsExpression(props.currentFilters, props.currentSelectedUnits, 'icons-allied'));
      map.setLayoutProperty('land_units_text_'+nextDate, 'text-size', returnLandUnitsExpression(props.currentFilters, props.currentSelectedUnits, 'text'));
    } else if(iconType === 'icons-german') {
      map.setLayoutProperty('land_units_'+nextDate, 'text-font', ['P44GermanMarkers Regular']);
      map.setLayoutProperty('land_units_'+nextDate, 'text-size', returnLandUnitsExpression(props.currentFilters, props.currentSelectedUnits, 'icons-german'));
      map.setLayoutProperty('land_units_text_'+nextDate, 'text-size', returnLandUnitsExpression(props.currentFilters, props.currentSelectedUnits, 'text'));
    } else if(iconType === 'icons-nato') {
      map.setLayoutProperty('land_units_'+nextDate, 'text-font', ['P44NatoMarker Regular']);
      map.setLayoutProperty('land_units_'+nextDate, 'text-size', returnLandUnitsExpression(props.currentFilters, props.currentSelectedUnits, 'icons-nato'));
      map.setLayoutProperty('land_units_text_'+nextDate, 'text-size', returnLandUnitsExpression(props.currentFilters, props.currentSelectedUnits, 'text'));
    }

    // Overlapping text
    if(map.getLayoutProperty('land_units_text_'+prevDate, 'text-allow-overlap')) {
      map.setLayoutProperty('land_units_text_'+nextDate, 'text-allow-overlap', true)
      map.setLayoutProperty('land_units_text_'+nextDate, 'text-ignore-placement', true);
    } else {
      map.setLayoutProperty('land_units_text_'+nextDate, 'text-allow-overlap', false)
      map.setLayoutProperty('land_units_text_'+nextDate, 'text-ignore-placement', false);
    }

    map.setLayoutProperty('land_units_'+prevDate, 'visibility', 'none');
    map.setLayoutProperty('land_units_'+nextDate, 'visibility', 'visible');
    // Marker label visible or not
    if(map.getLayoutProperty('land_units_text_'+prevDate, 'visibility') === 'none') {
      map.setLayoutProperty('land_units_text_'+nextDate, 'visibility', 'none');
    } else {
      map.setLayoutProperty('land_units_text_'+prevDate, 'visibility', 'none');
      map.setLayoutProperty('land_units_text_'+nextDate, 'visibility', 'visible');
    }


    if (props.mapLoadStatus.loadPercentage < 101) {
        setTimeout(() => {
          props.dispatch(setMapLoadStatus({
            areIconsLoaded: true,
            loadPercentage: props.mapLoadStatus.loadPercentage + 50
          }))
        }, 1000)
    }

    return newPopups;
}

export function toggleMarkerLabels(map, date) {
  if(map.getLayoutProperty('land_units_text_'+date, 'visibility') === 'none') {
    map.setLayoutProperty('land_units_text_'+date, 'visibility', 'visible');
  } else {
    map.setLayoutProperty('land_units_text_'+date, 'visibility', 'none');
  }
}
