import { connect } from 'react-redux'

import DashboardContainerComponent from '../../components/Dashboard/DashboardContainer.js'

const mapStateToProps = state => {
  return {
    allDates : state.mainReducer.dates.allDates,
    currentDate : state.mainReducer.dates.currentDate,
    allUnits : state.mainReducer.units.allUnits,
    distinctUnits : state.mainReducer.units.distinctUnits,
    allLines : state.mainReducer.lines.allLines,
    allUsers : state.mainReducer.users.allUsers,
    currentUser : state.mainReducer.users.currentUser,
    allDiaries: state.mainReducer.diaries.allDiaries,
    allPois : state.mainReducer.pois.allPois,
    allPoisFetched : state.mainReducer.pois.fetched,
    auth: state.mainReducer.auth,
    error: state.mainReducer.error,
    info: state.mainReducer.info,
    isDiariesSearching: state.mainReducer.diaries.isSearching,
    allUserCreatedUnits: state.mainReducer.user_created_units.allUserCreatedUnits,
    customDataJson: state.mainReducer.custom_data.json,
    allBlogs : state.mainReducer.blogs.allBlogs
  }
}

const DashboardContainer = connect(
  mapStateToProps
)(DashboardContainerComponent)

export default DashboardContainer
