import Moment from 'moment';
import { extendMoment } from 'moment-range';

import Constants from '../Constants.js';
import { getUnitRangeByMonthYear, getUnits } from './actions_units.js';

export const GET_ALL_DATES = 'GET_ALL_DATES';
export const SET_CURRENT_DATE = 'SET_CURRENT_DATE';
export const DATE_ERROR = 'DATE_ERROR';
export const SET_MONTHS_FETCHED = 'SET_MONTHS_FETCHED';

const moment = extendMoment(Moment);

export function getAllDates(initialLoad) {
    return (dispatch) => {
        return fetch(`${process.env.REACT_APP_BACKEND}/dates/range`, {
                method: 'GET',
                // headers: header
            })
            .then(response => Constants.handleErrors(response, dispatch, dateError))
            .then(json => {
                const range = moment.range([moment(json.min, 'YYYY-MM-DD'), moment(json.max, 'YYYY-MM-DD')])
                const datesToAdd = Array.from(range.by('day')).map((day) => moment(day).format('YYYY-MM-DD'))
                dispatch(addDates(datesToAdd))
                if (initialLoad) {
                    // june
                    dispatch(getUnitRangeByMonthYear('1944-06-01'))
                    dispatch(setMonthsFetched('06'))
                    // push this to the end of the execution stack
                    setTimeout(() => {
                        // july
                        dispatch(getUnitRangeByMonthYear('1944-07-01'))
                        dispatch(setMonthsFetched('07'))
                        // august
                        dispatch(getUnitRangeByMonthYear('1944-08-01'))
                        dispatch(setMonthsFetched('08'))
                    }, 0);
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(dateError(error))
            });
    }
}


export function addDates(data) {
    return {
        type: GET_ALL_DATES,
        payload: data
    }
}

export function setCurrentDate(nextDate) {
    return (dispatch, getState) => {
        // removed because we're fetching all the units from the beginning now
        const { dates } = getState().mainReducer
        const nextFullDate = moment(nextDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
        const nextMonthYearOnly = moment(nextDate, 'YYYY-MM-DD').format('YYYY-MM')
        const currentMonthYear = moment(dates.currentDate, 'YYYY-MM-DD').format('YYYY-MM')

        if (!dates.monthsFetched.includes(nextMonthYearOnly) && nextMonthYearOnly !== currentMonthYear) {
            dispatch(getUnitRangeByMonthYear(nextFullDate))
            dispatch(setMonthsFetched(nextMonthYearOnly))
        }
        dispatch({
            type: SET_CURRENT_DATE,
            payload: nextDate
        })
    }
}

export function dateError(error) {
    return {
        type: DATE_ERROR,
        payload: error
    }
}

export function setMonthsFetched(month) {
    return {
        type: SET_MONTHS_FETCHED,
        payload: month
    }
}
