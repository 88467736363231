import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translate, withLocalize } from "react-localize-redux";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { logoutUser } from '../../actions/actions_auth'
import { ROLE_NO_AUTH } from '../../Constants';

import Account from '../../containers/Dashboard/AccountContainer';
import Login from '../../containers/Login/LoginContainer';

class Navigation extends Component {
    state = {
        currentWarDiary: null,
        collapsed: true
    }

    toggleNavbar = () => {
        this.setState({
          collapsed: !this.state.collapsed
        });
      }

    userNav = () => {
        if ((this.props.auth && (!this.props.auth.userRole || this.props.auth.userRole === ROLE_NO_AUTH))
            || (this.props.users && !this.props.users.currentUser)) {
            return (
                <NavItem onClick={this.props.toggleLoginModal} >
                    <Login loginModal={this.props.loginModal} closeLoginModal={this.props.toggleLoginModal} {...this.props}></Login>
                </NavItem>
            )
        } else if (this.props.auth && this.props.auth.userRole && this.props.auth.userRole !== ROLE_NO_AUTH) {
            return (
            <NavItem onClick={this.props.toggleAccountModal} >
                Account
                <Account accountModal={this.props.accountModal} closeAccountModal={this.props.toggleAccountModal} {...this.props}></Account>
            </NavItem>
            )
        }
    }
    render() {
        return (
            <Navbar color="dark" dark className="main-nav" expand="md">
                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                <NavbarBrand></NavbarBrand>
                <Collapse isOpen={!this.state.collapsed} navbar>
                    <Nav navbar className="ml-auto">
                        {this.props.location.pathname === '/' || this.props.location.pathname === '/story-maps' ?
                            false
                        : <NavItem>
                            <Link to="/">
                            Map
                            </Link>
                        </NavItem>}
                        <NavItem className="capitalize" >
                            <a target="_blank" href="https://www.project44.ca"><Translate id="about" /></a>
                        </NavItem>
                        <NavItem className="capitalize" >
                            <a target="_blank" href="https://www.project44.ca/intel"><Translate id="intel" /></a>
                        </NavItem>
                        <NavItem className="capitalize" >
                            <a target="_blank" href="https://www.project44.ca/education"><Translate id="education" /></a>
                        </NavItem>
                        <NavItem className="capitalize" >
                            <a target="_blank" href="https://secure.squarespace.com/checkout/donate?donatePageId=5c8aa665eef1a10f5a1b748a&ss_cid=250d0b71-f7fe-4e93-bd4b-237f00cd144f&ss_cvisit=1570594888664&ss_cvr=31e2a7a5-435e-4fc2-8e01-2863dc4f217f%7C1570459780820%7C1570499989346%7C1570594888578%7C3"><Translate id="donate" /></a>
                        </NavItem>
                        <NavItem className="capitalize" >
                            <a target="_blank" href="http://store.project44.ca/"><Translate id="store" /></a>
                        </NavItem>

                        {window.innerWidth > 767 && this.userNav()}

                        {this.props.activeLanguage ?
                          <NavItem onClick={() => {
                            var newLangCode = this.props.activeLanguage.code==='en' ? 'fr' : 'en';
                            this.props.setActiveLanguage(newLangCode);
                            if (window.history.pushState) {
                                var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?lang='+newLangCode;
                                window.history.pushState({path:newurl},'',newurl);
                            }
                          }} className="capitalize">
                            {this.props.activeLanguage.code==='en' ? 'FR' : 'EN'}
                          </NavItem>
                        : false }
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

export default withLocalize(Navigation);
