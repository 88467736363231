import {
    SET_CUSTOM_DATA,
    DELETE_CUSTOM_DATA,
    EDIT_CUSTOM_DATA
} from "../actions/actions_custom_data";

const initialState = {
    json: false
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_CUSTOM_DATA:
            return {
                ...state,
                json: payload
            };
        case DELETE_CUSTOM_DATA:
            return {
                ...state,
                json: state.json.filter((feature) => {
                    const ids = payload
                    return !ids.find(id => id === feature.properties.feature_id)
                })
            }
        case EDIT_CUSTOM_DATA:
            return {
                ...state,
                json: state.json.reduce((allFeatures, feature) => {
                    const editedFeature = payload.find((f) => f.properties.feature_id === feature.properties.feature_id)
                    allFeatures.push(editedFeature || feature)
                    return allFeatures
                }, [])
            }
        default:
            return state;
    }
};
