import React, { Component } from "react"
import ReactTable from "react-table"
import { Button, Form, Input, Select, Accordion, Icon, Message } from "semantic-ui-react"
import "react-table/react-table.css"
import isEqual from 'lodash.isequal'

import { getCustomData, patchCustomData, deleteCustomData } from "../../actions/actions_custom_data"
import { CUSTOM_DATA_FIELDS, consoleLogDev } from "../../Constants"

class CustomDataList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            activeIndex: 0,
            featureDetails: {},
            errors: [],
            infos: [],
            icons: {}
        }
    }

    componentDidMount() {
        this.props.dispatch(getCustomData())
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(nextProps.customDataJson, this.props.customDataJson)) {
            const featureDetails = {}
            const tableData = nextProps.customDataJson && nextProps.customDataJson.reduce((uniqueFeatures, feature) => {
                if (!uniqueFeatures.find((f) => f.properties.name === feature.properties.name)) {
                    uniqueFeatures.push(feature)
                }

                featureDetails[feature.properties.name] = !featureDetails[feature.properties.name] ?
                    [feature] : [...featureDetails[feature.properties.name], feature]
                return uniqueFeatures
            }, [])
            this.setState({
                tableData,
                featureDetails
            })
        }
    }

    uploadToCloudinary = async (id, name) => {
        const file = this.state.icons[id]
        if (!file) {
            this.setState({ infos: [{ msg: 'No file for icon upload found.' }] })
            return
        } else {
            const formData = new FormData()
            formData.append('upload_preset', 'ml_default')
            formData.append('tags', 'p44_browser_upload')
            formData.append('file', file)
            formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY)
            const res = await fetch(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`, {
                method: 'POST',
                body: formData
            })
            const { secure_url } = await res.json()
            const icons = {
                ...this.state.icons,
                [id]: secure_url
            }
            this.setState({
                icons,
                infos: [{ msg: 'New icon uploaded! Saving feature.' }]
            })

            setTimeout(() => {
                this.handleSave(name);
            }, 3000);
        }
    }

    iconChangeHandler = (e, { name }) => {
        const id = name.split('-')[0]
        const icons = {
            ...this.state.icons,
            [id]: e.target.files && e.target.files[0]
        }
        this.setState({
            icons,
            infos: [{ msg: 'Click "Upload new icon" to upload your icon.' }]
        })
    }

    resetInfos = (millis = 5000) => {
        setTimeout(() => {
            this.setState({ infos: [] })
        }, millis)
    }

    handleSave = (name) => {
        const newFeature = this.state.tableData.find(f => f.properties.name === name)
        const id = newFeature.properties.feature_id
        const icon = this.state.icons[id] && typeof this.state.icons[id] === 'string' ? this.state.icons[id] : undefined

        const allNewFeatures = this.state.featureDetails[name].map(feature => {
            if (icon) { feature.properties.icon = icon }
            feature.properties.properties_map = newFeature.properties.properties_map;
            feature.properties.properties.properties_map = newFeature.properties.properties_map;
            return feature;
        });

        this.props.dispatch(patchCustomData(allNewFeatures))
        this.setState({ infos: [{ msg: `Feature: ${name} was updated.` }] })
        this.resetInfos()
    }

    handleDelete = (name) => {
        if (window.confirm('Are you sure you want to delete this?')) {
            const featureIds = this.state.featureDetails[name].map(({ properties }) => properties.feature_id)
            this.props.dispatch(deleteCustomData(featureIds))
            this.setState({ infos: [{ msg: `Feature: ${name} was deleted.` }] })
            this.resetInfos()
        }
    }

    onSelectChange = (e, { value, name }) => {
        const tableData = [...this.state.tableData].reduce((allFeatures, currentFeature) => {
            const feature_id = Number(name.split('-')[0])
            const propToChange = name.split('-')[1]
            if (currentFeature.properties.feature_id === feature_id) {
                currentFeature.properties.properties_map[propToChange] = value
            }

            allFeatures.push(currentFeature)
            return allFeatures
        }, [])
        this.setState({ tableData })
    }

    getSelectValue = (feature, propKey) => {
        return this.state.tableData.find((f) => {
            return feature.properties.feature_id === f.properties.feature_id
        })
            .properties
            .properties_map[propKey]
    }

    handleAccordianClick = (e, { index }) => {
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    render() {
        const { tableData } = this.state
        const columns = [
            { Header: "Name", accessor: "properties.name" },
            {
                Header: "Icon",
                accessor: "properties.icon",
                Cell: ({ original: feature }) => {
                    return (
                        <div>
                            <img className="custom-data-icon" src={feature.properties.icon} />
                        </div>
                    )
                }
            },
            {
                Header: "Mapped Properties",
                accessor: "properties.properties_map",
                Cell: ({ original: feature }) => {
                    return (
                        <div>
                            <Accordion>
                                <Accordion.Title
                                    active={this.state.activeIndex === feature.properties.feature_id}
                                    index={feature.properties.feature_id}
                                    onClick={this.handleAccordianClick}
                                >
                                    <Icon name="dropdown" />
                                    Show properties
                                </Accordion.Title>
                                <Accordion.Content active={this.state.activeIndex === feature.properties.feature_id}>
                                    <Form className="props">
                                        {
                                            Object.keys(feature.properties.properties_map).map((propKey) => {
                                                const name = feature.properties.feature_id + '-' + propKey
                                                if (propKey === 'name') {
                                                    return (
                                                        <Form.Field inline key={name}>
                                                            <label style={{ width: 80 }}>{propKey}</label>
                                                            <Input
                                                                name={name}
                                                                style={{ width: 250 }}
                                                                value={feature.properties.properties_map[propKey]}
                                                            />
                                                        </Form.Field>
                                                    )
                                                } else {
                                                    const currValues = Object.values(feature.properties.properties_map)
                                                    const uniqueTest = typeCandidate => currValues.some(type => type === typeCandidate)
                                                    const options = Object.entries(CUSTOM_DATA_FIELDS).map(([fieldType, value]) => {
                                                        const isNotUnique = uniqueTest(fieldType)
                                                        return {
                                                            text: value,
                                                            key: fieldType,
                                                            value: fieldType,
                                                            disabled: isNotUnique
                                                        }
                                                    }).sort((a, b) => {
                                                        if (a.disabled) return 1
                                                        if (b.disabled) return -1
                                                        return 0
                                                    })
                                                    const value = this.getSelectValue(feature, propKey)
                                                    return (
                                                        <Form.Field inline key={name}>
                                                            <label style={{ width: 80 }}>{propKey}</label>
                                                            <Select
                                                                name={name}
                                                                placeholder="Select field type"
                                                                options={options}
                                                                selection
                                                                onChange={this.onSelectChange}
                                                                value={value}
                                                                clearable
                                                            />
                                                        </Form.Field>
                                                    )
                                                }
                                            })
                                        }
                                    </Form>
                                </Accordion.Content>
                            </Accordion>
                        </div>
                    )
                }
            },
            {
                filterable: false,
                Cell: ({ original: feature }) => (
                    <div>
                        <div style={{ display: 'flex' }}>
                            <Button
                                positive
                                style={{ flex: 1 }}
                                onClick={() => this.handleSave(feature.properties.name)}
                            > Save </Button>
                            <Button
                                negative
                                style={{ flex: 1 }}
                                onClick={() => this.handleDelete(feature.properties.name)}
                            > Delete </Button>
                        </div>
                        <div style={{ marginTop: 10, marginBottom: 10 }}>
                            <Input
                                name={feature.properties.feature_id + '-icon'}
                                type="file"
                                style={{ display: 'flex', flexDirection: 'column-reverse' }}
                                onChange={this.iconChangeHandler}
                                action={{
                                    icon: "upload",
                                    onClick: () => this.uploadToCloudinary(feature.properties.feature_id, feature.properties.name),
                                    color: this.state.icons[feature.properties.feature_id] ? "teal" : "grey",
                                    content: "Upload new icon",
                                }}
                                accept=".png, .jpg, .jpeg"
                            />
                        </div>
                    </div>
                )
            }
        ]

        return (
            <div className="custom-data-list-page" style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                    {this.state.errors.length > 0 &&
                        this.state.errors.map(error => {
                            const content = error.line
                                ? `Line ${error.line}: ${error.msg}`
                                : error.feature
                                    ? `${error.msg}\n\n${JSON.stringify(error.feature, null, 4)}`
                                    : error.msg;
                            return <Message error children={<pre>{content}</pre>} key={error.msg + error.line} />;
                        })}
                    {this.state.infos.length > 0 &&
                        this.state.infos.map(info => {
                            return <Message info content={info.msg} key={info.msg + info.line} />;
                        })}
                </div>
                <div>
                    {tableData && <ReactTable
                        data={tableData}
                        columns={columns}
                        filterable={true}
                        defaultFilterMethod={(filter, row, column) => {
                            const id = filter.pivotId || filter.id
                            return row[id] !== undefined
                                ? String(row[id])
                                    .toLowerCase()
                                    .indexOf(filter.value.toLowerCase()) > -1
                                : true
                        }}
                        className="feature-table"
                    />}
                </div>
            </div>
        )
    }
}

export default CustomDataList
